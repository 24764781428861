import React from "react";
import {
  Box, Button,
  createStyles, Dialog, MenuItem,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {colors} from "../AppTheme";
import {ProviderOutput} from "../types";
import {ScrollbarList} from "./ScrollbarList";
import {client} from "../Apollo/ApolloClient";
import {DoctorAddressViewOutput} from "../enrollment-types";
import * as _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: 8,
      paddingBottom: 6,
      minWidth: 441,
      position: 'relative'
    },
    title: {
      textAlign: 'left',
      lineHeight: '25px',
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 16,
      marginLeft: 10,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 300,
    },
    radio: {
      background: '#F5F6F7',
    },
    info: {
      background: '#F5F6F7',
      paddingLeft: 30,
      marginTop: 20,
    },
    link: {
      color: colors.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    scrollbar: {
      maxHeight: '400px'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    item: {
      height: 37,
      '&:not(:last-child)': {
        borderBottom: '1px solid #DDDDDD'
      }
    }
  })
);

type AddressSelectionModalProps = {
  onClose: (addresses?: DoctorAddressViewOutput[]) => void,
  open: boolean,
  doctor?: ProviderOutput
}

export default function AddressSelectionModal(props: AddressSelectionModalProps) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [addresses, setAddresses] = React.useState<DoctorAddressViewOutput[]>([]);
  const matches = useMediaQuery('(min-height:600px)');

  const [getAddresses] = useLazyQuery<{findProviderAddressesByNpi: DoctorAddressViewOutput[]}, {npi: string}>(gql(getAddressesQuery), {
    onCompleted: data => setAddresses(data.findProviderAddressesByNpi),
    fetchPolicy: 'no-cache',
    client
  });

  React.useEffect(() => {
    if (props.open && props.doctor) {
      getAddresses({variables: {npi: props.doctor.npi}})
      setSelected(props.doctor.addresses.map(a => a.id));
    } else {
      setSelected([]);
      setAddresses([]);
    }
  }, [props.open, props.doctor])

  const height = React.useMemo(() => {
    if (!matches || !addresses.length) {
      return undefined
    } else {
      return addresses.length * 37 + 8;
    }
  }, [matches, addresses])

  const onClick = (id: string) => {
    setSelected(prev => {
      const index = prev.indexOf(id);
      if (index >= 0) {
        return _.without(prev, id);
      } else {
        return [...prev, id];
      }
    })
  }

  const isSelected = React.useCallback((id: string) => {
    return selected.includes(id)
  }, [selected])

  const save = () => {
    props.onClose(addresses.filter(a => selected.includes(a.id)))
  }

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
  >
    <>
      {props.doctor && <div className={classes.wrapper}>
        <Box position={'absolute'} display={'flex'} right={10}>
          <Button onClick={() => props.onClose()} variant={'outlined'} className={'mr-10'} size={'small'}>CLOSE</Button>
          <Button onClick={save} variant={'contained'} color={'primary'} size={'small'}>SAVE</Button>
        </Box>
        <Typography color={'textPrimary'} className={classes.title}>Choose doctor office</Typography>
        {!!addresses.length && <ScrollbarList height={height} className={classes.scrollbar}>
              {addresses.map(address => <MenuItem key={address.id} className={classes.item} selected={isSelected(address.id)} onClick={() => onClick(address.id)}>
                <Typography className={`fs-13 ${isSelected(address.id) && 'bold'}`} color={'textPrimary'}>{[address.addressLine1, address.addressLine2].join(', ')}</Typography>
              </MenuItem>)}
        </ScrollbarList>}
      </div>
      }
    </>
  </Dialog>
}
const getAddressesQuery = `
query ($npi: String!) {
  findProviderAddressesByNpi(npi: $npi) {
    addressLine1
    addressLine2
    id
    specialty
  }
}
`
