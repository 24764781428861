import Card from "../../shared/Card";
import React from "react";
import PersonalInfoForm, {PersonalInfoFormValues} from "../components/PersonalInfoForm";
import {GraphQLErrorType} from "../../Apollo/EnrollmentApolloClient";
import {useSnackbar} from "notistack";
import { ProfileOption} from "../../enrollment-types";
import useSaveUserProfile from "../hooks/useSaveUserProfile";
import {Box} from "@material-ui/core";
import {useHistory} from "react-router";
import paths from "../../router-paths";
import useClientIdByProfileId from "../hooks/useClientIdByProfileId";
import moment from "moment";

const CreateClientForm = () => {
  const {enqueueSnackbar} = useSnackbar();
  let history = useHistory();

  const [getClientId] = useClientIdByProfileId()
  const [create, data] = useSaveUserProfile({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = 'Looks like that email is already in use.';
          break;
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: (data) => {
      enqueueSnackbar('User saved!', {variant: "success"});
      getClientId({variables: {profileId: data.saveUserProfile.profileId}})
        .then(response => history.push(paths.client + '/' + response.data?.clientIdByProfileId))
    },
  })

  const onSubmit = (values: PersonalInfoFormValues) => {
    return create({
      variables: {
        data: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          gender: values.gender,
          zip: values.zip,
          countyName: values.county,
          state: values.state,
          phoneNumber: values.phoneNumber,
          options: values.email ? [ProfileOption.RegisterUser] : undefined,
        }
      }
    })
  };

  return <Box maxWidth={600}>
    <Card>
      <PersonalInfoForm onSubmit={onSubmit}
                       onCancel={() => history.push(paths.clients)}
                       loading={data.loading}
      />
    </Card>
  </Box>
}

export default CreateClientForm;
