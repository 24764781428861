import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Card from "../../shared/Card";
import {
  Box,
  Typography
} from "@material-ui/core";
import {DoctorSelection} from "../../shared/DoctorSelection";
import {MedicareQuoteFilterExtraBenefit} from "../../types";
import {ClientViewOutput, DoctorViewOutput} from "../../enrollment-types";
import useSaveQuote from "../hooks/useSaveQuote";
import {GraphQLErrorType} from "../../Apollo/EnrollmentApolloClient";
import {useSnackbar} from "notistack";
import useCreateMedicareQuoteForClient from "../hooks/useCreateMedicareQuoteForClient";
import {config} from "../../config/config";

interface UserPrioritiesProps {
  client?: ClientViewOutput
}

const Doctors = ({client}: UserPrioritiesProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const [selectedDoctors, setSelectedDoctors] = React.useState<DoctorViewOutput[]>([]);
  const [createQuote] = useCreateMedicareQuoteForClient();
  const [save] = useSaveQuote({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Changes saved!', {variant: "success"});
    }
  });

  React.useEffect(() => {
    if (client?.doctors) {
      setSelectedDoctors(client?.doctors)
    }
  }, [client?.doctors]);

  const onDoctorsChange = async (doctors: DoctorViewOutput[]) => {
    if (client) {
      let quoteId = client.quoteId;
      if (!quoteId) {
        const result = await createQuote({
          variables: {
            clientId: client.id,
            data: {
              county: client.countyName,
              zip: client.zip,
              filters: {
                extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
              },
            }
          }
        });
        quoteId = result.data?.createMedicareQuoteForClient.maQuoteID;
      }
      await save({variables: {
          data: {
            id: quoteId,
            zip: client.zip,
            county: client.countyName,
            preferredDoctors: doctors.map(d => ({
              npi: d.npi,
              addresses: d.addresses.map(a => ({addressId: a.id}))
            }))
          }
        }})
    }
  }

  return <Card>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Typography variant={'h4'} className={'mb-5'}>Doctors:</Typography>
      <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.userFlowUrl}private?tag=medicare&magic-link=true`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>
    </Box>
    <DoctorSelection zip={client?.zip || ''} selectedDoctors={selectedDoctors} onChange={onDoctorsChange} />
  </Card>;
}

export default Doctors;

