import React from "react";
import {
  AppBar,
  Box, Button, Card, Container,
  createStyles, Drawer,
  makeStyles, MenuItem, MenuList,
  Theme, Toolbar, Typography, useScrollTrigger,
} from "@material-ui/core";
import {BrowserRouter, Link, Route, Switch, useHistory, useLocation} from "react-router-dom";
import paths from "./router-paths";
import withErrorBoundary from "./shared/withErrorBoundary";
import CssBaseline from '@material-ui/core/CssBaseline';
import {keycloak, KeycloakRoles} from "./shared/keycloak";
import RequestList from "./Request/RequestList";
import RequestDetails from "./Request/RequestDetails";
import PlanDetails from "./PlanDetails/PlanDetails";
import EnrollmentList from "./Enrollment/EnrollmentList";
import GlobalSearch from "./shared/GlobalSearch";
import ClientDetails from "./Client/ClientDetails";
import ClientList from "./Client/ClientList";
import VideoCategories from "./Video/VideoCategories";
import VideoList from "./Video/VideoList";
import {CreateVideoForm, UpdateVideoForm} from "./Video/VideoForm";
import VideoCategoryForm from "./Video/VideoCategoryForm";
import Tags from "./Tags/Tags";
import FilesList from "./Files/FilesList";
import AttemptedLeads from "./AttemptedLeads/AttemptedLeads";
import {useSubscription} from "@apollo/client";
import {NewLeadsStatisticsOutput, PageableSlaOutput} from "./enrollment-types";
import {gql} from "@apollo/client";
import moment from "moment";
import useInterval from "./shared/useInterval";
import TwoHourSLA from "./SLA/2HourSLA";
import useUrgentCallRequests from "./UrgentCalls/hooks/useUrgentCallRequests";
import UrgentCalls from "./UrgentCalls/UrgentCalls";
import MaEnrollmentEditForm from "./Client/enrollment/MaEnrollmentEditForm";
import MgEnrollmentEditForm from "./Client/enrollment/MgEnrollmentEditForm";
import PdpEnrollmentEditForm from "./Client/enrollment/PdpEnrollmentEditForm";
import CreateClientForm from "./Client/widgets/CreateClientForm";
import AgentList from "./Agent/AgentList";
import AgentForm from "./Agent/AgentForm";
import Dashboard from "./Dashboard/Dashboard";
import * as _ from 'lodash';
import Integrations, {GmailVerificationCode} from "./Integrations/Integrations";
import MissingApps from "./MissingApps/MissingApps";
import PlanCoverage from "./PlanCoverage/PlanCoverage";

function ElevationScroll({children}: any) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '20px',
      position: 'relative',
      width: '100%',
    },
    logoutButton: {
      color: 'white',
      marginLeft: 54,
      fontWeight: 700,
      cursor: 'pointer',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      position: 'relative',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    drawer: {
      width: 185,
      backgroundColor: 'white',
      paddingTop: 8
    },
    menu: {
      position: 'sticky',
      top: 0
    }
  }),
);

//277821
function Layout() {
  const classes = useStyles();
  let history = useHistory();

  const isAdmin = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN)
  }, [keycloak])

  const isAgentManager = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER)
  }, [keycloak])

  const LeadsStatistics = React.useMemo(() => {
    return isAdmin ? <NewLeadsStatistics /> : null
  }, [isAdmin])

  const CallRequests = React.useMemo(() => {
    return isAdmin ? <UrgentCallRequests /> : null
  }, [isAdmin])

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight: '48px'}}>
            <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant="h2" className={'ml-16 bold'}>CoverRight</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                {isAdmin && <GlobalSearch onOpen={() => {}} onHide={() => {}} />}
                <Typography onClick={() => keycloak.logout()} className={classes.logoutButton}>Logout</Typography>
              </Box>
            </Box>
          </Toolbar>
          {LeadsStatistics}
          <TwoHrCallback />
          {CallRequests}
        </AppBar>
      </ElevationScroll>
      <Box display={'flex'} flex={1}>
        <Box
          className={classes.drawer}
        >
          {isAdmin && <MenuList className={classes.menu}>
            <MenuItem onClick={() => history.push(paths.dashboard)}>Dashboard</MenuItem>
            <MenuItem onClick={() => history.push(paths.clients)}>Clients</MenuItem>
            {/*<MenuItem onClick={() => history.push(paths.enrollments)}>Enrollments</MenuItem>*/}
            <MenuItem onClick={() => history.push(paths.tags)}>Tags</MenuItem>
            <MenuItem onClick={() => history.push(paths.files)}>Files</MenuItem>
            <MenuItem onClick={() => history.push(paths.missingApps)}>Missing Apps</MenuItem>
            <MenuItem onClick={() => history.push(paths.twoHourSla)}>2 Hour SLA</MenuItem>
            <MenuItem onClick={() => history.push(paths.attemptedLeads)}>Attempted Leads</MenuItem>
            {isAgentManager && <MenuItem onClick={() => history.push(paths.agents)}>Agents</MenuItem>}
            <MenuItem onClick={() => history.push(paths.integrations)}>Integrations</MenuItem>
            <MenuItem onClick={() => history.push(paths.planCoverage)}>Plan coverage</MenuItem>
            {!isAgentManager && <MenuItem onClick={() => history.push(paths.agent)}>Agent data</MenuItem>}
          </MenuList>}
          {!isAdmin && <MenuList className={classes.menu}>
            <MenuItem onClick={() => history.push(paths.clients)}>Clients</MenuItem>
          </MenuList>}
        </Box>
        <Box className={classes.container}>
          <Box>
            <Switch>
              <Route exact path={paths.home} component={ClientList}/>
              <Route exact path={paths.requests} component={RequestList}/>
              <Route exact path={paths.videoCategories} component={VideoCategories}/>
              <Route exact path={paths.tags} component={Tags}/>
              <Route exact path={paths.videos + '/:id'} component={VideoList}/>
              <Route exact path={paths.video + '/:id'} component={UpdateVideoForm}/>
              <Route exact path={paths.video} component={CreateVideoForm}/>
              <Route exact path={paths.videoCategory + '/:id?'} component={VideoCategoryForm}/>
              <Route exact path={paths.enrollments} component={EnrollmentList}/>
              <Route exact path={paths.clients} component={ClientList}/>
              <Route exact path={paths.client + '/:id/:tab?/:agentId?/:emailId?'} component={ClientDetails}/>
              <Route exact path={paths.request + '/:id'} component={RequestDetails}/>
              <Route exact path={paths.plan + '/:id'} component={PlanDetails}/>
              <Route exact path={paths.agent + '/:id?'} component={AgentForm}/>
              <Route exact path={paths.agents} component={AgentList}/>
              <Route exact path={paths.dashboard} component={Dashboard}/>
              <Route exact path={paths.files + '/:id'} component={FilesList}/>
              <Route exact path={paths.files} component={FilesList}/>
              <Route exact path={paths.attemptedLeads} component={AttemptedLeads}/>
              <Route exact path={paths.planCoverage} component={PlanCoverage}/>
              <Route exact path={paths.urgentCalls} component={UrgentCalls}/>
              <Route exact path={paths.twoHourSla} component={TwoHourSLA}/>
              <Route exact path={paths.missingApps} component={MissingApps}/>
              <Route exact path={paths.createClient} component={CreateClientForm}/>
              <Route exact path={paths.integrations} component={Integrations}/>
              <Route exact path={paths.integrations + '/gmail'} component={GmailVerificationCode}/>
              <Route exact path={paths.maEnrollmentEdit + '/:clientId/:id?'} component={MaEnrollmentEditForm}/>
              <Route exact path={paths.mgEnrollmentEdit + '/:clientId/:id?'} component={MgEnrollmentEditForm}/>
              <Route exact path={paths.pdpEnrollmentEdit + '/:clientId/:id?'} component={PdpEnrollmentEditForm}/>
            </Switch>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default withErrorBoundary(Layout, true);

const newLeadsStatisticsSubscription = `
  subscription {
    newLeadsStatistics {
      count
      oldestCreatedAt
    }
  }
`

const NewLeadsStatistics = () => {
  const [time, setTime] = React.useState<string>();

  const {data} = useSubscription<{newLeadsStatistics: NewLeadsStatisticsOutput}>(gql(newLeadsStatisticsSubscription));

  useInterval(() => {
    setTime(calcDurationToNow(data?.newLeadsStatistics?.oldestCreatedAt));
  }, 1000);

  if (!data?.newLeadsStatistics?.count) {
    return null
  }

  return <>
    {!!data?.newLeadsStatistics?.count && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#5ecb9e'} p={'10px 16px'}>
      <Typography color={'textPrimary'} className={'medium'}>{data?.newLeadsStatistics?.count} NEW LEADS - Time to oldest: {time}</Typography>
    </Box>}
  </>
}

const TwoHrCallbackSubscription = `
  subscription {
    slas (pageInput: {page: 0, size: 100}, filter: {within2Hours: true, range: {from: "${moment().startOf('month').toISOString()}", to: "${moment().add(1, 'week').toISOString()}"}}) {
      data {
        createdAt
      }
      totalElements
    }
  }
`


const TwoHrCallback = () => {
  const [time, setTime] = React.useState<string>();

  const {data} = useSubscription<{slas: PageableSlaOutput}>(gql(TwoHrCallbackSubscription));

  useInterval(() => {
    const oldestTime = _.min(data?.slas?.data?.map(s => s.createdAt))
    setTime(calcDurationToNow(oldestTime));
  }, 1000);

  const total = React.useMemo(() => {
    return data?.slas?.totalElements ? parseFloat(data?.slas?.totalElements) : 0
  }, [data?.slas?.totalElements])

  if (!total) {
    return null
  }

  return <>
    {!!total && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#f1db40'} p={'10px 16px'}>
      <Typography color={'textPrimary'} className={'medium'}>{data?.slas?.totalElements} Leads Due for 2hr Callback. Most Overdue:  {time}</Typography>
    </Box>}
  </>
}

export function calcDurationToNow(time?: string) {
  if (!time) {
    return '-';
  }

  const f = (v: number) => v < 10 ? '0' + v : v;

  const duration = moment.duration(moment().diff(moment(time))).asMilliseconds()
  var s = Math.floor( (duration/1000) % 60 );
  var m = Math.floor( (duration/1000/60) % 60 );
  var h = Math.floor( (duration/(1000*60*60)) % 24 );
  var d = Math.floor( duration/(1000*60*60*24) );
  let t = f(h) + ':' + f(m) + ':' + f(s);
  if (d > 0) {
    t = d + 'd ' + t;
  }
  return t;
}

const UrgentCallRequests = () => {
  const {data: urgentCallData} = useUrgentCallRequests()
  let history = useHistory();

  if (!urgentCallData?.urgentCallRequests?.length) {
    return null
  }

  return <>
    {!!urgentCallData?.urgentCallRequests?.length && <Box onClick={() => history.push(paths.urgentCalls)} className={'pointer'} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#C00000'} p={'10px 16px'}>
      <Typography className={'bold white'}>URGENT CALL REQUESTS: {urgentCallData?.urgentCallRequests?.length}</Typography>
    </Box>}
  </>
}
