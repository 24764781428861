import {LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  QuerySendInBlueHistoryByEmailArgs,
  SendInBlueContactWithHistory
} from "../../enrollment-types";

export default function useSendInBlueHistoryByEmail(options?: LazyQueryHookOptions<{sendInBlueHistoryByEmail: SendInBlueContactWithHistory}, QuerySendInBlueHistoryByEmailArgs>) {
  return useLazyQuery<{sendInBlueHistoryByEmail: SendInBlueContactWithHistory}, QuerySendInBlueHistoryByEmailArgs>(gql(query), options)
}

const query = `
query($email: String!) {
  sendInBlueHistoryByEmail(email: $email) {
    history {
      date
      fromEmail
      status
      subject
      toEmail
    }
  }
}
`;
