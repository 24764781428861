import {createTheme} from '@material-ui/core/styles';

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
  green: {variant1: string, variant2: string}
} = {
  text: {
    primary: '#242424',
    secondary: '#787885',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "#76A9FF",
    "500": "#4F91FF",
    "600": "#2979FF",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "#133774",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  },
  green: {
    variant1: '#5ECB9E',
    variant2: '#029094'
  }
};


export default createTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.warning["200"],
      main: colors.warning["400"],
      dark: colors.warning["200"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: 13,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      label: {
        whiteSpace: 'nowrap',
      },
      containedPrimary: {
        color: 'white',
        backgroundColor: '#5ECB9E',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'rgba(81, 224, 165, 1)',
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: 'rgba(81, 224, 165, 1)',
          boxShadow: 'none',
        },
      },
      textPrimary: {
        color: '#029094',
      },
      outlinedPrimary: {
        color: '#5ECB9E',
        borderColor: '#5ECB9E',
        '&:hover': {
          color: '#5ECB9E',
          borderColor: '#5ECB9E'
        },
        '&:active': {
          color: '#5ECB9E',
          borderColor: '#5ECB9E'
        },
      }
    },
    MuiChip: {
      root: {
        borderRadius: 5,
        '& .MuiChip-labelSmall': {
          fontSize: 13
        }
      }
    },
    MuiFormLabel: {
      root: {
        textTransform: 'uppercase',
        '&.Mui-focused': {
          color: colors.green.variant2,
          background: 'white',
          paddingRight: 5
        }
      }
    },
    MuiTab: {
      textColorPrimary: {
        '&.Mui-selected': {
          color: colors.green.variant2,
        },
        textTransform: 'none',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colors.green.variant2,
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'rgba(28, 67, 79, 1)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: '48px',
        height: '48px',
        padding: '6px 26px 6px 16px',
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white!important',
        borderRadius: 4,
        border: '1px solid #CCD8DC'
      },
      inputMarginDense: {
        paddingBottom: '9px!important',
        paddingTop: '9px!important'
      }
    }
  },
  typography: {
    fontWeightBold: 500,
    fontFamily: [
      'Sailec', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
    h1: {
      // fontSize: 50,
      fontSize: '29px',
      fontWeight: 400
    },
    h2: {
      fontSize: '24px',
      // fontSize: 32,
      fontWeight: 400
    },
    h3: {
      fontSize: '18px',
      fontWeight: 400,
    } ,
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    //Callout
    h5: {
      fontSize: '12px',
      fontWeight: 400,
    },
    // subtitle2: {
    //   fontSize: 10,
    //   lineHeight: '12px',
    //   color: '#A7A7A7',
    //   textTransform: "uppercase",
    //   marginTop: '4px',
    // } as any,
    body1: {
       fontSize: 13,
    },
    body2: {
       fontSize: 12,
    },
  },
});
