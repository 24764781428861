import {useUploadEnrollmentsImportCsv} from "./hooks/use-upload-enrollments-import-csv";
import React from "react";
import {Box, Button, CircularProgress, Typography} from "@material-ui/core";
import useEnrollmentsImportStates from "./hooks/useEnrollmentsImportStates";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useEnrollmentUploadErrorsPageSize from "./hooks/useEnrollmentUploadErrorsPageSize";
import {useSnackbar} from "notistack";
import useResetImportedEnrollments from "./hooks/useResetImportedEnrollments";


const columns: GridColDef[] = [
  { field: 'errorMessage', headerName: 'ErrorMessage', sortable: false, width: 1200 },
];

const EnrollmentUpload = () => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useEnrollmentUploadErrorsPageSize();
  const [file, setFile] = React.useState<any>();
  const [upload] = useUploadEnrollmentsImportCsv()
  const [reset] = useResetImportedEnrollments()
  const [getEnrollmentsImportStates, data] = useEnrollmentsImportStates()
  const {enqueueSnackbar} = useSnackbar();
  const [uploadErrors, setUploadErrors] = React.useState([]);
  const ref = React.useRef<any>();
  const [rows, setRows] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);

  const onUploadClick = () => {
    setRows([])
    setLoading(true)
    upload({variables: {csv: file}})
      .then(() => {
        enqueueSnackbar('File uploaded!', {variant: "info"});
      })
      .catch(e => {
        enqueueSnackbar('File upload error', {variant: "error"})
        setUploadErrors(e.graphQLErrors?.map((err: any) => err.message))
      })
      .finally(() => {
        setPage(0)
        setFile(undefined)
        if (ref?.current) {
          ref.current.value = '';
        }
        return getEnrollmentsImportStates({variables: {pageInput: {size: 1000, page: 0}}}).finally(() => setLoading(false))
      })
  }

  React.useEffect(() => {
    getEnrollmentsImportStates({variables: {pageInput: {size: 1000, page: 0}}}).finally(() => setLoading(false))
  }, [])

  React.useEffect(() => {
    if (data.data?.enrollmentsImportStates) {
      setRows(data.data?.enrollmentsImportStates?.data)
    }
  }, [data.data?.enrollmentsImportStates])

  const onResetClick = () => {
    reset().then(() => {
      setRows([])
      setPage(0)
      if (ref?.current) {
        ref.current.value = '';
      }
      setUploadErrors([])
      setFile(undefined)
    })
  }

  return <Box height={'76vh'} display={'flex'} flexDirection={'column'} p={'20px 30px'}>
    <Box display={'flex'} alignItems={'center'}>
      <input ref={ref} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
             onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFile((e?.target?.files as any)[0])}
             type="file" />
      <Button disabled={!file || loading}
              onClick={onUploadClick}
              className={'ml-20'}
              variant={'contained'}
              color={'primary'}>UPLOAD</Button>
      {/*<Button onClick={onResetClick}
              disabled={loading}
              className={'ml-20'}
              variant={'contained'}
              color={'secondary'}>RESET</Button>*/}
      {loading && <CircularProgress className={'ml-20'} />}
    </Box>

    <Box my={1}>
      {uploadErrors.map(err => <Typography className={'fs-14 lh-20'} style={{color: 'red'}}>{err}</Typography>)}
    </Box>

    <Box display={'flex'} flex={1} style={{ height: '80vh', width: '100%', position: 'relative' }}>
      <DataGrid rows={rows}
                paginationMode={"client"}
                columns={columns}
                page={page}
                onPageChange={(page) => {
                  setPage(page);
                }}
                rowsPerPageOptions={[20, 50, 100]}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowCount={data.data?.enrollmentsImportStates?.totalElements}
      />
    </Box>
  </Box>
}

export default EnrollmentUpload;