import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationChangeMedigapEnrollmentStatusArgs} from "../../enrollment-types";

export default function useChangeMedigapEnrollmentStatus(options?: MutationHookOptions<{changeMedigapEnrollmentStatus: string}, MutationChangeMedigapEnrollmentStatusArgs>) {
  return useMutation<{changeMedigapEnrollmentStatus: string}, MutationChangeMedigapEnrollmentStatusArgs>(gql(save), options)
}

const save = `
mutation ($enrollmentId: UUID!, $status: MedigapEnrollmentStatus!) {
  changeMedigapEnrollmentStatus(enrollmentId: $enrollmentId, status: $status)
}
`;

