import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveStatusArgs} from "../../enrollment-types";

export default function useSaveStatus(options?: MutationHookOptions<any, MutationSaveStatusArgs>) {
  return useMutation<any, MutationSaveStatusArgs>(gql(saveNoteMutation), options)
}

const saveNoteMutation = `
mutation ($id: UUID!, $status: StatusDto!, $agentId: UUID, $followUpDate: LocalDate) {
  saveStatus(id: $id, status: $status, agentId: $agentId, followUpDate: $followUpDate)
}
`;
