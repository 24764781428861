import { QueryHookOptions, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {DrugInfoWithPack, QueryDrugsWithPackageArgs} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";

export function useDrugsWithPackage(options?: QueryHookOptions<{drugsWithPackage: DrugInfoWithPack[]}, QueryDrugsWithPackageArgs>) {
  return useQuery<{drugsWithPackage: DrugInfoWithPack[]}, QueryDrugsWithPackageArgs>(
    gql(getDrugRequest),
    {
      fetchPolicy: 'no-cache',
      skip: true,
      ...options,
      client: client
    }
  );
}


const getDrugRequest = `
query ($pairs: [ProductPackagePairInput!]!) {
  drugsWithPackage(pairs: $pairs) {
    rxcui
    packRxcui
    packName
    name
    isGeneric
    genericRxcui
    genericName
  }
}
`;
