import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    padding: '10px 15px',
  },
  headerRow: {
    backgroundColor: '#ddd',
    color: '#333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 14px',
  },
  value: {
    whiteSpace: 'pre',
  },
  title: {
    fontWeight: 500,
    width: '50%',
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: 16,
  },
  body: {
    '& > tr:nth-child(even)': {
      backgroundColor: '#f6f6f6',
    }
  },
  arrowIcon: {
    transform: 'rotate(0)',
    transition: 'all 0.3s'
  },
  arrowIconOpen: {
    transform: 'rotate(-180deg) !important',
  },
}));

export default function Section(props: {section: ISection}) {
  const classes = useStyles();
  const [openInfo, setOpenInfo] = React.useState(true);

  return (
    <>
      {props.section.title && <div className={classes.headerRow}>
          <div className={classes.headerTitle}>{props.section.title}</div>
          <div>
            <IconButton size={'medium'} onClick={() => setOpenInfo(s => !s)} className={'t-expand-info'}>
              <KeyboardArrowDownIcon className={clsx(classes.arrowIcon, openInfo && classes.arrowIconOpen)}/>
            </IconButton>
          </div>
        </div>}
      <Collapse in={openInfo}>
        <TableContainer>
          <Table>
              <TableBody className={classes.body}>
                {props.section.params.map((param, index) =>
                  <TableRow id={param.id} key={index}>
                    <TableCell className={clsx(classes.cell, classes.title)}>{param.title}</TableCell>
                    <TableCell className={clsx(classes.cell, classes.value)}>
                      {typeof param.value === 'string' && <div dangerouslySetInnerHTML={{__html: param.value as string}}></div>}
                      {typeof param.value !== 'string' && param.value}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  )
}

export interface ISection {
  title?: string;
  params: ISectionParam[];
}

export interface ISectionParam {
  id?: string,
  title: string;
  value: string | JSX.Element;
}
