import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import TextInput from "../shared/TextInput";
import {useFormik} from "formik";
import * as yup from "yup";
import useSaveVideoCategory from "./hooks/useSaveVideoCategory";
import paths from "../router-paths";
import {RouteComponentProps, useHistory} from "react-router";
import {Button, Grid, Typography} from "@material-ui/core";
import useVideoCategories from "./hooks/useVideoCategories";
import Card from "../shared/Card";
import {VideoCategoryInput} from "../content-management";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
});

const VideoCategoryForm = (props: RouteComponentProps<{ id?: string }>) => {
  const classes = useStyles();
  const {id} = props.match.params;
  const [save] = useSaveVideoCategory();
  let history = useHistory();
  const {data} = useVideoCategories();

  const initial = React.useMemo(() => {
    if (data?.videoCategories && id) {
      return data?.videoCategories.find(c => c.id === id);
    }
    return undefined;
  }, [data, id]);

  React.useEffect(() => {
      if (initial) {
        formik.resetForm();
      }
  }, [initial]);

  const onSaveClick = (values: VideoCategoryInput) => {
    save({variables: {category: {...initial, title: values.title, subTitle: ''}}}).then(() => {
      history.push(paths.videoCategories)
    })
  }

  const formik = useFormik({
    initialValues: {
      title: initial?.title || '',
      subTitle: initial?.subTitle || '',
    },
    validationSchema: validationSchema,
    onSubmit: onSaveClick,
    enableReinitialize: true
  });

  return <Card>
      <Typography color={'textPrimary'} variant={'h4'}>Video category</Typography>
      <form onSubmit={formik.handleSubmit} className={'mt-15'}>
        <TextInput
          fullWidth
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <div className={'h-20'} />
        <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
          <Grid item>
            <Button variant={'outlined'} onClick={() => history.push(paths.videoCategories)}>CANCEL</Button>
          </Grid>
          <Grid item>
            <Button disabled={!formik.isValid} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  ;
}

export default VideoCategoryForm;
