export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: { input: any; output: any; }
  /** A type representing local date */
  LocalDate: { input: any; output: any; }
  /** A type representing long */
  Long: { input: any; output: any; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
};

export type BrandedInfo = {
  __typename?: 'BrandedInfo';
  name: Scalars['String']['output'];
  rxcui: Scalars['String']['output'];
};

export type BrokenAddress = {
  __typename?: 'BrokenAddress';
  address: Scalars['String']['output'];
  geocodingType: GeocodingType;
};

export type ComparablePlan = {
  __typename?: 'ComparablePlan';
  bidId: Scalars['String']['output'];
  county: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  pdpPrimary?: Maybe<Scalars['Boolean']['output']>;
  planYear?: Maybe<PlanYear>;
  type: ComparablePlanType;
  zip: Scalars['String']['output'];
};

export type CompareOffer = {
  __typename?: 'CompareOffer';
  clientId: Scalars['UUID']['output'];
  id?: Maybe<Scalars['UUID']['output']>;
  plans: Array<ComparablePlan>;
};

export type CompareYearToYearOutput = {
  __typename?: 'CompareYearToYearOutput';
  clientId: Scalars['UUID']['output'];
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  currentMaPlanId?: Maybe<Scalars['String']['output']>;
  currentMgNaic?: Maybe<Scalars['String']['output']>;
  currentMgPlanKey?: Maybe<Scalars['String']['output']>;
  currentMgPlanName?: Maybe<Scalars['String']['output']>;
  currentPdpPlanId?: Maybe<Scalars['String']['output']>;
  currentYear?: Maybe<PlanYear>;
  id: Scalars['UUID']['output'];
  isLocked: Scalars['Boolean']['output'];
  maIsHidden: Scalars['Boolean']['output'];
  mgIsHidden: Scalars['Boolean']['output'];
  pdpIsHidden: Scalars['Boolean']['output'];
  suggestedMaPlanId?: Maybe<Scalars['String']['output']>;
  suggestedMgNaic?: Maybe<Scalars['String']['output']>;
  suggestedMgPlanKey?: Maybe<Scalars['String']['output']>;
  suggestedMgPlanName?: Maybe<Scalars['String']['output']>;
  suggestedPdpPlanId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type CostPlusOfferOutput = {
  __typename?: 'CostPlusOfferOutput';
  quantity: Scalars['BigDecimal']['output'];
  rxcui: Scalars['String']['output'];
  unitPrice: Scalars['BigDecimal']['output'];
  url: Scalars['String']['output'];
};

export type CoverageEntryOutput = {
  __typename?: 'CoverageEntryOutput';
  month: Scalars['Int']['output'];
  stage: DrugCalculationStage;
};

export type Doctor = {
  __typename?: 'Doctor';
  businessPracticeLocationFirstLine?: Maybe<Scalars['String']['output']>;
  businessPracticeLocationSecondLine?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  namePrefix?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  officialFirstName?: Maybe<Scalars['String']['output']>;
  officialLastName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type DoctorCoverage = {
  __typename?: 'DoctorCoverage';
  coverage?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  npi: Scalars['String']['output'];
};

export type DrugCostMonthlyBreakdownOutput = {
  __typename?: 'DrugCostMonthlyBreakdownOutput';
  costs: Array<DrugCostOutput>;
  coverageEntry: Array<CoverageEntryOutput>;
  monthlyCosts: Array<MonthlyTotalCost>;
  totalClientCost: Scalars['String']['output'];
};

export type DrugCostOutput = {
  __typename?: 'DrugCostOutput';
  cost: Scalars['String']['output'];
  isGeneric?: Maybe<Scalars['Boolean']['output']>;
  month: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numericCost: Scalars['BigDecimal']['output'];
  packageName: Scalars['String']['output'];
  rxcui: Scalars['String']['output'];
  stage: DrugCalculationStage;
};

export type DrugCoverage = {
  __typename?: 'DrugCoverage';
  coverage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  packName: Scalars['String']['output'];
  packRxcui: Scalars['String']['output'];
  rxcui?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type DrugDiscount = {
  __typename?: 'DrugDiscount';
  carrierCost: Scalars['BigDecimal']['output'];
  costPlusCost?: Maybe<Scalars['BigDecimal']['output']>;
  costPlusDetails?: Maybe<CostPlusOfferOutput>;
  discount?: Maybe<Scalars['BigDecimal']['output']>;
  discountBrand?: Maybe<DrugDiscountBrand>;
  goodRxCost?: Maybe<Scalars['BigDecimal']['output']>;
  goodRxDetails?: Maybe<GoodRxOfferOutput>;
  normalizedQuantity?: Maybe<Scalars['BigDecimal']['output']>;
  rxcui: Scalars['String']['output'];
};

export type DrugInfoWithGeneric = {
  __typename?: 'DrugInfoWithGeneric';
  genericName?: Maybe<Scalars['String']['output']>;
  genericRxcui?: Maybe<Scalars['String']['output']>;
  isGeneric?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rxcui?: Maybe<Scalars['String']['output']>;
};

export type DrugInfoWithPack = {
  __typename?: 'DrugInfoWithPack';
  genericName?: Maybe<Scalars['String']['output']>;
  genericRxcui?: Maybe<Scalars['String']['output']>;
  isGeneric?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packName?: Maybe<Scalars['String']['output']>;
  packRxcui?: Maybe<Scalars['String']['output']>;
  rxcui?: Maybe<Scalars['String']['output']>;
};

export type DrugInfoWithPackagesFlags = {
  __typename?: 'DrugInfoWithPackagesFlags';
  brandedDetails: Array<BrandedInfo>;
  genericName?: Maybe<Scalars['String']['output']>;
  genericRxcui?: Maybe<Scalars['String']['output']>;
  hasBrandedPackages: Scalars['Boolean']['output'];
  hasGenericPackages: Scalars['Boolean']['output'];
  isGeneric: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  rxcui: Scalars['String']['output'];
};

export type DrugOutput = {
  __typename?: 'DrugOutput';
  frequency: CalcDrugFrequencyPeriod;
  genericName?: Maybe<Scalars['String']['output']>;
  genericRxcui?: Maybe<Scalars['String']['output']>;
  isGeneric: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  packName?: Maybe<Scalars['String']['output']>;
  packRxcui: Scalars['String']['output'];
  purchaseFrequency: FrequencyPeriod;
  quantity: Scalars['BigDecimal']['output'];
  rxcui: Scalars['String']['output'];
};

export type DrugPackageOutput = {
  __typename?: 'DrugPackageOutput';
  packageDescription: Scalars['String']['output'];
  unitType: Scalars['String']['output'];
};

export type DrugPriceOutput = {
  __typename?: 'DrugPriceOutput';
  catastrophicPrice: Scalars['String']['output'];
  coverageGapPrice: Scalars['String']['output'];
  initialCoveragePrice: Scalars['String']['output'];
  isAggregatedPrice: Scalars['Boolean']['output'];
  isCoveredByPlan: Scalars['Boolean']['output'];
  isGeneric: Scalars['Boolean']['output'];
  isInsulinDrug: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  packageDescription: Scalars['String']['output'];
  price: Scalars['String']['output'];
  quantityLimit?: Maybe<Scalars['String']['output']>;
  quoteQuantity: Scalars['String']['output'];
  rxcui: Scalars['String']['output'];
  tier?: Maybe<Scalars['Int']['output']>;
  unitPrice: Scalars['String']['output'];
};

export type DrugProductDto = {
  __typename?: 'DrugProductDto';
  activeIngredUnit?: Maybe<Scalars['String']['output']>;
  activeNumeratorStrength?: Maybe<Scalars['String']['output']>;
  applicationNumber?: Maybe<Scalars['String']['output']>;
  deadSchedule?: Maybe<Scalars['String']['output']>;
  dosageFormName?: Maybe<Scalars['String']['output']>;
  endMarketingDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  isGeneric: Scalars['Boolean']['output'];
  labelName?: Maybe<Scalars['String']['output']>;
  listingRecordCertifiedThrough?: Maybe<Scalars['String']['output']>;
  marketingCategoryName?: Maybe<Scalars['String']['output']>;
  ndcExcludeFlag?: Maybe<Scalars['String']['output']>;
  nonProprietaryName?: Maybe<Scalars['String']['output']>;
  pharmClasses?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  productNdc?: Maybe<Scalars['String']['output']>;
  productTypeName?: Maybe<Scalars['String']['output']>;
  proprietaryName?: Maybe<Scalars['String']['output']>;
  proprietaryNameSuffix?: Maybe<Scalars['String']['output']>;
  routeName?: Maybe<Scalars['String']['output']>;
  startMarketingDate?: Maybe<Scalars['String']['output']>;
  substanceName?: Maybe<Scalars['String']['output']>;
};

export type DrugTier = {
  __typename?: 'DrugTier';
  title: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type DrugTierCoverageOutput = {
  __typename?: 'DrugTierCoverageOutput';
  coverage: Scalars['String']['output'];
  tier: DrugTierOutput;
  tierName: Scalars['String']['output'];
};

export type DrugTiersOutput = {
  __typename?: 'DrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
};

export type DrugsDetails = {
  __typename?: 'DrugsDetails';
  catastrophicCopayBranded: Scalars['String']['output'];
  catastrophicCopayGeneric: Scalars['String']['output'];
  catastrophicCoverage: Array<PharmacyTier>;
  catastrophicCoverageDescription: Scalars['String']['output'];
  catastrophicThreshold: Scalars['String']['output'];
  coverageGap: Array<PharmacyTier>;
  coverageGapDescription: Scalars['String']['output'];
  initialCoverage: Array<PharmacyTier>;
  initialCoverageDescription: Scalars['String']['output'];
  initialCoverageLimit: Scalars['String']['output'];
  mrxAltDedAmount: Scalars['String']['output'];
  mrxAltDedAmountShort: Scalars['String']['output'];
  mrxAltNoDedTier: Scalars['String']['output'];
};

export type EnabledPbpContractOutput = {
  __typename?: 'EnabledPbpContractOutput';
  company: Scalars['String']['output'];
  contract: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  parentCompany: Scalars['String']['output'];
};

export type EnabledPbpStatesOutput = {
  __typename?: 'EnabledPbpStatesOutput';
  enabled: Scalars['Boolean']['output'];
  state: AvailableStateDto;
};

export type EnabledPdpContractOutput = {
  __typename?: 'EnabledPdpContractOutput';
  company: Scalars['String']['output'];
  contract: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  parentCompany: Scalars['String']['output'];
};

export type EnabledPdpStatesOutput = {
  __typename?: 'EnabledPdpStatesOutput';
  enabled: Scalars['Boolean']['output'];
  state: AvailableStateDto;
};

export type ExternalId = {
  __typename?: 'ExternalId';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExtraBenefits = {
  __typename?: 'ExtraBenefits';
  dental: Scalars['Boolean']['output'];
  drugCoverage: Scalars['Boolean']['output'];
  fitness: Scalars['Boolean']['output'];
  hearing: Scalars['Boolean']['output'];
  insulinSavings: Scalars['Boolean']['output'];
  overTheCounter: Scalars['Boolean']['output'];
  telehealth: Scalars['Boolean']['output'];
  transportation: Scalars['Boolean']['output'];
  vision: Scalars['Boolean']['output'];
  worldwideEmergency: Scalars['Boolean']['output'];
};

export type ExtraBenefitsWithMaxBenefitAmountOutput = {
  __typename?: 'ExtraBenefitsWithMaxBenefitAmountOutput';
  dental: Scalars['String']['output'];
  fitness: Scalars['String']['output'];
  hearing: Scalars['String']['output'];
  insulinSavings: Scalars['String']['output'];
  overTheCounter: Scalars['String']['output'];
  partB: Scalars['String']['output'];
  telehealth: Scalars['String']['output'];
  transportation: Scalars['String']['output'];
  vision: Scalars['String']['output'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FiltersDto = {
  __typename?: 'FiltersDto';
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  extraBenefits: Array<MedicareQuoteFilterExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']['output']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']['output']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']['output']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']['output']>;
  planTypes?: Maybe<Array<Scalars['String']['output']>>;
  rating?: Maybe<Array<Rating>>;
};

export type GoodRxOfferOutput = {
  __typename?: 'GoodRxOfferOutput';
  dosage: Scalars['String']['output'];
  form: Scalars['String']['output'];
  mobileUrl: Scalars['String']['output'];
  price?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  rxcui: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HospitalityDetails = {
  __typename?: 'HospitalityDetails';
  ambulanceServices: Array<Scalars['String']['output']>;
  emergencyRoom: Scalars['String']['output'];
  urgentServices: Scalars['String']['output'];
};

export type LocationOutput = {
  __typename?: 'LocationOutput';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type ManuallyUploadedPlanFileOutput = {
  __typename?: 'ManuallyUploadedPlanFileOutput';
  bidId: Scalars['String']['output'];
  evidenceOfCoverageDocUrl?: Maybe<Scalars['String']['output']>;
  nondiscriminationNoticeDocUrl?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planYear: PlanYear;
  preEnrollmentChecklistDocUrl?: Maybe<Scalars['String']['output']>;
  starRatingDescDocUrl?: Maybe<Scalars['String']['output']>;
  summaryOfBenefitsUrl?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type MedicarePlansAggregationByCompanyOutput = {
  __typename?: 'MedicarePlansAggregationByCompanyOutput';
  averageRating?: Maybe<Scalars['BigDecimal']['output']>;
  maxOutOfPocketInNetworkMax?: Maybe<Scalars['BigDecimal']['output']>;
  maxOutOfPocketInNetworkMin?: Maybe<Scalars['BigDecimal']['output']>;
  maxOutOfPocketOutOfNetworkMax?: Maybe<Scalars['BigDecimal']['output']>;
  maxOutOfPocketOutOfNetworkMin?: Maybe<Scalars['BigDecimal']['output']>;
  monthlyPremiumMax?: Maybe<Scalars['BigDecimal']['output']>;
  monthlyPremiumMin?: Maybe<Scalars['BigDecimal']['output']>;
  orgName: Scalars['String']['output'];
  parentOrgName: Scalars['String']['output'];
  plansCount: Scalars['Int']['output'];
  primaryCareVisitsInNetworkMax?: Maybe<Scalars['BigDecimal']['output']>;
  primaryCareVisitsInNetworkMin?: Maybe<Scalars['BigDecimal']['output']>;
  primaryCareVisitsOutOfNetworkMax?: Maybe<Scalars['BigDecimal']['output']>;
  primaryCareVisitsOutOfNetworkMin?: Maybe<Scalars['BigDecimal']['output']>;
};

export type MedicarePlansAggregationOutput = {
  __typename?: 'MedicarePlansAggregationOutput';
  deductibleMax?: Maybe<Scalars['String']['output']>;
  deductibleMin?: Maybe<Scalars['String']['output']>;
  doctorInNetworkMax?: Maybe<Scalars['String']['output']>;
  doctorInNetworkMin?: Maybe<Scalars['String']['output']>;
  doctorMax?: Maybe<Scalars['String']['output']>;
  doctorMin?: Maybe<Scalars['String']['output']>;
  doctorOutOfNetworkMax?: Maybe<Scalars['String']['output']>;
  doctorOutOfNetworkMin?: Maybe<Scalars['String']['output']>;
  dsnpPlansExist: Scalars['Boolean']['output'];
  longTermHospitalStayMax?: Maybe<Scalars['String']['output']>;
  longTermHospitalStayMaxDays?: Maybe<Scalars['Int']['output']>;
  longTermHospitalStayMin?: Maybe<Scalars['String']['output']>;
  longTermHospitalStayMinDays?: Maybe<Scalars['Int']['output']>;
  maxOutOfPocketInNetworkMax?: Maybe<Scalars['String']['output']>;
  maxOutOfPocketInNetworkMin?: Maybe<Scalars['String']['output']>;
  maxOutOfPocketOutOfNetworkMax?: Maybe<Scalars['String']['output']>;
  maxOutOfPocketOutOfNetworkMin?: Maybe<Scalars['String']['output']>;
  mmpHmoPlansExist: Scalars['Boolean']['output'];
  monthlyPremiumMax?: Maybe<Scalars['String']['output']>;
  monthlyPremiumMin?: Maybe<Scalars['String']['output']>;
  shortTermHospitalStayMax?: Maybe<Scalars['String']['output']>;
  shortTermHospitalStayMaxDays?: Maybe<Scalars['Int']['output']>;
  shortTermHospitalStayMin?: Maybe<Scalars['String']['output']>;
  shortTermHospitalStayMinDays?: Maybe<Scalars['Int']['output']>;
  tooFewMPDPPlans: Scalars['Boolean']['output'];
  tooFewPPOPlans: Scalars['Boolean']['output'];
};

export type MedicareQuoteAuditLogOutput = {
  __typename?: 'MedicareQuoteAuditLogOutput';
  cId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  form?: Maybe<FormName>;
  id: Scalars['UUID']['output'];
};

export type MedicareQuoteOutput = {
  __typename?: 'MedicareQuoteOutput';
  abCase?: Maybe<Scalars['String']['output']>;
  agentOffer?: Maybe<Scalars['Boolean']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  chronicIllness?: Maybe<Scalars['Boolean']['output']>;
  clientId: Scalars['UUID']['output'];
  county?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<Array<Scalars['String']['output']>>;
  coverageFilled?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  customAnswers?: Maybe<Scalars['String']['output']>;
  doctorVisitsNumber?: Maybe<Scalars['Int']['output']>;
  doctorsWithinNetwork?: Maybe<Scalars['Boolean']['output']>;
  drugDeliveryType?: Maybe<DrugDeliveryTypeDto>;
  employed?: Maybe<Scalars['Boolean']['output']>;
  enrolled?: Maybe<Array<Scalars['String']['output']>>;
  enrolledFilled?: Maybe<Scalars['Boolean']['output']>;
  favorites: Array<Scalars['String']['output']>;
  filters?: Maybe<FiltersDto>;
  governmentAssistant?: Maybe<Array<Scalars['String']['output']>>;
  governmentAssistantFilled?: Maybe<Scalars['Boolean']['output']>;
  hasMore20?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  medicarePlanType?: Maybe<Scalars['String']['output']>;
  pin: Scalars['String']['output'];
  planYear?: Maybe<PlanYear>;
  preferredDoctors: Array<ProviderOutput>;
  preferredDoctorsFilled?: Maybe<Scalars['Boolean']['output']>;
  preferredDrugs: Array<DrugOutput>;
  preferredDrugsFilled?: Maybe<Scalars['Boolean']['output']>;
  preferredPharmacies: Array<PharmacyLocationDistanceOutput>;
  preferredPharmaciesFilled?: Maybe<Scalars['Boolean']['output']>;
  prepayCoverage?: Maybe<Scalars['Boolean']['output']>;
  receivedBenefits?: Maybe<Scalars['Boolean']['output']>;
  receivesLIS?: Maybe<Scalars['Boolean']['output']>;
  receivesMedicalSupport?: Maybe<Scalars['Boolean']['output']>;
  restrictProviderNetwork?: Maybe<Scalars['Boolean']['output']>;
  specialistVisitsNumber?: Maybe<Scalars['Int']['output']>;
  spouseEmployed?: Maybe<Scalars['Boolean']['output']>;
  spouseHasMore20?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: Maybe<Scalars['Boolean']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
  work8years?: Maybe<Scalars['Boolean']['output']>;
  zip: Scalars['String']['output'];
};

export type MedigapPreferredDrugOutput = {
  __typename?: 'MedigapPreferredDrugOutput';
  dosageRxcui: Scalars['String']['output'];
  frequency: MedigapCalcDrugFrequencyPeriodOutput;
  productRxcui: Scalars['String']['output'];
  purchaseFrequency: MedigapFrequencyPeriodOutput;
  quantity: Scalars['BigDecimal']['output'];
};

export type MonthlyTotalCost = {
  __typename?: 'MonthlyTotalCost';
  amount: Scalars['BigDecimal']['output'];
  amountString: Scalars['String']['output'];
  month: Scalars['Int']['output'];
  stage: DrugCalculationStage;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedicareQuoteForClient: SaveMedicareQuoteOutput;
  createMedicareQuoteFromMedigapQuote: SaveMedicareQuoteOutput;
  createPdpQuoteForClient: PdpQuoteOutput;
  generatePlanEmail?: Maybe<Scalars['String']['output']>;
  refreshPlans: Scalars['Boolean']['output'];
  saveCompareOffer: Scalars['String']['output'];
  saveCompareYearToYear: Scalars['Boolean']['output'];
  saveEnabledPbpContract: Scalars['Boolean']['output'];
  saveEnabledPbpState: Scalars['Boolean']['output'];
  saveEnabledPdpContract: Scalars['Boolean']['output'];
  saveEnabledPdpState: Scalars['Boolean']['output'];
  saveMedicareQuote: SaveMedicareQuoteOutput;
  savePdpQuote: PdpQuoteOutput;
  sendMedicareTypeCompareEmail: Scalars['String']['output'];
  sendRequestFormReceivedEmail: Scalars['String']['output'];
  setCompareYearToYearLocked: Scalars['Boolean']['output'];
  warmUpPharmaciesGeocodingCache?: Maybe<Scalars['String']['output']>;
};


export type MutationCreateMedicareQuoteForClientArgs = {
  agentOffer?: InputMaybe<Scalars['Boolean']['input']>;
  clientId: Scalars['UUID']['input'];
  data: MedicareQuoteInput;
};


export type MutationCreateMedicareQuoteFromMedigapQuoteArgs = {
  medigapQuoteId: Scalars['UUID']['input'];
};


export type MutationCreatePdpQuoteForClientArgs = {
  clientId: Scalars['UUID']['input'];
  input: PdpQuoteInput;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationGeneratePlanEmailArgs = {
  input: PlansCompareInput;
};


export type MutationRefreshPlansArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSaveCompareOfferArgs = {
  compareOffer: CompareOfferInput;
};


export type MutationSaveCompareYearToYearArgs = {
  input: CompareYearToYearInput;
};


export type MutationSaveEnabledPbpContractArgs = {
  input: EnabledPbpContractInput;
};


export type MutationSaveEnabledPbpStateArgs = {
  enabled: Scalars['Boolean']['input'];
  state: AvailableStateDto;
};


export type MutationSaveEnabledPdpContractArgs = {
  input: EnabledPdpContractInput;
};


export type MutationSaveEnabledPdpStateArgs = {
  enabled: Scalars['Boolean']['input'];
  state: AvailableStateDto;
};


export type MutationSaveMedicareQuoteArgs = {
  data: MedicareQuoteInput;
};


export type MutationSavePdpQuoteArgs = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  input: PdpQuoteInput;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSendMedicareTypeCompareEmailArgs = {
  email: Scalars['String']['input'];
  input: MedicareTypeCompareInput;
};


export type MutationSendRequestFormReceivedEmailArgs = {
  bidId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  filterInput: PlansFilterInput;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
  quoteId: Scalars['UUID']['input'];
};


export type MutationSetCompareYearToYearLockedArgs = {
  id: Scalars['UUID']['input'];
  isLocked: Scalars['Boolean']['input'];
};


export type MutationWarmUpPharmaciesGeocodingCacheArgs = {
  stateCode?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Network = {
  __typename?: 'Network';
  name: Scalars['String']['output'];
  tier?: Maybe<Scalars['String']['output']>;
};

export type NetworkInfoOutput = {
  __typename?: 'NetworkInfoOutput';
  carrierName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OtherDefinedSupplementalBenefits = {
  __typename?: 'OtherDefinedSupplementalBenefits';
  counselingServices: Array<Scalars['String']['output']>;
  enhancedDiseaseManagement: Array<Scalars['String']['output']>;
  fitnessBenefit: Array<Scalars['String']['output']>;
  healthEducation: Array<Scalars['String']['output']>;
  homeAndBathroomSafety: Array<Scalars['String']['output']>;
  inHomeSafetyAssessment: Array<Scalars['String']['output']>;
  medicalNutritionTherapy: Array<Scalars['String']['output']>;
  nutritionalDietaryBenefit: Array<Scalars['String']['output']>;
  personalEmergencyResponseSystem: Array<Scalars['String']['output']>;
  postDischargeInHome: Array<Scalars['String']['output']>;
  remoteAccessTechnologies: Array<Scalars['String']['output']>;
  smokingAndTobaccoCessationCounseling: Array<Scalars['String']['output']>;
  telemonitoringServices: Array<Scalars['String']['output']>;
};

export type PackInfoOuput = {
  __typename?: 'PackInfoOuput';
  name?: Maybe<Scalars['String']['output']>;
  rxcui?: Maybe<Scalars['String']['output']>;
};

export type PageableManuallyUploadedPlanFileOutput = {
  __typename?: 'PageableManuallyUploadedPlanFileOutput';
  data: Array<ManuallyUploadedPlanFileOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableMedicareQuoteAuditLogOutput = {
  __typename?: 'PageableMedicareQuoteAuditLogOutput';
  data: Array<MedicareQuoteAuditLogOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableMedicareQuoteOutput = {
  __typename?: 'PageableMedicareQuoteOutput';
  data: Array<MedicareQuoteOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageablePdpPlanOutput = {
  __typename?: 'PageablePdpPlanOutput';
  data: Array<PdpPlan>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageablePharmacyLocationOutput = {
  __typename?: 'PageablePharmacyLocationOutput';
  data: Array<PharmacyLocationDistanceOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageablePlanOutput = {
  __typename?: 'PageablePlanOutput';
  data: Array<Plan>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PdpDrugsDetailsDto = {
  __typename?: 'PdpDrugsDetailsDto';
  catastrophicCopayBranded: Scalars['String']['output'];
  catastrophicCopayGeneric: Scalars['String']['output'];
  catastrophicCoverageDescription: Scalars['String']['output'];
  catastrophicThreshold: Scalars['String']['output'];
  coverageGapDescription: Scalars['String']['output'];
  initialCoverageDescription: Scalars['String']['output'];
  initialCoverageLimit: Scalars['String']['output'];
  mrxAltDedAmount: Scalars['String']['output'];
  mrxAltDedAmountShort: Scalars['String']['output'];
  mrxAltNoDedTier: Scalars['String']['output'];
};

export type PdpPlan = {
  __typename?: 'PdpPlan';
  SNPType?: Maybe<SnpType>;
  bidId: Scalars['String']['output'];
  contractNumber?: Maybe<Scalars['String']['output']>;
  discountedDrugs?: Maybe<Array<DrugDiscount>>;
  drugCostMonthlyBreakdown?: Maybe<DrugCostMonthlyBreakdownOutput>;
  drugDetails?: Maybe<PdpDrugsDetailsDto>;
  drugPrices: Array<DrugPriceOutput>;
  drugTiers?: Maybe<DrugTiersOutput>;
  drugsCost?: Maybe<Scalars['String']['output']>;
  drugsCoverage: Array<DrugCoverage>;
  inNetworkDeductableAmount?: Maybe<Scalars['BigDecimal']['output']>;
  insulinSavings?: Maybe<Scalars['Boolean']['output']>;
  maxEnrollmentAmount?: Maybe<Scalars['BigDecimal']['output']>;
  missingFormularyInAep?: Maybe<Scalars['Boolean']['output']>;
  monthlyCost?: Maybe<Scalars['String']['output']>;
  monthlyPremium?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  outOfPocketAmount?: Maybe<Scalars['BigDecimal']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  partBPremium?: Maybe<Scalars['BigDecimal']['output']>;
  pbpADsnpZeroDollar?: Maybe<Scalars['Boolean']['output']>;
  pbpASnpStateCvgYn?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  planTypeCode?: Maybe<Scalars['String']['output']>;
  planYear?: Maybe<PlanYear>;
  quoteDrugsTiers?: Maybe<QuoteDrugTiersOutput>;
};


export type PdpPlanDrugTiersArgs = {
  deliveryMethod: DrugDeliveryMethod;
  supplyDuration: DrugSupplyDuration;
};


export type PdpPlanQuoteDrugsTiersArgs = {
  supplyDuration: DrugSupplyDuration;
};

export type PdpPlanAggregationOutput = {
  __typename?: 'PdpPlanAggregationOutput';
  monthlyPremiumMax?: Maybe<Scalars['BigDecimal']['output']>;
  monthlyPremiumMin?: Maybe<Scalars['BigDecimal']['output']>;
};

export type PdpQuoteOutput = {
  __typename?: 'PdpQuoteOutput';
  cId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  drugDeliveryType?: Maybe<MedigapDrugDeliveryType>;
  id?: Maybe<Scalars['UUID']['output']>;
  insulinSavings?: Maybe<Scalars['Boolean']['output']>;
  preferredDrugs: Array<MedigapPreferredDrugOutput>;
  preferredPharmacies: Array<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type PharmacyLocationDistanceOutput = {
  __typename?: 'PharmacyLocationDistanceOutput';
  address: Scalars['String']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  npi: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type PharmacyTier = {
  __typename?: 'PharmacyTier';
  items: Array<DrugTier>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Plan = {
  __typename?: 'Plan';
  SNPType?: Maybe<SnpType>;
  alternativeMedicine: Array<Scalars['String']['output']>;
  annualPhysicalExam: Array<Scalars['String']['output']>;
  bidId: Scalars['String']['output'];
  calculableDoctorsCost: Scalars['Boolean']['output'];
  chiropractic: Array<Scalars['String']['output']>;
  dental: Array<Scalars['String']['output']>;
  discountedDrugs?: Maybe<Array<DrugDiscount>>;
  doctorsCost?: Maybe<Scalars['String']['output']>;
  doctorsCoverage: Array<DoctorCoverage>;
  drugCostMonthlyBreakdown?: Maybe<DrugCostMonthlyBreakdownOutput>;
  drugDetails?: Maybe<DrugsDetails>;
  drugPrices: Array<DrugPriceOutput>;
  drugTiers?: Maybe<DrugTiersOutput>;
  drugsCost?: Maybe<Scalars['String']['output']>;
  drugsCoverage: Array<DrugCoverage>;
  extraBenefits: ExtraBenefits;
  firstWeekOfStay?: Maybe<Scalars['String']['output']>;
  hearing: Array<Scalars['String']['output']>;
  homeHealth: Array<Scalars['String']['output']>;
  hospitalBenefits: Array<Scalars['String']['output']>;
  hospitalityDetails: HospitalityDetails;
  inNetworkDeductableAmount?: Maybe<Scalars['String']['output']>;
  maxEnrollmentAmount?: Maybe<Scalars['String']['output']>;
  meal: Array<Scalars['String']['output']>;
  mentalHealth: Array<Scalars['String']['output']>;
  missingFormularyInAep?: Maybe<Scalars['Boolean']['output']>;
  monthlyCost?: Maybe<Scalars['String']['output']>;
  monthlyPremium?: Maybe<Scalars['String']['output']>;
  oTC: Array<Scalars['String']['output']>;
  optionalSupplementalBenefits: Scalars['Boolean']['output'];
  optionalSupplementalBenefitsItems: Array<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  otherDefinedSupplemental: OtherDefinedSupplementalBenefits;
  outOfPocketAmount?: Maybe<Scalars['String']['output']>;
  outOfPocketMaximum: Array<Scalars['String']['output']>;
  outpatientHospitality: Array<Scalars['String']['output']>;
  outpatientRehabilitation: Array<Scalars['String']['output']>;
  outpatientSurgery: Array<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  partBAmount?: Maybe<Scalars['String']['output']>;
  partBGiveBack?: Maybe<Scalars['Boolean']['output']>;
  physicianSpecialist: Array<Scalars['String']['output']>;
  physicianSpecialistShort: Array<Scalars['String']['output']>;
  planFiles?: Maybe<PlanFilesOutput>;
  planName?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  planTypeCode?: Maybe<Scalars['String']['output']>;
  planYear?: Maybe<PlanYear>;
  preventiveCare: Array<Scalars['String']['output']>;
  primaryCarePhysician: Array<Scalars['String']['output']>;
  primaryCarePhysicianInNetwork?: Maybe<Scalars['String']['output']>;
  primaryCarePhysicianOutOfNetwork?: Maybe<Scalars['String']['output']>;
  primaryCarePhysicianShort?: Maybe<Scalars['String']['output']>;
  quoteDrugsTiers?: Maybe<QuoteDrugTiersOutput>;
  rating?: Maybe<Scalars['Float']['output']>;
  ratingNote?: Maybe<Scalars['String']['output']>;
  skilledNursing: Array<Scalars['String']['output']>;
  telehealth: Array<Scalars['String']['output']>;
  transportation: Array<Scalars['String']['output']>;
  vision: Array<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};


export type PlanDrugTiersArgs = {
  deliveryMethod: DrugDeliveryMethod;
  supplyDuration: DrugSupplyDuration;
};


export type PlanQuoteDrugsTiersArgs = {
  supplyDuration: DrugSupplyDuration;
};

export type PlanFilesOutput = {
  __typename?: 'PlanFilesOutput';
  bidId: Scalars['String']['output'];
  evidenceOfCoverageDocUrl?: Maybe<Scalars['String']['output']>;
  nonDiscriminationNoticeDocUrl?: Maybe<Scalars['String']['output']>;
  preEnrollmentChecklistDocUrl?: Maybe<Scalars['String']['output']>;
  starRatingDescDocUrl?: Maybe<Scalars['String']['output']>;
  summaryOfBenefitsUrl?: Maybe<Scalars['String']['output']>;
};

export type PlansCountByZipOutput = {
  __typename?: 'PlansCountByZipOutput';
  maPlansCount: Scalars['Long']['output'];
  maPlansTotalCount: Scalars['Long']['output'];
  pdpPlansCount: Scalars['Long']['output'];
  pdpPlansTotalCount: Scalars['Long']['output'];
  zip: Scalars['String']['output'];
};

export type PlansSummaryOutput = {
  __typename?: 'PlansSummaryOutput';
  drugsCoveredPercent: Scalars['Int']['output'];
  hmoCount: Scalars['Int']['output'];
  ppoCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  zeroPremiumPercent: Scalars['Int']['output'];
};

export type ProviderAddressOutput = {
  __typename?: 'ProviderAddressOutput';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPcp: Scalars['Boolean']['output'];
  specialty: Scalars['String']['output'];
};

export type ProviderOutput = {
  __typename?: 'ProviderOutput';
  addresses: Array<ProviderAddressOutput>;
  addressesNumber: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  npi: Scalars['String']['output'];
  pcpDetails: Array<ProviderPcpInfoOutput>;
};

export type ProviderPcpInfoOutput = {
  __typename?: 'ProviderPcpInfoOutput';
  id: Scalars['UUID']['output'];
  networkDetails: Array<NetworkInfoOutput>;
  pcpId: Scalars['String']['output'];
};

export type ProviderPlanOutput = {
  __typename?: 'ProviderPlanOutput';
  carrierName?: Maybe<Scalars['String']['output']>;
  externalIds: Array<ExternalId>;
  id: Scalars['String']['output'];
  lineOfCoverage: Scalars['String']['output'];
  market?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  networks: Array<Network>;
};

export type Query = {
  __typename?: 'Query';
  PdpPlan: PdpPlan;
  PdpPlans: PageablePdpPlanOutput;
  SNPTypes: Array<FilterItem>;
  companies: Array<FilterItem>;
  compareOffer?: Maybe<CompareOffer>;
  compareYearToYear?: Maybe<CompareYearToYearOutput>;
  compareYearToYearByClientId?: Maybe<CompareYearToYearOutput>;
  drugPackagesInfo?: Maybe<DrugInfoWithPackagesFlags>;
  drugs: Array<DrugInfoWithGeneric>;
  drugsWithPackage: Array<DrugInfoWithPack>;
  emailStats: Array<SibEvent>;
  enabledPbpContracts: Array<EnabledPbpContractOutput>;
  enabledPbpStates: Array<EnabledPbpStatesOutput>;
  enabledPdpContracts: Array<EnabledPdpContractOutput>;
  enabledPdpStates: Array<EnabledPdpStatesOutput>;
  extraBenefits: Array<FilterItem>;
  extraBenefitsWithMaxBenefitAmount: ExtraBenefitsWithMaxBenefitAmountOutput;
  findBrokenAddresses: Array<BrokenAddress>;
  findDoctorsByNpi?: Maybe<Doctor>;
  findDrugByProductId?: Maybe<DrugProductDto>;
  findDrugsDetailsByBidId?: Maybe<DrugsDetails>;
  findPharmaciesByAddress: PageablePharmacyLocationOutput;
  findPharmaciesByLocation: PageablePharmacyLocationOutput;
  findPharmaciesWithinRectangle: PageablePharmacyLocationOutput;
  findPharmacyByNpi?: Maybe<PharmacyLocationDistanceOutput>;
  findProviderAddressesByNpi: Array<ProviderAddressOutput>;
  findProvidersByFilter: Array<ProviderOutput>;
  findProvidersPlansByFilter: Array<ProviderPlanOutput>;
  findRequestAuditLogById?: Maybe<MedicareQuoteAuditLogOutput>;
  findRequestAuditLogs: PageableMedicareQuoteAuditLogOutput;
  findStarRatings: Array<FilterItem>;
  findZipLocation?: Maybe<LocationOutput>;
  generateEhealthPlanLink: Scalars['String']['output'];
  getMyQuoteId?: Maybe<Scalars['UUID']['output']>;
  isTherePlanWithFullDrugCoverage: Scalars['Boolean']['output'];
  loginStats: Array<UserLogin>;
  manuallyUploadedPlanFiles: PageableManuallyUploadedPlanFileOutput;
  maxCostRanges: Array<FilterItem>;
  maxOutOfPocketRanges: Array<FilterItem>;
  medicarePlansAggregateByCompany: Array<MedicarePlansAggregationByCompanyOutput>;
  medicarePlansAggregation: MedicarePlansAggregationOutput;
  medicareQuote?: Maybe<MedicareQuoteOutput>;
  medicareQuoteByPin?: Maybe<MedicareQuoteOutput>;
  medicareQuotes: PageableMedicareQuoteOutput;
  myCompareYearToYear?: Maybe<CompareYearToYearOutput>;
  packageDescriptionByNdc?: Maybe<Scalars['String']['output']>;
  packageDescriptionByRxcui: DrugPackageOutput;
  packages: Array<PackInfoOuput>;
  partBPremiumReductionCount: Scalars['Int']['output'];
  pdpCompanies: Array<FilterItem>;
  pdpPlanAggregation: PdpPlanAggregationOutput;
  pdpQuote: PdpQuoteOutput;
  percentOfZeroPremiumMedicarePlans: Scalars['String']['output'];
  plan?: Maybe<Plan>;
  planTypes: Array<FilterItem>;
  plans: PageablePlanOutput;
  plansCountByZip: PlansCountByZipOutput;
  plansSummary: PlansSummaryOutput;
};


export type QueryPdpPlanArgs = {
  bidId: Scalars['String']['input'];
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  countyName: Scalars['String']['input'];
  fullYear?: InputMaybe<Scalars['Boolean']['input']>;
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryPdpPlansArgs = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  filter: PdpPlansFilterInput;
  fullYear?: InputMaybe<Scalars['Boolean']['input']>;
  page: PageInput;
  sort: Array<PdpPlansSortInput>;
};


export type QuerySnpTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryCompaniesArgs = {
  filter: PlansFilterInput;
};


export type QueryCompareOfferArgs = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryCompareYearToYearArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCompareYearToYearByClientIdArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryDrugPackagesInfoArgs = {
  rxcui: Scalars['String']['input'];
};


export type QueryDrugsArgs = {
  name: Scalars['String']['input'];
};


export type QueryDrugsWithPackageArgs = {
  pairs: Array<ProductPackagePairInput>;
};


export type QueryEmailStatsArgs = {
  email: Scalars['String']['input'];
};


export type QueryExtraBenefitsArgs = {
  filter: PlansFilterInput;
};


export type QueryExtraBenefitsWithMaxBenefitAmountArgs = {
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryFindBrokenAddressesArgs = {
  addressSearchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindDoctorsByNpiArgs = {
  npi: Scalars['String']['input'];
};


export type QueryFindDrugByProductIdArgs = {
  planYear?: InputMaybe<PlanYear>;
  productId: Scalars['String']['input'];
};


export type QueryFindDrugsDetailsByBidIdArgs = {
  bidId: Scalars['String']['input'];
  countyName: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryFindPharmaciesByAddressArgs = {
  filterInput: PharmacyAddressFilterInput;
  page: PageInput;
  planYear?: InputMaybe<PlanYear>;
};


export type QueryFindPharmaciesByLocationArgs = {
  filterInput: PharmacyLocationFilterInput;
  page: PageInput;
};


export type QueryFindPharmaciesWithinRectangleArgs = {
  filterInput: PharmacyWithinRectangleFilterInput;
  page: PageInput;
};


export type QueryFindPharmacyByNpiArgs = {
  lengthUnit?: InputMaybe<LengthUnit>;
  npi: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindProviderAddressesByNpiArgs = {
  npi: Scalars['String']['input'];
};


export type QueryFindProvidersByFilterArgs = {
  filterInput: ProviderFilterInput;
};


export type QueryFindProvidersPlansByFilterArgs = {
  filterInput: ProviderPlansFilterInput;
};


export type QueryFindRequestAuditLogByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryFindRequestAuditLogsArgs = {
  filterInput?: InputMaybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryFindStarRatingsArgs = {
  filter: PlansFilterInput;
};


export type QueryFindZipLocationArgs = {
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryGenerateEhealthPlanLinkArgs = {
  bidId: Scalars['String']['input'];
  countyName: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryIsTherePlanWithFullDrugCoverageArgs = {
  countyName: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  zip: Scalars['String']['input'];
};


export type QueryLoginStatsArgs = {
  email: Scalars['String']['input'];
};


export type QueryManuallyUploadedPlanFilesArgs = {
  filter?: InputMaybe<PlanFileFilterInput>;
  page: PageInput;
};


export type QueryMaxCostRangesArgs = {
  filter: PlansFilterInput;
  fullYear?: InputMaybe<Scalars['Boolean']['input']>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryMaxOutOfPocketRangesArgs = {
  filter: PlansFilterInput;
};


export type QueryMedicarePlansAggregateByCompanyArgs = {
  county: Scalars['String']['input'];
  planTypes: Array<Scalars['String']['input']>;
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryMedicarePlansAggregationArgs = {
  allowAllStates?: InputMaybe<Scalars['Boolean']['input']>;
  county: Scalars['String']['input'];
  dsnpOnly?: InputMaybe<Scalars['Boolean']['input']>;
  planType?: InputMaybe<MedicarePlanType>;
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryMedicareQuoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMedicareQuoteByPinArgs = {
  pin: Scalars['String']['input'];
};


export type QueryMedicareQuotesArgs = {
  filterInput?: InputMaybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryPackageDescriptionByNdcArgs = {
  ndc: Scalars['String']['input'];
  planYear?: InputMaybe<PlanYear>;
};


export type QueryPackageDescriptionByRxcuiArgs = {
  planYear?: InputMaybe<PlanYear>;
  rxcui: Scalars['String']['input'];
};


export type QueryPackagesArgs = {
  rxcui: Scalars['String']['input'];
};


export type QueryPartBPremiumReductionCountArgs = {
  filter: PlansFilterInput;
};


export type QueryPdpCompaniesArgs = {
  filter: PdpPlansFilterInput;
};


export type QueryPdpPlanAggregationArgs = {
  county: Scalars['String']['input'];
  dsnpOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryPdpQuoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryPercentOfZeroPremiumMedicarePlansArgs = {
  allowAllStates?: InputMaybe<Scalars['Boolean']['input']>;
  county: Scalars['String']['input'];
  planTypes?: InputMaybe<Array<MedicarePlanType>>;
  planYear?: InputMaybe<PlanYear>;
  zip: Scalars['String']['input'];
};


export type QueryPlanArgs = {
  bidId: Scalars['String']['input'];
  countyName: Scalars['String']['input'];
  fullYear?: InputMaybe<Scalars['Boolean']['input']>;
  planYear?: InputMaybe<PlanYear>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  zip: Scalars['String']['input'];
};


export type QueryPlanTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryPlansArgs = {
  filter: PlansFilterInput;
  fullYear?: InputMaybe<Scalars['Boolean']['input']>;
  page: PageInput;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  sort: Array<PlansSortInput>;
};


export type QueryPlansCountByZipArgs = {
  zip: Scalars['String']['input'];
};


export type QueryPlansSummaryArgs = {
  countyName: Scalars['String']['input'];
  extraBenefits?: InputMaybe<Array<ExtraBenefit>>;
  planYear?: InputMaybe<PlanYear>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  showAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
  zip: Scalars['String']['input'];
};

export type QuoteDrugTiersOutput = {
  __typename?: 'QuoteDrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  deliveryType: DrugDeliveryMethod;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  npi: Scalars['String']['output'];
  pharmacyName: Scalars['String']['output'];
};

export type SaveMedicareQuoteOutput = {
  __typename?: 'SaveMedicareQuoteOutput';
  clientId: Scalars['UUID']['output'];
  maQuoteID: Scalars['UUID']['output'];
};

export type SibEvent = {
  __typename?: 'SibEvent';
  emailToken?: Maybe<Scalars['String']['output']>;
  eventDate: Scalars['Instant']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['Int']['output']>;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  emailToken: Scalars['String']['output'];
  event: Event;
  eventDate: Scalars['Instant']['output'];
  id: Scalars['UUID']['output'];
};

export enum AvailableStateDto {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum CalcDrugFrequencyPeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Quarterly = 'QUARTERLY'
}

export enum ComparablePlanType {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export enum DrugCalculationStage {
  Catastrophic = 'CATASTROPHIC',
  CoverageGap = 'COVERAGE_GAP',
  Deductible = 'DEDUCTIBLE',
  InitialCoverage = 'INITIAL_COVERAGE',
  NotCovered = 'NOT_COVERED'
}

export enum DrugDeliveryMethod {
  PreferredMail = 'PREFERRED_MAIL',
  PreferredRetail = 'PREFERRED_RETAIL',
  StandardMail = 'STANDARD_MAIL',
  StandardRetail = 'STANDARD_RETAIL'
}

export enum DrugDeliveryTypeDto {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export enum DrugDiscountBrand {
  CostPlus = 'COST_PLUS',
  GoodRx = 'GOOD_RX'
}

export enum DrugSupplyDuration {
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  TwoMonths = 'TWO_MONTHS'
}

export enum DrugTierOutput {
  Generic = 'GENERIC',
  NonPreferredBrand = 'NON_PREFERRED_BRAND',
  PreferredBrand = 'PREFERRED_BRAND',
  PreferredGeneric = 'PREFERRED_GENERIC',
  SelectCareDrugs = 'SELECT_CARE_DRUGS',
  Specialty = 'SPECIALTY'
}

export enum Event {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  RefreshTokenError = 'REFRESH_TOKEN_ERROR'
}

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum Field {
  Deductible = 'DEDUCTIBLE',
  DoctorsCoverage = 'DOCTORS_COVERAGE',
  MaxOutOfPocket = 'MAX_OUT_OF_POCKET',
  MonthlyCost = 'MONTHLY_COST',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Rating = 'RATING'
}

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum FrequencyPeriod {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export enum GeocodingType {
  Google = 'GOOGLE'
}

export enum LengthUnit {
  Kilometer = 'KILOMETER',
  Mile = 'MILE'
}

export enum MedicarePlanType {
  Hmo = 'HMO',
  MmpHmo = 'MMP_HMO',
  Ppo = 'PPO'
}

export enum MedicareQuoteFilterExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum MedigapCalcDrugFrequencyPeriodOutput {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum MedigapDrugDeliveryType {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export enum MedigapFrequencyPeriodOutput {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export enum PdpField {
  Deductible = 'DEDUCTIBLE',
  MaxOutOfPocket = 'MAX_OUT_OF_POCKET',
  MonthlyCost = 'MONTHLY_COST',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Rating = 'RATING'
}

export enum PdpSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PlanYear {
  Year2021 = 'Year2021',
  Year2022 = 'Year2022',
  Year2023 = 'Year2023',
  Year2024 = 'Year2024'
}

export enum Rating {
  None = 'NONE',
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export enum SnpType {
  CSnp = 'C_SNP',
  DSnp = 'D_SNP',
  ISnp = 'I_SNP'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortType {
  Distance = 'DISTANCE',
  Name = 'NAME'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type ComparablePlanInput = {
  bidId: Scalars['String']['input'];
  county: Scalars['String']['input'];
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  pdpPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  planYear?: InputMaybe<PlanYear>;
  type: ComparablePlanType;
  zip: Scalars['String']['input'];
};

export type CompareOfferInput = {
  clientId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  plans: Array<ComparablePlanInput>;
};

export type CompareYearToYearInput = {
  clientId: Scalars['UUID']['input'];
  county: Scalars['String']['input'];
  currentMaPlanId?: InputMaybe<Scalars['String']['input']>;
  currentMgNaic?: InputMaybe<Scalars['String']['input']>;
  currentMgPlanKey?: InputMaybe<Scalars['String']['input']>;
  currentMgPlanName?: InputMaybe<Scalars['String']['input']>;
  currentPdpPlanId?: InputMaybe<Scalars['String']['input']>;
  currentYear: PlanYear;
  id?: InputMaybe<Scalars['UUID']['input']>;
  maIsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  mgIsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  pdpIsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  suggestedMaPlanId?: InputMaybe<Scalars['String']['input']>;
  suggestedMgNaic?: InputMaybe<Scalars['String']['input']>;
  suggestedMgPlanKey?: InputMaybe<Scalars['String']['input']>;
  suggestedMgPlanName?: InputMaybe<Scalars['String']['input']>;
  suggestedPdpPlanId?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type DoctorInput = {
  addresses: Array<DoctorInputAddressInput>;
  npi: Scalars['String']['input'];
};

export type DoctorInputAddressInput = {
  addressId: Scalars['String']['input'];
};

export type EnabledPbpContractInput = {
  company: Scalars['String']['input'];
  contract: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  parentCompany: Scalars['String']['input'];
};

export type EnabledPdpContractInput = {
  company: Scalars['String']['input'];
  contract: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  parentCompany: Scalars['String']['input'];
};

export type FiltersDtoInput = {
  SNPTypes?: InputMaybe<Array<SnpType>>;
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  extraBenefits: Array<MedicareQuoteFilterExtraBenefit>;
  maxCostRangeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  maxOutOfPocketRangeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  partBPremiumReduction?: InputMaybe<Scalars['Boolean']['input']>;
  partDDrugCoverage?: InputMaybe<Scalars['Boolean']['input']>;
  planTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rating?: InputMaybe<Array<Rating>>;
};

export type MedicareQuoteFilterInput = {
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
  statuses?: InputMaybe<Array<RequestStatusDto>>;
};

export type MedicareQuoteInput = {
  abCase?: InputMaybe<Scalars['String']['input']>;
  cId?: InputMaybe<Scalars['String']['input']>;
  chronicIllness?: InputMaybe<Scalars['Boolean']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Array<Scalars['String']['input']>>;
  coverageFilled?: InputMaybe<Scalars['Boolean']['input']>;
  customAnswers?: InputMaybe<Scalars['String']['input']>;
  doctorVisitsNumber?: InputMaybe<Scalars['Int']['input']>;
  doctorsWithinNetwork?: InputMaybe<Scalars['Boolean']['input']>;
  employed?: InputMaybe<Scalars['Boolean']['input']>;
  enrolled?: InputMaybe<Array<Scalars['String']['input']>>;
  enrolledFilled?: InputMaybe<Scalars['Boolean']['input']>;
  favorites?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<FiltersDtoInput>;
  form?: InputMaybe<FormName>;
  governmentAssistant?: InputMaybe<Array<Scalars['String']['input']>>;
  governmentAssistantFilled?: InputMaybe<Scalars['Boolean']['input']>;
  hasMore20?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  medicarePlanType?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planYear?: InputMaybe<PlanYear>;
  preferredDoctors?: InputMaybe<Array<DoctorInput>>;
  preferredDoctorsFilled?: InputMaybe<Scalars['Boolean']['input']>;
  prepayCoverage?: InputMaybe<Scalars['Boolean']['input']>;
  receivedBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  receivesLIS?: InputMaybe<Scalars['Boolean']['input']>;
  receivesMedicalSupport?: InputMaybe<Scalars['Boolean']['input']>;
  restrictProviderNetwork?: InputMaybe<Scalars['Boolean']['input']>;
  specialistVisitsNumber?: InputMaybe<Scalars['Int']['input']>;
  spouseEmployed?: InputMaybe<Scalars['Boolean']['input']>;
  spouseHasMore20?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: InputMaybe<Scalars['Boolean']['input']>;
  work8years?: InputMaybe<Scalars['Boolean']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type MedicareTypeCompareInput = {
  certainDoctors?: InputMaybe<Scalars['Boolean']['input']>;
  drugsCoverage?: InputMaybe<Scalars['Boolean']['input']>;
  extraBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyBudget?: InputMaybe<Scalars['Boolean']['input']>;
  prepay?: InputMaybe<Scalars['Boolean']['input']>;
  receiveMedicaid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PageInput = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PdpPlansFilterInput = {
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  countyName: Scalars['String']['input'];
  insulinSavings?: InputMaybe<Scalars['Boolean']['input']>;
  maxCostRangeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  planYear?: InputMaybe<PlanYear>;
  showAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
  zip: Scalars['String']['input'];
};

export type PdpPlansSortInput = {
  direction: PdpSort;
  pdpField: PdpField;
};

export type PdpQuoteInput = {
  cId?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insulinSavings?: InputMaybe<Scalars['Boolean']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PharmacyAddressFilterInput = {
  address: Scalars['String']['input'];
  lengthUnit?: InputMaybe<LengthUnit>;
  name?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<SortType>;
};

export type PharmacyLocationFilterInput = {
  latitude: Scalars['Float']['input'];
  lengthUnit?: InputMaybe<LengthUnit>;
  longitude: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<SortType>;
};

export type PharmacyWithinRectangleFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  rectangle: RectangleInput;
};

export type PlanFileFilterInput = {
  bidId?: InputMaybe<Scalars['String']['input']>;
  planYear?: InputMaybe<PlanYear>;
};

export type PlansCompareInput = {
  bidIds: Array<Scalars['String']['input']>;
  county: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  onlyPreview?: InputMaybe<Scalars['Boolean']['input']>;
  planYear?: InputMaybe<PlanYear>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  zip: Scalars['String']['input'];
};

export type PlansFilterInput = {
  SNPTypes?: InputMaybe<Array<SnpType>>;
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  countyName: Scalars['String']['input'];
  extraBenefits?: InputMaybe<Array<ExtraBenefit>>;
  isMA?: InputMaybe<Scalars['Boolean']['input']>;
  isMD?: InputMaybe<Scalars['Boolean']['input']>;
  isMS?: InputMaybe<Scalars['Boolean']['input']>;
  maxCostRangeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  maxOutOfPocketRangeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  maxPremium?: InputMaybe<Scalars['BigDecimal']['input']>;
  onlyFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  partBPremiumReduction?: InputMaybe<Scalars['Boolean']['input']>;
  planTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  planYear?: InputMaybe<PlanYear>;
  rating?: InputMaybe<Array<Rating>>;
  showAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
  zip: Scalars['String']['input'];
};

export type PlansSortInput = {
  direction: Sort;
  field: Field;
};

export type PointInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type ProductPackagePairInput = {
  packageRxcui: Scalars['String']['input'];
  productRxcui: Scalars['String']['input'];
};

export type ProviderFilterInput = {
  radius?: InputMaybe<Scalars['Int']['input']>;
  searchTerm: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type ProviderPlansFilterInput = {
  lineOfCoverage?: InputMaybe<Scalars['String']['input']>;
  npi: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RectangleInput = {
  diagonalPoint: PointInput;
  firstPoint: PointInput;
};
