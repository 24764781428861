import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {LeadSourceOutput, MutationUpdateSourcesArgs, UserProfileInput} from "../../enrollment-types";

export default function useUpdateSources(options?: MutationHookOptions<LeadSourceOutput[], MutationUpdateSourcesArgs>) {
  return useMutation<LeadSourceOutput[], MutationUpdateSourcesArgs>(gql(saveUserInfoMutation), options)
}

const saveUserInfoMutation = `
mutation($clientId: UUID!) {updateSources(clientId: $clientId) {campaign }}
`;
