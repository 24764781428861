import React from "react";
import {DataGrid, GridColumns, GridValueGetterParams} from "@mui/x-data-grid";
import moment from "moment";
import {AppointmentOutput} from "../../enrollment-types";
import {withStyles} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import {colors} from "../../AppTheme";
import {Link} from "react-router-dom";


const columns: GridColumns = [
  { field: 'startTime', headerName: 'Time', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      return moment(params.row.startTime).format('L H:mm') + '-' + moment(params.row.endTime).format('H:mm');
    }
  },
  { field: 'name', headerName: 'Name', width: 250, disableColumnMenu: false,
    renderCell: (params: GridValueGetterParams) => {
      return <LightTooltip title={params.row.name}>
        <div>{params.row.name}</div>
      </LightTooltip>
    }
  },
  { field: 'endTime', headerName: 'Client Name', width: 150, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      if (params.row.invitee.clientId) {
        return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.invitee.clientId}>{params.row.invitee.name}</Link>
      }
      return params.row.invitee.name
    }

  },
  { field: 'url', headerName: 'Email', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.invitee.email;
    }
  },
];

const Appointments = ({appointments}: {appointments?: AppointmentOutput[]}) => {

  return <div style={{ height: '400px', width: '100%' }}>
        <DataGrid rows={appointments || []}
                  loading={!appointments}
                  getRowId={row => row.url}
                  columns={columns}
                  hideFooterPagination
                  disableSelectionOnClick
                  getRowClassName={params => moment(String(params.getValue(params.id, 'endTime'))).isBefore(moment()) ? 'deactivated-data-grid-row' : ''}
        />
    </div>
}

export default Appointments;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
