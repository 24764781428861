import { MutationHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {DrugInfoWithPackagesFlags} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";

export function useDrugPackagesInfoRequest(options?: MutationHookOptions<{drugPackagesInfo: DrugInfoWithPackagesFlags}, {rxcui: string}>) {
  return useLazyQuery<{drugPackagesInfo: DrugInfoWithPackagesFlags}, {rxcui: string}>(
    gql(getDrugRequest),
    {
      fetchPolicy: 'no-cache',
      client,
      ...options
    }
  );
}

const getDrugRequest = `
query ($rxcui: String!) {
  drugPackagesInfo(rxcui: $rxcui) {
    genericName
    genericRxcui
    brandedDetails {
      name
      rxcui
    }
    hasBrandedPackages
    hasGenericPackages
    isGeneric
    name
    rxcui
  }
}
`;
