import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSortModel,
  GridRenderEditCellParams,
  useGridApiRef
} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import moment from "moment";
import * as _ from "lodash";
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  CallHistoryCallType,
  CallHistoryOutput,
  CallHistoryResultInput,
  CallHistoryResultOutput, ClientViewOutput
} from "../../../enrollment-types";
import useCallHistoryItems from "../../hooks/useCallHistoryItems";
import useSaveCallHistory from "../../hooks/useSaveCallHistory";
import useSetUrgentCalRequested from "../../hooks/useSetUrgentCalRequested";
import useUrgentCallRequests from "../../../UrgentCalls/hooks/useUrgentCallRequests";
import Card from "../../../shared/Card";
import Preloader from "../../../shared/Preloader";
import FormSelect from "../../../shared/FormSelect";
import useCallHistoryPage from "./useCallHistoryPage";
import useCallHistoryPageSize from "./useCallHistoryPageSize";
import {keycloak, KeycloakRoles} from "../../../shared/keycloak";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import {config} from "../../../config/config";
import CloseIcon from '@material-ui/icons/Close';

const stringifyResult = (type?: CallHistoryResultInput) => {
  switch (type) {
    case CallHistoryResultInput.SuccessfulSeeNotesForCallDetails: return 'Successful - See notes for call details';
    case CallHistoryResultInput.UnsuccessfulInvalidNumber: return 'Unsuccessful - Invalid Number (Wrong/Disconnected)';
    case CallHistoryResultInput.UnsuccessfulLeftText: return 'Unsuccessful - Left Text';
    case CallHistoryResultInput.UnsuccessfulLeftVm: return 'Unsuccessful - Left VM';
    case CallHistoryResultInput.SuccessfulBookedCall: return 'Successful - Booked Call';
    case CallHistoryResultInput.UnsuccessfulLeftEmail: return 'Unsuccessful - Left Email';
    case CallHistoryResultInput.SuccessfulWarmTransfer: return 'Successful - Warm Transfer';
    default: return '';
  }
}

const ResultSelect = (props: GridRenderEditCellParams) => {
  const { id, value, field, api } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    /*api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');*/
  };

  return <FormSelect value={value?.toString()}
                     onChange={handleChange}
  >
    <MenuItem value={CallHistoryResultInput.SuccessfulSeeNotesForCallDetails}>{stringifyResult(CallHistoryResultInput.SuccessfulSeeNotesForCallDetails)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.SuccessfulBookedCall}>{stringifyResult(CallHistoryResultInput.SuccessfulBookedCall)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.SuccessfulWarmTransfer}>{stringifyResult(CallHistoryResultInput.SuccessfulWarmTransfer)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulInvalidNumber}>{stringifyResult(CallHistoryResultInput.UnsuccessfulInvalidNumber)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftText}>{stringifyResult(CallHistoryResultInput.UnsuccessfulLeftText)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftVm}>{stringifyResult(CallHistoryResultInput.UnsuccessfulLeftVm)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftEmail}>{stringifyResult(CallHistoryResultInput.UnsuccessfulLeftEmail)}</MenuItem>
  </FormSelect>
}


function renderResultEditInputCell(params: GridRenderEditCellParams) {
  return <ResultSelect {...params} />;
}

const stringifyCallType = (type?: CallHistoryCallType) => {
  switch (type) {
    case CallHistoryCallType.Inbound: return 'Inbound call';
    case CallHistoryCallType.Outbound: return 'Outbound attempt';
    default: return '';
  }
}

const CallTypeSelect = (props: GridRenderEditCellParams) => {
  const { id, value, field, api } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
      /*api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');*/
  };

  return <FormSelect value={value?.toString()}
                     onChange={handleChange}
  >
    <MenuItem value={CallHistoryCallType.Inbound}>{stringifyCallType(CallHistoryCallType.Inbound)}</MenuItem>
    <MenuItem value={CallHistoryCallType.Outbound}>{stringifyCallType(CallHistoryCallType.Outbound)}</MenuItem>
  </FormSelect>
}

function renderCallTypeEditInputCell(params: GridRenderEditCellParams) {
  return <CallTypeSelect {...params} />;
}

const columns: GridColDef[] = [
  { field: 'createdAt', headerName: 'Date and Time (ET)', type: 'dateTime', width: 200,
    renderCell: params => moment(params.value?.toString()).format('MM/DD/YYYY H:mm:SS')
  },
  { field: 'callType',
    headerName: 'Call Type',
    renderCell: (params) => stringifyCallType(params.value?.toString() as CallHistoryCallType),
    renderEditCell: renderCallTypeEditInputCell,
    width: 200,
    editable: true
  },
  { field: 'result',
    headerName: 'Result',
    renderCell: (params) => stringifyResult(params.value?.toString() as CallHistoryResultInput),
    renderEditCell: renderResultEditInputCell,
    width: 200,
    editable: true },
  { field: 'phoneNumber', headerName: 'Number', width: 150, editable: true },
  { field: 'agent',
    headerName: 'Agent',
    renderCell: (params) => {
      return params.value ? (params.value as any)?.firstName + ' ' + (params.value as any)?.lastName : ''
    },
    width: 200 },
  {field: 'audioRecordId',
    headerName: 'Audio',
    renderCell: (params) => {
      return <AudioButton id={params.row.audioRecordId} />
    },
    width: 200
  },
  {field: 'transcription',
    headerName: 'Transcription',
    renderCell: (params) => {
      return <TranscriptionButton transcription={params.row.transcription}  />
    },
    width: 200
  }
];

const AudioButton = ({id}: {id?: string}) => {
  const [link, setLink] = React.useState<string>();
  const [showLoading, setShowLoading] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      setLink(config.aircallUrl + '/' + id)
    }
  }, [id])

  const onClick = React.useCallback(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + keycloak?.token
      },
    };
    if (link) {
      setShowLoading(true);
      fetch(link, requestOptions).then(response => {
        return response.blob().then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', id + '.mpeg');
          document.body.appendChild(link);
          link.click();
        })
      }).finally(() => setShowLoading(false))
    }
  }, [link])

  if (showLoading) {
    return <CircularProgress size={15} />
  }

  if (!id) {
    return null;
  }

  return <>
    <IconButton component="span" disabled={!link} onClick={onClick}>
      <PlayCircleOutlineIcon fontSize={'small'} />
    </IconButton>
  </>
}

const TranscriptionButton = ({transcription}: {transcription?: string}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!transcription) {
     return null;
  }

  return <>
    <IconButton component="span" onClick={handleClickOpen}>
      <ViewHeadlineIcon fontSize={'small'} />
    </IconButton>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        Audio transcription
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.transcription} dangerouslySetInnerHTML={{__html: transcription as string}} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 8,
      right: 5,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    transcription: {
      whiteSpace: 'pre-line',
    }
  }),
);

interface CallLogProps {
  loading: boolean;
  client?: ClientViewOutput;
  phoneNumber?: string | null;
}


const CallHistory = (props: CallLogProps) => {
  const [page, setPage] = useCallHistoryPage();
  const [pageSize, setPageSize] = useCallHistoryPageSize();

  const [getCalls, {loading, refetch, data}] = useCallHistoryItems({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.callHistoryItems) {
        setRows(data.callHistoryItems.data)
      }
    }
  });
  const [rows, setRows] = React.useState<any>([]);
  const [save] = useSaveCallHistory()
  const {enqueueSnackbar} = useSnackbar();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);
  const [setConnected] = useSetUrgentCalRequested()


  const handleEditRow = React.useCallback((params: any) => {
    const newVal = {...params.row, createdAt: undefined, [params.field]: params.value?.toString(), id: _.isNumber(params.row.id) ? undefined : params.row.id};
    delete newVal.agent;
    delete newVal.audioRecordId;
    delete newVal.transcription;
    if (newVal.callType && newVal.result && props.client?.id) {
      save({variables: {input: newVal}})
        .then(() => {
          getCalls({variables: {clientId: props.client?.id, page: {page: 0, size: 1000}}})
        })
        .catch(e => {
          enqueueSnackbar(e.message, {variant: "error"});
        })
    }
  }, [props.client?.id]);

  React.useEffect(() => {
    load();
  }, [props.client?.id]);

  const load = React.useCallback(() => {
    if (props.client?.id) {
      getCalls({variables: {clientId: props.client?.id, page: {page: 0, size: 1000}}})
    }
  }, [props.client?.id])

  const onAddClick = () => {
    const id = Math.random();
    setRows([{id: id, createdAt: new Date(), clientId: props.client?.id, phoneNumber: props.phoneNumber}, ...rows]);
    /*apiRef.current.setRowMode(id, 'edit');
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });

      apiRef.current.setCellFocus(id, 'name');
    }, 150);*/
    /*save({variables: {input: {clientId: props.clientId, phoneNumber: props.phoneNumber || ''}}})
      .then(res => {
        setRows([{id: res.data?.saveCallHistory, createdAt: new Date(), clientId: props.clientId, phoneNumber: props.phoneNumber}, ...rows]);
      }).catch(e => {
        enqueueSnackbar(e.message, {variant: "error"});
      })*/
  }

  const onConnectedClick = async () => {
    await setConnected({variables: {clientId: props.client?.id, urgentCallRequested: false}})
  }

  return <>
    <Box display={'flex'} justifyContent={'space-between'}>
      <Card padding={'16px'} className={'relative w-400'}>
        <Preloader in={props.loading} />
        <div className={'flex-space-between'}>
          <Typography color={'textPrimary'} className={'fs-12'}>Successful:</Typography>
          <Typography color={'textPrimary'} className={'fs-12'}>
            {rows.filter((r: CallHistoryOutput) => r.result === CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length}
          </Typography>
        </div>
        <div className={'flex-space-between'}>
          <Typography color={'textPrimary'} className={'fs-12'}>Unsuccessful:</Typography>
          <Typography color={'textPrimary'} className={'fs-12'}>
            {rows.filter((r: CallHistoryOutput) => r.result !== CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length}
          </Typography>
        </div>
        <Divider />
        <div className={'h-4'}/>
        <div className={'flex-space-between'}>
          <Typography color={'textPrimary'} className={'fs-12 medium'}>Total calls:</Typography>
          <Typography color={'textPrimary'} className={'fs-12 medium'}>{rows.length}</Typography>
        </div>
      </Card>
    </Box>
    <div className={'h-16'}/>
    <div className={'flex-space-between flex-align-end'}>
      <Typography color={'textPrimary'} className={'fs-16 medium'}>Call History</Typography>
      <div className={'flex-space-between'}>
        {props.client?.urgentCallRequested && <Button className={'mb-5 mr-16'}
                                   variant={'contained'}
                                   onClick={() => onConnectedClick()}
                                   color={'secondary'}>REMOVE FROM URGENT CALLS</Button>}
        <Button className={'mb-5 mr-16'}
                variant={'outlined'}
                onClick={load}
                disabled={loading}
                startIcon={<RefreshIcon />}
                color={'primary'}>REFRESH</Button>
        <Button className={'mb-5'}
                variant={'contained'}
                onClick={() => onAddClick()}
                disabled={loading}
                startIcon={<AddIcon />}
                color={'primary'}>ADD CALL</Button>
      </div>
    </div>
    <Card padding={0} className={'relative'}>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <Preloader in={props.loading} />
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid rows={rows}
                    columns={columns}
                    page={page}
                    loading={loading}
                    editMode="row"
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowCount={rows.length}
                    onRowEditStop={handleEditRow}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
          />
        </div>
      </Box>
    </Card>
  </>;
}

export default CallHistory;
