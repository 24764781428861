import {QueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  EnabledPbpStatesOutput
} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useEnabledMaStates(options?: QueryHookOptions<{enabledPbpStates: EnabledPbpStatesOutput[]}>) {
  return useQuery<{enabledPbpStates: EnabledPbpStatesOutput[]}>(gql(query), {
    fetchPolicy: 'no-cache',
    client,
    ...options
  });
}

const query = `
  query {
    enabledPbpStates {
      enabled
      state
    }
  }
`
