import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveEnabledPdpContractArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";


export default function useSaveEnabledMaContract(options?: MutationHookOptions<{saveEnabledPdpContract: boolean}, MutationSaveEnabledPdpContractArgs>) {
  return useMutation<{saveEnabledPdpContract: boolean}, MutationSaveEnabledPdpContractArgs>(gql(save), {
    client,
    ...options
  })
}

const save = `
mutation ($input: EnabledPdpContractInput!) {
  saveEnabledPdpContract(input: $input)
}
`;

