import React from "react";
import {gql, QueryResult, useLazyQuery} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import * as _ from 'lodash';

function useCompanies<T>(companiesQuery: string, mapFunc: (data: any) => string[], filter: T, filterCondition?: (filter: T) => boolean, vars?: any): [string[], QueryResult<any>] {
  const [companies, setCompanies] = React.useState<string[]>([]);
  const [innerFilter, setInnerFilter] = React.useState<any>();
  const [getCompanies, data] = useLazyQuery(gql(companiesQuery), {
    onCompleted: data => {
      setCompanies(mapFunc(data))
    },
    client
  });

  React.useEffect(() => {
    if ((filterCondition && innerFilter && filterCondition(innerFilter)) || (!filterCondition && innerFilter)) {
      let variables = {
        filter,
      };
      if (vars) {
        variables = {...variables, ...vars};
      }
      getCompanies({
        variables
      })
    }
  }, [innerFilter])

  React.useEffect(() => {
    if (!_.isEqual(filter, innerFilter)) {
      setInnerFilter(filter)
    }
  }, [filter])

  return [companies, data]
}

export default useCompanies;