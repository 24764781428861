import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, IconButton} from "@material-ui/core";
import Preloader from "../../shared/Preloader";
import Card from "../../shared/Card";
import moment from "moment";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import * as _ from 'lodash';
import useEmailStats from "../hooks/useEmailStats";
import {SibEvent} from "../../types";

const columns: GridColDef[] = [
  { field: 'eventDate', headerName: 'Event Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'eventType', headerName: 'Event Type', width: 150 },
  { field: 'emailToken', headerName: 'Email Token', width: 250 },
  { field: 'messageId', headerName: 'Message Id', width: 150 },
  { field: 'subject', headerName: 'Subject', width: 150 },
  { field: 'templateId', headerName: 'Template Id', width: 150 },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    }
  }),
);

interface EmailStatsProps {
  loading: boolean;
  email?: string | null;
}

const EmailStats = (props: EmailStatsProps) => {
  const classes = useStyles();
  const [getEmailStats, {loading, refetch, data}] = useEmailStats({
    fetchPolicy: 'no-cache'
  });
  const [rows, setRows] = React.useState<SibEvent[]>([]);

  React.useEffect(() => {
    if (props.email) {
      getEmailStats({variables: {email: props.email}})
    }
  }, [props.email]);

  React.useEffect(() => {
    if (!!data?.emailStats) {
      setRows(data.emailStats);
    }
  }, [data]);

  return <Card padding={0} className={'relative'}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={props.loading} />
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                  columns={columns}
                  loading={loading}
                  page={0}
                  pageSize={100}
                  paginationMode={'client'}
                  rowCount={rows.length}
        />
      </div>
    </Box>
  </Card>;;
}

export default EmailStats;
