import { MutationHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import {client} from "../../../Apollo/ApolloClient";
import {PackInfoOuput} from "../../../types";

export function useGetPackagesRequest(options?: MutationHookOptions<{packages: PackInfoOuput[]}, {rxcui: string}>) {
  return useLazyQuery<{packages: PackInfoOuput[]}, {rxcui: string}>(
    gql(getDrugRequest),
    {
      fetchPolicy: 'no-cache',
      client,
      ...options
    }
  );
}

const getDrugRequest = `
query ($rxcui: String!) {
  packages(rxcui: $rxcui) {
    name
    rxcui
  }
}
`;
