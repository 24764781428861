import React, {Dispatch, SetStateAction} from "react";
import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  IconButton, Popover,
  Theme,
  Typography
} from "@material-ui/core";
import * as _ from "lodash";
import AscendAutocomplete from "./AscendAutocomplete";
import TextInput from "./TextInput";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {ProviderAddressOutput, ProviderOutput} from "../types";
import {ScrollbarList} from "./ScrollbarList";
import {client} from "../Apollo/ApolloClient";
import AddressSelectionModal from "./AddressSelectionModal";
import {DoctorAddressViewOutput, DoctorViewOutput} from "../enrollment-types";

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 90
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 8,
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
  },
});

type DoctorSelectionProps = {
  zip: string,
  selectedDoctors: DoctorViewOutput[],
  onChange: (doctors: DoctorViewOutput[]) => void
}

export const DoctorSelection = ({
                                  zip,
                                  selectedDoctors,
                                  onChange
                                }: DoctorSelectionProps) => {
  const classes = useStyles();
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [getDoctors, data] = useLazyQuery<{findProvidersByFilter: ProviderOutput[]}, {zip: string, searchTerm: string}>(gql(doctorsRequest), {client});
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState<ProviderOutput>();
  const inputRef = React.useRef(null);
  const [showForm, setShowForm] = React.useState<number>();

  React.useEffect(() => {
    if (doctor) {
      if (doctor.addressesNumber > 1) {
        setModalOpen(true)
      } else {

        onChange([...selectedDoctors, doctor as DoctorViewOutput]);
        setDoctor(undefined);
      }
    }
  }, [doctor])

  React.useEffect(() => {
      if (selectedDoctors.length) {
        setShowAutocomplete(false);
      }
  }, [selectedDoctors]);

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  React.useEffect(() => {
    if (!!autocompleteValue) {
      getDoctors({
        variables: {
          zip,
          searchTerm: autocompleteValue
        }
      })
    }
  }, [autocompleteValue])

  const onModalClose = (addresses?: DoctorAddressViewOutput[]) => {
    if (addresses && doctor) {
      onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi),
        {
          ...doctor as DoctorViewOutput,
          addresses,
        }])
    } else {
      setDoctor(undefined);
    }
    setModalOpen(false);
  }

  const onDeleteClick = (doctor: DoctorViewOutput) => {
    onChange(_.without(selectedDoctors, doctor));
  }

  const onEditClick = (index: number) => {
    setShowForm(index);
  }

  const onSaveClick = (doctor: DoctorViewOutput) => {
    if (doctor) {
      onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi), doctor
      ]);
    }
    setShowForm(undefined);
  }

  const autocompleteOpen = React.useMemo(() => {
    return !!autocompleteValue && !data.loading && data.data?.findProvidersByFilter?.length && open
  }, [autocompleteValue, data, open])

  return <>
    <AddressSelectionModal open={modalOpen} doctor={doctor} onClose={onModalClose} />
    {selectedDoctors.map((doctor, index) => <Box key={doctor.npi} display={'flex'} className={'mb-10'}>
      <Typography variant={'body1'} color={'textPrimary'} className={`w-15`}>{index + 1}.</Typography>
      {showForm !== index && <DoctorLine doctor={doctor} showPCP showDelete onEditClick={() => onEditClick(index)} onDeleteClick={() => onDeleteClick(doctor)} />}
      {showForm === index && <DoctorForm doctor={doctor} onCancel={() => setShowForm(undefined)} onSaveClick={onSaveClick} />}
    </Box>)}
    {!!selectedDoctors.length && <div className={'h-16'} />}
    {showAutocomplete && <AscendAutocomplete fullWidth
                                             open={!!autocompleteOpen}
                                             onClose={() => {
                                               setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                                             }}
                                             onFocus={() => setOpen(true)}
                                             options={(data.data?.findProvidersByFilter || []).filter((doctor: ProviderOutput) =>
                                               !selectedDoctors.some(d => d.npi === doctor.npi))
                                             }
                                             value={autocompleteValue}
                                             onBlur={() => {
                                               if (!autocompleteValue) {
                                                 setShowAutocomplete(false)
                                               }
                                             }}
                                             onInputChange={(event, newInputValue) => {
                                               setAutocompleteValue(newInputValue);
                                             }}
                                             clearOnBlur
                                             filterOptions={(options) => options}
                                             popupIcon={data.loading ? <CircularProgress className={classes.loadingIcon}/> : undefined}
                                             onChange={(e: any, doctor: any | null) => {
                                               if (doctor) {
                                                 setDoctor({...doctor, address: {...doctor.address}});
                                                 setAutocompleteValue('');
                                                 setShowAutocomplete(false);
                                               }
                                             }}
                                             ListboxComponent={ScrollbarList as any}
                                             renderOption={(doctor: any, state) => <DoctorLine doctor={doctor} />}
                                             renderInput={params => <TextInput inputRef={inputRef}
                                                                               placeholder={'Search doctor name'}
                                                                               {...params} />} />}
    {!showAutocomplete && <Typography className={'dark-green pointer mt-10'}
                                      variant={'body2'}
                                      onClick={() => setShowAutocomplete(true)}>
      <img src={'/img/add-icon.svg'}/> Add Doctor
    </Typography>}
  </>
}

const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);

type DoctorLineProps = {
  doctor: DoctorViewOutput,
  showDelete?: boolean,
  showPCP?: boolean,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
}

const DoctorLine = (props: DoctorLineProps) => {
  const classes = useLineStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return <Box className={classes.root}>
    <Box flex={'1 1 50%'} overflow={'hidden'}>
      <Typography variant={'body1'} color={'textPrimary'}>{props.doctor.name}</Typography>
      {props.showPCP && <Typography variant={'body1'} color={'textSecondary'}>PCP: {props.doctor.pcpDetails ? props.doctor.pcpDetails[0]?.pcpId : '-'}</Typography>}
    </Box>
    <Box flex={'1 1 50%'} overflow={'hidden'} className={'ml-8'}>
      <Typography noWrap align={'right'} color={'textSecondary'}>{props.doctor?.addresses[0]?.specialty}</Typography>
      <AddressBlock address={props.doctor.addresses[0]} />
      {props.doctor.addresses.length > 1 && <>
        <Typography onMouseEnter={(event: any) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    onMouseLeave={() => {
                      setAnchorEl(null);
                    }}
                    align={'right'}
                    className={'fs-12 lh-15 mb-4'}>+ {props.doctor.addresses.length - 1} more</Typography>
        <Popover
          style={{pointerEvents: 'none'}}
          PaperProps={{
            className: 'ph-16 pv-8'
          }}
          open={open}
          anchorEl={anchorEl}
          elevation={2}
          onClose={() => {
            setAnchorEl(null);
          }}
          disableRestoreFocus
          hideBackdrop
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {props.doctor.addresses.slice(1).map((a, i) => <div key={i}>
            <Divider className={'mb-4' + (i > 0 ? ' mt-8' : '')}/>
            <AddressBlock address={a} />
          </div>)}
        </Popover>
      </>}
    </Box>
    {props.showDelete && <Box display={'flex'} alignItems={'center'}>
      <IconButton size={'small'} className={'ml-16'} onClick={props.onEditClick}><img src={'/img/mode.svg'}/></IconButton>
      <IconButton size={'small'} className={'ml-8 mr-35'} onClick={props.onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
    </Box>}
  </Box>
}
const AddressBlock = ({address}: {address?: DoctorAddressViewOutput}) => (
  <Box display={'flex'} flexDirection={'column'} marginTop={0} alignItems={{xs: 'flex-start', sm: 'flex-end'}}>
    <Typography variant={'body2'}>{address?.addressLine1}</Typography>
    <Typography variant={'body2'}>{address?.addressLine2}</Typography>
  </Box>
)

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);

type DoctorFormProps = {
  doctor: DoctorViewOutput,
  onCancel: () => void,
  onSaveClick: (doctor: DoctorViewOutput) => void,
}

const DoctorForm = (props: DoctorFormProps) => {
  const classes = useFormStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState(props.doctor);

  const onModalClose = (addresses?: DoctorAddressViewOutput[]) => {
    if (addresses) {
      setDoctor({
          ...doctor as DoctorViewOutput,
          addresses,
        });
    }
    setModalOpen(false);
  }

  return <div className={'fill-width'}>
    <AddressSelectionModal open={modalOpen} doctor={props.doctor as ProviderOutput} onClose={onModalClose} />
    <Box className={classes.root}>
      <Box flex={'1 1 50%'} overflow={'hidden'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        <Typography variant={'body1'} color={'textPrimary'}>{doctor.name}</Typography>
        <TextInput
          disabled
          className={'mt-10'}
          fullWidth
          name="pcp"
          label="PCP"
          value={doctor.pcpDetails ? doctor.pcpDetails[0]?.pcpId : ''}
          onChange={(e) => {
            e.persist();
            setDoctor(prev => ({
              ...prev,
              pcpDetails: [{networkDetails: [{carrierName: '', name: ''}], pcpId: e.target?.value, id: ''}]
            }))
          }}
        />
      </Box>
      <Box flex={'1 1 50%'} overflow={'hidden'} className={'ml-8'}>
        <Typography noWrap align={'right'} color={'textSecondary'}>{doctor.addresses[0].specialty}</Typography>
        <Typography color={'textSecondary'} noWrap align={'right'}>
          {doctor.addresses[0].addressLine1}
          {doctor.addresses[0].addressLine2}
        </Typography>
        <Typography className={'dark-green pointer fs-12 lh-16 mt-10'}
                    align={'right'}
                    onClick={() => setModalOpen(true)}>
          Change office
        </Typography>
      </Box>
    </Box>
    <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button variant={'contained'} onClick={() => props.onSaveClick(doctor)} color={'primary'}>SAVE</Button>
      </Grid>
    </Grid>
  </div>
}

const doctorsRequest = `
query ($zip: String!, $searchTerm: String!) {
  findProvidersByFilter(filterInput: {zipCode: $zip, searchTerm: $searchTerm}) {
    addresses {
      addressLine1
      addressLine2
      id
      specialty
    }
    addressesNumber
    name
    npi
    pcpDetails {
      id
      networkDetails {
        carrierName
        name
      }
      pcpId
    }
  }
}
`;
