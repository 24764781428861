import {LazyQueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentEmailOutput,
  QueryGmailMessagesByClientArgs
} from "../../../../enrollment-types";

export default function useGmailMessagesByClients(options?: LazyQueryHookOptions<{gmailMessagesByClient: AgentEmailOutput[]}, QueryGmailMessagesByClientArgs>) {
  return useQuery<{gmailMessagesByClient: AgentEmailOutput[]}, QueryGmailMessagesByClientArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($clientId: UUID!) {
  gmailMessagesByClient(clientId: $clientId) {
    body
    createdAt
    from
    snippet
    subject
    to
    agentId
    id
  }
}
`
