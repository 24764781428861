import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveCompareOfferArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export function useSaveCompareOffer(options?: MutationHookOptions<{saveCompareOffer: string}, MutationSaveCompareOfferArgs>) {
  return useMutation<{saveCompareOffer: string}, MutationSaveCompareOfferArgs>(gql(mutation), {
    ...options,
    client
  })
}

const mutation = `
mutation ($compareOffer: CompareOfferInput!) {
  saveCompareOffer(compareOffer: $compareOffer)
}
`;
