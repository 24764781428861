import React from "react";
import {Grid, GridSize, Typography} from "@material-ui/core";
import Card from "../shared/Card";
import FollowUps from "./components/FollowUps";
import moment from "moment";
import {AgentPerformanceMetricOutput} from "../enrollment-types";
import momentDurationFormatSetup from "moment-duration-format";
import Appointments from "./components/Appointments";
import useAppointments from "./hooks/useAppointments";
import Contacted from "./components/Contacted";
import SalesQualified from "./components/SalesQualified";
import FollowUpsMissingApps from "./components/FollowUpsMissingApps";
momentDurationFormatSetup(moment as any);

const AgentDashboard = (props: {data: AgentPerformanceMetricOutput[], agentId?: string}) => {
  const getMetric = React.useCallback(metrics(props.data || []), [props.data]);
  const {data: appointmentsData, } = useAppointments({variables: {agentId: props.agentId}, shouldResubscribe: true});

  const getColor = (val1?: number, val2?: number): string | undefined => {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined') {
      return undefined;
    } else {
      return val1 >= val2 ? '#5ecb9e' : '#c00000'
    }
  };

  const submittedAppsQuarterTotal = React.useMemo(() => {
    return getMetric(Metrics.submitted_apps_quarter, 'MA') + getMetric(Metrics.submitted_apps_quarter, 'MG') + getMetric(Metrics.submitted_apps_quarter, 'PDP')
  }, [getMetric]);

  const submittedAppsMtdTotal = React.useMemo(() => {
    return getMetric(Metrics.submitted_apps_mtd, 'MA') + getMetric(Metrics.submitted_apps_mtd, 'MG') + getMetric(Metrics.submitted_apps_mtd, 'PDP');
  }, [getMetric]);

  const cohortSubmittedAppsMtdTotal = React.useMemo(() => {
    return getMetric(Metrics.cohort_submitted_apps_mtd, 'MA') + getMetric(Metrics.cohort_submitted_apps_mtd, 'MG') + getMetric(Metrics.cohort_submitted_apps_mtd, 'PDP');
  }, [getMetric]);

  const cohortSubmittedAppsQuarterTotal = React.useMemo(() => {
    return getMetric(Metrics.cohort_submitted_apps_quarter, 'MA') + getMetric(Metrics.cohort_submitted_apps_quarter, 'MG') + getMetric(Metrics.cohort_submitted_apps_quarter, 'PDP');
  }, [getMetric]);

  return <Grid className={'mt-10'} container spacing={2} direction={'column'}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography className={'bold fs-18 w-400'} color={'textPrimary'}>Call Metrics</Typography>
        <Grid container direction={'column'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Outbound Calls'} values={[[{label: 'Today', value: getMetric(Metrics.outbound_call_today), color: getColor(getMetric(Metrics.outbound_call_today), getMetric(Metrics.outbound_call_mtd))}, {label: 'MTD', value: getMetric(Metrics.outbound_call_mtd)}]]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'Inbound Calls'} values={[[{label: 'Today', value: getMetric(Metrics.inbound_call_today), color: getColor(getMetric(Metrics.inbound_call_today), getMetric(Metrics.inbound_call_mtd))}, {label: 'MTD', value: getMetric(Metrics.inbound_call_mtd)}]]} />
            </Grid>
          </Grid>
          <Grid container className={'mt-8'} spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Outbound Success'} values={[[{label: 'Today', value: getMetric(Metrics.outbound_call_success_today) + '%', color: getColor(getMetric(Metrics.outbound_call_success_today), getMetric(Metrics.outbound_call_success_mtd))}, {label: 'MTD', value: getMetric(Metrics.outbound_call_success_mtd) + '%'}]]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'Average Talk Time'} values={[[{label: 'Today', value: secToMin(getMetric(Metrics.average_talk_time_today)), color: getColor(getMetric(Metrics.average_talk_time_today), getMetric(Metrics.average_talk_time_mtd))}, {label: 'MTD', value: secToMin(getMetric(Metrics.average_talk_time_mtd))}]]} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={'bold fs-18 mt-30'} color={'textPrimary'}>Your Calendar</Typography>
            <div>
              <Card padding={0}>
                <Appointments appointments={appointmentsData?.appointments} />
              </Card>
            </div>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Calendar</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Cohort</Typography>
          </Grid>
        </Grid>
        <Grid container direction={'column'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Live Sales Qualified Leads'} values={[
                [{label: 'Current Total', value: getMetric(Metrics.sales_qualified_leads_live)}],
              ]} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Live Follow Ups'} values={[
                [{label: 'Current Total', value: getMetric(Metrics.follow_up_live)}, {label: 'High Intent', value: getMetric(Metrics.calendar_high_intent_mtd)}],
              ]} />
            </Grid>
          </Grid>
          <Grid container className={'mt-8'} spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Submitted Apps'} values={[
                [{label: 'MTD', value: submittedAppsMtdTotal}],
                [{label: 'MA', value: getMetric(Metrics.submitted_apps_mtd, 'MA')}, {label: 'MG', value: getMetric(Metrics.submitted_apps_mtd, 'MG')}, {label: 'PDP', value: getMetric(Metrics.submitted_apps_mtd, 'PDP')}],
              ]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'This Month'} values={[
                [{label: 'Follow Up (Static)', value: getMetric(Metrics.cohort_follow_up_mtd)}],
                [{label: 'Submitted Apps', value: cohortSubmittedAppsMtdTotal}, {label: 'Submitted Apps %', value: toString(cohortSubmittedAppsMtdTotal / getMetric(Metrics.cohort_follow_up_mtd) * 100)}],
                [{label: 'Unique client enrollment', value: getMetric(Metrics.cohort_clients_mtd)}, {label: 'Client enrollment %', value: toString(getMetric(Metrics.cohort_clients_mtd) / getMetric(Metrics.cohort_follow_up_mtd) * 100)}],
              ]} />
            </Grid>
          </Grid>
          <Grid container className={'mt-8'} spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Submitted Apps'} values={[
                [{label: 'This quarter total', value: submittedAppsQuarterTotal}],
                [{label: 'MA', value: getMetric(Metrics.submitted_apps_quarter, 'MA')}, {label: 'MG', value: getMetric(Metrics.submitted_apps_quarter, 'MG')}, {label: 'PDP', value: getMetric(Metrics.submitted_apps_quarter, 'PDP')}],
              ]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'This Quarter'} values={[
                [{label: 'Follow Up (Static)', value: getMetric(Metrics.cohort_follow_up_quarter)}],
                [{label: 'Submitted Apps', value: cohortSubmittedAppsQuarterTotal}, {label: 'Submitted Apps %', value: toString(cohortSubmittedAppsQuarterTotal / getMetric(Metrics.cohort_follow_up_quarter) * 100)}],
                [{label: 'Unique client enrollment', value: getMetric(Metrics.cohort_clients_quarter)}, {label: 'Client enrollment %', value: toString(getMetric(Metrics.cohort_clients_quarter) / getMetric(Metrics.cohort_follow_up_quarter) * 100)}],
              ]} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item style={{marginLeft: -8, marginRight: -8, marginTop: 20}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Your Sales qualified</Typography>
          <div>
            <Card padding={0}>
              <SalesQualified agentId={props.agentId} />
            </Card>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Your Follow-Ups</Typography>
          <div>
            <Card padding={0}>
              <FollowUps agentId={props.agentId} />
            </Card>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Missing Apps</Typography>
          <div>
            <Card padding={0}>
              <FollowUpsMissingApps agentId={props.agentId} />
            </Card>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Contacted</Typography>
          <div>
            <Card padding={0}>
              <Contacted agentId={props.agentId} />
            </Card>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default AgentDashboard;

const Metric = (props: {title: string, values: Value[][]}) => {
  return <Card className={'mv-16'}>
    <Typography className={'medium fs-16 mb-5'} color={'textPrimary'}>{props.title}</Typography>
    <Grid container>
      {props.values.map((valuesRow, i1) => <Grid key={i1 + 'i1'} container>
        {valuesRow.map((value, i2, array) => <Grid key={i2 + 'i2'} xs={Math.floor(12 / array.length) as GridSize} item>
          <Typography className={'fs-12'} color={'textSecondary'}>{value.label.toUpperCase()}</Typography>
          <Typography className={'bold fs-24'} style={{color: value.color}} color={'textPrimary'}>{value.value}</Typography>
        </Grid>)}
      </Grid>)}
    </Grid>
  </Card>
}

interface Value {
  color?: string
  label: string;
  value?: number | string;
}

const toString = (value: any) => isNaN(value) ? '0.0%' : value.toFixed(1) + '%';

const secToMin = (value?: number) => value ? moment.duration(value, 'seconds').format("h[h] mm[m]") : 0;

const metrics = (data: Array<AgentPerformanceMetricOutput>) => (metric: Metrics, secondaryKey: string | null = null): number => data.some(i => i.primaryKey === metric && i.secondaryKey === secondaryKey) ? Math.floor(parseFloat(data.find(i => i.primaryKey === metric && i.secondaryKey === secondaryKey)!.value)) : 0;

enum Metrics {
  inbound_call_mtd = 'inbound_call_mtd',
  inbound_call_today = 'inbound_call_today',
  average_talk_time_mtd = 'average_talk_time_mtd',
  average_talk_time_today = 'average_talk_time_today',
  outbound_call_mtd = 'outbound_call_mtd',
  outbound_call_today = 'outbound_call_today',
  outbound_call_success_mtd = 'outbound_call_success_mtd',
  outbound_call_success_today = 'outbound_call_success_today',
  submitted_apps_mtd = 'submitted_apps_mtd',
  submitted_apps_quarter = 'submitted_apps_quarter',
  follow_up_mtd = 'follow_up_mtd',
  follow_up_quarter = 'follow_up_quarter',
  follow_up_live = 'follow_up_live',
  calendar_high_intent_mtd = 'calendar_high_intent_mtd',
  cohort_follow_up_mtd = 'cohort_follow_up_mtd',
  cohort_submitted_apps_mtd = 'cohort_submitted_apps_mtd',
  cohort_clients_mtd = 'cohort_clients_mtd',
  cohort_follow_up_quarter = 'cohort_follow_up_quarter',
  cohort_submitted_apps_quarter = 'cohort_submitted_apps_quarter',
  cohort_clients_quarter = 'cohort_clients_quarter',
  sales_qualified_leads_live = 'sales_qualified_leads_live',
}
