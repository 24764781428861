import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveEnabledPbpStateArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";


export default function useSaveEnabledMaState(options?: MutationHookOptions<{saveEnabledPbpState: boolean}, MutationSaveEnabledPbpStateArgs>) {
  return useMutation<{saveEnabledPbpState: boolean}, MutationSaveEnabledPbpStateArgs>(gql(save), {
    client,
    ...options
  })
}

const save = `
mutation ($enabled: Boolean!, $state: AvailableStateDto!) {
  saveEnabledPbpState(enabled: $enabled, state: $state)
}
`;

