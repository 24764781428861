import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {QueryVideoContentArgs, VideoCategoryWithVideos} from "../../content-management";


export default function useVideoContent() {
  return useLazyQuery<{videoContent: VideoCategoryWithVideos}, QueryVideoContentArgs>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
      client
    }
  );
}

const request = `
query($categoryId: UUID!) {
  videoContent(categoryId: $categoryId) {
    id
    title
    sortingOrder
    videos {
      sortingOrder
      categoryId
      description
      duration
      id
      title
      url
    }
  }
}
`;
