import {ISectionParam} from "./Section";
import {IRawPlan} from "./PlanList";
import moment from "moment";

export function getAgeByBirthday(birthday: string): number {
  return getAge(birthday)
}

export function getAge(birthday: string, secondDate?: string): number {
  return Math.floor(moment.duration(moment(secondDate).diff(moment(birthday))).asYears())
}

export function fillBenefits(rp: IRawPlan): ISectionParam[] {
  rp = convertToNumbers(rp, fieldsToConvert);
  const result: ISectionParam[] = [];
  const primary = fillPrimaryDoctorBenefits(rp) as ISectionParam;
  const specialist = fillSpecialistBenefits(rp) as ISectionParam;

  if (primary) {
    result.push(primary);
  }

  if (specialist) {
    result.push(specialist);
  }

  return result;
}

export function formatPhoneLink(
    phoneNumberString: string,
    useFormat: boolean = false
): string | null {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})/);
  if (useFormat && match) {
    return [match[2], match[3], match[4]].join("-");
  }
  return cleaned ?? null;
}

const getString = (max: number | null, min: number | null, paramName: 'copay' | 'coinsurance', prefix?: string): string => {
  if (max === min) {
    return paramName === 'copay' ?
      `${prefix}$${max} copay` :
      `${prefix}${max}% coinsurance`
  } else {
    return paramName === 'copay' ?
      `${prefix}$${min}-$${max} copay, depending on the service` :
      `${prefix}${min}%-${max}% coinsurance, depending on the service`
  }
}

function fillBenefitsBase(
  title: string,
  copay_yn: number | null,
  copay_min: number | null,
  copay_max: number | null,
  coins_yn: number | null,
  coins_min: number | null,
  coins_max: number | null,
  out_copay_yn: number | null,
  out_copay_min: number | null,
  out_copay_max: number | null,
  out_coins_yn: number | null,
  out_coins_min: number | null,
  out_coins_max: number | null,
  asArray?: boolean
): ISectionParam | undefined | string[] {
  const output: string[] = [];

  if (typeof copay_yn === 'undefined' && typeof coins_yn === 'undefined') {
    return undefined
  }

  const getInNetworkCopay = (): string =>
    getString(copay_max, copay_min, 'copay', 'In network: ');

  const getOutOfNetworkCopay = (): string =>
    getString(out_copay_max, out_copay_min, 'copay', 'Out of network: ');

  const getInNetworkCoinsurance = (): string =>
    getString(coins_max, coins_min, 'coinsurance', 'In network: ');

  const getOutOfNetworkCoinsurance = (): string =>
    getString(out_coins_max, out_coins_min, 'coinsurance', 'Out of network: ');

  if (copay_yn !== 1 && coins_yn !== 1 && out_copay_yn !== 1 && out_coins_yn !== 1) {
    output.push('You pay nothing')
  } else {
    // copay
    if (copay_yn === 1 || out_copay_yn === 1) {
      if (copay_yn === out_copay_yn) {
        // IN and OUT OF network copay
        if (isAllEqual([copay_max, copay_min, out_copay_max, out_copay_min])) {
          // all equal
          output.push(`$${copay_max} copay`)
        } else {
          output.push(getInNetworkCopay())
          output.push(getOutOfNetworkCopay())
        }
      } else if (copay_yn === 1) {
        // IN network copay
        if (copay_max === copay_min && out_copay_yn !== 1 && out_coins_yn !== 1) {
          output.push(`$${copay_max} copay`)
        } else {
          output.push(getInNetworkCopay())
        }
      } else if (out_copay_yn) {
        // OUT OF network copay
        output.push(getOutOfNetworkCopay())
      }
    } else if (out_coins_yn === 1) {
      // NO copay, but out of network coinsurance exists
      output.push(`In network: You pay nothing`)
    }
    if (coins_yn === 1 || out_coins_yn === 1) {
      // coinsurance
      if (coins_yn === out_coins_yn) {
        // IN and OUT OF network coinsurance
        if (isAllEqual([coins_max, coins_min, out_coins_max, out_coins_min])) {
          // all equal
          output.push(`${coins_max}% coinsurance`)
        } else {
          output.push(getInNetworkCoinsurance())
          output.push(getOutOfNetworkCoinsurance())
        }
      } else if (coins_yn === 1) {
        // IN network coinsurance
        if (coins_max === coins_min && out_coins_max === null && out_coins_min === null) {
          output.push(`${coins_max}% coinsurance`)
        } else {
          output.push(getInNetworkCoinsurance())
        }
      } else if (out_coins_yn) {
        // OUT OF network coinsurance
        output.push(getOutOfNetworkCoinsurance())
      }
    }
  }

  if (asArray) {
    return output;
  }

  return output.length ? {
    title,
    value: output.map(s => `<div>${s}</div>`).join('')
  } : undefined;
}

export const fillPrimaryDoctorBenefits = (rp: IRawPlan, asArray?: boolean): ISectionParam | undefined | string[] => fillBenefitsBase(
  'Office Visit for Primary Doctor',
  rp.pbp_b7a_copay_yn,
  rp.pbp_b7a_copay_amt_mc_min,
  rp.pbp_b7a_copay_amt_mc_max,
  rp.pbp_b7a_coins_yn,
  rp.pbp_b7a_coins_pct_mc_min,
  rp.pbp_b7a_coins_pct_mc_max,
  rp.oon_primary_pbp_c_oon_outpt_copay_yn,
  rp.oon_primary_pbp_c_oon_outpt_copay_min_amt,
  rp.oon_primary_pbp_c_oon_outpt_copay_max_amt,
  rp.oon_primary_pbp_c_oon_outpt_coins_yn,
  rp.oon_primary_pbp_c_oon_outpt_coins_min_pct,
  rp.oon_primary_pbp_c_oon_outpt_coins_max_pct,
  asArray
)

export const fillSpecialistBenefits = (rp: IRawPlan, asArray?: boolean): ISectionParam | undefined | string[] => fillBenefitsBase(
  'Office Visit for Specialist',
  rp.pbp_b7d_copay_yn,
  rp.pbp_b7d_copay_amt_mc_min,
  rp.pbp_b7d_copay_amt_mc_max,
  rp.pbp_b7d_coins_yn,
  rp.pbp_b7d_coins_pct_mc_min,
  rp.pbp_b7d_coins_pct_mc_max,
  rp.oon_specialist_pbp_c_oon_outpt_copay_yn,
  rp.oon_specialist_pbp_c_oon_outpt_copay_min_amt,
  rp.oon_specialist_pbp_c_oon_outpt_copay_max_amt,
  rp.oon_specialist_pbp_c_oon_outpt_coins_yn,
  rp.oon_specialist_pbp_c_oon_outpt_coins_min_pct,
  rp.oon_specialist_pbp_c_oon_outpt_coins_max_pct,
  asArray
)

function isAllEqual(params: any[]): boolean {
  if (!params.length) {
    return false
  }
  const test = params[0];

  for (const p of params) {
    if (p !== test) {
      return false;
    }
  }

  return true;
}

function convertToNumbers(plan: any, fields: string[]): IRawPlan {
  const res = {...plan};
  for (const field of fields) {
    if (typeof res[field] === 'string') {
      res[field] = parseFloat(res[field]) || null;
    }
  }
  return res;
}


export const forTests = {
  fillSpecialistBenefits,
  fillPrimaryDoctorBenefits
}


const fieldsToConvert = [
  'oon_primary_pbp_c_oon_outpt_coins_max_pct',
  'oon_primary_pbp_c_oon_outpt_coins_min_pct',
  'oon_primary_pbp_c_oon_outpt_coins_yn',
  'oon_primary_pbp_c_oon_outpt_copay_max_amt',
  'oon_primary_pbp_c_oon_outpt_copay_min_amt',
  'oon_primary_pbp_c_oon_outpt_copay_yn',
  'oon_specialist_pbp_c_oon_outpt_coins_max_pct',
  'oon_specialist_pbp_c_oon_outpt_coins_min_pct',
  'oon_specialist_pbp_c_oon_outpt_coins_yn',
  'oon_specialist_pbp_c_oon_outpt_copay_max_amt',
  'oon_specialist_pbp_c_oon_outpt_copay_min_amt',
  'oon_specialist_pbp_c_oon_outpt_copay_yn',
]
