import {QueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  EnabledPbpContractOutput
} from "../../types";
import {client} from "../../Apollo/ApolloClient";


export default function useEnabledPbpContracts(options?: QueryHookOptions<{enabledPbpContracts: EnabledPbpContractOutput[]}>) {
  return useQuery<{enabledPbpContracts: EnabledPbpContractOutput[]}>(gql(query), {
    fetchPolicy: 'no-cache',
    client,
    ...options
  });
}

const query = `
  query {
    enabledPbpContracts {
      company
      contract
      enabled
      parentCompany
    }
  }
`
