import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {MedicareQuoteOutput, QueryMedicareQuoteArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useMedicareQuote(options?: LazyQueryHookOptions<{medicareQuote: MedicareQuoteOutput}, QueryMedicareQuoteArgs>) {
  return useLazyQuery<{medicareQuote: MedicareQuoteOutput}, QueryMedicareQuoteArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options,
    client
  });
}

const query = `query ($id: UUID!) {
  medicareQuote(id: $id) {
    abCase
    createdAt
    county
    employed
    pin
    id
    clientId
    favorites
    restrictProviderNetwork
    travelMoreThan30DaysAYear
    doctorsWithinNetwork
    work8years
    filters {
      SNPTypes
      companies
      extraBenefits
      maxCostRangeNames
      maxOutOfPocketRangeNames
      partBPremiumReduction
      planTypes
      rating
    }
    planYear
    enrolled
    coverageFilled
    enrolledFilled
    drugDeliveryType
    hasMore20
    receivesMedicalSupport
    receivesLIS
    receivedBenefits
    governmentAssistant
    spouseEmployed
    spouseHasMore20
    zip
    doctorVisitsNumber
    specialistVisitsNumber
    coverage
    chronicIllness
    preferredPharmacies {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    medicarePlanType
    prepayCoverage
    customAnswers
    preferredDrugs {
      frequency
      genericName
      genericRxcui
      purchaseFrequency
      isGeneric
      name
      packName
      packRxcui
      quantity
      rxcui
    }
    preferredDoctors {
      npi
      addresses {
        addressLine1
        addressLine2
        id
        isPcp
        specialty
      }
      name
      addressesNumber
    }
  }
}`