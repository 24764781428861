import React from 'react';
import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveMedicareQuoteArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useSaveQuote(options?: MutationHookOptions<any, MutationSaveMedicareQuoteArgs>) {
  return useMutation<any, MutationSaveMedicareQuoteArgs>(
    gql(saveRequestMutation),
    {
      client: client,
      ...options
    }
  );
}

const saveRequestMutation = `
mutation ($data: MedicareQuoteInput!) {
  saveMedicareQuote(data: $data) {
    maQuoteID
  }
}
`;
