import {LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  LeadSourceOutput,
  MutationUpdateSourcesArgs, PageableCallHistoryOutput,
  QueryCallHistoryItemsArgs,
  UserProfileInput
} from "../../enrollment-types";

export default function useCallHistoryItems(options?: LazyQueryHookOptions<{callHistoryItems: PageableCallHistoryOutput}, QueryCallHistoryItemsArgs>) {
  return useLazyQuery<{callHistoryItems: PageableCallHistoryOutput}, QueryCallHistoryItemsArgs>(gql(callHistoryItemsQuery), options)
}

const callHistoryItemsQuery = `
query($clientId: UUID!, $page: PageInput!) {
  callHistoryItems(clientId: $clientId, page: $page) {
    data {
      callType
      clientId
      createdAt
      id
      phoneNumber
      result
      audioRecordId
      transcription
      agent {
        firstName
        lastName
        id
      }
    }
    totalElements
  }
}
`;
