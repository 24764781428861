import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableEnrollmentListItemOutput,
  QueryAllEnrollmentsArgs,
} from "../../enrollment-types";

export default function useAllEnrollments(options?: LazyQueryHookOptions<{allEnrollments: PageableEnrollmentListItemOutput}, QueryAllEnrollmentsArgs>) {
  return useLazyQuery<{allEnrollments: PageableEnrollmentListItemOutput}, QueryAllEnrollmentsArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($filter: AllEnrollmentsFilterInput!, $page: PageInput!) {
  allEnrollments(filter: $filter, page: $page) {
    data {
      age
      agentId
      approvedAt
      bidId
      birthDate
      clientId
      county
      mgPlanKey
      createdAt
      effectiveDate
      applicationDate
      firstName
      gender
      email
      id
      lastName
      mgPlanName
      planYear
      naic
      phoneNumber
      carrierName
      planName
      started
      status
      tobacco
      type
      zip
      pdpQuoteId
      enrollmentType
    }
  }
}
`
