import {Box, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import useShortenedCostActivationLink from "../hooks/useShortenedCostActivationLink";
import {useSnackbar} from "notistack";
import FilterNoneIcon from '@material-ui/icons/FilterNone';

export default function CustomerIoLinkButton({clientId}: {clientId: string}) {
  const [link, setLink] = useState<string>()
  const {enqueueSnackbar} = useSnackbar();

  const [getLink] = useShortenedCostActivationLink()

  React.useEffect(() => {
    if (clientId) {
      getLink({variables: {clientId}})
        .then(res => setLink(res.data?.shortenedCostActivationLink))
    }
  }, [clientId])

  const onClick = async () => {
    if (link) {
      await navigator.clipboard.writeText(link);
      enqueueSnackbar('Copied!', {variant: "info"});
    }
  }

  if (!link) {
    return null
  }

  return <Box className={'flex'}>
    <a style={{color: '#242424'}} href={link} className={'pointer fs-16 mr-8 underline'}>{link}</a>
    <IconButton size={'small'} onClick={onClick}>
      <FilterNoneIcon fontSize={'small'} />
    </IconButton>
  </Box>
}
