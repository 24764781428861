import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 10,
      textTransform: 'uppercase'
    }
  }),
);

interface ParamProps {
  label: string;
  value?: string | null;
  valueClassName?: string;
  className?: string;
}

const Param = (props: ParamProps) => {
  const classes = useStyles();
  return <div className={props.className}>
    <Typography color={'textSecondary'} className={classes.label}>{props.label}</Typography>
    {props.value && <Typography color={'textPrimary'} variant={'body1'} className={`mb-16 ${props.valueClassName}`}>{props.value}</Typography>}
  </div>;
}

export default Param;
