import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, IconButton} from "@material-ui/core";
import Preloader from "../../shared/Preloader";
import Card from "../../shared/Card";
import {LeadSourceOutput, SendInBlueHistoryItem} from "../../enrollment-types";
import moment from "moment";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useLeadSources from "../hooks/useLeadSources";
import useUpdateSources from "../hooks/useUpdateSources";
import useSendInBlueHistoryByEmail from "../hooks/useSendInBlueHistoryByEmail";
import * as _ from 'lodash';
import useUpdateSendInBlueByEmail from "../hooks/useUpdateSendInBlueByEmail";

const columns: GridColDef[] = [
  { field: 'status', headerName: 'Events', width: 150,
    renderCell: (params) => _.upperFirst(params.value?.toString())
  },
  { field: 'date', headerName: 'Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'subject', headerName: 'Subject', width: 200 },
  { field: 'fromEmail', headerName: 'From', width: 150 },
  { field: 'toEmail', headerName: 'To', width: 150 },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    }
  }),
);

interface SendInBlueProps {
  loading: boolean;
  email?: string | null;
}

const SendInBlue = (props: SendInBlueProps) => {
  const classes = useStyles();
  const [getLeadSources, {loading, refetch, data}] = useSendInBlueHistoryByEmail({
    fetchPolicy: 'no-cache'
  });
  const [update, {loading: updateLoading}] = useUpdateSendInBlueByEmail();
  const [rows, setRows] = React.useState<SendInBlueHistoryItem[]>([]);

  React.useEffect(() => {
    if (props.email) {
      getLeadSources({variables: {email: props.email}})
    }
  }, [props.email]);

  React.useEffect(() => {
    if (!!data?.sendInBlueHistoryByEmail?.history) {
      setRows(data.sendInBlueHistoryByEmail.history.filter(i => !!i) as SendInBlueHistoryItem[]);
    }
  }, [data]);

  const updateRows = () => {
    if (props.email) {
      update({variables: {email: props.email}}).then(() => refetch({email: props.email as string}));
    }
  }

  return <Card padding={0} className={'relative'}>
    <IconButton className={`${classes.refreshButton} ${loading ? classes.disabled : ''}`}
                disabled={loading}
                onClick={updateRows}>
      <img src={'/img/replay.svg'} />
    </IconButton>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={props.loading} />
        <div style={{ height: 300, width: '100%' }}>
          <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                    columns={columns}
                    loading={loading || updateLoading}
                    page={0}
                    paginationMode={'client'}
                    pageSize={100}
                    rowCount={rows.length}
          />
        </div>
    </Box>
  </Card>;;
}

export default SendInBlue;
