import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {LeadSourceOutput, MutationUpdateSendInBlueByEmailArgs} from "../../enrollment-types";

export default function useUpdateSendInBlueByEmail(options?: MutationHookOptions<{updateSendInBlueByEmail: string}, MutationUpdateSendInBlueByEmailArgs>) {
  return useMutation<{updateSendInBlueByEmail: string}, MutationUpdateSendInBlueByEmailArgs>(gql(mutation), options)
}

const mutation = `
mutation($email: String!) {updateSendInBlueByEmail(email: $email)}
`;
