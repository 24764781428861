import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  QueryClientIdByProfileIdArgs,
} from "../../enrollment-types";

export default function useClientIdByProfileId(options?: LazyQueryHookOptions<{clientIdByProfileId: string}, QueryClientIdByProfileIdArgs>) {
  return useLazyQuery<{clientIdByProfileId: string}, QueryClientIdByProfileIdArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($profileId: UUID!) {
  clientIdByProfileId(profileId: $profileId)
}
`
