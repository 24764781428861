import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

export default function useAssignClientViewToMe(options?: MutationHookOptions<any, {clientViewId: string}>) {
  return useMutation<any, {clientViewId: string}>(gql(saveNoteMutation), options)
}

const saveNoteMutation = `
mutation ($clientViewId: UUID!) {
  assignClientViewToMe(clientViewId: $clientViewId)
}
`;