import Card from "../../shared/Card";
import {Box, Button, createStyles, Divider, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import {config} from "../../config/config";
import {FormName, MedicareQuoteFilterExtraBenefit, MedicareQuoteOutput, PlanYear} from "../../types";
import {useSnackbar} from "notistack";
import {ClientViewOutput} from "../../enrollment-types";
import useCreateMedicareQuoteForClient from "../hooks/useCreateMedicareQuoteForClient";
import useMedicareQuote from "../../shared/hooks/useMedicareQuote";
import moment from "moment";
import * as _ from "lodash";
import Param from "../../shared/Param";
import {GetPlansYear} from "../../shared/tools";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    table: {
      '& tr td:first-child': {
        textAlign: 'right',
        paddingRight: 10,
      }
    },
    year: {
      color: 'white',
      position: 'absolute',
      fontWeight: 500,
      padding: '2px 9px',
      right: -20,
      top: -20,
      borderRadius: '3px'
    }
  }),
);

interface QuoteAnswersProps {
  pin?: string
  client?: ClientViewOutput,
  county?: string
}

const MedicareQuote = ({client, county, pin}: QuoteAnswersProps) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [createQuote] = useCreateMedicareQuoteForClient();
  const [getQuote] = useMedicareQuote();
  const [quote, setQuote] = React.useState<MedicareQuoteOutput>();

  React.useEffect(() => {
    if (client?.quoteId) {
      getQuote({
        variables: {
          id: client.quoteId
        },
      }).then(res => setQuote(res.data?.medicareQuote))
    }
  }, [client?.quoteId])

  const quoteUrl = (year: PlanYear) => {
    return `${config.resultsUrl}plans/${client?.zip}/${county}?clientId=${client?.id}&resetFilters=true`
  }

  const create = React.useCallback(() => {
    if (client) {
      createQuote({
        variables: {
          clientId: client.id,
          data: {
            form: FormName.Doctors,
            county: client.countyName,
            zip: client.zip,
            planYear: GetPlansYear(),
            filters: {
              extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
            },
          }
        }
      })
    }
  }, [client]);

  return <Card className={classes.root}>
    <div className={'flex relative'}>
      {!!quote && <Box bgcolor={quote?.planYear ? '#5ECB9E' : '#fd201a'} className={classes.year}>{quote?.planYear ? quote?.planYear : 'Unknown year'}</Box>}
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-16'}>Medicare quote</Typography>
      {pin && <Typography color={'textPrimary'} variant={'body2'} style={{marginTop: 2}} className={'ml-10'}>pin: {pin}</Typography>}
    </div>
    {!!quote && <Grid container spacing={2}>
      <Grid item sm={12} md={12}>
        <Param className={'flex-column'} label={'createdAt'} value={moment(quote?.createdAt).format('L H:mm:ss')}/>
      </Grid>
      <Grid  item sm={12} md={6}>
        <Param className={'flex-column'} label={'zip'} value={quote?.zip}/>
      </Grid>
      <Grid  item sm={12} md={6}>
        <Param className={'flex-column'} label={'zip'} value={quote?.county}/>
      </Grid>
      {quote?.filters && Object.keys(quote.filters).filter((key: any) => (quote as any).filters[key]?.length > 0)
        .map((key: string) => <Grid key={key} item sm={12} md={12}>
          <Param className={'flex-column'} label={_.lowerCase(key).toUpperCase()} value={getValue(key, quote.filters)}/>
        </Grid>)}
    </Grid>}
    <Box>
      {(!!client && !client?.quoteId) && <Button onClick={create}
                                                 className={'mb-10'}
                                                 variant={'contained'}
                                                 color={'primary'}>CREATE QUOTE</Button>}
      <Button disabled={!client?.quoteId}
              href={quoteUrl(PlanYear.Year2022)}
              target={'_blank'}
              className={'mb-10'}
              variant={'contained'}
              color={'primary'}>START QUOTE</Button>
      {client?.quoteId && <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.resultsUrl}?quote=${client?.quoteId}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>}
      {client?.agentMedicareQuote?.quoteId && <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.resultsUrl}?adminQuote=${client?.agentMedicareQuote?.quoteId}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy offer link</Typography>}
    </Box>

  </Card>
}

export default MedicareQuote;

const getValue = (key: string, request: any) => {
  if (!request)
    return '';
  if (Array.isArray(request[key]) && request[key].length === 0) {
    return '-'
  }
  if (Array.isArray(request[key])) {
    return (request[key] as any).join(', ')
  }
  if (request[key] === null || typeof request[key] === 'undefined') {
    return '-'
  }
  if (_.isBoolean(request[key])) {
    return !!request[key] ? 'Yes' : 'No';
  }
  return request[key];
}
