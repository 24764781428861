import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveDisqualifiedReasonArgs} from "../../enrollment-types";

export default function useSaveDisqualifiedReason(options?: MutationHookOptions<{saveDisqualifiedReason: string}, MutationSaveDisqualifiedReasonArgs>) {
  return useMutation<{saveDisqualifiedReason: string}, MutationSaveDisqualifiedReasonArgs>(gql(mutation), options)
}

const mutation = `
mutation ($disqualifiedReason: DisqualifiedReasonDto!, $id: UUID!) {
  saveDisqualifiedReason(disqualifiedReason: $disqualifiedReason, id: $id)
}
`;
