import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeletePdpEnrollmentArgs} from "../../enrollment-types";

export default function useDeletePdpEnrollment(options?: MutationHookOptions<{deletePdpEnrollment: string}, MutationDeletePdpEnrollmentArgs>) {
  return useMutation<{deletePdpEnrollment: string}, MutationDeletePdpEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($enrollmentId: UUID!) {
  deletePdpEnrollment(enrollmentId: $enrollmentId)
}
`;

