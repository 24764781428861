import * as React from 'react';
import {Box, Button, createStyles, Dialog, IconButton, makeStyles, Theme, Typography} from "@material-ui/core";
import {colors} from "../../../AppTheme";
import {DrugInfoWithPack} from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
    },
    drug: {
      color: colors.green.variant2,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 25
    },
    link: {
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    }
  })
);

interface ConfirmBrandedDrugModalProps {
  open: boolean,
  drug?: DrugInfoWithPack,
  onClose: (useGeneric?: boolean) => void,
}

export default function ConfirmBrandedDrugModal(props: ConfirmBrandedDrugModalProps) {
  const classes = useStyles();

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
    PaperProps={{
      className: 'p-30'
    }}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/icon-close.svg'} /></IconButton>
      <Typography className={'fs-26 lh-32'} align={'center'}>You selected a branded drug.</Typography>
      <Typography className={'fs-25 lh-31 bold mt-12'} align={'center'}>{props.drug?.name}</Typography>
      <Typography className={'fs-18 lh-22 mt-10'}  align={'center'}>has a lower cost generic version called</Typography>
      <Typography className={'fs-25 lh-31 bold mt-10'} align={'center'}>{props.drug?.genericName}</Typography>
      <Typography className={'fs-25 lh-31 mt-12'} align={'center'}>Would you like to add the generic<br />version to your list instead?</Typography>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'10px'}>
        <Button data-test={'add_generic_drug_button'} variant={'contained'}  color={'primary'} className={'w-290'} onClick={() => props.onClose(true)}>Add generic drug</Button>
        <Box pt={'20px'}>
          <Typography data-test={'add_branded_drug_button'} variant={'h4'} className={`${classes.link} branded-drug-button`} onClick={() => props.onClose(false)}>Continue with branded</Typography>
        </Box>
      </Box>
    </>
  </Dialog>
}
