import {
  Box,
  Checkbox,
  Chip,
  createStyles,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Switch,
  Theme,
  Typography
} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import Card from "../shared/Card";
import React from "react";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import paths from "../router-paths";
import moment from "moment";
import StatusView from "../Client/components/StatusView";
import {
  AssignedTagViewOutput,
  PageableSlaOutput,
  SlaOutput,
  StatusDto,
  SubscriptionSlasArgs
} from "../enrollment-types";
import {useHistory} from "react-router";
import useSLASorting from "./hooks/useSLASorting";
import clsx from "clsx";
import {gql, useSubscription} from "@apollo/client";
import FormSelect from "../shared/FormSelect";
import useSLAFilter from "./hooks/useSLAFilter";
import useSLADuration from "./hooks/useSLADuration";
import useSLAPeriod from "./hooks/useSLAPeriod";
import DatePicker from 'react-datepicker';
import TextInput from "../shared/TextInput";
import {getTagLabel} from "../Client/widgets/ClientTags";


const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 150, disableColumnMenu: true },
  { field: 'phoneNumber', headerName: 'Phone', width: 120, disableColumnMenu: true},
  { field: 'createdAt', headerName: 'Quote submitted time', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
  { field: 'calls', headerName: 'Calls', width: 70, sortable: false, disableColumnMenu: true},
  { field: 'firstCallTime', headerName: 'First Call Time', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const firstCallTime = params.row.firstCallTime as string;
      if (params.row.firstCallAgentName) {
        return firstCallTime + ` (${params.row.firstCallAgentName})`
      }
      return firstCallTime
    }
  },
  { field: 'within5min', headerName: 'Called within 5 min', width: 170, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return params.row.within5min === 'No' ? <Typography className={'red'}>No</Typography> : <Typography className={'dark-green'}>Yes</Typography>
    }
  },
  { field: 'expected2ndCall', headerName: 'Expected 2nd Call', width: 170, disableColumnMenu: true },
  { field: 'secondCallTime', headerName: '2nd Call Time', width: 250, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const secondCallTime = params.row.secondCallTime as string;
      if (params.row.secondCallAgentName) {
        return secondCallTime + ` (${params.row.secondCallAgentName})`
      }
      return secondCallTime
    }
  },
  { field: 'within2Hours', headerName: 'Called Within 2 hrs', width: 170, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      switch (params.row.within2Hours) {
        case 'No': return <Typography className={'red'}>No</Typography>
        case 'Yes': return <Typography className={'dark-green'}>Yes</Typography>
        default: return <Typography>N/A</Typography>
      }
    }
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    agentSelector: {
      minWidth: '188px'
    }
  }),
);

export default function TwoHourSLA() {
  const classes = useStyles();
  let history = useHistory();
  const [sorting, setSorting] = useSLASorting();
  const [duration, setDuration] = useSLADuration();
  const [usePeriod, setUsePeriod] = useSLAPeriod();
  const [filter, setFilter] = useSLAFilter();
  const {data, loading} = useSubscription<{slas: PageableSlaOutput}, SubscriptionSlasArgs>(gql(query), {
    variables: {
      pageInput: {
        page: 0,
        size: 500
      },
      filter
    }
  });

  const rows = React.useMemo(() => {
    return data?.slas?.data.map(mapToRow) || []
  }, [data])


  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={loading} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
          <Typography color={'textPrimary'} variant={'h4'}>2 Hour SLA</Typography>
          <Box display={'flex'} alignItems={'center'}>
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={!!filter.excludeBadNumber}
                  onChange={e => {
                    setFilter(prev => ({...(prev || {}), excludeBadNumber: e.target.checked}))
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Bad Numbers"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={!!filter.excludeNotAttempted}
                  onChange={e => {
                    setFilter(prev => ({...(prev || {}), excludeNotAttempted: e.target.checked}))
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Not Attempted"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={!!filter.excludeDoNotCall}
                  onChange={e => {
                    setFilter(prev => ({...(prev || {}), excludeDoNotCall: e.target.checked}))
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Do Not Call"
            />

            <Box display={'flex'}>
              {usePeriod && <FormSelect label={'Period'}
                                        className={clsx('mb-0 ', classes.agentSelector)}
                                        value={duration}
                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                          setDuration(event.target.value as number);
                                          setFilter(prev => ({...(prev || {}), range: {to: moment().toISOString(), from: moment().subtract(event.target.value as number, 'days').toISOString()}}))
                                        }}
              >
                <MenuItem value={2}>Last 2 days</MenuItem>
                <MenuItem value={5}>Last 5 days</MenuItem>
                <MenuItem value={10}>Last 10 days</MenuItem>
              </FormSelect>}
              {!usePeriod && <>
                <DatePicker selected={new Date(filter.range?.from)}
                            showTimeSelect
                            dateFormat="MM/dd/yyyy h:mm aa"
                            timeIntervals={60}
                            maxDate={new Date(filter.range?.to)}
                            customInput={<TextInput className={'mb-0'} label="From" />}
                            onChange={(date: Date) => setFilter(prev => ({...(prev || {}), range: {to: prev.range!.to, from: date.toISOString()}}))} />
                <DatePicker selected={new Date(filter.range?.to)}
                            showTimeSelect
                            dateFormat="MM/dd/yyyy h:mm aa"
                            timeIntervals={60}
                            minDate={new Date(filter.range?.from)}
                            customInput={<TextInput className={'mb-0 ml-16'} label="To" />}
                            onChange={(date: Date) => setFilter(prev => ({...(prev || {}), range: {from: prev.range!.from, to: date.toISOString()}}))} />
              </>}
              <FormControlLabel className={'mb-0 ml-16 w-160'} control={<Switch checked={!usePeriod}
                                                                                onChange={(e, val) => {
                                                                                  setUsePeriod(!val)
                                                                                  if (!val) {
                                                                                    setFilter(prev => ({...(prev || {}), range: {to: moment().toISOString(), from: moment().subtract(duration, 'days').toISOString()}}))
                                                                                  }
                                                                                }}
                                                                                color={'primary'} />} label="Use date range" />
              {/*<AgentSelector className={clsx('mb-0 ml-16 mr-10 ', classes.agentSelector)}
                           showEmptyOption
                           value={searchAgentId}
                           onChange={setSearchAgentId}/>*/}
            </Box>
          </Box>
        </Box>
        <div style={{ height: 'calc(100vh - 250px)', width: '100%' }}>
          <DataGrid rows={rows}
                    paginationMode={"server"}
                    columns={columns}
                    page={0}
                    onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                    sortModel={sorting ? [sorting] : []}
                    onSortModelChange={(sortModel) => {
                      setSorting(sortModel[0])
                    }}
                    rowCount={rows.length}
                    pagination
          />
        </div>
      </Card>

    </Box>
  </Box>
}

const mapToRow = (item: SlaOutput) => {
  let name = '';
  if (!item.firstName && !item.lastName) {
    name = 'Unknown user';
  } else {
    name = item.firstName + ' ' + item.lastName || '';
  }

  let within5min = '';
  if (item.firstCallAt) {
    const diff = moment(moment(item.firstCallAt)).diff(item.createdAt as string) / 1000 / 60;
    if (diff > 5) {
      within5min = 'No'
    } else {
      within5min = 'Yes'
    }
  } else {
    within5min = 'No'
  }

  let expected2ndCall = '';
  if (item.firstCallAt && item.status === StatusDto.Attempted) {
    expected2ndCall = moment(item.firstCallAt).add(2, 'hour').format('HH:mm')
  } else if (!!item.firstCallAt) {
    expected2ndCall = 'N/A'
  } else {
    expected2ndCall = '-'
  }


  let secondCallTime = '';
  if (item.secondCallAt) {
    secondCallTime = moment(item.secondCallAt).format('L H:mm:ss')
  } else if (!item.firstCallAt) {
    secondCallTime = 'N/A'
  } else {
    secondCallTime = '-'
  }

  let within2Hours = '';
  if (item.secondCallAt) {
    within2Hours = moment(item.secondCallAt).isSameOrBefore(moment(item.firstCallAt).add(2, 'hour')) ? 'Yes' : 'No';
  } else {
    within2Hours = 'N/A'
  }



  return {
    ...item,
    id: item.clientId,
    calls: item.successfulCallsCount + '/' + item.callsCount,
    firstCallTime: item.firstCallAt ? moment(item.firstCallAt).format('HH:mm') : '-',
    within5min,
    expected2ndCall,
    secondCallTime,
    within2Hours,
    name,
  }
}

const query = `
subscription($pageInput: PageInput!, $filter: SLAFilterInput!) {
  slas (pageInput: $pageInput, filter: $filter) {
    data {
      agent {
        email
        firstName
        id
        lastName
      }
      firstCallAgentName
      secondCallAgentName
      callsCount
      tags {
        expiresAt
        tag {
          color
          id
          name
        }
      }
      clientId
      createdAt
      firstCallAt
      firstName
      lastName
      phoneNumber
      secondCallAt
      status
      successfulCallsCount
    }
    totalElements
  }
}
`;
