import {
  QueryHookOptions,
  useQuery
} from "@apollo/client";
import {gql} from "@apollo/client";
import {TagOutput} from "../../enrollment-types";

export default function useTags(options?: QueryHookOptions<{tags: TagOutput[]}, any>) {
  return useQuery<{tags: TagOutput[]}, any>(gql(callHistoryItemsQuery), {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

const callHistoryItemsQuery = `
query {
  tags {
    color
    id
    name
  }
}
`;
