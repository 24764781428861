import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      padding: (props: any) => typeof props.padding === 'undefined' ? 15 : props.padding,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }
  }),
);

const Card = (props: PropsWithChildren<any> & {padding?: number | string, className?: string}) => {
  const classes = useStyles(props);

  return <div className={`${classes.card} ${props.className}`}>
    {props.children}
  </div>;
}

export default Card;
