import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveEnabledPbpContractArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";


export default function useSaveEnabledMaContract(options?: MutationHookOptions<{saveEnabledPbpContract: boolean}, MutationSaveEnabledPbpContractArgs>) {
  return useMutation<{saveEnabledPbpContract: boolean}, MutationSaveEnabledPbpContractArgs>(gql(save), {
    client,
    ...options
  })
}

const save = `
mutation ($input: EnabledPbpContractInput!) {
  saveEnabledPbpContract(input: $input)
}
`;

