import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentOutput, QueryAgentByIdArgs,
} from "../../enrollment-types";

export default function useAgentById(options?: LazyQueryHookOptions<{agentById: AgentOutput}, QueryAgentByIdArgs>) {
  return useLazyQuery<{agentById: AgentOutput}, QueryAgentByIdArgs>(gql(query), {...options, fetchPolicy: 'no-cache'});
}

const query = `
query($id: UUID!) {
  agentById(id: $id) {
    consultationUrl
    email
    enabled
    enrollmentMeetingUrl
    b2bMeetingUrl
    firstName
    id
    lastName
    phone
    picUrl
    planReviewUrl
    receiveEmails
    isDefault
    isCSR
  }
}
`;
