import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/EnrollmentApolloClient";
import {PageableEnrollmentOutput, QueryEnrollmentsArgs} from "../../enrollment-types";

export default function useEnrollments(onCompleted?: (data: {enrollments: PageableEnrollmentOutput}) => void) {
  return useLazyQuery<{enrollments: PageableEnrollmentOutput}, QueryEnrollmentsArgs>(
    gql(request),
    {
      onCompleted,
      fetchPolicy: 'no-cache',
      client
    }
  );
}

const request = `
query ($page: PageInput!, $input: EnrollmentFilterInput){
  enrollments(page: $page, input: $input) {
    totalElements
    data {
      approvedAt
      planName: bidId
      birthDate
      communicationPreference
      createdAt
      email
      firstName
      gender
      id
      clientId
      isElectronicallyCommunications
      lastName
      mailingAddress {
        aptNumber
        city
        state
        streetName
        zip
      }
      medicareNumber
      permanentAddress {
        aptNumber
        city
        state
        streetName
        zip
      }
      phoneNumber
      preferredLanguage
      status
      userId
    }
  }
}
`;
