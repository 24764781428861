import React from 'react';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import { ApolloProvider } from '@apollo/client';
import Layout from "./Layout";
import {withKeycloak} from "./shared/keycloak";
import {client} from "./Apollo/EnrollmentApolloClient";
import {setErrorHandler} from "./GraphQLErrorHandler";
import {useSnackbar} from "notistack";
import {withConfirmDialog} from "./shared/ConfirmDialog/ConfirmDialogContext";

function App() {
  const { enqueueSnackbar } = useSnackbar();

  // todo refactor this!!!!!
  setErrorHandler(({ operation, response, graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
          if (message.indexOf('The field at path') < 0) {
            switch (extensions?.status) {
              case '422': {
              }; break;
              default: {
                console.error(
                  `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                )
                enqueueSnackbar(message, {variant: 'error'});
              }
            }
          }
        }
      );
  });

  return (
    <ApolloProvider client={client}>
      <Layout/>
    </ApolloProvider>
  );
}

export default withKeycloak(withConfirmDialog(App));
