import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {QueryVideoArgs, Video} from "../../content-management";


export default function useVideo() {
  return useLazyQuery<{video: Video}, QueryVideoArgs>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
      client
    }
  );
}

const request = `
query($videoId: UUID!) {
  video(videoId: $videoId) {
    sortingOrder
    categoryId
    description
    duration
    id
    title
    url
  }
}
`;
