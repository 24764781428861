import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, Typography} from "@material-ui/core";
import moment from "moment";
import Preloader from "./Preloader";
import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import Param from "./Param";
import {ClientViewOutput} from "../enrollment-types";
import {useHistory} from "react-router";
import paths from "../router-paths";
import {clientFields} from "../Client/ClientDetails";
import useAssignClientViewToMe from "../Client/hooks/useAssignClientViewToMe";
import {useSnackbar} from "notistack";
import {GraphQLErrorType} from "../Apollo/EnrollmentApolloClient";
import {getAgentDisplayName} from "../Agent/AgentSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 341,
      minHeight: 270,
      borderRadius: 5,
      background: 'white',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      marginTop: 10,
      padding: 15,
      display: 'flex',
      flexDirection: 'column'
    }
  }),
);

interface PINLookupResultProps {
  pin: string,
  onAssignClick: () => void
}

const PINLookupResult = (props: PINLookupResultProps) => {
  const classes = useStyles();
  let history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  const [assignToMe] = useAssignClientViewToMe({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Assigned', {variant: "success"});
    }
  });

  const [getClient, {data, loading}] = useLazyQuery<{clientViewByPin: ClientViewOutput}, {pin: string}>(gql(query), {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
      if (props.pin) {
        getClient({variables: {pin: props.pin}})
      }
  }, [props.pin]);

  const client = React.useMemo(() => {
    return data?.clientViewByPin;
  }, [data]);

  const name = client?.firstName || client?.lastName ? client?.firstName + ' ' + client?.lastName : 'Unknown user';
  const agentName = client?.agent ? getAgentDisplayName(client.agent) : '-';

  const county = React.useMemo(() => {
    if (client?.customAnswers) {
      const parsed = JSON.parse(client.customAnswers);
      if (parsed?.STEP_2?.county || parsed?.STEP_2?.countyName) {
        return parsed?.STEP_2?.county || parsed?.STEP_2?.countyName;
      }
    }
    return client?.countyName;
  }, [client]);

  return <div className={classes.container}>
    <Preloader in={loading} />
    {client && <>
      <Typography color={'textPrimary'} variant={'h4'} className={'pointer underline'}
                  onClick={() => {
                    history.push(paths.client + '/' + client.id);
                    props.onAssignClick();
                  }}>
      {name}
      </Typography>
      <Param label={'QUOTE CREATED AT'} value={moment(client.createdAt).format('MM/DD/YYYY')} />
      <Param label={'ASSIGNED AGENT'} value={agentName} />
      <Param label={'EMAIL'} value={client.email || 'N/A'} />
      <Param label={'PHONE NUMBER'} value={client.phoneNumber || 'N/A'} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant={'contained'}
                onClick={() => {
                  assignToMe({
                    variables: {
                      clientViewId: client.id
                    }
                  });
                  history.push(paths.client + '/' + client.id);
                  props.onAssignClick()
                }}
                color={'primary'}>ASSIGN TO ME</Button>
      </Box>
    </>}
    {client === null && <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'}>
      <Typography color={'textPrimary'} className={'medium fs-16'}>Nothing found</Typography>
    </Box>}

  </div>;
}

export default PINLookupResult;

const query = `
  query ($pin: String!) {
    clientViewByPin(pin: $pin) {
      ${clientFields}
    }
  }
`
