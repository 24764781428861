import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {VideoCategoryWithVideos} from "../../content-management";


export default function useVideoCategories() {
  return useQuery<{videoCategories: VideoCategoryWithVideos[]}>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
      client
    }
  );
}

const request = `
query {
  videoCategories {
    id
    title
    sortingOrder
  }
}
`;
