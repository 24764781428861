import React from "react";
import Card from "../../shared/Card";
import {createStyles, IconButton, makeStyles, Theme, Typography} from "@material-ui/core";
import {ClientViewOutput} from "../../enrollment-types";
import useDeleteClientFile from "../hooks/useDeleteClientFile";
import {config} from "../../config/config";
import {keycloak} from "../../shared/keycloak";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {useSnackbar} from "notistack";
import useAddClientFiles from "../hooks/useAddClientFiles";

interface ClientFilesProps {
  client?: ClientViewOutput
}

const ClientFiles = ({client}: ClientFilesProps) => {
  const [deleteFile] = useDeleteClientFile()
  const {enqueueSnackbar} = useSnackbar();
  const [addClientFiles] = useAddClientFiles();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (files?.length) {
      const formData = new FormData();
      Array.from(files).forEach(file => formData.append('files', file, file.name))
      formData.append('clientId', client?.id)

      const requestOptions = {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + keycloak?.token
        },
        body: formData
      };

      const url = config.clientFileUploadUrl + '/api/client-file'

      fetch(url, requestOptions)
        .then(res => res.json())
        .then(res => addClientFiles({variables: {
            clientId: client?.id,
            clientFiles: res.links
          }}))
        .catch(() => enqueueSnackbar('Error in uploading file!', {variant: "error"}))
    }
  }

  return <Card className={'relative'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"}>Client files</Typography>
      <div>
        <input accept="application/pdf,application/vnd.ms-excel" onChange={onChange} multiple style={{display: 'none'}} id={'file-upload'} type="file" />
        <label htmlFor={'file-upload'}>
          <IconButton component="span">
            <NoteAddIcon fontSize={'small'} />
          </IconButton>
        </label>
      </div>
    </div>

    {client?.clientFiles.map(file => <div key={file.link} className={'flex flex-align-center mt-8'}>
      <Typography color={"textPrimary"}
                  variant={"h5"}
                  className={'underline pointer'}
                  style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                  onClick={() => {
                    const requestOptions = {
                      method: 'GET',
                      headers: {
                        authorization: 'Bearer ' + keycloak?.token
                      },
                    };
                    const url = config.clientFileUploadUrl + file.link;
                    fetch(url, requestOptions).then(response => {
                      response.blob().then(blob => {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file.name);
                        document.body.appendChild(link);
                        link.click();
                      })
                    })
                  }}>
        {file.name}
      </Typography>
      <IconButton className={`ml-8`}
                  size={'small'}
                  onClick={() => {
                    deleteFile({variables: {clientId: client?.id, link: file.link}})
                    const requestOptions = {
                      method: 'DELETE',
                      headers: {
                        authorization: 'Bearer ' + keycloak?.token
                      },
                    };
                    const url =  config.clientFileUploadUrl + file.link;
                    fetch(url, requestOptions)
                  }}>
        <img className={'w-10 h-10'} src={'/img/delete-icon.svg'} />
      </IconButton>
    </div>)}
  </Card>
};

export default ClientFiles;
