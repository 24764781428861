import {QueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  EnabledPdpStatesOutput
} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useEnabledMaStates(options?: QueryHookOptions<{enabledPdpStates: EnabledPdpStatesOutput[]}>) {
  return useQuery<{enabledPdpStates: EnabledPdpStatesOutput[]}>(gql(query), {
    fetchPolicy: 'no-cache',
    client,
    ...options
  });
}

const query = `
  query {
    enabledPdpStates {
      enabled
      state
    }
  }
`
