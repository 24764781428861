import React from "react";
import {Box, Typography} from "@material-ui/core";
import {StatusDto} from "../../enrollment-types";

const Tick = ({color}: {color: string}) => <Box mr={'9px'} width={16} height={16} bgcolor={color} borderRadius={'8px'} />

interface StatusFormProps {
  status: string;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (status: StatusDto) => void;
}

const colorByStatus = (status?: StatusDto | null): string => {
  switch(status) {
    case StatusDto.ApplicationSubmitted:
      return '#70ad47';
    case StatusDto.Disenrolled:
    case StatusDto.Disqualified:
      return '#DD3D3D';
    case StatusDto.NotYetEngaged: return '#767171';
    case StatusDto.SalesQualified:
    case StatusDto.FollowUp: return '#ed7d31';
    case StatusDto.Test:
    case StatusDto.OutOfService:
    case StatusDto.Duplicated: return '#44546a';
    default: return '#FFB800';
  }
}

const labelByStatus = (status?: StatusDto | null): string => {
  switch(status) {
    case StatusDto.Draft: return 'Draft';
    case StatusDto.NotYetEngaged: return 'Not yet engaged';
    case StatusDto.ApplicationSubmitted: return 'Application submitted';
    case StatusDto.Attempted: return 'Attempted';
    case StatusDto.Contacted: return 'Contacted';
    case StatusDto.Disqualified: return 'Disqualified';
    case StatusDto.SalesQualified: return 'Sales Qualified Lead';
    case StatusDto.Disenrolled: return 'Disenrolled / Churned / Withdrawn';
    case StatusDto.FollowUp: return 'Follow up';
    case StatusDto.OutOfService: return 'Out of service';
    case StatusDto.Duplicated: return 'Duplicated';
    case StatusDto.Test: return 'Test';
    default: return 'Untouched';
  }
}

const StatusView = ({status}: {status?: StatusDto}) => <Box display={'flex'} alignItems={'center'}>
  <Tick color={colorByStatus(status)} />
  <Typography variant={'body1'} color={'textPrimary'}>{labelByStatus(status)}</Typography>
</Box>

export default StatusView;
