import React from "react";
import {ClientViewOutput} from "../../enrollment-types";
import {Box, Button, createStyles, Divider, makeStyles, Theme, Typography} from "@material-ui/core";
import Card from "../../shared/Card";
import Param from "../../shared/Param";
import moment from "moment";
import {config} from "../../config/config";
import {useSnackbar} from "notistack";
import useSaveUserProfile from "../hooks/useSaveUserProfile";
import useCreatePdpQuoteForClient from "../hooks/useCreatePdpQuoteForClient";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  }),
);

interface PdpQuoteProps {
  client?: ClientViewOutput
}

const PdpQuote = ({client}: PdpQuoteProps) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [createQuote] = useCreatePdpQuoteForClient()
  const [saveProfile] = useSaveUserProfile()

  const create = React.useCallback(() => {
    if (client) {
      createQuote({
        variables: {
          clientId: client.id,
          input: {
            insulinSavings: false,
            zip: client.zip as string,
            countyName: client.countyName as string,
          }
        }
      }).then(res => {
        if (client.profileId && res.data?.createPdpQuoteForClient?.id) {
          return saveProfile({
            variables: {
              data: {
                pdpQuoteId: res.data?.createPdpQuoteForClient?.id,
                profileId: client.profileId
              }
            }
          })
        }
      })
    }
  }, [client]);

  if (!client) {
    return null;
  }

  const quote = client.pdpQuote;


  return <Card className={classes.root}>
    <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Pdp Quote</Typography>
    <div className={"flex-space-between"}>
      {quote?.createdAt && <Param className={'flex-column'} label={"CREATED"} value={moment(quote?.createdAt).format('L H:mm:ss')}/>}
    </div>
    <div className={"flex-space-between"}>
      <Param className={'flex-column'} label={"INSULIN SAVINGS"} value={quote?.insulinSavings ? 'Yes' : 'No'}/>
    </div>
    <Divider className={'mb-15'} />
    <Box>
      {(!!client && !client?.pdpQuote) && <Button onClick={create}
                                                 className={'mb-10'}
                                                 variant={'contained'}
                                                 color={'primary'}>CREATE QUOTE</Button>}
      <Button href={`${config.pdpUrl}plans/${quote?.zip}/${quote?.countyName}?clientId=${client.id}`}
              target={'_blank'}
              disabled={!client?.pdpQuote}
              variant={'contained'}
              className={'mb-10'}
              color={'primary'}>START QUOTE</Button>
      <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.pdpUrl}?clientId=${client.id}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 underline'} color={'textPrimary'}>Copy link</Typography>
    </Box>
  </Card>;
}

export default PdpQuote;
