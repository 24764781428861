import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {UserProfileInput, UserProfileSaveOutput} from "../../enrollment-types";

export default function useSaveUserProfile(options?: MutationHookOptions<{saveUserProfile: UserProfileSaveOutput}, {data: UserProfileInput}>) {
  return useMutation<{saveUserProfile: UserProfileSaveOutput}, {data: UserProfileInput}>(gql(saveUserInfoMutation), {
    ...options
  })
}

const saveUserInfoMutation = `
mutation ($data: UserProfileInput!) {
  saveUserProfile(input: $data) {
    enrollmentId
    profileId
    userId
    userToken {
      accessToken
      idToken
      refreshToken
    }
  }
}
`;
