import {MutationHookOptions, useMutation, gql} from "@apollo/client";
import {MutationMergeClientsInOneGroupArgs} from "../../enrollment-types";

export default function useMergeClientsInOneGroup(options?: MutationHookOptions<any, MutationMergeClientsInOneGroupArgs>) {
  return useMutation<any, MutationMergeClientsInOneGroupArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($clientIds: [UUID!]!) {
  mergeClientsInOneGroup(clientIds: $clientIds)
}
`;
