import React from "react";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import paths from "../router-paths";
import {useHistory} from "react-router";
import Card from "../shared/Card";
import useAgentListPageSize from "./hooks/useAgentListPageSize";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useAgents from "./hooks/useAgents";
import {formatPhoneLink} from "../PlanList/utils";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import useAgentListPage from "./hooks/useAgentListPage";
import useAgentListEnabled from "./hooks/useAgentListEnabled";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import FormSelect from "../shared/FormSelect";
import useAgentListSorting from "./hooks/useAgentListSorting";
import {AgentField, AgentOutput, AgentSortInput, CallHistoryOutput, CallHistoryResultOutput} from "../enrollment-types";
import {client} from "../Apollo/EnrollmentApolloClient";
import {gql} from "graphql-tag";


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 350, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown agent';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return name
    }
  },
  { field: 'email', headerName: 'Email', width: 250, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.email
    }
  },
  { field: 'phone', headerName: 'Phone', width: 250, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phone) {
        return formatPhoneLink(params.row.phone, true)
      }
      return ''
    }
  },
  { field: 'enabled', headerName: 'Enabled', width: 150, sortable: true, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.enabled ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'receiveEmails', headerName: 'Receive emails', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.receiveEmails ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'gmail', headerName: 'Gmail connected', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.gmail ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <div />
  },
  { field: 'isDefault', headerName: 'Is Default', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.isDefault ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <div />
  },
];

const AgentList = () => {
  const [page, setPage] = useAgentListPage();
  let history = useHistory();
  const [pageSize, setPageSize] = useAgentListPageSize();
  const [enabled, setEnabled] = useAgentListEnabled();
  const [sorting, setSorting] = useAgentListSorting();

  const [searchRequest, {loading, data}] = useAgents();

  const [agents, setAgents] = React.useState<(AgentOutput & {gmail: boolean})[]>([]);

  React.useEffect(() => {
    (async function () {
      const data = await searchRequest({
        variables: {
          filter: {
            enabled: enabled === 'null' ? null : enabled === 'true' ? true : false,
          },
          page: {
            page: page,
            size: pageSize
          },
          sort: sorting && {
            field: sorting.field!.toUpperCase() as AgentField,
            direction: sorting.sort!.toUpperCase()
          } as AgentSortInput
        }
      }).then(res => res.data?.agents?.data || []);

      const query = `
      query {
        ${data.map((a, i) => 'a' + i + `: isAgentGmailSetUp(agentId: "${a.id}")`).join(' ')}
      }
    `;

      const setup = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});

      setAgents(data.map((a, i) => ({...a, gmail: setup['a' + i]})))
    })()
  }, [page, pageSize, enabled, sorting]);


  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={loading} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography color={'textPrimary'} variant={'h4'}>Agent list</Typography>
            <Button className={'ml-40'} variant={'contained'} size={'small'} color={'primary'} onClick={() => history.push(paths.agent)}>Create new agent</Button>
          </Box>
          <Box>
            <FormSelect className={clsx('mb-0 w-120')}
                        label={'Enabled'}
                        value={String(enabled)}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          setEnabled(event.target.value as string)
                        }}
            >
              <MenuItem value={'null'}>All</MenuItem>
              <MenuItem value={'true'}>Enabled</MenuItem>
              <MenuItem value={'false'}>Disabled</MenuItem>
            </FormSelect>
          </Box>
        </Box>
        <div style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
          <DataGrid rows={agents}
                    paginationMode={"server"}
                    columns={columns}
                    page={page}
                    disableSelectionOnClick
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                    onRowClick={(param, event) => {
                      history.push(paths.agent + '/' + param.row.id)
                    }}
                    sortModel={sorting ? [sorting] : []}
                    onSortModelChange={(sortModel) => {
                      setSorting(sortModel[0])
                    }}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowCount={data?.agents?.totalElements}
                    pagination
          />
        </div>
      </Card>

    </Box>
  </Box>;
}

export default AgentList;