import React, { ChangeEvent } from "react";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

const useStyles = makeStyles({
    icon: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    searchFieldWrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: 6,
      marginBottom: 6,
    }
  }
);

type SearchFieldProps = {
  onChange?: (search: string) => void,
  onSubmit?: (search: string) => void,
  onReset?: () => void,
  onBlur?: () => void,
  onFocus?: () => void,
  value?: string,
  searchIcon?: boolean,
  autoFocus?: boolean,
  label?: string,
  outlined?: boolean,
  backgroundColor?: string,
}

export default function SearchField(props: SearchFieldProps) {
  const classes = useStyles(props);
  let ref: HTMLElement;

  const [searchString, setSearchString] = React.useState('');
  const { onChange } = props;
  const debouncedOnChange = React.useRef(_.debounce(onChange!!, 500)).current

  React.useEffect(() => {
    if (onChange) {
      debouncedOnChange(searchString)
    }
  }, [searchString, onChange]);

  const resetSearchString = () => {
    if (props.onReset) {
      props.onReset();
    }
    setSearchString('');
    ref.focus();
  };

  React.useEffect(() => {
    if (typeof props.value !== 'undefined' && props.value !== searchString) {
      setSearchString(props.value)
    }
  }, [props.value]);

  const submit = () => {
    if (props.onSubmit) {
      props.onSubmit(searchString);
    }
  };

  const searchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  const handleBlur = () => {
    const inputRef = ref;
    setTimeout(() => {
        if (inputRef !== document.activeElement && props.onBlur) {
          props.onBlur()
        }
      }, 100
    )
  }

  const inputProps: any = {
    endAdornment:
      <InputAdornment position="end">
        {searchString !== '' && <CloseIcon cursor={'pointer'} onClick={resetSearchString} fontSize={'small'} color={'inherit'} />}
      </InputAdornment>,
  };

  if (props.searchIcon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">

        <SearchIcon color="disabled"  className={classes.icon} />
      </InputAdornment>
    )
  }

  if (!props.outlined) {
    inputProps.disableUnderline = true;
  }

  return (
    <FormControl className={classes.searchFieldWrapper}>
      <TextField
        inputRef={(input) => { ref = input; }}
        size="small"
        hiddenLabel
        autoFocus={props.autoFocus}
        onFocus={props.onFocus}
        placeholder={props.label || "Search"}
        variant={(props.outlined ? "outlined" : "filled") as any}
        onChange={searchChange}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            submit();
          }
        }}
        onBlur={handleBlur}
        value={searchString}
        InputProps={inputProps}
      />
    </FormControl>
  );
}
