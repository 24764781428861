import React from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Box from "@material-ui/core/Box";
import SearchField from "./SearchField";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import {ClickAwayListener, Fade, Popper} from "@material-ui/core";
import PINLookupResult from "./PINLookupResult";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchFieldWrapper: {
      display: 'flex',
      flex: 1,
    },
    searchButton: {

    },
    popup: {
      zIndex: 100
    }
  }),
);

type GlobalSearchProps = {
  collapsed?: boolean,
  onOpen?: () => void,
  onHide?: () => void,
}

const SearchButton = withStyles((theme: Theme) => ({
  root: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    padding: '9px 0',
    marginTop: 3,
    minWidth: 42,
    boxShadow: 'none',
  },
  label: {
    color: 'white'
  }
}))(Button);


export default function GlobalSearch(props: GlobalSearchProps) {
  const classes = useStyles();
  const [searchString, setSearchString] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [skipHide, setSkipHide] = React.useState(false);

  React.useEffect(() => {
    setOpen(searchString.length >= 6);
  }, [searchString]);

  const toggleShow = () => {
    const newVal = !show;
    if (newVal && props.onOpen) {
      props.onOpen();
    }
    if (!newVal && props.onHide) {
      props.onHide();
    }
    setShow(newVal)
  }

  return (
    <Box>
      {!props.collapsed && <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
        <div ref={setAnchorEl}>
          <SearchField
              label={'Search by PIN'}
              onChange={setSearchString}
              value={searchString}
              onFocus={() => {
                setOpen(searchString.length >= 6)
                setSkipHide(searchString.length >= 6)
              }}
          />
        </div>
      </Box>}
      {props.collapsed && show && <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
        <SearchField autoFocus
                     label={'Search by PIN'}
                     onChange={setSearchString}
                     onBlur={toggleShow}/>
        <SearchButton variant="contained" color="primary"
                      className={classes.searchButton}>
          <SearchIcon />
        </SearchButton>
      </Box>}
      {props.collapsed && !show && <IconButton aria-label="search" onClick={toggleShow}>
        <SearchIcon />
      </IconButton>}
      <ClickAwayListener onClickAway={() => {
        if (open && !skipHide) {
          setOpen(false)
        }
        setSkipHide(false)
      }}>
        <Popper open={open} anchorEl={anchorEl} transition placement={'bottom-end'} className={classes.popup}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <PINLookupResult pin={searchString} onAssignClick={() => {
                setSearchString('');
              }} />
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}
