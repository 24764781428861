import {LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {SibEvent, QueryEmailStatsArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useEmailStats(options?: LazyQueryHookOptions<{emailStats: SibEvent[]}, QueryEmailStatsArgs>) {
  return useLazyQuery<{emailStats: SibEvent[]}, QueryEmailStatsArgs>(gql(query), {...options, client})
}

const query = `
query($email: String!) {
  emailStats(email: $email) {
    emailToken
    eventDate
    eventType
    id
    messageId
    subject
    templateId
  }
}
`;
