import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationCreateMedicareQuoteForClientArgs} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useCreateMedicareQuoteForClient(options?: MutationHookOptions<{createMedicareQuoteForClient: {clientId: string, maQuoteID: string}}, MutationCreateMedicareQuoteForClientArgs>) {
  return useMutation<{createMedicareQuoteForClient: {clientId: string, maQuoteID: string}}, MutationCreateMedicareQuoteForClientArgs>(gql(mutation), {
    ...options,
    client
  })
}

const mutation = `
mutation ($clientId: UUID!, $data: MedicareQuoteInput!) {
  createMedicareQuoteForClient(clientId: $clientId, data: $data) {
    clientId
    maQuoteID
  }
}
`;
