import React from "react";
import {createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import {OutlinedTextFieldProps} from "@material-ui/core/TextField/TextField";
import clsx from "clsx";
import {colors} from "../AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '25px',
      width: '100%'
    },
    inputLabel: {
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '10px',
      color: colors.text.primary,
      fontWeight: 500,
    },
  }),
);

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #CCD8DC!important',
      },
      borderRadius: '5px',
    },
    multiline: {
      padding: '11px 7px 10px',
    },
    inputMultiline: {
      padding: 0,
    },
    error: {
      backgroundColor: 'rgba(220, 16, 28, 0.05)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px!important',
      }
    },
    focused: {
      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
      }
    }
  }),
);

const useStylesHelperText = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    contained: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginTop: 0,
      whiteSpace: 'nowrap',
    },
  }),
);

const TextInput = (props: Partial<OutlinedTextFieldProps & {inputLabelClass?: string}>, ref: any) => {
  const classes = useStyles();
  const {inputLabelClass, ...rest} = props;
  const inputClasses = useStylesInput();
  const helperTextClasses = useStylesHelperText();

  return (
    <div className={clsx(classes.root, props.className)}>
      <TextField
        {...rest}
        ref={ref}
        size="small"
        className={undefined}
        variant="outlined"
        inputProps={{
          ...props.inputProps,
          className: 'data-hj-allow'
        }}
        InputProps={{
          ...props.InputProps,
          classes: {
            ...inputClasses,
            ...props?.InputProps?.classes
          },
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          classes: {
            ...helperTextClasses,
            ...props?.FormHelperTextProps?.classes
          },
        }}
      />
    </div>
  );
}

export default React.forwardRef(TextInput);
