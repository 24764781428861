import React, {ChangeEvent} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, Checkbox, Chip, FormControlLabel, IconButton, Typography} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import Card from "../shared/Card";
import clsx from "clsx";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useAttemptedLeadsPage from "./hooks/useAttemptedLeadsPage";
import useAttemptedLeadsPageSize from "./hooks/useAttemptedLeadsPageSize";
import {
  AssignedTagViewOutput,
  AttemptedLeadGroupDto, AttemptedLeadSortDirection, AttemptedLeadSortField, ClientViewTimeZone, TagViewOutput,
} from "../enrollment-types";
import moment from "moment";
import StatusView from "../Client/components/StatusView";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import paths from "../router-paths";
import {useHistory} from "react-router";
import useAttemptedLeads from "./hooks/useAttemptedLeads";
import useAttemptedLeadExcludeBadNumbers from "./hooks/useAttemptedLeadExcludeBadNumbers";
import useAttemptedLeadIEP from "./hooks/useAttemptedLeadIEP";
import useAttemptedLeadSorting from "./hooks/useAttemptedLeadSorting";
import {colors} from "../AppTheme";
import {Link} from "react-router-dom";
import useAttemptedLeadDateRange from "./hooks/useAttemptedLeadDateRange";
import {DateRangePicker} from "materialui-daterange-picker";
import InputAdornment from "@material-ui/core/InputAdornment";
import useAttemptedLeadState from "./hooks/useAttemptedLeadState";
import CloseIcon from '@material-ui/icons/Close';
import FormSelect from "../shared/FormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {GetLicensedStates} from "../Client/widgets/PersonalInfo";
import {getTagLabel} from "../Client/widgets/ClientTags";
import useAttemptedLeadTimezone from "./hooks/useAttemptedLeadTimezone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusSelector: {
      minWidth: '186px'
    },
    dateRange: {
      position: 'absolute',
      zIndex: 1
    },
    stateSelector: {
      width: '100px',
      minWidth: '100px'
    },
    searchInput: {
      minWidth: '100px',
      width: '100px'
    },
  }),
);


const columns: GridColDef[] = [
  { field: 'leadDate', headerName: 'Lead Date', width: 170, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const leadDateStr = params.row.leadDate as string;
      if (leadDateStr) {
        return moment(leadDateStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      if (!firstName && !lastName) {
        return 'Unknown user';
      } else {
        const name = firstName + ' ' + lastName || '';
        return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
      }
    }
  },
  { field: 'phoneNumber', headerName: 'Number', width: 120, disableColumnMenu: true, sortable: false,},
  { field: 'state', headerName: 'State', width: 120, disableColumnMenu: true, sortable: false,},
  { field: 'birthDate', headerName: 'Birth date', width: 120, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L');
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridValueGetterParams) => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'iepFlag', headerName: 'IEP flag', width: 120, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridValueGetterParams) =>
      params.row.iepFlag ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'lastName', headerName: 'Called today', width: 170, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridValueGetterParams) => {
      return params.row.leadGroup === AttemptedLeadGroupDto.CalledToday ? 'Y' : 'N';
    }
  },
  { field: 'lastCallDate', headerName: 'Last Call Date', width: 170, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const lastCallDateStr = params.row.lastCallDate as string;
      if (lastCallDateStr) {
        return moment(lastCallDateStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'lastCallAgentFullName', headerName: 'Call Agent', width: 170, disableColumnMenu: true, sortable: false,},
  { field: 'callsCount', headerName: 'Calls Count', width: 150, disableColumnMenu: true, sortable: false,},
  { field: 'urgent', headerName: 'Urgent', width: 120, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridValueGetterParams) =>
      params.row.urgent ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
];

interface AttemptedLeadsProps {

}

const AttemptedLeads = (props: AttemptedLeadsProps) => {
  const classes = useStyles();
  const [page, setPage] = useAttemptedLeadsPage();
  const [pageSize, setPageSize] = useAttemptedLeadsPageSize();
  const [excludeBadNumbers, setExcludeBadNumbers] = useAttemptedLeadExcludeBadNumbers();
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = useAttemptedLeadDateRange();
  const [iep, setIep] = useAttemptedLeadIEP();
  let history = useHistory();
  const [sorting, setSorting] = useAttemptedLeadSorting();
  const [timezone, setTimezone] = useAttemptedLeadTimezone();
  const [excludeCalledToday, setExcludeCalledToday] = React.useState(true);
  const [excludeDoNotCall, setExcludeDoNotCall] = React.useState(true);
  const [state, setState] = useAttemptedLeadState();

  const [searchRequest, {loading, data, refetch}] = useAttemptedLeads()

  React.useEffect(() => {
    search();
  }, [page, pageSize, sorting]);

  const search = React.useCallback(() => {
    searchRequest({
      variables: {
        filter: {
          excludeDoNotCall,
          excludeCalledToday,
          range: dateRange.startDate ? {from: moment(dateRange.startDate).startOf('day').toISOString(), to: moment(dateRange.endDate).endOf('day').toISOString()} : undefined,
          iep,
          excludeBadNumbers,
          state: state ? state.toUpperCase() : null,
          timeZone: timezone || undefined,
        },
        pageInput: {
          page: page,
          size: pageSize
        },
        sort: sorting ? {
          field: sorting?.field === 'leadDate' ? AttemptedLeadSortField.LeadDate : AttemptedLeadSortField.Birthdate,
          direction: sorting?.sort?.toString().toUpperCase() as AttemptedLeadSortDirection
        } : null
      }
    });
  }, [page, pageSize, sorting, excludeDoNotCall, excludeCalledToday, dateRange, iep, excludeBadNumbers, state, timezone]);

  const onSearch = () => {
    if (page > 0) {
      setPage(0);
    } else {
      search();
    }
  }

  const inputProps: any = {
    endAdornment:
      <InputAdornment position="end">
        {!!state && <CloseIcon cursor={'pointer'} onClick={() => {
          setState('');
          onSearch();
        }} fontSize={'small'} color={'inherit'} />}
      </InputAdornment>,
  };

  const dateRangeStr = React.useMemo(() => {
    if (!dateRange.startDate) {
      return 'Not selected';
    }
    return moment(dateRange.startDate).format('L') + ' - ' + moment(dateRange.endDate).format('L')
  }, [dateRange])

  const toggle = () => setOpen(!open);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={loading} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
          <Typography color={'textPrimary'} variant={'h4'}>Attempted Leads</Typography>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'}  alignItems={'center'} position={'relative'} mr={'10px'}>
              <Typography color={'textPrimary'} className={'no-wrap'} variant={'h5'}>Date range: {dateRangeStr}</Typography>
              <IconButton size={'small'} className={'ml-15'} onClick={toggle}><img className={'w-15 h-15'} src={'/img/mode.svg'}/></IconButton>
              <IconButton size={'small'} className={'ml-15'} onClick={() => setDateRange({})}><img className={'w-15 h-15'} src={'/img/delete-icon.svg'}/></IconButton>
              <DateRangePicker
                closeOnClickOutside
                wrapperClassName={classes.dateRange}
                open={open}
                toggle={toggle}
                onChange={(range) => setDateRange(range)}
              />
            </Box>
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={iep}
                  onChange={e => setIep(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Initial Enrollment Period"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeBadNumbers}
                  onChange={e => {
                    setExcludeBadNumbers(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Bad Numbers"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeCalledToday}
                  onChange={e => {
                    setExcludeCalledToday(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Called Today"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeDoNotCall}
                  onChange={e => {
                    setExcludeDoNotCall(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Do Not Call"
            />

            <FormSelect className={clsx('mb-0 mr-8', classes.stateSelector)}
                        label={'State'}
                        value={state}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          setState(event.target.value as string)
                        }}
            >
              <MenuItem value={''}>-</MenuItem>
              {GetLicensedStates().sort().map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </FormSelect>
            <FormSelect className={clsx('mb-0 mr-8', classes.stateSelector)}
                        label={'Timezone'}
                        value={timezone}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          setTimezone(event.target.value as any)
                        }}
            >
              <MenuItem value={''}>-</MenuItem>
              <MenuItem value={ClientViewTimeZone.Akst}>{ClientViewTimeZone.Akst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Cst}>{ClientViewTimeZone.Cst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Est}>{ClientViewTimeZone.Est}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Hst}>{ClientViewTimeZone.Hst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Mst}>{ClientViewTimeZone.Mst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Pst}>{ClientViewTimeZone.Pst}</MenuItem>
            </FormSelect>


            <Button variant={'contained'} color={'primary'} onClick={onSearch}>Search</Button>
          </Box>
        </Box>
        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid rows={data?.attemptedLeads?.data ? data?.attemptedLeads?.data.map(i => ({...i, id: i.clientId})) : []}
                    paginationMode={"server"}
                    sortingMode={'server'}
                    columns={columns}
                    page={page}
                    onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                    sortModel={sorting ? [sorting] : []}
                    onSortModelChange={(sortModel) => {
                      setSorting(sortModel[0])
                    }}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowCount={data?.attemptedLeads?.totalElements}
                    pagination
          />
        </div>
      </Card>

    </Box>
  </Box>;
}

export default AttemptedLeads;


const GroupView = ({group}: {group?: AttemptedLeadGroupDto}) => <Box display={'flex'} alignItems={'center'}>
  <Typography variant={'body1'} color={'textPrimary'}>{mapString(group)}</Typography>
</Box>

const mapString = (group?: AttemptedLeadGroupDto) => {
  switch (group) {
    case AttemptedLeadGroupDto.CalledToday: return 'CalledToday';
    case AttemptedLeadGroupDto.Group_1: return 'Group 1';
    case AttemptedLeadGroupDto.Group_2: return 'Group 2';
    case AttemptedLeadGroupDto.Group_3: return 'Group 3';
    case AttemptedLeadGroupDto.Group_4: return 'Group 4';
    case AttemptedLeadGroupDto.Group_5: return 'Group 5';
    case AttemptedLeadGroupDto.Group_6: return 'Group 6';
    case AttemptedLeadGroupDto.Group_7: return 'Group 7';
  }
}
