import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import React from "react";
import {ClientViewOutput} from "../../enrollment-types";
import EnrollmentInfo from "./EnrollmentInfo";
import Preloader from "../../shared/Preloader";
import MedigapEnrollmentInfo from "./MedigapEnrollmentInfo";
import PdpEnrollmentInfo from "./PdpEnrollmentInfo";
import paths from "../../router-paths";
import {useHistory} from "react-router";
import useClientEnrollments from "../hooks/useClientEnrollments";

interface ClientEnrollmentsProps {
  client?: ClientViewOutput
}

const ClientEnrollments = (props: ClientEnrollmentsProps) => {
  const history = useHistory();
  const {loading, refetch, pdpEnrollments, maEnrollments, mgEnrollments} = useClientEnrollments(true, props.client?.id)

  return <div className={'relative'}>
    <Preloader in={loading} />
    <div className={'flex flex-align-center h-80'}>
      <Typography color={"textPrimary"} variant={"h4"}>Medicare Advantage</Typography>
      <Button onClick={() => history.push(paths.maEnrollmentEdit + `/${props.client?.id}`)}
              className={'ml-15'}
              variant={'contained'}
              color={'primary'}>CREATE</Button>
    </div>
    <Grid container spacing={2}>
      {maEnrollments.map(e => <Grid key={e.id} item><EnrollmentInfo enrollment={e}
                                                                    email={props.client?.email}
                                                                    onDeleteClick={refetch}
                                                                    scopeOfAppointmentStatus={props.client?.scopeOfAppointmentStatus} /></Grid>)}
    </Grid>
    <div className={'flex flex-align-center h-80'}>
      <Typography color={"textPrimary"} variant={"h4"}>Medigap</Typography>
      <Button onClick={() => history.push(paths.mgEnrollmentEdit + `/${props.client?.id}`)}
              className={'ml-15'}
              variant={'contained'}
              color={'primary'}>CREATE</Button>
    </div>
    <Grid container spacing={2}>
      {mgEnrollments.map(e => <Grid key={e.id} item><MedigapEnrollmentInfo enrollment={e}
                                                                           onDeleteClick={refetch} /></Grid>)}
    </Grid>
    <div className={'flex flex-align-center h-80'}>
      <Typography color={"textPrimary"} variant={"h4"}>Prescription Drug Plan</Typography>
      <Button onClick={() => history.push(paths.pdpEnrollmentEdit + `/${props.client?.id}`)}
              className={'ml-15'}
              variant={'contained'}
              color={'primary'}>CREATE</Button>
    </div>
    <Grid container spacing={2}>
      {pdpEnrollments.map(e => <Grid key={e.id} item><PdpEnrollmentInfo enrollment={e}
                                                                        onDeleteClick={refetch} /></Grid>)}
    </Grid>
  </div>
}

export default ClientEnrollments;
