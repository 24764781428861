import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

export default function useResetImportedEnrollments(options?: MutationHookOptions<any>) {
  return useMutation<any>(gql(saveMutation), options)
}

const saveMutation = `
mutation {
  resetImportedEnrollments
}
`;
