import {
  Box, Checkbox, CircularProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tab, Tabs,
  Typography
} from "@material-ui/core";
import Card from "../shared/Card";
import React, {ChangeEvent} from "react";
import useEnabledMaContracts from "./hooks/useEnabledMaContracts";
import useEnabledMaStates from "./hooks/useEnabledMaStates";
import * as _ from 'lodash';
import useSaveEnabledMaState from "./hooks/useSaveEnabledMaState";
import {
  AvailableStateDto,
  EnabledPbpContractOutput,
  EnabledPdpContractInput,
  EnabledPdpContractOutput,
  EnabledPdpStatesOutput
} from "../types";
import useSaveEnabledMaContract from "./hooks/useSaveEnabledMaContract";
import {EnabledPbpStatesOutput, EnabledPbpContractInput} from "../types";
import TextInput from "../shared/TextInput";
import {useDebouncedEffect} from "../shared/useDebouncedEffect";
import clsx from "clsx";
import AscendAutocomplete from "../shared/AscendAutocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from '@material-ui/icons/Close';
import useEnabledPdpContracts from "./hooks/useEnabledPdpContracts";
import useEnabledPdpStates from "./hooks/useEnabledPdpStates";
import useSaveEnabledPdpState from "./hooks/useSaveEnabledPdpState";
import useSaveEnabledPdpContract from "./hooks/useSaveEnabledPdpContract";

export default function PlanCoverage() {
  const [value, setValue] = React.useState(0);


  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>

      <Card padding={0}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setValue(newValue)}
        >
          <Tab label="Medicare Advantage" />
          <Tab label="PDP" />
        </Tabs>
        {value === 0 && <MaPlanCoverage />}
        {value === 1 && <PdpPlanCoverage />}
      </Card>

    </Box>
  </Box>
}

function PdpPlanCoverage() {
  const enabledContractsData = useEnabledPdpContracts({onCompleted: data => setEnabledContracts(data.enabledPdpContracts)});
  const enabledStatesData = useEnabledPdpStates({onCompleted: data => setEnabledStates(_.orderBy(data.enabledPdpStates, 'state'))});

  const [saveState] = useSaveEnabledPdpState()
  const [saveContract] = useSaveEnabledPdpContract()
  const [term, setTerm] = React.useState('');
  const [parentCompany, setParentCompany] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const [enabledContracts, setEnabledContracts] = React.useState<EnabledPdpContractOutput[]>([]);
  const [filteredContracts, setFilteredContracts] = React.useState<EnabledPdpContractOutput[]>([]);
  const [enabledStates, setEnabledStates] = React.useState<EnabledPdpStatesOutput[]>([]);


  useDebouncedEffect(() => {
    if (term || parentCompany) {
      const filteredByTerm = enabledContracts.filter(i => [i.contract.toLowerCase(), i.company.toLowerCase(), i.parentCompany.toLowerCase()].some(t => t.indexOf(term.toLowerCase()) >= 0));
      setFilteredContracts(parentCompany ? filteredByTerm.filter(i => i.parentCompany === parentCompany) : filteredByTerm)
    } else {
      setFilteredContracts(enabledContracts)
    }
  }, 500, [term, enabledContracts, parentCompany])


  const onStateToggle = (state: AvailableStateDto, enabled: boolean) => {
    saveState({variables: {state, enabled}}).then(() => enabledStatesData.refetch())
  }

  const onContractToggle = (input: EnabledPdpContractInput) => {
    saveContract({variables: {input}}).then(() => enabledContractsData.refetch())
  }

  const parentCompanies = React.useMemo(() => {
    return _.uniq(enabledContracts.map(c => c.parentCompany)).sort();
  }, [enabledContracts])

  return <Box display={'flex'} p={'30px'} alignItems={'top'} width={'100%'}>
    <Box flex={1}>
      <Typography className={'mb-16'} color={'textPrimary'} variant={'h4'}>Contract Coverage</Typography>
      <Box display={'flex'}>
        <TextInput
          fullWidth
          label="Search"
          value={term}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setTerm(e.target.value || '')}
        />
        <AscendAutocomplete fullWidth
                            options={parentCompanies}
                            value={searchTerm}
                            onInputChange={(event, newInputValue) => {
                              setSearchTerm(newInputValue);
                            }}
                            filterOptions={(options, state) => {
                              return options.filter((i: any) => i.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
                            }}
                            onChange={(e: any, value: any | null) => {
                              setParentCompany(value);
                            }}
                            renderInput={params => <TextInput label={'Parent company'}
                                                              className={clsx('mb-0 ml-16')}
                                                              {...params}
                                                              InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment:
                                                                  <InputAdornment position="end" style={{position: 'absolute', right: '13px'}}>
                                                                    {searchTerm !== '' && <CloseIcon cursor={'pointer'} onClick={() => {
                                                                      setSearchTerm('')
                                                                      setParentCompany('')
                                                                    }} fontSize={'small'} color={'inherit'} />}
                                                                  </InputAdornment>,
                                                              }}
                            />} />
      </Box>
      <table>
        <thead>
        <tr>
          <Box component={'td'} sx={{minWidth: 100}}><b>Contract</b></Box>
          <td><b>Parent Company</b></td>
          <td><b>Company</b></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        {filteredContracts.map((item, i) => <Row item={item} key={i} onClick={(checked) => onContractToggle({...item, enabled: checked})}/>)}
        </tbody>
      </table>
    </Box>
    <Box flex={1} ml={20}>
      <Typography color={'textPrimary'} variant={'h4'}>State Coverage</Typography>
      <Box sx={{maxWidth: 120, width: '100%'}}>
        <List>
          {enabledStates.map((item, i) => <ListItem
            key={item.state + i}
          >
            <ListItemText primary={item.state} />
            <ListItemSecondaryAction>
              <Checkbox
                color={'primary'}
                edge="end"
                onChange={(e) => {onStateToggle(item.state, e.target.checked)}}
                checked={item.enabled}
              />
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>
      </Box>
    </Box>
  </Box>
}

function MaPlanCoverage() {
  const enabledMaContractsData = useEnabledMaContracts({onCompleted: data => setEnabledMaContracts(data.enabledPbpContracts)});
  const enabledMaStatesData = useEnabledMaStates({onCompleted: data => setEnabledMaStates(_.orderBy(data.enabledPbpStates, 'state'))});

  const [saveState] = useSaveEnabledMaState()
  const [saveContract] = useSaveEnabledMaContract()
  const [term, setTerm] = React.useState('');
  const [parentCompany, setParentCompany] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const [enabledMaContracts, setEnabledMaContracts] = React.useState<EnabledPbpContractOutput[]>([]);
  const [filteredMaContracts, setFilteredMaContracts] = React.useState<EnabledPbpContractOutput[]>([]);
  const [enabledMaStates, setEnabledMaStates] = React.useState<EnabledPbpStatesOutput[]>([]);


  useDebouncedEffect(() => {
    if (term || parentCompany) {
      const filteredByTerm = enabledMaContracts.filter(i => [i.contract.toLowerCase(), i.company.toLowerCase(), i.parentCompany.toLowerCase()].some(t => t.indexOf(term.toLowerCase()) >= 0));
      setFilteredMaContracts(parentCompany ? filteredByTerm.filter(i => i.parentCompany === parentCompany) : filteredByTerm)
    } else {
      setFilteredMaContracts(enabledMaContracts)
    }
  }, 500, [term, enabledMaContracts, parentCompany])


  const onStateToggle = (state: AvailableStateDto, enabled: boolean) => {
    saveState({variables: {state, enabled}}).then(() => enabledMaStatesData.refetch())
  }

  const onContractToggle = (input: EnabledPbpContractInput) => {
    saveContract({variables: {input}}).then(() => enabledMaContractsData.refetch())
  }

  const parentCompanies = React.useMemo(() => {
    return _.uniq(enabledMaContracts.map(c => c.parentCompany)).sort();
  }, [enabledMaContracts])

  return <Box display={'flex'} p={'30px'} alignItems={'top'} width={'100%'}>
    <Box flex={1}>
      <Typography className={'mb-16'} color={'textPrimary'} variant={'h4'}>Contract Coverage</Typography>
      <Box display={'flex'}>
        <TextInput
          fullWidth
          label="Search"
          value={term}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setTerm(e.target.value || '')}
        />
        <AscendAutocomplete fullWidth
                            options={parentCompanies}
                            value={searchTerm}
                            onInputChange={(event, newInputValue) => {
                              setSearchTerm(newInputValue);
                            }}
                            filterOptions={(options, state) => {
                              return options.filter((i: any) => i.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
                            }}
                            onChange={(e: any, value: any | null) => {
                                setParentCompany(value);
                            }}
                            renderInput={params => <TextInput label={'Parent company'}
                                                              className={clsx('mb-0 ml-16')}
                                                              {...params}
                                                              InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment:
                                                                  <InputAdornment position="end" style={{position: 'absolute', right: '13px'}}>
                                                                    {searchTerm !== '' && <CloseIcon cursor={'pointer'} onClick={() => {
                                                                      setSearchTerm('')
                                                                      setParentCompany('')
                                                                    }} fontSize={'small'} color={'inherit'} />}
                                                                  </InputAdornment>,
                                                              }}
                            />} />
      </Box>
      <table>
        <thead>
          <tr>
            <Box component={'td'} sx={{minWidth: 100}}><b>Contract</b></Box>
            <td><b>Parent Company</b></td>
            <td><b>Company</b></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
        {filteredMaContracts.map((item, i) => <Row item={item} key={i} onClick={(checked) => onContractToggle({...item, enabled: checked})}/>)}
        </tbody>
      </table>
    </Box>
    <Box flex={1} ml={20}>
      <Typography color={'textPrimary'} variant={'h4'}>State Coverage</Typography>
      <Box sx={{maxWidth: 120, width: '100%'}}>
        <List>
          {enabledMaStates.map((item, i) => <ListItem
            key={item.state + i}
          >
            <ListItemText primary={item.state} />
            <ListItemSecondaryAction>
              <Checkbox
                color={'primary'}
                edge="end"
                onChange={(e) => {onStateToggle(item.state, e.target.checked)}}
                checked={item.enabled}
              />
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>
      </Box>
    </Box>
  </Box>
}

interface RowProps {
  item: EnabledPbpContractOutput | EnabledPdpContractOutput,
  onClick: (checked: boolean) => void
}

const Row = React.memo(({item, onClick}: RowProps) => {

  return <tr
    key={item.contract}
  >
    <td>{item.contract}</td>
    <td>{item.parentCompany}</td>
    <td>{item.company}</td>
    <td>
      <Checkbox
        color={'primary'}
        edge="end"
        onChange={e => {
          e.preventDefault();
          e.stopPropagation()
          onClick(e.target.checked)
        }}
        checked={item.enabled}
      />
    </td>
  </tr>
}, areEqual);

function areEqual(prevProps: RowProps, nextProps: RowProps): boolean {
  return prevProps.item.contract === nextProps.item.contract && prevProps.item.enabled === nextProps.item.enabled
}