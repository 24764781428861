import React from "react";
import {Box, Button, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import PersonalInfo from "./widgets/PersonalInfo";
import MedicareQuote from "./widgets/MedicareQuote";
import UserPriorities from "./widgets/UserPriorities";
import { RouteComponentProps, useParams } from "react-router-dom";
import {useSubscription} from "@apollo/client";
import {gql} from "@apollo/client";
import {ClientViewOutput, StatusDto} from "../enrollment-types";
import Notes from "./widgets/notes/Notes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Status from "./widgets/Status";
import LeadSources from "./widgets/LeadSources";
import CallHistory from "./widgets/CallHistory/CallHistory";
import MedigapQuote from "./widgets/MedigapQuote";
import SendInBlue from "./widgets/SendInBlue";
import ClientTags from "./widgets/ClientTags";
import ProductCounter from "./widgets/ProductCounter";
import EmailStats from "./widgets/EmailStats";
import LoginStats from "./widgets/LoginStats";
import CompareOffer from "./widgets/CompareOffer";
import PdpQuote from "./widgets/PdpQuote";
import {useDropzone} from "react-dropzone";
import {config} from "../config/config";
import {useSnackbar} from "notistack";
import useAddClientFiles from "./hooks/useAddClientFiles";
import ClientFiles from "./widgets/ClientFiles";
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import ClientEnrollments from "./enrollment/ClientEnrollments";
import {YearToYearCompare} from "./widgets/YearToYearCompare";
import PdpEnrollmentInfo from "./enrollment/PdpEnrollmentInfo";
import MedigapEnrollmentInfo from "./enrollment/MedigapEnrollmentInfo";
import EnrollmentInfo from "./enrollment/EnrollmentInfo";
import useClientEnrollments from "./hooks/useClientEnrollments";
import ClientGroups from "./widgets/ClientGroups";
import SetUserIdForm from "./components/SetUserIdForm";
import Card from "../shared/Card";
import Doctors from "./widgets/Doctors";
import Drugs from "./widgets/Drugs";
import QuizAnswers from "./widgets/QuizAnswers";
import ChannelAttribution from "./widgets/ChannelAttribution";
import ClientEmails from "./widgets/emails/ClientEmails";
import paths from "../router-paths";
import FollowUpModal from "./components/FollowUpModal";
import moment from "moment";
import useClientCommunicationNoShow from "./hooks/useClientCommunicationNoShow";
import useSaveStatus from "./hooks/useSaveStatus";
import {GraphQLErrorType} from "../Apollo/EnrollmentApolloClient";
import CustomerIoLinkButton from "./widgets/CustomerIoLinkButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstColumn: {
      flexGrow: 0,
      flexShrink: 0,
      '& > *': {
        paddingBottom: theme.spacing(2)
      }
    },
    firstColumnItem: {
      flexShrink: 0,
      width: '340px',
      maxWidth: '340px',
      flexBasis: '340px',
    }
  }),
);

const ClientDetails = (props: RouteComponentProps<{ id: string }>) => {
  const params = useParams<{id: string, tab?: string, agentId?: string, emailId?: string}>();
  const classes = useStyles();
  const {id} = props.match.params;
  const [tab, setTab] = React.useState(0);
  const {pdpEnrollments, maEnrollments, mgEnrollments} = useClientEnrollments(false, id)
  const [followUpModalOpen, setFollowUpModalOpen] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const [saveStatus] = useSaveStatus({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Follow up date changed!', {variant: "success"});
    }
  });

  const [noShow] = useClientCommunicationNoShow()
  const {data, loading} = useSubscription<{clientCard: ClientViewOutput}>(gql(query), {
    variables: {
      id
    }
  });

  React.useEffect(() => {
    if (params.tab === 'enroll') {
      setTab(1)
    }
    if (params.tab === 'email') {
      setTab(2)
    }
  }, [params.tab]);

  const changeTab = (newVal: number) => {
    setTab(newVal)
    if (newVal === 2 && !props.location.pathname.includes('/email')) {
      props.history.replace(paths.client + '/' + id + '/email')
    } else if (newVal === 1 && !props.location.pathname.includes('/enroll')) {
      props.history.replace(paths.client + '/' + id + '/enroll')
    } else if (newVal === 0 && props.location.pathname.includes('/enroll') || props.location.pathname.includes('/email')) {
      props.history.replace(paths.client + '/' + id)
    }
  }

  const isAdmin = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN)
  }, [keycloak])


  const client = React.useMemo(() => {
      return data?.clientCard;
  }, [data]);

  const county = React.useMemo(() => {
      if (!client?.countyName && client?.customAnswers) {
        const parsed = JSON.parse(client.customAnswers);
        if (parsed?.STEP_2?.county || parsed?.STEP_2?.countyName) {
          return parsed?.STEP_2?.county || parsed?.STEP_2?.countyName;
        }
      }
      return client?.countyName;
  }, [client]);

  const isTech = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_TECH_ADMIN)
  }, [keycloak])

  React.useEffect(() => {
    if (client && client.status === StatusDto.FollowUp &&
      client.followUpDate && moment(client.followUpDate).isSameOrBefore(moment()) &&
      keycloak.tokenParsed.sub === client?.agent?.id
    ) {
      setFollowUpModalOpen(true);
    }
  }, [client, keycloak])

  const onFollowUpModalClose = (date?: Date | null) => {
    if (date) {
      const followUpDateStr = moment(date).format('YYYY-MM-DD');
      saveStatus({
        variables: {
          id: client?.id,
          followUpDate: followUpDateStr,
          agentId: client?.agent?.id,
          status: client?.status || StatusDto.FollowUp,
        }
      })
    }
    setFollowUpModalOpen(false)
  }

  return <div>
    {!!client && <FollowUpModal open={followUpModalOpen} onClose={onFollowUpModalClose} currentDate={moment(client?.followUpDate).toDate()} />}
    <Box sx={{ borderBottom: '1px solid #d9d9d9', marginBottom: 16 }}>
      <Tabs value={tab} onChange={(e, val) => changeTab(val)} aria-label="basic tabs example">
        <Tab label="Info" />
        <Tab label="Enrollments" />
        <Tab label="Emails" />
      </Tabs>
    </Box>
    {tab === 0 && <>
      <Grid container spacing={2}>
        <Grid item container xs={9} spacing={2} direction={'column'}>
          <Grid item spacing={2} container>
            <Grid item xs={3}>
              <div>
                {isTech && <>
                  <Card>
                    <SetUserIdForm client={client} />
                  </Card>
                  <div className={'h-16'}/>
                </>}
                <PersonalInfo client={client} />
                <div className={'h-16'}/>
                <ChannelAttribution client={client} />
                <div className={'h-16'}/>
                <ClientGroups client={client}/>
                <div className={'h-16'}/>
                <ProductCounter client={client}/>
                <div className={'h-16'}/>
                {maEnrollments.map(enrollment => <>
                  <EnrollmentInfo enrollment={enrollment} viewOnly />
                  <div className={'h-16'}/>
                </>)}
                {mgEnrollments.map(enrollment => <>
                  <MedigapEnrollmentInfo enrollment={enrollment} viewOnly />
                  <div className={'h-16'}/>
                </>)}
                {pdpEnrollments.map(enrollment => <>
                  <PdpEnrollmentInfo enrollment={enrollment} viewOnly />
                  <div className={'h-16'}/>
                </>)}
              </div>
            </Grid>
            <Grid item xs={9}>
              <div>
                <UserPriorities client={client} />
                <div className={'h-16'}/>
                <Doctors client={client} />
                <div className={'h-16'}/>
                <Drugs client={client} />
                <div className={'h-16'}/>
                <Box display={'flex'}>
                  <MedicareQuote pin={client?.pin || undefined} client={client} county={county} />
                  <div className={'w-16'}/>
                  <MedigapQuote client={client} />
                  <div className={'w-16'}/>
                  <PdpQuote client={client} />
                </Box>
                <div className={'h-16'}/>
                <QuizAnswers client={client} county={county} answers={client?.customAnswers ? JSON.parse(client.customAnswers) : undefined} />
                <div className={'h-16'}/>
                <Typography color={'textPrimary'} className={'fs-16 medium'}>Total calls:</Typography>
                {isAdmin && <CallHistory client={client} phoneNumber={client?.phoneNumber} loading={loading} />}
                <div className={'h-16'}/>
                <Typography color={'textPrimary'} className={'fs-16 medium flex flex-align-center'}>Lead Sources <Typography color={'textSecondary'} className={'ml-15 fs-12'}>{client?.cId}</Typography></Typography>
                <LeadSources clientId={client?.id} loading={loading} />
                <div className={'h-16'}/>
                <Typography color={'textPrimary'} className={'fs-16 medium'}>Send In Blue</Typography>
                {isAdmin && <SendInBlue email={client?.email} loading={loading} />}
                <div className={'h-16'}/>
                <Typography color={'textPrimary'} className={'fs-16 medium'}>Email Stats</Typography>
                <EmailStats email={client?.email} loading={loading} />
                <div className={'h-16'}/>
                <Typography color={'textPrimary'} className={'fs-16 medium'}>Login Stats</Typography>
                <LoginStats email={client?.email} loading={loading} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={3} spacing={2} direction={'column'}>
          <Grid item>
            <Status client={client}/>
          </Grid>
          <Grid item>
            <Notes client={client}/>
          </Grid>
          <Grid item>
            <ClientTags client={client} />
          </Grid>
          <Grid item>
            <YearToYearCompare client={client}/>
          </Grid>
          <Grid item>
            <CompareOffer client={client}/>
          </Grid>
          <Grid item>
            <ClientFiles client={client} />
          </Grid>
          <Grid item>
            <Box sx={{mt: 1}}>
              {!client?.noShow && <Button variant={'contained'} color={'secondary'} onClick={() => noShow({variables: {clientId: client?.id}})}>NO SHOW</Button>}
              {!!client?.noShow && <Typography color={"textPrimary"} variant={"body1"} className={"medium"}>Client didn't show up</Typography>}
            </Box>
          </Grid>
          <Grid item>
            {client?.id && <CustomerIoLinkButton clientId={client?.id}/>}
          </Grid>
        </Grid>
      </Grid>
    </>}
    {tab === 1 && <ClientEnrollments client={client} />}
    {tab === 2 && <ClientEmails client={client} agentId={params.agentId} emailId={params.emailId}  />}
  </div>;
};

const withDropZone = (WrappedComponent: any) => (props: RouteComponentProps<{ id: string }>) => {
  const {enqueueSnackbar} = useSnackbar();
  const [addClientFiles] = useAddClientFiles();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: files => {
      if (files.length) {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file, file.name))
        formData.append('clientId', props.match.params.id)

        const requestOptions = {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + keycloak?.token
          },
          body: formData
        };

        const url = config.clientFileUploadUrl + '/api/client-file'

        fetch(url, requestOptions)
          .then(res => res.json())
          .then(res => addClientFiles({variables: {
              clientId: props.match.params.id,
              clientFiles: res.links
            }}))
          .catch(() => enqueueSnackbar('Error in uploading file!', {variant: "error"}))
      }
    }
  })
  return <Box {...getRootProps()} position={'relative'}>
    <input {...getInputProps()} />
    {isDragActive && <Box position={'absolute'} zIndex={1001} width={'100%'} height={'100%'} bgcolor={'white'} borderRadius={3} border={'5px dashed #eaeaea'}>
      <Box position={'fixed'} ml={'35%'} mt={'100px'}>
        <Typography color={'textSecondary'} className={'fs-18 bold'}>Drop files to upload</Typography>
      </Box>
    </Box>}
    <WrappedComponent {...props} />
  </Box>
}

export default withDropZone(ClientDetails);

export const clientFields = `
    id
    agentMedicareQuote {
      quoteId
      zip
      countyName
    }
    agentMedigapQuote {
      id
    }
    clientFiles {
      link
      name
    }
    urgentCallRequested
    urgentCallType
    marketingCampaign
    marketingSource
    enrolled
    adviserName
    adviserEmail
    profileSource
    customAnswers
    countyName
    email
    firstName
    lastName
    phoneNumber
    userId
    pin
    noShow
    disqualifiedReason
    scheduledTagPlacement
    notes {
      clientId
      createdAt
      createdByAgent {
        email
        firstName
        id
        lastName
      }
      id
      note
      updatedAt
    }
    gender
    profileId
    quoteId
    status
    birthDate
    zip
    cId
    state
    followUpDate
    originalClientId
    pdpEnrollment {
      birthDate
      cId
      clientId
      createdAt
      firstName
      id
      insulinSavings
      lastName
      pdpQuoteId
      phoneNumber
      planId
      updatedAt
      userId
    }
    leadSources {
      source
    }
    filters {
      extraBenefits
    }
    pdpQuote {
      cId
      clientId
      countyName
      createdAt
      id
      insulinSavings
      updatedAt
      userId
      zip
    }
    medigapQuote {
      cId
      createdAt
      id
      medigapFilterState {
        age
        benefits
        companies
        county
        effectiveDate
        gender
        id
        monthlyPlanPremiumRanges
        planCategory
        planNames
        tobacco
        zip
      }
      updatedAt
      userId
    }
    expectedCloseDate
    medigapEnrollment {
      age
      approvedAt
      birthDate
      createdAt
      effectiveDate
      email
      firstName
      gender
      id
      lastName
      pdpBidId
      naic
      phoneNumber
      planName
      quoteId
      status
      tobacco
      userId
      medicareNumber
      preferredLanguage
      medicalDate
      hospitalDate
      approvedAt
      accessibleFormatPreference
      permanentAddress {
        aptNumber
        city
        county
        state
        streetName
        zip
      }
      mailingAddress {
        aptNumber
        city
        county
        state
        streetName
        zip
      }
    }
    scopeOfAppointmentStatus
    createdAt
    tags {
      expiresAt
      tag {
        color
        id
        name
      }
    }
    doctors {
      addresses {
        addressLine1
        addressLine2
        id
        isPcp
        specialty
      }
      pcpDetails {
        id
        networkDetails {
          carrierName
          name
        }
        pcpId
      }
      addressesNumber
      name
      npi
    }
    preferredDrugs {
      dosageRxcui
      frequency
      productRxcui
      purchaseFrequency
      quantity
    }
    agent {
      email
      firstName
      lastName
      id
    }
    purchasedProducts {
      firstName
      id
      isPrimaryPerson
      lastName
      quantity
      type
    }
`;

const query = `
subscription($id: UUID!) {
  clientCard (data:{
    clientId: $id
  }) {
    ${clientFields}
  }
}
`;


