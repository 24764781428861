import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteEnrollmentArgs} from "../../enrollment-types";

export default function useDeleteEnrollment(options?: MutationHookOptions<{deleteEnrollment: string}, MutationDeleteEnrollmentArgs>) {
  return useMutation<{deleteEnrollment: string}, MutationDeleteEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($enrollmentId: UUID!) {
  deleteEnrollment(enrollmentId: $enrollmentId)
}
`;

