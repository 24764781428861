import React from "react";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import moment from "moment";
import {colors} from "../../AppTheme";
import {formatPhoneLink} from "../../PlanList/utils";
import useFollowUps from "../hooks/useFollowUps";
import {AssignedTagViewOutput} from "../../enrollment-types";
import paths from "../../router-paths";
import useFollowUpsPage from "../hooks/useFollowUpsPage";
import useFollowUpsPageSize from "../hooks/useFollowUpsPageSize";
import {Chip} from "@material-ui/core";
import useContacted from "../hooks/useContacted";
import useContactedPageSize from "../hooks/useContactedPageSize";
import useContactedPage from "../hooks/useContactedPage";



const columns: GridColDef[] = [
  { field: 'createdAt', headerName: 'Lead date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtDateStr = params.row.createdAt;
      if (createdAtDateStr) {
        return moment(createdAtDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false,
    renderCell: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown user';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
    }
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'followUpDate', headerName: 'Follow up date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={value.tag.name}  />
        ))}
      </div>
    }
  },
];

const Contacted = (props: {agentId?: string}) => {
  const [page, setPage] = useContactedPage();
  const [pageSize, setPageSize] = useContactedPageSize();
  const [getContacted, {loading, data}] = useContacted();
  let history = useHistory();

  React.useEffect(() => {
    if (props.agentId) {
      getContacted({variables: {
          agentId: props.agentId,
          pageInput: {
            page: page,
            size: pageSize
          },
        }
      })
    }
  }, [props.agentId, pageSize, page]);

  const rows = React.useMemo(() => {
    return data?.contacted.data || [];
  }, [data]);

  const total = React.useMemo(() => {
    return data?.contacted.totalElements ? parseFloat(data?.contacted.totalElements) : 0;
  }, [data]);

  return <div style={{ height: '400px', width: '100%' }}>
        <DataGrid rows={rows}
                  loading={loading}
                  columns={columns}
                  disableSelectionOnClick
                  paginationMode={"server"}
                  page={page}
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  onRowClick={(param, event) => {
                    history.push(paths.client + '/' + param.row.id)
                  }}
                  rowCount={total}
                  pagination
        />
    </div>
}

export default Contacted;
