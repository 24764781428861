import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
  Box, Button,
  IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, Divider
} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import Card from "../shared/Card";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from "react-router";
import { RouteComponentProps } from "react-router-dom";
import useVideoContent from "./hooks/useVideoContent";
import paths from "../router-paths";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import useSaveVideoOrder from "./hooks/useSaveVideoOrder";
import * as _ from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../Apollo/ApolloClient";
import {Video} from "../content-management";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);


const PAGE_SIZE = 10;

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', width: 400 },
  { field: 'duration', headerName: 'Duration', width: 200},
];

const VideoList = (props: RouteComponentProps<{id: string}>) => {
  const {id} = props.match.params;
  const [getVideos, videoData] = useVideoContent();
  const [saveOrder, saveOrderData] = useSaveVideoOrder();
  let history = useHistory();
  const [rows, setRows] = React.useState<Video[]>([]);
  const [deleteRow, deleteData] = useMutation(gql(deleteRowMutation), {
    onCompleted: () => videoData.refetch(),
    client
  });

  React.useEffect(() => {
      if (id) {
        getVideos({variables: { categoryId: id }})
      }
  }, [id]);

  React.useEffect(() => {
    if (videoData?.data?.videoContent) {
      setRows(_.sortBy(videoData.data.videoContent.videos, 'sortingOrder'))
    }
  }, [videoData]);

  const onDrop = React.useCallback(({ removedIndex, addedIndex }: DropResult) => {
    const result = arrayMove(rows, removedIndex as number, addedIndex as number)
    saveOrder({variables: {ids: result.map(i => i.id)}});
    setRows(result);
  }, [rows]);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={false} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Box display={'flex'} alignItems={'center'}>
            <Button onClick={() => history.push(paths.videoCategories)}>BACK</Button>
            <Typography color={'textPrimary'} className={'ml-15'} variant={'h4'}>{videoData?.data?.videoContent?.title}</Typography>
          </Box>
          <IconButton component="span" onClick={() => history.push(paths.video + '?categoryId=' + id)}>
            <AddIcon />
          </IconButton>
        </Box>
        <List>
          <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {rows.map(({ id, title }, i, items) => (
              <Draggable key={id}>
                <ListItem button onClick={() => history.push(paths.video + '/' + id)}>
                  <ListItemText primary={title} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        deleteRow({variables: {videoId: id}})
                      }}>
                        <ClearIcon style={{color: 'red'}}  />
                      </IconButton>
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
                {i < items.length - 1 && <Divider />}
              </Draggable>
            ))}
          </Container>
        </List>
      </Card>
    </Box>
  </Box>;
}

export default VideoList;

const deleteRowMutation = `
  mutation ($videoId: UUID!) {
    deleteVideo(videoId: $videoId)
  }
`
