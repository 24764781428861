import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/EnrollmentApolloClient";
import {PageableMedigapEnrollmentOutput, QueryMedigapEnrollmentsArgs} from "../../enrollment-types";

export default function useMedigapEnrollments(onCompleted?: (data: {medigapEnrollments: PageableMedigapEnrollmentOutput}) => void) {
  return useLazyQuery<{medigapEnrollments: PageableMedigapEnrollmentOutput}, QueryMedigapEnrollmentsArgs>(
    gql(request),
    {
      onCompleted,
      fetchPolicy: 'no-cache',
      client
    }
  );
}

const request = `
query ($page: PageInput!, $filter: MedigapFilterInput){
  medigapEnrollments(page: $page, filter: $filter) {
    totalElements
    data {
      approvedAt
      birthDate
      createdAt
      email
      firstName
      id
      clientId
      lastName
      phoneNumber
      preferredLanguage
      planName
      status
    }
  }
}
`;
