import React from "react";
import {Box, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Section, {ISection} from "../PlanList/Section";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& tr td:first-child': {
      textAlign: 'right',
      paddingRight: 10,
    }
  },
}));

export default function Drugs({drugs}: any) {
  const classes = useStyles();

  return <Box>
    <Title title={'Initial Coverage'}/>
    <SubTitle title={'Initial coverage starts after you pay your deductible, if applicable, up to the initial coverage limit of $4,020'} />
    {drugs.initialCoverage.map((section: IDrugSection, i: number) => <Section key={i} section={drugToSection(section)}/>)}
    <Title title={'Coverage Gap (Donut Hole)'}/>
    <SubTitle title={drugs.coverageGapDescription} />
    {drugs.coverageGap.map((section: IDrugSection, i: number) => <Section key={i} section={drugToSection(section)}/>)}
    <Title title={'Catastrophic Coverage'}/>
    <SubTitle title={drugs.catastrophicCoverageDescription} />
    {drugs.catastrophicCoverage.map((section: IDrugSection, i: number) => <Section key={i} section={drugToSection(section)}/>)}
  </Box>
}

const drugToSection = (section: IDrugSection): ISection => {
  return {
  title: section.title,
  params: section.items.map(i => ({title: i.title, value: i.values.map(v => `<div>${v}</div>`).join('')}))
}}

const Title = React.memo(withStyles({
  bold: {
    fontWeight: 500
  }
})((props: {title: string, classes: any}) => <Box display={'flex'} justifyContent={'center'} pb={1} pt={4}>
  <Typography variant={'h2'} color={'textPrimary'} className={props.classes.bold}>{props.title}</Typography>
</Box>))

const SubTitle = React.memo((props: {title: string}) => <Box display={'flex'} justifyContent={'center'} pb={3}>{props.title}</Box>)

interface IDrugSection {
  title: string,
  items: {
    title: string,
    values: string[]
  }[]
}
