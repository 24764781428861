import React from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {Box, Button, Paper, Tab, Tabs, Theme, Typography} from "@material-ui/core";
import {useLazyQuery, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {makeStyles} from "@material-ui/core/styles";
import {IPlan} from "../PlanList/PlanCard";
import {IRawPlan} from "../PlanList/PlanList";
import Section from "../PlanList/Section";
import paths from "../router-paths";
import Drugs from "./Drugs";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {fillBenefits, fillPrimaryDoctorBenefits, fillSpecialistBenefits} from "../PlanList/utils";

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 500
  },
  paper: {
    paddingBottom: 40,
  },
  header: {
    position: 'sticky',
    top: 64,
    backgroundColor: 'white',
    zIndex: 100
  },
  ref: {
    marginTop: -150,
    paddingBottom: 150
  },
  text: {
    lineHeight: '12px',
    textDecoration: 'underline'
  },
  title: {
    fontWeight: 600,
    marginRight: 5,
    paddingTop: 5,
  }
}));

export default function PlanDetails({ match: { params } }: RouteComponentProps<{id: string}>) {
  const classes = useStyles();
  const history = useHistory();
  const [plan, setPlan] = React.useState<IPlan>();
  const [drugs, setDrugs] = React.useState();
  const [tabIndex, setTabIndex] = React.useState(0);

  const benefitsRef = React.useRef(null);
  const drugsRef = React.useRef(null);
  const coverageRef = React.useRef(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      if (benefitsRef?.current) {
        (benefitsRef.current as any).scrollIntoView({behavior: "smooth"});
      }
    }
    if (newValue === 1) {
      if (drugsRef?.current) {
        (drugsRef.current as any).scrollIntoView({behavior: "smooth"});
      }
    }
    if (newValue === 2) {
      if (coverageRef?.current) {
        (coverageRef.current as any).scrollIntoView({behavior: "smooth"});
      }
    }
  };

  useScrollPosition(({ currPos }) => {
    const els = [benefitsRef, drugsRef, coverageRef].map(isInViewport);
    const index = els.indexOf(true);
    if (index >= 0 && index !== tabIndex) {
      setTabIndex(index);
    }
  }, [tabIndex], undefined, undefined, 300);

  useQuery(gql(drugsQuery), {
    variables: {bid: params.id},
    onCompleted: result => {
      if (result.findDrugsDetailsByBidId) {
        setDrugs(result.findDrugsDetailsByBidId);
      }
    }
  });

  const {data} = useQuery(gql(planQuery), {
    variables: {
      bid: params.id
    },
    onCompleted: result => getAdditionalBenefits({variables: {bid: params.id}})
  });

  const [getAdditionalBenefits] = useLazyQuery<IAdditionalBenefitsResponse>(gql(additionalBenefitsQuery), {
    onCompleted: result => {
      if (result.findDentalBenefitByBidId) {
        setPlan(toPlan(Object.assign(
          data.plans_plan_details[0] || {},
          data.plans_plan_benefits[0] || {},
          {dentalBenefits: result.findDentalBenefitByBidId},
          {hearingBenefits: result.findHearingBenefitByBidId},
          {visionBenefits: result.findVisionBenefitByBidId},
          {hospitalBenefits: result.findHospitalBenefits},
        ), (id: string) => {
          if (id) {
            const el = document.getElementById(id);
            if (el) {
              el.scrollIntoView({behavior: "smooth"});
            }
          }
        }))
      }
    }
  });


  return <Box>
    <Box pb={2}>
      <Button variant={'contained'} onClick={() => history.push(paths.home)} color={'primary'}>Back to plan list</Button>
    </Box>
    <Paper square className={classes.paper}>
      <Box px={2} py={1} className={classes.header}>
        <Box py={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h1'} className={classes.bold} color={'textPrimary'}>{plan?.title}</Typography>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'170px'}>
            <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>$N/A</Typography>
            <Typography variant={'body1'} color={'textPrimary'} className={classes.text}>monthly premium</Typography>
          </Box>
        </Box>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Benefit Highlights" />
          <Tab label="Drug Coverage" />
          <Tab label="Doctor & Hospital Coverage" />
        </Tabs>
      </Box>


      <div ref={benefitsRef} className={classes.ref}/>
      <Box display={'flex'} justifyContent={'center'} p={4}>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.bold}>Benefit Highlights</Typography>
      </Box>
      {plan?.sections && <Section section={plan.sections[0]} />}

      <div ref={drugsRef} className={classes.ref}/>
      <Box display={'flex'} justifyContent={'center'} pt={4}>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.bold}>Drug Coverage</Typography>
      </Box>
      {drugs && <Drugs drugs={drugs} />}

      <div ref={coverageRef} className={classes.ref}/>
      <Box display={'flex'} justifyContent={'center'} p={4}>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.bold}>Doctor & Hospital Coverage</Typography>
      </Box>
      {plan?.sections && plan.sections.slice(1).map((sec, i) => <Section key={i} section={sec} /> )}
    </Paper>
  </Box>
}


const toPlan = (rp: IRawPlan, clickHandler: (value: string) => void): IPlan => {
  const benefits = fillBenefits(rp)
  const doctorBenefits = fillPrimaryDoctorBenefits(rp, true) as string[];
  const specialistBenefits = fillSpecialistBenefits(rp, true) as string[];
  return {
    title: rp.plan_name,
    bidId: rp.bid_id,
    company: rp.org_name,
    headerInfo: {
      doctor: doctorBenefits && doctorBenefits[0].replace('In network: ', ''),
      specialist: specialistBenefits && specialistBenefits[0].replace('In network: ', ''),
      flags: {
        dental: rp.dental,
        drugs: rp.drugs,
        hearing: rp.hearing,
        vision: rp.vision
      }
    },
    sections: [{
      title: 'Plan Coverage Overview',
      params: [
        {
          title: 'Plan type',
          value: rp.plan_type
        },
        {
          title: 'Annual In-Network Deductible',
          value: rp.in_network_deductable_amount || 'This plan does not have a deductible.'
        },
        {
          title: 'Out-of-Pocket Maximum',
          value: `<div>$${rp.out_of_pocket_amount || 0} for services you receive from in-network providers.</div>${rp.max_enrollment_amount ? `<div>$${rp.max_enrollment_amount || 0} for services you receive from any provider.</div>` : ''}`
        },
        {
          title: 'Inpatient Hospital Coverage',
          value: <Box>
            <Box mb={'3px'}>
              <Typography variant={'h4'}><b>Inpatient hospital-acute:</b></Typography>
            </Box>
            <Box display={'flex'}><Box width={'115px'}><b>In network:</b></Box> <div>{rp.hospitalBenefits?.acute?.inNetwork?.join('\n') || 'Not covered'}</div></Box>
            <Box display={'flex'}><Box width={'115px'}><b>Out of network:</b></Box> <div>{rp.hospitalBenefits?.acute?.outOfNetwork?.join('\n') || 'Not covered'}</div></Box>
            <Box mt={1} mb={'3px'}>
              <Typography variant={'h4'}><b>Inpatient hospital psychiatric:</b></Typography>
            </Box>
            <Box display={'flex'}><Box width={'115px'}><b>In network:</b></Box> <div>{rp.hospitalBenefits?.psychiatric?.inNetwork?.join('\n') || 'Not covered'}</div></Box>
            <Box display={'flex'}><Box width={'115px'}><b>Out of network:</b></Box> <div>{rp.hospitalBenefits?.psychiatric?.outOfNetwork?.join('\n') || 'Not covered'}</div></Box>
          </Box>
        },

        {
          title: 'Dental Services',
          value: <span>{rp.dentalBenefits?.length ? 'Yes' : 'No'} <a onClick={() => clickHandler('dentalBenefits')}>View details</a></span>
        },
        {
          title: 'Vision Services',
          value: <span>{rp.visionBenefits?.length ? 'Yes' : 'No'} <a onClick={() => clickHandler('visionBenefits')}>View details</a></span>
        },
        {
          title: 'Hearing Services',
          value: <span>{rp.hearingBenefits?.length ? 'Yes' : 'No'} <a onClick={() => clickHandler('hearingBenefits')}>View details</a></span>
        },
        ...benefits,
      ]
    }, {
      title: 'Additional Coverage',
      params: [
        {
          id: 'dentalBenefits',
          title: 'Dental Services',
          value: rp.dentalBenefits?.join('\n') || 'Not covered'
        },
        {
          id: 'visionBenefits',
          title: 'Vision Services',
          value: rp.visionBenefits?.join('\n') || 'Not covered'
        },
        {
          id: 'hearingBenefits',
          title: 'Hearing Services',
          value: rp.hearingBenefits?.join('\n') || 'Not covered'
        },
      ]
    }]
  }
}

function isInViewport(element: React.MutableRefObject<null>): boolean {
  if (!element?.current) return false;
  const rect = (element.current as any).getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const planQuery = `
  query ($bid: String!) {
    plans_plan_details(where: {bid_id: {_eq: $bid}}, limit: 1, offset: 0) {
      bid_id
      org_name
      plan_name
      plan_type
      in_network_deductable_amount
      out_of_pocket_amount
      max_enrollment_amount
    }
    plans_plan_benefits(where: {bid_id: {_eq: $bid}}, limit: 1, offset: 0) {
      pbp_b7a_coins_yn
      pbp_b7a_coins_pct_mc_min
      pbp_b7a_coins_pct_mc_max
      pbp_b7a_copay_amt_mc_max
      pbp_b7a_copay_amt_mc_min
      pbp_b7a_copay_yn
      pbp_b7d_coins_pct_mc_max
      pbp_b7d_coins_pct_mc_min
      pbp_b7d_coins_yn
      pbp_b7d_copay_amt_mc_max
      pbp_b7d_copay_amt_mc_min
      pbp_b7d_copay_yn
      bid_id
      oon_primary_pbp_c_oon_outpt_coins_max_pct
      oon_primary_pbp_c_oon_outpt_coins_min_pct
      oon_primary_pbp_c_oon_outpt_coins_yn
      oon_primary_pbp_c_oon_outpt_copay_max_amt
      oon_primary_pbp_c_oon_outpt_copay_min_amt
      oon_primary_pbp_c_oon_outpt_copay_yn
      oon_specialist_pbp_c_oon_outpt_coins_max_pct
      oon_specialist_pbp_c_oon_outpt_coins_min_pct
      oon_specialist_pbp_c_oon_outpt_coins_yn
      oon_specialist_pbp_c_oon_outpt_copay_max_amt
      oon_specialist_pbp_c_oon_outpt_copay_min_amt
      oon_specialist_pbp_c_oon_outpt_copay_yn
    }
  }
`

interface IAdditionalBenefitsResponse {
  findDentalBenefitByBidId: string[],
  findHearingBenefitByBidId: string[],
  findVisionBenefitByBidId: string[],
  findHospitalBenefits: {
    acute: {
      inNetwork: string[];
      outOfNetwork: string[];
    }
    psychiatric: {
      inNetwork: string[];
      outOfNetwork: string[];
    }
  }
}

const additionalBenefitsQuery = `
query ($bid: String!) {
    findDentalBenefitByBidId(bidId: $bid)
    findHearingBenefitByBidId(bidId: $bid)
    findVisionBenefitByBidId(bidId: $bid)
    findHospitalBenefits(bidId: $bid) {
      acute {
        inNetwork
        outOfNetwork
      }
      psychiatric {
        inNetwork
        outOfNetwork
      }
    }
}
`;
const drugsQuery = `
query ($bid: String!) {
    findDrugsDetailsByBidId(bidId: $bid) {
      catastrophicCoverage {
        items {
          title
          values
        }
        title
      }
      catastrophicCoverageDescription
      coverageGap {
        items {
          title
          values
        }
        title
      }
      coverageGapDescription
      initialCoverage {
        items {
          title
          values
        }
        title
      }
      mrxAltDedAmount
      mrxAltNoDedTier
    }
  }
`
