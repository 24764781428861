export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A type representing BigDecimal */
    BigDecimal: any;
    /** A type representing local date */
    LocalDate: any;
    /** A type representing long */
    Long: any;
    /** A type representing a string identifier */
    UUID: any;
};

export type MedigapCompanyFilterItem = {
    __typename?: 'MedigapCompanyFilterItem';
    count: Scalars['Int'];
    key: Scalars['String'];
    naics: Array<Scalars['String']>;
    name: Scalars['String'];
};

export type MedigapCompanyOutput = {
    __typename?: 'MedigapCompanyOutput';
    fullName: Scalars['String'];
    key: Scalars['String'];
    naic: Scalars['String'];
    name: Scalars['String'];
    parentCompanyName: Scalars['String'];
};

export type MedigapFilterItem = {
    __typename?: 'MedigapFilterItem';
    count: Scalars['Int'];
    key: Scalars['String'];
    name: Scalars['String'];
};

export type MedigapFilterStateOutput = {
    __typename?: 'MedigapFilterStateOutput';
    age: Scalars['Int'];
    benefits?: Maybe<Array<MedigapPlanBenefit>>;
    companies?: Maybe<Array<Scalars['String']>>;
    county?: Maybe<Scalars['String']>;
    effectiveDate?: Maybe<Scalars['LocalDate']>;
    gender: Gender;
    id: Scalars['UUID'];
    monthlyPlanPremiumRanges?: Maybe<Array<Scalars['String']>>;
    planCategory?: Maybe<Scalars['String']>;
    planNames?: Maybe<Array<MedigapPlanName>>;
    tobacco: Scalars['Boolean'];
    zip: Scalars['String'];
};

export type MedigapPlanOutput = {
    __typename?: 'MedigapPlanOutput';
    amBestRating?: Maybe<Scalars['String']>;
    duplicates?: Maybe<Array<MedigapPlanOutput>>;
    effectiveDate?: Maybe<Scalars['String']>;
    householdDiscount?: Maybe<Scalars['String']>;
    key: Scalars['String'];
    monthlyPremium?: Maybe<Scalars['String']>;
    naic: Scalars['String'];
    orgName?: Maybe<Scalars['String']>;
    parentOrgName?: Maybe<Scalars['String']>;
    planName?: Maybe<Scalars['String']>;
    ratingClass?: Maybe<Scalars['String']>;
    showAdditionalBenefitsDescription: Scalars['Boolean'];
    spRating?: Maybe<Scalars['String']>;
    subTitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type MedigapPreferredDrugOutput = {
    __typename?: 'MedigapPreferredDrugOutput';
    dosageRxcui: Scalars['String'];
    frequency: MedigapCalcDrugFrequencyPeriodOutput;
    productRxcui: Scalars['String'];
    purchaseFrequency: MedigapFrequencyPeriodOutput;
    quantity: Scalars['BigDecimal'];
};

export type MedigapPremiumRange = {
    __typename?: 'MedigapPremiumRange';
    planName: MedigapPlanName;
    rangeDescription: Scalars['String'];
};

export type MedigapQuoteOutput = {
    __typename?: 'MedigapQuoteOutput';
    cId?: Maybe<Scalars['String']>;
    clientId: Scalars['UUID'];
    drugDeliveryType?: Maybe<MedigapDrugDeliveryType>;
    id: Scalars['UUID'];
    medigapFilterState: MedigapFilterStateOutput;
    preferredDrugs: Array<MedigapPreferredDrugOutput>;
    preferredPharmacies: Array<Scalars['String']>;
    userId?: Maybe<Scalars['UUID']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    createMedigapQuoteForClient: SaveMedigapQuoteOutput;
    createMedigapQuoteFromMedicareQuote: SaveMedigapQuoteOutput;
    saveMedigapFilterState: Scalars['UUID'];
    saveMedigapQuote: SaveMedigapQuoteOutput;
};


export type MutationCreateMedigapQuoteForClientArgs = {
    agentOffer?: InputMaybe<Scalars['Boolean']>;
    input: MedigapQuoteInput;
};


export type MutationCreateMedigapQuoteFromMedicareQuoteArgs = {
    medicareQuoteId: Scalars['UUID'];
};


export type MutationSaveMedigapFilterStateArgs = {
    input: MedigapFilterStateInput;
};


export type MutationSaveMedigapQuoteArgs = {
    input: MedigapQuoteInput;
};

export type PageableMedigapPlanOutput = {
    __typename?: 'PageableMedigapPlanOutput';
    data: Array<MedigapPlanOutput>;
    hasNext: Scalars['Boolean'];
    hasPrevious: Scalars['Boolean'];
    isFirst: Scalars['Boolean'];
    isLast: Scalars['Boolean'];
    number: Scalars['Int'];
    size: Scalars['Int'];
    totalElements: Scalars['Long'];
    totalPages: Scalars['Int'];
};

export type Query = {
    __typename?: 'Query';
    medigapBenefits: Array<MedigapFilterItem>;
    medigapCompanies: Array<MedigapCompanyFilterItem>;
    medigapCompany?: Maybe<MedigapCompanyOutput>;
    medigapFilterState: MedigapFilterStateOutput;
    medigapMonthlyPlanPremium: Array<MedigapFilterItem>;
    medigapPlan: MedigapPlanOutput;
    medigapPlanNames: Array<MedigapFilterItem>;
    medigapPlans: PageableMedigapPlanOutput;
    medigapPlansUnfiltered: PageableMedigapPlanOutput;
    medigapPremiumRanges: Array<MedigapPremiumRange>;
    medigapQuote: MedigapQuoteOutput;
};


export type QueryMedigapCompaniesArgs = {
    filter: MedigapPlansFilterInput;
};


export type QueryMedigapCompanyArgs = {
    naic: Scalars['String'];
};


export type QueryMedigapFilterStateArgs = {
    id: Scalars['UUID'];
};


export type QueryMedigapPlanArgs = {
    key: Scalars['String'];
};


export type QueryMedigapPlanNamesArgs = {
    filter: MedigapPlansFilterInput;
};


export type QueryMedigapPlansArgs = {
    filter: MedigapPlansFilterInput;
    page: PageInput;
    sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPlansUnfilteredArgs = {
    filter: MedigapPlansFilterInput;
    page: PageInput;
    sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPremiumRangesArgs = {
    age: Scalars['Int'];
    gender: CsgGender;
    tobacco: Scalars['Boolean'];
    zip: Scalars['String'];
};


export type QueryMedigapQuoteArgs = {
    id: Scalars['UUID'];
};

export type SaveMedigapQuoteOutput = {
    __typename?: 'SaveMedigapQuoteOutput';
    clientId: Scalars['UUID'];
    mgQuoteId: Scalars['UUID'];
};

export enum CsgGender {
    F = 'F',
    M = 'M'
}

export enum Gender {
    F = 'F',
    M = 'M'
}

export enum MedigapCalcDrugFrequencyPeriodOutput {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Weekly = 'WEEKLY'
}

export enum MedigapDrugDeliveryType {
    Both = 'BOTH',
    Mail = 'MAIL',
    Pharmacy = 'PHARMACY'
}

export enum MedigapFrequencyPeriodOutput {
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY'
}

export enum MedigapPlanBenefit {
    ForeignTravelEmergency = 'FOREIGN_TRAVEL_EMERGENCY',
    PartADeductible = 'PART_A_DEDUCTIBLE',
    PartBDeductible = 'PART_B_DEDUCTIBLE',
    PartBExcessCharges = 'PART_B_EXCESS_CHARGES',
    SkilledNursingFacilities = 'SKILLED_NURSING_FACILITIES'
}

export enum MedigapPlanName {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    Hdf = 'HDF',
    Hdg = 'HDG',
    I = 'I',
    K = 'K',
    L = 'L',
    M = 'M',
    MaCore = 'MA_CORE',
    MaSupp1 = 'MA_SUPP1',
    MnrExcs = 'MNR_EXCS',
    MnrPrev = 'MNR_PREV',
    MnrPta = 'MNR_PTA',
    MnrPtb = 'MNR_PTB',
    MnrUhc1 = 'MNR_UHC1',
    MnrUprv = 'MNR_UPRV',
    MnBasic = 'MN_BASIC',
    MnExtb = 'MN_EXTB',
    MnHded = 'MN_HDED',
    MnHded2 = 'MN_HDED2',
    MnPbco = 'MN_PBCO',
    MnXbas = 'MN_XBAS',
    N = 'N'
}

export enum MedigapPlansField {
    MonthlyPremium = 'MONTHLY_PREMIUM',
    PlanName = 'PLAN_NAME'
}

export enum Sort {
    Asc = 'ASC',
    Desc = 'DESC'
}

export enum UserRole {
    RoleAdmin = 'ROLE_ADMIN',
    RoleNone = 'ROLE_NONE',
    RoleTechStaff = 'ROLE_TECH_STAFF',
    RoleUnauthorized = 'ROLE_UNAUTHORIZED',
    RoleUser = 'ROLE_USER'
}

export type MedigapFilterStateInput = {
    age: Scalars['Int'];
    benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
    companies?: InputMaybe<Array<Scalars['String']>>;
    county?: InputMaybe<Scalars['String']>;
    effectiveDate?: InputMaybe<Scalars['LocalDate']>;
    gender: Gender;
    id?: InputMaybe<Scalars['UUID']>;
    monthlyPlanPremiumRanges?: InputMaybe<Array<Scalars['String']>>;
    planCategory?: InputMaybe<Scalars['String']>;
    planNames?: InputMaybe<Array<MedigapPlanName>>;
    tobacco: Scalars['Boolean'];
    zip: Scalars['String'];
};

export type MedigapPlansFilterInput = {
    age: Scalars['Int'];
    benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
    companies: Array<Scalars['String']>;
    county?: InputMaybe<Scalars['String']>;
    effectiveDate?: InputMaybe<Scalars['LocalDate']>;
    gender: Gender;
    monthlyPlanPremiumRanges: Array<Scalars['String']>;
    planNames: Array<MedigapPlanName>;
    tobacco: Scalars['Boolean'];
    zip: Scalars['String'];
};

export type MedigapPlansSortInput = {
    direction: Sort;
    field: MedigapPlansField;
};

export type MedigapQuoteInput = {
    cId?: InputMaybe<Scalars['String']>;
    clientId?: InputMaybe<Scalars['UUID']>;
    id?: InputMaybe<Scalars['UUID']>;
    medigapFilterState: MedigapFilterStateInput;
};

export type PageInput = {
    page: Scalars['Int'];
    size: Scalars['Int'];
};
