import {LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {QueryLoginStatsArgs, UserLogin} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useLoginStats(options?: LazyQueryHookOptions<{loginStats: UserLogin[]}, QueryLoginStatsArgs>) {
  return useLazyQuery<{loginStats: UserLogin[]}, QueryLoginStatsArgs>(gql(query), {...options, client})
}

const query = `
query($email: String!) {
  loginStats(email: $email) {
    emailToken
    event
    eventDate
    id
  }
}
`;
