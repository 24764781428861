import {Box, Button, Grid, IconButton, Typography} from "@material-ui/core";
import React from "react";
import Preloader from "../shared/Preloader";
import {useUserId} from "../shared/keycloak";
import useIsAgentGmailSetUp from "../Client/widgets/emails/hooks/useIsAgentGmailSetUp";
import Card from "../shared/Card";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useAgentGmailAuthUrl from "./hooks/useAgentGmailAuthUrl";
import {RouteComponentProps, useHistory} from "react-router";
import useSaveAgentGmailCode from "./hooks/useSaveAgentGmailCode";
import paths from "../router-paths";

const Integrations = () => {

  return <Card padding={24}>
    <Gmail />
  </Card>
}

export default Integrations;
/// https://admin.staging.ascendinsure.com/integrations/gmail?code=4/0AbUR2VMH7XeanfVd-yiN5lgEYRo5mmKAueLi6TxG_NB-58HEJP3glm8IAsTldJp8E2Y3PQ&scope=https://www.googleapis.com/auth/gmail.readonly
export const GmailVerificationCode = () => {
  const [saveCode] = useSaveAgentGmailCode();
  const history = useHistory();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
      saveCode({variables: {code: urlParams.get('code')!}}).then(() => history.push(paths.integrations))
    } else {
      history.push(paths.integrations)
    }
  }, [])

  return <div className={'relative'}>
    <Preloader in={true} />
  </div>
}

const Gmail = () => {
  const {data, loading} = useIsAgentGmailSetUp({variables: {agentId: useUserId()}});
  const [getUrl] = useAgentGmailAuthUrl()

  const isSetup = React.useMemo(() => {
    return data?.isAgentGmailSetUp === true;
  }, [data])

  const onIntegrateClick = async () => {
    document.location.href = await getUrl().then(res => res.data!.agentGmailAuthUrl);
  }


  return <IntegrationItem isIntegrated={isSetup}
                          loading={loading}
                          image={'/img/gmail-logo.jpg'}
                          onIntegrateClick={onIntegrateClick} />
}

interface IntegrationItemProps {
  loading?: boolean,
  isIntegrated: boolean,
  onIntegrateClick: () => void,
  image: string,
}

const IntegrationItem = (props: IntegrationItemProps) => {
  return <div className={'relative'}>
    <Preloader in={props.loading} />
    <Box sx={{maxWidth: 100, mb: 2}}>
      <img src={props.image} />
    </Box>
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {props.isIntegrated && <><Typography><b>Integrated</b></Typography><Box sx={{color: 'green', ml: .5}}><CheckIcon fontSize={'small'} color={'inherit'} /></Box></>}
      {!props.isIntegrated && <><Typography><b>Not integrated</b></Typography><Box sx={{color: 'red', ml: .5}}><CloseIcon fontSize={'small'} color={'inherit'} /></Box></>}
    </Box>
    {!props.isIntegrated && <Button onClick={props.onIntegrateClick} className={'mt-8'} color={'primary'} variant={'contained'}>Integrate</Button>}
  </div>
}
