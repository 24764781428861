const paths = {
  home: '/',
  requests: '/requests',
  enrollments: '/enrollments',
  request: '/request',
  clients: '/clients',
  videoCategories: '/videoCategories',
  tags: '/tags',
  videos: '/videos',
  maEnrollmentEdit: '/ma-enrollment-edit',
  mgEnrollmentEdit: '/mg-enrollment-edit',
  pdpEnrollmentEdit: '/pdp-enrollment-edit',
  video: '/video',
  videoCategory: '/videoCategory',
  client: '/client',
  plan: '/plan',
  agents: '/agents',
  dashboard: '/dashboard',
  agent: '/agent',
  files: '/files',
  planCoverage: '/planCoverage',
  attemptedLeads: '/attemptedLeads',
  integrations: '/integrations',
  urgentCalls: '/urgentCalls',
  twoHourSla: '/2-hour-sla',
  missingApps: '/missing-apps',
  createClient: '/create-client',
};

export default paths;
