import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import TextInput from "../../shared/TextInput";
import {
  Box,
  Button,
  CircularProgress, createStyles,
  Divider,
  Grid,
  ListItemText,
  makeStyles,
  MenuItem, Theme,
  Typography
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import FormSelect from "../../shared/FormSelect";
import Card from "../../shared/Card";
import {RouteComponentProps, useHistory} from "react-router";
import {gql, useLazyQuery, useSubscription} from "@apollo/client";
import {
  ClientViewOutput, EnrollmentListItemOutput,
  EnrollmentType,
  PdpEnrollmentStatus, PdpEnrollmentType
} from "../../enrollment-types";
import {
  PageablePdpPlanOutput,
  PdpField,
  PdpPlan, PdpSort,
  Plan, PlanYear,
  QueryPdpPlansArgs
} from "../../types";
import {client} from "../../Apollo/ApolloClient";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {config} from "../../config/config";
import paths from "../../router-paths";
import useCompanies from "../hooks/useCompanies";
import useZipCounties from "../hooks/useZipCounties";
import useSavePdpEnrollment from "../hooks/useSavePdpEnrollment";
import useChangePdpEnrollmentStatus from "../hooks/useChangePdpEnrollmentStatus";
import useCreatePdpQuoteForClient from "../hooks/useCreatePdpQuoteForClient";
import useAllEnrollments from "../hooks/useAllEnrollments";
import {isEffectiveDateValid} from "./MgEnrollmentEditForm";
import * as _ from "lodash";
import AgentSelector from "../../Agent/AgentSelector";
import useAgentByContext from "../../Agent/hooks/useAgentByContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    enrollmentTypeSelect: {
      '& p': {display: 'none'},
      '& .MuiListItemText-primary': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
  }),
);

const validationSchema = yup.object({
  zip: yup.string().required('Zip is required'),
  county: yup.string().required('County is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  plan: yup
    .string()
    .required('Plan is required'),
  carrierName: yup
      .string()
      .required('Carrier is required'),
  planName: yup
      .string(),
  phoneNumber: yup
    .string()
    .required('Plan is required'),
  agentId: yup
    .string(),
  enrollmentType: yup
    .string(),
  birthDate: yup
    .date().nullable()
    .required('Birth date is required')
    .typeError('Please enter a valid date'),
  applicationDate: yup
    .date().nullable()
    .required('Application date is required')
    .typeError('Please enter a valid date'),
  effectiveDate: yup
    .date().nullable()
    .required('Effective date is required')
    .typeError('Please enter a valid date'),
});

interface IEnrollmentForm {
  firstName: string;
  lastName: string;
  email: string;
  zip: string;
  county: string;
  phoneNumber: string;
  enrollmentType?: string;
  status: any;
  plan: string;
  planYear: string;
  birthDate: string | null;
  carrierName: string;
  planName: string | null;
  applicationDate: string | null;
  effectiveDate: string | null;
  agentId: string | null;
}

const Form = ({input, onSubmit}: {input?: Partial<EnrollmentListItemOutput>, onSubmit: (data: IEnrollmentForm) => void}) => {
  const classes = useStyles();
  const [plans, setPlans] = React.useState<PdpPlan[]>([]);
  const [prevInput, setPrevInput] = React.useState<Partial<EnrollmentListItemOutput>>();

  const formik = useFormik({
    initialValues: {
      firstName: input?.firstName || '',
      lastName: input?.lastName || '',
      email: input?.email || '',
      zip: input?.zip || '',
      county: input?.county || '',
      carrierName: input?.carrierName || '',
      enrollmentType: input?.enrollmentType || '',
      planName: input?.planName || '',
      planYear: input?.planYear || '',
      agentId: input?.agentId || '',
      status: input?.status || PdpEnrollmentStatus.Draft,
      plan: input?.bidId || '',
      phoneNumber: input?.phoneNumber || '',
      birthDate: input?.birthDate ? moment.utc(input?.birthDate).format(config.dateFormat) : null,
      applicationDate: input?.applicationDate ? moment.utc(input?.applicationDate).format(config.dateFormat) : null,
      effectiveDate: input?.effectiveDate ? moment(input?.effectiveDate).format(config.dateFormat) : null,
    },
    validationSchema: validationSchema,
    onSubmit,
  });
  const {getCounties, counties, resetCounties} = useZipCounties(data => {
    if (data.plans_zip_county_fips.length === 1) {
      formik.setFieldValue('county', data.plans_zip_county_fips[0].countyname);
    }
  })
  const history = useHistory();

  const onPlansCompleted = React.useCallback((data: any) => {
    const planList = data.PdpPlans?.data || [];
    setPlans(planList);
    const selectedPlan = planList.find((p: any) => p.bidId === input?.bidId);
    if (selectedPlan) {
      formik.setFieldValue('carrier', selectedPlan?.parentOrgName || '');
    }
  }, [input])

  React.useEffect(() => {
    if (!_.isEqual(prevInput, input)) {
      formik.setValues({
        firstName: formik.values.firstName || input?.firstName || '',
        lastName: formik.values.lastName || input?.lastName || '',
        email: formik.values.email || input?.email || '',
        zip: formik.values.zip || input?.zip || '',
        county: formik.values.county || input?.county || '',
        carrierName: formik.values.carrierName || input?.carrierName || '',
        planName: formik.values.planName || input?.planName || '',
        status: formik.values.status || input?.status || '',
        agentId: formik.values.agentId || input?.agentId || '',
        planYear: formik.values.planYear || input?.planYear || '',
        plan: formik.values.plan || input?.bidId || '',
        enrollmentType: formik.values.enrollmentType || input?.enrollmentType || '',
        phoneNumber: formik.values.phoneNumber || input?.phoneNumber || '',
        birthDate: formik.values.birthDate || input?.birthDate ? moment.utc(input?.birthDate).format(config.dateFormat) : null,
        applicationDate: formik.values.applicationDate || input?.applicationDate ? moment.utc(input?.applicationDate).format(config.dateFormat) : null,
        effectiveDate: formik.values.effectiveDate || input?.effectiveDate ? moment.utc(input?.effectiveDate).format(config.dateFormat) : null,
      })
      if (input?.zip !== formik.values.zip) {
        resetCounties();
      }
      setPrevInput(input)
    }
  }, [formik.values, prevInput, input]);

  const [getPlans] = useLazyQuery<{PdpPlans: PageablePdpPlanOutput}, QueryPdpPlansArgs>(gql(plansQuery), {
    onCompleted: onPlansCompleted,
    fetchPolicy: 'no-cache',
    client: client
  });

  React.useEffect(() => {
    const zip = formik.values.zip;
    if (zip && zip.length === 5) {
      if (!counties.length) {
        getCounties({variables: {zip}})
      }
    }
    if (zip.length < 5) {
      formik.setFieldValue('county', '');
      formik.setFieldValue("planYear", '');
      formik.setFieldValue("plan", '');
      formik.setFieldValue("planName", '');
      formik.setFieldValue("carrierName", '');
      resetCounties();
    }
  }, [formik.values.zip, formik.values.county]);

  const planYear = React.useMemo(() => {
    const date = formik.values.effectiveDate ? formik.values.effectiveDate : input?.effectiveDate;
    if (isEffectiveDateValid(date)) {
      return 'Year' + moment(date).year() as PlanYear
    }
    return undefined
  }, [formik.values.effectiveDate, input?.effectiveDate])

  const [companies] = useCompanies<{zip: string, countyName: string, planYear?: PlanYear, showAllPlans: boolean}>(
    companiesQuery,
    data => data.pdpCompanies.filter((c: any) => c.count).map((c: any) => `${c.name} (${c.count})`) || [],
    {zip: formik.values.zip, countyName: formik.values.county, planYear, showAllPlans: true},
    filter => !!filter.countyName
  );


  React.useEffect(() => {
    if (formik.values.county && formik.values.zip) {
      getPlans({
        variables: {
          page: {
            size: 1000,
            page: 0
          },
          sort: [{direction: PdpSort.Asc, pdpField: PdpField.Name}],
          filter: {
            countyName: formik.values.county,
            zip: formik.values.zip,
            companies: formik.values.carrierName ? [formik.values.carrierName] : null,
            showAllPlans: true,
            planYear
          }
        }
      })
    }
  }, [formik.values.carrierName, formik.values.county, formik.values.zip, planYear])

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15 w-500'}>
      <Box sx={{display: 'flex'}}>
        <TextInput
          fullWidth
          name="firstName"
          label="First name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextInput
          fullWidth
          className={'ml-5'}
          name="lastName"
          label="Last name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Box>
      <Box sx={{display: 'flex'}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Birthdate'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
            helperText={formik.touched.birthDate && formik.errors.birthDate}
            value={formik.values.birthDate}
            onChange={value => formik.setFieldValue("birthDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>
        <TextInput
          name="phoneNumber"
          label="Phone"
          fullWidth
          className={'ml-5'}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />
      </Box>
      <TextInput
        fullWidth
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Divider />
      <Box sx={{display: 'flex', mt: 3}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Effective date'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.effectiveDate && Boolean(formik.errors.effectiveDate)}
            helperText={(formik.touched.effectiveDate && formik.errors.effectiveDate) || (planYear && `Plan registry: ${planYear.toString().replace('Year', '')}`)}
            value={formik.values.effectiveDate}
            onChange={value => formik.setFieldValue("effectiveDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Application Date'}
            className={'ml-5'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.applicationDate && Boolean(formik.errors.applicationDate)}
            helperText={formik.touched.applicationDate && formik.errors.applicationDate}
            value={formik.values.applicationDate}
            onChange={value => formik.setFieldValue("applicationDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box sx={{display: 'flex'}}>
        <TextInput
          fullWidth
          name="zip"
          label="Zip"
          value={formik.values.zip}
          onChange={formik.handleChange}
          error={formik.touched.zip && Boolean(formik.errors.zip)}
          helperText={formik.touched.zip && formik.errors.zip}
        />
        <FormSelect label={'County'}
                    className={'ml-5'}
                    value={formik.values.county}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("plan", '');
                      formik.setFieldValue("planYear", '');
                      formik.setFieldValue("county", event.target.value as string);
                      formik.setFieldValue("planName", '');
                      formik.setFieldValue("carrierName", '');
                    }}
                    error={formik.touched.county && Boolean(formik.errors.county)}
                    helperText={formik.touched.county ? formik.errors.county : undefined}
        >
          {counties.map(c => <MenuItem key={c.countyname} value={c.countyname}>{c.countyname}</MenuItem>)}
        </FormSelect>
      </Box>
      <Box sx={{display: 'flex'}}>
        <FormSelect label={'Carrier'}
                    value={formik.values.carrierName}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("carrierName", event.target.value as string);
                    }}
                    error={formik.touched.carrierName && Boolean(formik.errors.carrierName)}
                    helperText={formik.touched.carrierName ? formik.errors.carrierName : undefined}
        >
          {companies.map(c => <MenuItem key={c} value={c.substr(0, c.indexOf('(') - 1)}>{c}</MenuItem>)}
        </FormSelect>
        <FormSelect label={'Status'}
                    value={formik.values.status}
                    className={'ml-5'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("status", event.target.value as string);
                    }}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status ? formik.errors.status as string : undefined}
        >
          <MenuItem value={PdpEnrollmentStatus.Draft}>Draft</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Enrolled}>Enrolled</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.New}>New</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Rejected}>Rejected</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Sent}>Sent</MenuItem>
        </FormSelect>
      </Box>
      <FormSelect label={'Plan'}
                  value={formik.values.plan}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue("plan", event.target.value as string);
                    const plan = plans.find(p => p.bidId === event.target.value);
                    formik.setFieldValue("planYear", plan?.planYear);
                    formik.setFieldValue("planName", plan?.planName);
                  }}
                  error={formik.touched.plan && Boolean(formik.errors.plan)}
                  helperText={formik.touched.plan ? formik.errors.plan : undefined}
      >
        {plans.map(p => <MenuItem key={p.bidId} value={p.bidId}>{p.bidId} {p.planName}</MenuItem>)}

      </FormSelect>
      <AgentSelector value={formik.values.agentId || undefined} onChange={id => formik.setFieldValue("agentId", id)} />
      <FormSelect label={'Enrollment type'}
                  className={classes.enrollmentTypeSelect}
                  value={formik.values.enrollmentType}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue("enrollmentType", event.target.value as string);
                  }}
                  error={formik.touched.enrollmentType && Boolean(formik.errors.enrollmentType)}
                  helperText={formik.touched.enrollmentType ? formik.errors.enrollmentType : undefined}
      >
        {[PdpEnrollmentType.PdpNew, PdpEnrollmentType.PdpUnlikeChange, PdpEnrollmentType.PdpReplacement].map(type => <MenuItem key={type} onClick={() => {
          formik.setFieldValue("enrollmentType", type)
        }} value={type as string}>
          <ListItemText primary={getEnrollmentTypeTitle(type)} secondary={getEnrollmentTypeSubTitle(type)} />
        </MenuItem>)}
      </FormSelect>
      {!!formik.values['enrollmentType'] && <Typography color={'textPrimary'} variant={'body1'} className={'mb-20'}>
        {getEnrollmentTypeDescription(formik.values['enrollmentType'] as PdpEnrollmentType)}
      </Typography>}

      <Grid container alignItems={"center"} justifyContent={"space-between"} direction={'row'}>
        <Grid item>
          <Button variant={'outlined'} onClick={() => history.goBack()}>CANCEL</Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
        </Grid>
      </Grid>
    </form>
);
}



const PdpEnrollmentEditForm = ({ match: { params } }: RouteComponentProps<{clientId: string, id: string}>) => {
  const [getAgentByContext, {data: agentData}] = useAgentByContext();
  const [getEnrollments, {data, loading}] = useAllEnrollments();
  const [saveEnrollment, {loading: saveLoading}] = useSavePdpEnrollment();
  const [changeStatus, {loading: changeStatusLoading}] = useChangePdpEnrollmentStatus();
  const history = useHistory();
  const {data: clientData, loading: clientLoading} = useSubscription<{clientCard: ClientViewOutput}>(gql(query), {
    variables: {
      id: params.clientId
    }
  });

  const [initialData, setInitialData] = React.useState<Partial<EnrollmentListItemOutput>>();

  React.useEffect(() => {
    if (params.clientId) {
      getEnrollments({variables: {page: {size: 100, page: 0}, filter: {type: EnrollmentType.Pdp, clientId: params.clientId}}})
      getAgentByContext();
    }
  }, [params])

  const save = React.useCallback(async (values: IEnrollmentForm) => {

    saveEnrollment({
      variables: {
        clientId: params.id ? null : params.clientId,
        input: {
          id: params.id,
          planId: values.plan,
          zip: values.zip,
          county: values.county,
          agentId: values.agentId,
          carrierName: values.carrierName,
          planName: values.planName,
          applicationDate: moment(values.applicationDate).format('YYYY-MM-DD'),
          effectiveDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          enrollmentType: values.enrollmentType as PdpEnrollmentType,
          planYear: (values.planYear as PlanYear) || 'Year' + moment(values.effectiveDate).format('YYYY'),
        }
      }
    }).then(data => changeStatus({
      variables: {
        status: values.status as unknown as PdpEnrollmentStatus,
        enrollmentId: data.data?.savePdpEnrollment.id
      }
    }))
      .then(() => history.push(paths.client + '/' + params.clientId + '/enroll'))
  }, [params, data, clientData])


  const fillFromClient = React.useCallback(() => {
    setInitialData({
      ...data?.allEnrollments?.data.find(e => e.id === params.id),
      zip: clientData?.clientCard.zip,
      county: clientData?.clientCard.countyName,
      email: clientData?.clientCard.email || undefined,
      firstName: clientData?.clientCard.firstName,
      lastName: clientData?.clientCard.lastName,
      birthDate: clientData?.clientCard.birthDate,
      phoneNumber: clientData?.clientCard.phoneNumber,
    })

  }, [clientData, data])

  React.useEffect(() => {
    if (params.id) {
      setInitialData(data?.allEnrollments?.data.find(e => e.id === params.id))
    } else if (agentData?.agentByContext) {
      setInitialData(prev => ({
        ...prev,
        agentId: agentData?.agentByContext.id
      }));
    }
  }, [params.id, clientData, data, agentData])


  if (data && clientData && !clientLoading && !loading && !saveLoading && !changeStatusLoading) {
    return <Card>
      <Box sx={{display: 'flex', alignItems: 'center', width: 500, justifyContent: 'space-between'}}>
        <Typography color={'textPrimary'} variant={'h2'} className={'medium'}>{params.id ? 'Edit' : 'Create'} PDP enrollment</Typography>
        <Typography className={'pointer underline'} variant={'body1'} color={'textPrimary'} onClick={fillFromClient}>Fill from the client data</Typography>
      </Box>
      <Form input={initialData} onSubmit={save} />
    </Card>
  }
  return <Card>
    <CircularProgress />
  </Card>
};

export default PdpEnrollmentEditForm;



const plansQuery = `
query($filter: PdpPlansFilterInput!, $page: PageInput!, $sort: [PdpPlansSortInput!]!) {
    PdpPlans(filter: $filter, page: $page, sort: $sort) {
      data  {
        planName
        planType
        bidId
        orgName
        parentOrgName
        planYear
      }
      totalElements
    }
}
`;

const companiesQuery = `
query($filter: PdpPlansFilterInput!) {
    pdpCompanies(filter: $filter) {
      name
      count
    }
}
`;


const query = `
subscription($id: UUID!) {
  clientCard (data:{
    clientId: $id
  }) {
    zip
    countyName
    email
    firstName
    lastName
    birthDate
    phoneNumber
    pdpQuote {
      id
    }
  }
}
`;

const getEnrollmentTypeSubTitle = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'New enrollments (i.e., age-in, New to Medicare, or enrolls from Orignal Medicare getting MA for first time)',
  [PdpEnrollmentType.PdpUnlikeChange]: 'PDP > MAPD or MAPD > PDP // PDP > PDP or MAPD > MAPD (Yr 1 Change)',
  [PdpEnrollmentType.PdpReplacement]: 'Like plan change in Year 2+'
}[type])

const getEnrollmentTypeTitle = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'PDP - New-to-CMS',
  [PdpEnrollmentType.PdpUnlikeChange]: 'PDP - Unlike plan change / Yr 1 change',
  [PdpEnrollmentType.PdpReplacement]: 'PDP - Replacement / \'Like\' Plan Change'
}[type])

const getEnrollmentTypeDescription = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'Initial Commissions is paid for the first year product is in effect, followed by Renewal Rate Commission in subsequent calednar years (monthly basis)',
  [PdpEnrollmentType.PdpUnlikeChange]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment.  Inital Commission is paid on a pro-rated basis based on effective date.  Renewal Rate Commission is paid in subsequent calendar years',
  [PdpEnrollmentType.PdpReplacement]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment. Renewal Rate Commission is paid on a pro-rated basis based on effective date and then Renewal Rate Commission is paid in subsequent calendar years'
}[type])
