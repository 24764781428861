import React from "react";
import Card from "../../shared/Card";
import {
  Typography
} from "@material-ui/core";
import {
  CalcDrugFrequencyPeriodInput,
  ClientViewOutput, FrequencyPeriodInput,
  PreferredDrugInput,
  PreferredDrugViewOutput
} from "../../enrollment-types";
import {DrugSelection} from "../../shared/DrugSelection/DrugSelection";
import {usePatchClientPersonalInfo} from "../../shared/DrugSelection/hooks/usePatchClientPersonalInfo";


interface UserPrioritiesProps {
  client?: ClientViewOutput
}

const Drugs = ({client}: UserPrioritiesProps) => {
  const [drugs, setDrugs] = React.useState<PreferredDrugViewOutput[]>([]);

  const [saveClientInfo] = usePatchClientPersonalInfo();

  React.useEffect(() => {
    setDrugs(client?.preferredDrugs || [])
  }, [client?.preferredDrugs]);

  const onChange = async (data: PreferredDrugViewOutput[]) => {
    await saveClientInfo({
      variables: {
        clientId: client?.id,
        preferredDrugs: data.map(convertPreferredDrugInput),
      }})
  }

  return <Card>
    <Typography variant={'h4'} className={'mb-5'}>Drugs:</Typography>
    <DrugSelection value={drugs} onChange={onChange} />
  </Card>;
}

export default Drugs;

function convertPreferredDrugInput(drugInfo: PreferredDrugViewOutput): PreferredDrugInput {
  return {
    dosageRxcui: drugInfo.dosageRxcui,
    productRxcui: drugInfo.productRxcui,
    quantity: drugInfo.quantity,
    frequency: drugInfo.frequency as unknown as CalcDrugFrequencyPeriodInput,
    purchaseFrequency: drugInfo.purchaseFrequency as unknown as FrequencyPeriodInput,
  }
}
