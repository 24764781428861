import React, {useState} from "react";
import Card from "../../shared/Card";
import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  IconButton,
  makeStyles, MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import {ClientViewOutput} from "../../enrollment-types";
import useCompareOffer from "../hooks/useCompareOffer";
import {useSaveCompareOffer} from "../hooks/useSaveCompareOffer";
import {config} from "../../config/config";
import {useSnackbar} from "notistack";
import {useSendComparisonEmail} from "../hooks/use-send-comparison-email/useSendComparisonEmail";
import {ComparablePlan, ComparablePlanType, Plan, PlanYear} from "../../types";
import {client as apolloClient} from "../../Apollo/ApolloClient";
import {gql} from "@apollo/client";
import * as _ from 'lodash';
import FormSelect from "../../shared/FormSelect";

interface CompareOfferProps {
  client?: ClientViewOutput
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    }
  }),
);

const CompareOffer = ({client}: CompareOfferProps) => {
  const [getCompareOffer, data] = useCompareOffer();
  const [saveCompareOffer] = useSaveCompareOffer();
  const [sendEmail] = useSendComparisonEmail();
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const {enqueueSnackbar} = useSnackbar();
  const [defaultYear, setDefaultYear] = useState(PlanYear.Year2023)
  const [plans, setPlans] = React.useState<Array<ComparablePlan & {title: string}>>([]);

  const getOffer = async () => {
    let decoratedPlans: Array<ComparablePlan & {title: string}> = [];
    if (client?.id) {
      setLoading(true)
      const comparablePlans = await getCompareOffer({variables: {clientId: client.id}})
        .then(res => res?.data?.compareOffer?.plans);

      if (comparablePlans && comparablePlans.length) {
        decoratedPlans = await decoratePlans(defaultYear, comparablePlans);
      }

      setPlans(decoratedPlans);
      setLoading(false);
    }
    return decoratedPlans;
  }

  React.useEffect(() => {
    if (client?.id) {
      getOffer()
    }
  }, [client, defaultYear])

  const removeFromCompareOffer = React.useCallback(async (bidId: string) => {
    if (plans) {
      const compareOfferPlans = await getOffer();
      setLoading(true);
      compareOfferPlans.splice(plans.findIndex(p => p.bidId === bidId), 1);
      setPlans(compareOfferPlans);

      await saveCompareOffer({
        variables: {
          compareOffer: {
            clientId: client?.id,
            id: data?.data?.compareOffer?.id,
            plans: compareOfferPlans.map(o => _.omit(o, 'title'))
          }
        }
      }).then(getOffer)
    }
  }, [plans, data])

  return <Card className={'relative'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Compare offer</Typography>
      <IconButton className={`${classes.refreshButton} ${loading ? classes.disabled : ''}`}
                  disabled={loading}
                  onClick={() => {
                    setPlans([])
                    getOffer()
                  }}>
        <img src={'/img/replay.svg'} />
      </IconButton>

    </div>
    <div>
      <Button onClick={() => {
        sendEmail({variables: {clientId: client?.id, link: `${config.userFlowUrl}compare-offer?magic-link=true`}})
          .then(() => enqueueSnackbar('Email sent!', {variant: "info"}))
      }} className={'mb-20'} variant={'contained'} color={'primary'}>Send email</Button>
    </div>
    <div className={'flex flex-space-between flex-align-center'}>
      <div>
        <Typography onClick={() => {window.open(config.userFlowUrl + `compare-offer?clientId=${client?.id}`)}} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Preview</Typography>
        <Typography onClick={() => {
          navigator.clipboard.writeText(`${config.userFlowUrl}compare-offer?magic-link=true`)
          enqueueSnackbar('Copied!', {variant: "info"});
        }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>
      </div>
      <div className={'w-150'}>
        <FormSelect label={'Default Year'}
                    value={defaultYear}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setDefaultYear(event.target.value as PlanYear);
                    }}
        >
          <MenuItem value={PlanYear.Year2023}>2023</MenuItem>
          <MenuItem value={PlanYear.Year2024}>2024</MenuItem>
        </FormSelect>
      </div>
    </div>


    {(!plans.length && loading) && <CircularProgress size={20} />}


    {plans.map((p, i) => <React.Fragment key={p.bidId}>
      <div className={'flex flex-space-between flex-align-center mt-8'}>
        <div key={p.bidId} className={'flex flex-align-center'}>
          <Typography style={{color: getTypeColor(p.type)}} variant={"body1"}><b>{p.type}</b></Typography>
          <Typography color={"textSecondary"} className={'ml-10'} variant={"h5"}>{p.title}</Typography>
        </div>
        <IconButton className={`ml-8 ${loading ? classes.disabled : ''}`}
                    size={'small'}
                    disabled={loading}
                    onClick={() => removeFromCompareOffer(p.bidId)}>
          <img className={'w-10 h-10'} src={'/img/delete-icon.svg'} />
        </IconButton>
      </div>
      {i < plans.length - 1 && <Divider className={'mt-8'}/>}
    </React.Fragment>)}
  </Card>
};

export default CompareOffer;

const getTypeColor = (type: ComparablePlanType) => type === ComparablePlanType.Ma ? '#3879b1' : type === ComparablePlanType.Mg ? '#38b17b' : '#b19338';

const decoratePlans = async (defaultYear: PlanYear, plans?: ComparablePlan[]): Promise<Array<ComparablePlan & {title: string}>> => {
  function getPromises(plans: ComparablePlan[], year: PlanYear) {
    const promises = [];
    const maPlans = plans.filter(p => p.type === ComparablePlanType.Ma);
    if (maPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getMAPlansQuery(maPlans, defaultYear)}}`),
      }).then(res => Object.values(res.data)))
    }
    const mgPlans = plans.filter(p => p.type === ComparablePlanType.Mg);
    if (mgPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getMGPlansQuery(mgPlans.map(p => p.bidId))}}`),
      }).then(res => Object.values(res.data).map((p: any) => ({bidId: p.key, planName: `${p.title} (${p.planName})`}))))
    }
    const pdpPlans = plans.filter(p => p.type === ComparablePlanType.Pdp);
    if (pdpPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getPDPPlansQuery(pdpPlans, defaultYear)}}`),
      }).then(res => Object.values(res.data)))
    }
    return promises;
  }

  if (plans && plans.length) {
    let results: Plan[] = [];
    try {
      results = (_.flatten(await Promise.all(getPromises(plans, defaultYear)).catch(e => {
        throw Error();
      })) || []) as Plan[];
    } catch (e) {
      results = [];
    }

    return _.orderBy(plans, 'type').map(plan => (
      {...plan, title: results.find(p => p.bidId === plan.bidId)?.planName || plan.bidId}
    ));
  }
  return [];
}

const getMAPlansQuery = (plans: ComparablePlan[], defaultYear: PlanYear): string => (
  plans.map(plan => `plan${plan.bidId}: plan(bidId: "${plan.bidId}", planYear: ${plan.planYear || defaultYear}, countyName: "${plan.county}", zip: "${plan.zip}") {
      bidId
      planName
   }`).join(' ')
)

const getPDPPlansQuery = (plans: ComparablePlan[], defaultYear: PlanYear): string => (
  plans.map(plan => `plan${plan.bidId}: PdpPlan(bidId: "${plan.bidId}", planYear: ${plan.planYear || defaultYear}, countyName: "${plan.county}", zip: "${plan.zip}") {
      bidId
      planName
   }`).join(' ')
)

const getMGPlansQuery = (keys: string[]): string => (
  keys.map((key, i) => `plan${i}: medigapPlan(key: "${key}") {
      key
      title
      planName
   }`).join(' ')
)

