import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveEnrollmentArgs} from "../../enrollment-types";

export default function useSaveEnrollment(options?: MutationHookOptions<{saveEnrollment: string}, MutationSaveEnrollmentArgs>) {
  return useMutation<{saveEnrollment: string}, MutationSaveEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($input: EnrollmentInput!, $clientId: UUID, $userId: UUID) {
  saveEnrollment(input: $input, clientId: $clientId, userId: $userId)
}
`;

