import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentOutput,
} from "../../enrollment-types";

export default function useAgentByContext(options?: LazyQueryHookOptions<{agentByContext: AgentOutput}>) {
  return useLazyQuery<{agentByContext: AgentOutput}>(gql(query), {...options, fetchPolicy: 'no-cache'});
}

const query = `
query {
  agentByContext {
    consultationUrl
    email
    enabled
    enrollmentMeetingUrl
    firstName
    id
    lastName
    phone
    picUrl
    planReviewUrl
    receiveEmails
    isDefault
  }
}
`;
