import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSavePdpEnrollmentArgs} from "../../enrollment-types";

export default function useSavePdpEnrollment(options?: MutationHookOptions<{savePdpEnrollment: {id: string}}, MutationSavePdpEnrollmentArgs>) {
  return useMutation<{savePdpEnrollment: {id: string}}, MutationSavePdpEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($input: PdpEnrollmentInput!, $clientId: UUID) {
  savePdpEnrollment(input: $input, clientId: $clientId) {
    id
  }
}
`;

