import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {MutationSaveVideoCategoryArgs} from "../../content-management";

export default function useSaveVideoCategory(options?: MutationHookOptions<any, MutationSaveVideoCategoryArgs>) {
  return useMutation<any, MutationSaveVideoCategoryArgs>(gql(saveNoteMutation), {
    ...options,
    client
  })
}

const saveNoteMutation = `
mutation ($category: VideoCategoryInput!) {
  saveVideoCategory(category: $category)
}
`;
