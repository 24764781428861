import {LazyQueryHookOptions, useLazyQuery, gql} from "@apollo/client";
import {
  ClientGroupOutput,
  QueryClientGroupByClientIdArgs,
} from "../../enrollment-types";

export default function useClientGroupByClientId(options?: LazyQueryHookOptions<{clientGroupByClientId: ClientGroupOutput}, QueryClientGroupByClientIdArgs>) {
  return useLazyQuery<{clientGroupByClientId: ClientGroupOutput}, QueryClientGroupByClientIdArgs>(gql(callHistoryItemsQuery), {
    ...options,
    fetchPolicy: 'no-cache',
  })
}

const callHistoryItemsQuery = `
query($clientId: UUID!) {
  clientGroupByClientId(clientId: $clientId) {
    clients {
      clientId
      email
      firstName
      lastName
    }
    id
  }
}
`;
