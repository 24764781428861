import React from "react";
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";

interface ConfirmDialogContextData {
  content: string | JSX.Element,
  title?: string,
  okButtonTitle?: string,
}

export const ConfirmDialogContext = React.createContext((data: ConfirmDialogContextData) => new Promise(resolve => {}))

export function ConfirmDialogProvider({ children }: { children: any }) {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ConfirmDialogContextData>();

  const confirm = (data: ConfirmDialogContextData) => {
    setState(data)
    setOpen(true);
    return new Promise<boolean>((resolve) => {
      (ref as any).current = resolve;
    })
  }

  const fn = (choice: boolean) => {
    (ref as any).current(choice)
    ref.current = undefined;
  }

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      {state?.content && <Modal
        {...state}
        open={open}
        onClose={(value?: boolean) => {
          setOpen(false);
          fn(!!value);
        }}
      />}
    </ConfirmDialogContext.Provider>
  )
}

export const withConfirmDialog = (WrappedComponent: any) => (props: any) => {
  return (
    <ConfirmDialogProvider>
      <WrappedComponent {...{...props}} />
    </ConfirmDialogProvider>
  )
}

export function useConfirm() {
  return React.useContext(ConfirmDialogContext)
}


interface ConfirmModalProps {
  open: boolean,
  onClose: (value?: boolean) => void,
  title?: string,
  okButtonTitle?: string,
  content: string | JSX.Element,
}

function Modal(props: ConfirmModalProps) {
  return <Dialog
    open={props.open}
    onClose={() => props.onClose(false)}
  >
    {props.title && <DialogTitle>{props.title}</DialogTitle>}
    <DialogContent>
      {typeof props.content === 'string' && <DialogContentText>
        {props.content}
      </DialogContentText>}
      {typeof props.content !== 'string' && <>
        {props.content}
      </>}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.onClose(false)} color="primary">
        Cancel
      </Button>
      <Box color={'red'}>
        <Button onClick={() => props.onClose(true)} color="inherit">
          {props.okButtonTitle || 'Delete'}
        </Button>
      </Box>
    </DialogActions>
  </Dialog>
}