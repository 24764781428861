import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {ProviderOutput} from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginBottom: theme.spacing(2),
    '& tr td:first-child': {
      textAlign: 'right',
      paddingRight: 10,
      width: '140px'
    }
  }
}));

export default function Doctor({doctor}: {doctor: ProviderOutput}) {
  const classes = useStyles();

  return (<div>
    {doctor && <table className={classes.table}>
      <tbody>
      <tr><td><b>full name:</b></td><td>{doctor.name}</td></tr>
      <tr><td><b>specialty:</b></td><td>{doctor.addresses[0].specialty}</td></tr>
      <tr><td><b>npi:</b></td><td>{doctor.npi}</td></tr>
      </tbody>
    </table>}
  </div>)
}
