import React from "react";
import {EnrollmentType} from "../../enrollment-types";
import useAllEnrollments from "./useAllEnrollments";

const useClientEnrollments = (createdByAdmin: boolean, clientId?: string) => {
  const [getEnrollments, {data, loading, refetch}] = useAllEnrollments()

  React.useEffect(() => {
    if (clientId) {
      getEnrollments({variables: {page: {size: 100, page: 0}, filter: {clientId, createdByAdmin}}})
    }
  }, [clientId])

  const allEnrollments = React.useMemo(() => {
    return data?.allEnrollments.data || []
  }, [data]);

  const maEnrollments = React.useMemo(() => {
    return allEnrollments.filter(e => e.type === EnrollmentType.Ma)
  }, [allEnrollments])

  const mgEnrollments = React.useMemo(() => {
    return allEnrollments.filter(e => e.type === EnrollmentType.Mg)
  }, [allEnrollments])

  const pdpEnrollments = React.useMemo(() => {
    return allEnrollments.filter(e => e.type === EnrollmentType.Pdp)
  }, [allEnrollments])

  return {maEnrollments, mgEnrollments, pdpEnrollments, loading, refetch}
}

export default useClientEnrollments;