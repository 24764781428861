import moment from "moment";
import {Box, Button, Checkbox, FormControlLabel, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router";
import Preloader from "../shared/Preloader";
import useEnrollments from "./hooks/useEnrollments";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import paths from "../router-paths";
import useMedigapEnrollments from "./hooks/useMedigapEnrollments";
import useEnrollmentPageSize from "./hooks/useEnrollmentPageSize";
import EnrollmentUpload from "./EnrollmentUpload";
import useEnrollmentMAIsImported from "./hooks/useEnrollmentMAIsImported";
import useEnrollmentMGIsImported from "./hooks/useEnrollmentMGIsImported";
import usePdpEnrollments from "./hooks/usePdpEnrollments";
import useEnrollmentPDPIsImported from "./hooks/useEnrollmentPDPIsImported";
import clsx from "clsx";
import {StatusDto} from "../enrollment-types";
import MenuItem from "@material-ui/core/MenuItem";
import StatusView from "../Client/components/StatusView";
import FormSelect from "../shared/FormSelect";
import * as _ from 'lodash';
const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      if (!firstName && !lastName) {
        return 'Unknown user';
      } else {
        return firstName + ' ' + lastName || '';
      }
    }
  },
  { field: 'planName', headerName: 'Plan', width: 200},
  { field: 'email', headerName: 'Email', width: 200},
  { field: 'phoneNumber', headerName: 'Phone', width: 120},
  { field: 'birthDate', headerName: 'BirthDate', width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Created', width: 200,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'approvedAt', headerName: 'Approved', width: 200,
    valueGetter: (params: GridValueGetterParams) => {
      const approvedAt = params.row.approvedAt as string;
      if (approvedAt) {
        return moment(approvedAt).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'status', headerName: 'Status', sortable: false, width: 200 },
];

export default function EnrollmentList() {
  const [page, setPage] = React.useState(0);
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [pageSize, setPageSize] = useEnrollmentPageSize();
  const [maIsImported, setMaIsImported] = useEnrollmentMAIsImported();
  const [mgIsImported, setMgIsImported] = useEnrollmentMGIsImported();
  const [pdpIsImported, setPdpIsImported] = useEnrollmentPDPIsImported();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setPage(0);
  };

  const [getEnrollments, maData] = useEnrollments();
  const [getMGEnrollments, mgData] = useMedigapEnrollments();
  const [getPdpEnrollments, pdpData] = usePdpEnrollments();

  React.useEffect(() => {
    if (value === 0) {
      getEnrollments({
        variables: {
          input: {
            isImported: maIsImported === '' ? undefined : maIsImported === 'true' ? true : false
          },
          page: {
            page,
            size: pageSize
          }
        }
      })
    } else if (value === 1) {
      getMGEnrollments({
        variables: {
          filter: {
            isImported: mgIsImported === '' ? undefined : mgIsImported === 'true' ? true : false
          },
          page: {
            page,
            size: pageSize
          }
        }
      })
    } else if (value === 2) {
      getPdpEnrollments({
        variables: {
          filter: {
            isImported: pdpIsImported === '' ? undefined : pdpIsImported === 'true' ? true : false
          },
          page: {
            page,
            size: pageSize
          }
        }
      })
    }
  }, [page, value, pageSize, maIsImported, mgIsImported, pdpIsImported])

  return (
    <Box display={'flex'} flex={1}>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <Paper>
          <Box display={'flex'} flexDirection={'column'} style={{ minHeight: '75vh', width: '100%', position: 'relative' }}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Medicare Advantage" />
              <Tab label="Medigap" />
              <Tab label="Pdp" />
              <Tab label="Upload" />
            </Tabs>
            <Preloader in={maData.loading || mgData.loading} />
            {value === 0 && <>
              <Box p={'0 10px'}>
                <FormSelect className={clsx('mb-0 mt-10 w-200')}
                            label={'Is imported'}
                            value={maIsImported}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setMaIsImported(event.target.value as string)
                            }}
                >
                  <MenuItem value={''}>-</MenuItem>
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </FormSelect>
              </Box>
              <DataGrid rows={maData.data?.enrollments?.data || []}
                        paginationMode={"server"}
                        columns={columns}
                        page={page}
                        onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                        onPageChange={(page) => {
                          setPage(page);
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                        onPageSizeChange={setPageSize}
                        pageSize={pageSize}
                        rowCount={maData.data?.enrollments?.totalElements}
                        pagination
              />
            </>}
            {value === 1 && <>
              <Box p={'0 10px'}>
                <FormSelect className={clsx('mb-0 mt-10 w-200')}
                            label={'Is imported'}
                            value={mgIsImported}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setMgIsImported(event.target.value as string)
                            }}
                >
                  <MenuItem value={''}>-</MenuItem>
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </FormSelect>
              </Box>
              <DataGrid rows={mgData.data?.medigapEnrollments?.data || []}
                        paginationMode={"server"}
                        columns={columns}
                        page={page}
                        onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                        onPageChange={(page) => {
                          setPage(page);
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                        onPageSizeChange={setPageSize}
                        pageSize={pageSize}
                        rowCount={mgData.data?.medigapEnrollments?.totalElements}
                        pagination
              />
            </>}
            {value === 2 && <>
              <Box p={'0 10px'}>
                <FormSelect className={clsx('mb-0 mt-10 w-200')}
                            label={'Is imported'}
                            value={pdpIsImported}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setPdpIsImported(event.target.value as string)
                            }}
                >
                  <MenuItem value={''}>-</MenuItem>
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </FormSelect>
              </Box>
              <DataGrid rows={pdpData.data?.pdpEnrollments?.data.map(e => ({...e, planName: e.planId})) || []}
                        paginationMode={"server"}
                        columns={columns}
                        page={page}
                        onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                        onPageChange={(page) => {
                          setPage(page);
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                        onPageSizeChange={setPageSize}
                        pageSize={pageSize}
                        rowCount={pdpData.data?.pdpEnrollments?.totalElements}
                        pagination
              />
            </>}
            {value === 3 && <EnrollmentUpload />}
          </Box>
        </Paper>

      </Box>
    </Box>
  )
}
