import Card from "../../shared/Card";
import {Button, Divider, IconButton, Typography} from "@material-ui/core";
import Param from "../../shared/Param";
import React from "react";
import {
  EnrollmentListItemOutput,
} from "../../enrollment-types";
import moment from "moment";
import {gql} from "@apollo/client";
import {useLazyQuery} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import paths from "../../router-paths";
import {useHistory} from "react-router";
import useDeleteMedigapEnrollment from "../hooks/useDeleteMedigapEnrollment";

interface MedigapEnrollmentInfoProps {
  enrollment: EnrollmentListItemOutput,
  onDeleteClick?: () => void,
  viewOnly?: boolean
}

const MedigapEnrollmentInfo = ({enrollment, onDeleteClick, viewOnly}: MedigapEnrollmentInfoProps) => {
  const [carrier, setCarrier] = React.useState<string>();
  const [parent, setParent] = React.useState<string>();
  const history = useHistory();
  const [deleteEnrollment] = useDeleteMedigapEnrollment()

  const [getCompany, data] = useLazyQuery<{medigapCompany: {parentCompanyName: string, name: string}}, {naic: string}>(gql(query), {
    client,
  })

  React.useEffect(() => {
      if (data.data?.medigapCompany) {
        setCarrier(data.data.medigapCompany.name || undefined)
        setParent(data.data.medigapCompany.parentCompanyName || undefined)
      }
  }, [data]);

  React.useEffect(() => {
    if (enrollment?.naic) {
      getCompany({variables: {
          naic: enrollment.naic
        }})
    }
  }, [enrollment]);

  const name = React.useMemo(() => {
    let name = '';
    if (!enrollment?.firstName && !enrollment?.lastName) {
      name = 'Unknown user';
    } else {
      name = enrollment?.firstName + ' ' + enrollment?.lastName || '';
    }
    return name
  }, [enrollment?.firstName, enrollment?.lastName])


  const onDelete = () => {
    deleteEnrollment({variables: {medigapEnrollmentId: enrollment.id}}).then(onDeleteClick)
  }
  return <Card className={'w-260'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>MG Enrollment</Typography>
      {!viewOnly && <div className={'flex-space-between flex-align-center'}>
        <IconButton size={'small'} onClick={() => history.push(paths.mgEnrollmentEdit + `/${enrollment.clientId}/${enrollment?.id}`)}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-10'} onClick={onDelete}><img src={'/img/delete-icon.svg'}/></IconButton>
      </div>}
    </div>
    <Param className={'flex-column'} label={"NAME"} value={name}/>
    <div className={"flex-space-between"}>
      {enrollment?.mgPlanName && <Param className={'flex-column'} label={"PLAN"} value={enrollment?.mgPlanName}/>}
      {enrollment?.status && <Param className={'flex-column'} label={"STATUS"} valueClassName={'yellow'} value={enrollment?.status}/>}
    </div>
    {parent && <Param className={'flex-column'} label={"PARENT COMPANY NAME"} value={parent}/>}
    {carrier && <Param className={'flex-column'} label={"COMPANY NAME"} value={carrier}/>}
    <Divider className={'mb-15'} />
    <div className={"flex-space-between"}>
      {!!enrollment?.age && <Param className={'flex-column'} label={"AGE"} value={enrollment?.age.toString()}/>}
      <Param className={'flex-column'} label={"TOBACCO"} value={enrollment?.tobacco ? 'Yes' : 'No'}/>
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.gender && <Param className={'flex-column'} label={"GENDER"} value={enrollment?.gender}/>}
      {enrollment?.effectiveDate && <Param className={'flex-column'} label={"EFFECTIVE DATE"} value={moment(enrollment?.effectiveDate).format('MM/DD/YYYY')}/>}
    </div>
    <Divider className={'mb-15'} />
    <div className={"flex-space-between"}>
      {enrollment?.started && <Param className={'flex-column'} label={"ENROLLMENT STARTED"} value={moment(enrollment?.started).format('MM/DD/YYYY')}/>}
      {enrollment?.approvedAt && <Param className={'flex-column'} label={"ENROLLMENT APPROVED"} value={moment(enrollment?.approvedAt).format('MM/DD/YYYY')}/>}
    </div>
  </Card>
}

export default MedigapEnrollmentInfo;

const query = `
query($naic: String!) {
  medigapCompany(naic: $naic) {
    parentCompanyName
    name
  }
}
`;
