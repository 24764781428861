import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";

const useChipWrapperStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px solid #CCD8DC',
      borderRadius: 4,
      padding: 14,
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    title: {
      background: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 9,
      position: 'absolute',
      top: -12,
      left: 5,
    }
  }),
);

const ChipWrapper = (props: PropsWithChildren<any> & {title: string}) => {
  const classes = useChipWrapperStyles();
  return <div className={classes.container}>
    <Typography color={'textSecondary'} className={classes.title}>{props.title}</Typography>
    {props.children}
  </div>
}

export default ChipWrapper;
