import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {MutationCreateMedigapQuoteForClientArgs, SaveMedigapQuoteOutput} from "../../medigap-types";

export default function useCreateMedigapQuoteForClient(options?: MutationHookOptions<{createMedigapQuoteForClient: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteForClientArgs>) {
    return useMutation<{createMedigapQuoteForClient: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteForClientArgs>(gql(mutation), {
        ...options,
        client
    })
}

const mutation = `
mutation ($agentOffer: Boolean, $input: MedigapQuoteInput!) {
  createMedigapQuoteForClient(input: $input, agentOffer: $agentOffer) {
    clientId
    mgQuoteId
  }
}
`;
