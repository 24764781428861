import React, {ChangeEvent, useCallback} from "react";
import Card from "../../shared/Card";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import {ClientGroupItemOutput, ClientViewOutput, ClientViewSortInput, Field, SortDirection} from "../../enrollment-types";
import useClientGroupByClientId from "../hooks/useClientGroupByClientId";
import useClientViews from "../hooks/useClientViews";
import TextInput from "../../shared/TextInput";
import AscendAutocomplete from "../../shared/AscendAutocomplete";
import {useDebouncedEffect} from "../../shared/useDebouncedEffect";
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from "@material-ui/core/InputAdornment";
import * as _ from 'lodash';
import useMergeClientsInOneGroup from "../hooks/useMergeClientsInOneGroup";
import useDeleteClientsFromAnyGroup from "../hooks/useDeleteClientsFromAnyGroup";
import {Link} from "react-router-dom";
import paths from "../../router-paths";

interface ClientGroupsProps {
  client?: ClientViewOutput
}

const ClientGroups = ({client}: ClientGroupsProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const [saveError, setSaveError] = React.useState();
  const [getGroups, groupsData] = useClientGroupByClientId();
  const [save, {loading, error}] = useMergeClientsInOneGroup();
  const [deleteClients] = useDeleteClientsFromAnyGroup();

  React.useEffect(() => {
    if (client?.id) {
      getGroups({variables: {clientId: client?.id}})
    }
  }, [client?.id]);

  const clients = React.useMemo(() => {
    return groupsData.data?.clientGroupByClientId?.clients || []
  }, [groupsData])

  const submit = useCallback(async (data: ClientGroupItemOutput[]) => {
    const current = clients.map(c => c.clientId)
    const clientsToMerge = _.uniq([...data.map(c => c.clientId), client?.id]);
    const clientsToDelete = _.differenceBy(current, clientsToMerge);

    await save({variables: {clientIds: clientsToMerge}});
    if (clientsToDelete.length) {
      await deleteClients({variables: {clientIds: clientsToDelete}})
    }
    setShowForm(false);
    setSaveError(undefined)
    await getGroups({variables: {clientId: client?.id}})
  }, [client, deleteClients, save, clients]);

  React.useEffect(() => {
    if (error) {
      error.graphQLErrors.map((err: any) => {
          if (err.message.indexOf('The field at path') < 0) {
            switch (err.extensions?.status) {
              case '422': {
                setSaveError(err)
              };
            }
          }
        }
      );
    }
  }, [error])

  const cancel = useCallback(() => {
    setShowForm(false);
    setSaveError(undefined)
  }, [setShowForm]);

  return <Card>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Client Groups</Typography>
      {!showForm && <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showForm && <List>
    {clients.map(client => (
        <ClientLine firstName={client?.firstName}
                    lastName={client?.lastName}
                    email={client?.email}
                    key={client?.clientId}
                    isLink
                    id={client?.clientId}
                    onClick={() => submit(clients.filter(c => c.clientId != client.clientId))} />
    ))}
    </List>}
    {showForm && <ClientGroupsForm error={saveError} input={clients} onCancel={cancel} onSubmit={submit}/>}
  </Card>
};

export default ClientGroups;

interface ClientGroupsFormProps {
  error: any,
  input: ClientGroupItemOutput[];
  onCancel: () => void;
  onSubmit: (data: ClientGroupItemOutput[]) => void;
}

const ClientGroupsForm = (props: ClientGroupsFormProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchRequest, {loading, data, refetch}] = useClientViews({
    onCompleted: data => setOptions(data.clientViews?.data)
  });
  const [clients, setClients] = React.useState<ClientGroupItemOutput[]>(props.input);
  const [options, setOptions] = React.useState<ClientViewOutput[]>([]);

  useDebouncedEffect(() => {
    if (searchTerm.length > 2) {
      searchRequest({
        variables: {
          filterInput: {
            searchTerm,
          },
          pageInput: {
            page: 0,
            size: 2000
          },
          sort: {
            field: Field.FirstName,
            direction: SortDirection.Asc
          } as ClientViewSortInput
        }
      });
    } else {
      setOptions([])
    }
  }, 300, [searchTerm])

  return <div className={'mt-15'}>
    <AscendAutocomplete fullWidth
                        options={options}
                        value={searchTerm}
                        onInputChange={(event, newInputValue) => {
                          setSearchTerm(newInputValue);
                        }}
                        filterOptions={(options) => options}
                        onChange={(e: any, value: any | null) => {
                          if (value) {
                            setClients(prev => [...prev, {
                              clientId: value.id,
                              firstName: value.firstName,
                              lastName: value.lastName,
                              email: value.email
                            }]);
                          }
                        }}
                        renderOption={(client: any, state) => <ClientLine firstName={client?.firstName} lastName={client?.lastName} email={client?.email} />}
                        renderInput={params => <TextInput placeholder={'Search client'}
                                                          {...params}
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment:
                                                              <InputAdornment position="end" style={{position: 'absolute', right: '13px'}}>
                                                                {loading ? <CircularProgress className={'w-20 h-20'}/> : !!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
                                                                  setSearchTerm('');
                                                                }} fontSize={'small'} color={'inherit'} />}
                                                              </InputAdornment>,
                                                          }} />} />
    <List>
      {clients.map(client => (
        <ClientLine key={client?.clientId} error={props.error?.extensions?.clientId === client?.clientId} firstName={client?.firstName} lastName={client?.lastName} email={client?.email}  onClick={() => setClients(clients.filter(c => c.clientId != client.clientId))}/>
      ))}
    </List>
    <Typography style={{color: 'red'}}>{props.error?.message}</Typography>
    <Grid container className={'mt-10'} alignItems={"center"} justifyContent={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button variant={'contained'} color={'primary'} onClick={() => props.onSubmit(clients)}>SAVE</Button>
      </Grid>
    </Grid>
  </div>
};

interface ClientLineProps {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  id?: string | null,
  error?: boolean,
  onClick?: () => void,
  isLink?: boolean
}

const ClientLine = ({firstName, lastName, email, error, onClick, id, isLink}: ClientLineProps) => (
  <ListItem style={{backgroundColor: error ? 'rgba(255,0,0,0.2)' : undefined}} disableGutters dense>
    <ListItemText primary={isLink ? <Link to={paths.client + '/' + id}>{firstName} {lastName}</Link> : <span>{firstName} {lastName}</span>}
                  secondary={email}
    />
    {onClick && <ListItemSecondaryAction>
      <IconButton size={'small'} onClick={onClick}>
        <CloseIcon fontSize={'small'} color={'error'} />
      </IconButton>
    </ListItemSecondaryAction>}

  </ListItem>
)

