import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationChangeEnrollmentStatusArgs} from "../../enrollment-types";

export default function useChangeEnrollmentStatus(options?: MutationHookOptions<{changeEnrollmentStatus: string}, MutationChangeEnrollmentStatusArgs>) {
  return useMutation<{changeEnrollmentStatus: string}, MutationChangeEnrollmentStatusArgs>(gql(save), options)
}

const save = `
mutation ($enrollmentId: UUID!, $status: EnrollmentStatusInput!) {
  changeEnrollmentStatus(enrollmentId: $enrollmentId, status: $status)
}
`;

