import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import TextInput from "../../shared/TextInput";
import {Button, Grid, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {config} from "../../config/config";
import FormSelect from "../../shared/FormSelect";
import useZipCounties from "../hooks/useZipCounties";
import MaskedInput from 'react-text-mask'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  gender: yup
    .string()
    .required('Gender is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .test('valid', 'Phone format is not valid', value => {
      return /^(\d{10})/gm.test(value.replace(/\D/g, ""))
    })
  ,
  birthDate: yup
    .date().nullable()
    .typeError('Please enter a valid date')
    .required('Birthdate is required'),
  zip: yup
    .string()
    .required('Zip is required'),
  county: yup.string().required('County is required'),
});

export interface PersonalInfoFormValues {
  email: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  zip: string;
  county: string,
  state: string,
}

interface PersonalInfoFormProps {
  initialEmail?: string;
  initialFirstName?: string;
  initialLastName?: string;
  initialBirthDate?: string;
  initialPhoneNumber?: string;
  gender?: string;
  zip?: string;
  county?: string;
  state?: string;
  onSubmit: (values: PersonalInfoFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const PersonalInfoForm = (props: PersonalInfoFormProps) => {
  const formik = useFormik({
    initialValues: {
      email: props.initialEmail || '',
      birthDate: props.initialBirthDate || null,
      firstName: props.initialFirstName || '',
      lastName: props.initialLastName || '',
      phoneNumber: props.initialPhoneNumber || '',
      gender: props.gender || '',
      zip: props.zip || '',
      county: props.county || '',
      state: props.state || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      props.onSubmit({
        ...values,
        birthDate: moment(values.birthDate).format(config.dateFormat),
        phoneNumber: '+1' + values.phoneNumber.replace(/\D/g, "")
      });
    },
  });

  const {getCounties, counties, resetCounties} = useZipCounties(data => {
    if (data.plans_zip_county_fips.length === 1) {
      formik.setFieldValue('county', data.plans_zip_county_fips[0].countyname);
      formik.setFieldValue('state', data.plans_zip_county_fips[0].state);
    }
  })

  React.useEffect(() => {
    const zip = formik.values.zip;
    if (zip && zip.length === 5) {
      if (!counties.length) {
        getCounties({variables: {zip}})
      }
    }
    if (zip.length < 5) {
      formik.setFieldValue('county', '');
      resetCounties();
    }
  }, [formik.values.zip, formik.values.county]);

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={1} direction={'row'}>
        <Grid item xs={6}>
          <TextInput
            fullWidth
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fullWidth
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
      </Grid>
      <FormSelect label={'Gender'}
                  value={formik.values.gender}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue("gender", event.target.value as string);
                  }}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender ? formik.errors.gender : undefined}
      >
        <MenuItem value={'M'}>Male</MenuItem>
        <MenuItem value={'F'}>Female</MenuItem>
      </FormSelect>
      <TextInput
        name="phoneNumber"
        placeholder={'11234567890'}
        label="Phone"
        InputProps={{
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
          inputComponent: MaskedInput,
          inputProps: {
            mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] as any
          }
        }}
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
      <TextInput
        fullWidth
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableFuture
          variant="inline"
          format="MM/dd/yyyy"
          label={'Date of Birth'}
          InputAdornmentProps={{
            classes: {
              root: 'hidden'
            }
          }}
          placeholder={'MM / DD / YYYY'}
          error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
          helperText={formik.touched.birthDate && formik.errors.birthDate}
          value={formik.values.birthDate}
          onChange={value => formik.setFieldValue("birthDate", value)}
          invalidDateMessage={'Please enter a valid date'}
          TextFieldComponent={TextInput as any}
        />
      </MuiPickersUtilsProvider>
      <Grid container spacing={1} direction={'row'}>
        <Grid item xs={6}>
          <TextInput
            name="zip"
            label="Zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={formik.touched.zip && Boolean(formik.errors.zip)}
            helperText={formik.touched.zip && formik.errors.zip}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect label={'County'}
                      className={'ml-5'}
                      value={formik.values.county}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        formik.setFieldValue("county", event.target.value as string);
                        formik.setFieldValue('state', counties.find(c => c.countyname === event.target.value)?.state);
                      }}
                      error={formik.touched.county && Boolean(formik.errors.county)}
                      helperText={formik.touched.county ? formik.errors.county : undefined}
          >
            {counties.map(c => <MenuItem key={c.countyname} value={c.countyname}>{c.countyname}</MenuItem>)}
          </FormSelect>
        </Grid>
      </Grid>

      <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
        <Grid item>
          <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
        </Grid>
        <Grid item>
          <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonalInfoForm;
