import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveExpectedCloseDateArgs} from "../../enrollment-types";

export default function useSaveExpectedCloseDate(options?: MutationHookOptions<any, MutationSaveExpectedCloseDateArgs>) {
  return useMutation<any, MutationSaveExpectedCloseDateArgs>(gql(saveFollowUpDateMutation), options)
}

const saveFollowUpDateMutation = `
mutation ($id: UUID!, $expectedCloseDate: LocalDate!) {
  saveExpectedCloseDate(id: $id, expectedCloseDate: $expectedCloseDate)
}
`;
