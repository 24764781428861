import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, Chip, IconButton, Typography} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import paths from "../router-paths";
import {useHistory} from "react-router";
import Card from "../shared/Card";
import {
  StatusDto,
  AssignedTagViewOutput,
  ClientViewSortInput,
  ClientViewOutput,
  CallHistoryOutput,
  CallHistoryResultOutput, ClientViewTimeZone,
} from "../enrollment-types";
import { DateRangePicker } from "materialui-daterange-picker";
import TextInput from "../shared/TextInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from '@material-ui/icons/Close';
import StatusView from "./components/StatusView";
import moment from "moment";
import FormSelect from "../shared/FormSelect";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import clsx from "clsx";
import {default as AgentSelector, getAgentDisplayName} from "../Agent/AgentSelector";
import useClientListSorting from "./hooks/client-details-hooks/useClientListSorting";
import useClientListPage from "./hooks/client-details-hooks/useClientListPage";
import useClientListSearchTerm from "./hooks/client-details-hooks/useClientListSearchTerm";
import useClientListStatus from "./hooks/client-details-hooks/useClientListStatus";
import useClientListAgentId from "./hooks/client-details-hooks/useClientListAgentId";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import {getTagLabel, TagSelector} from "./widgets/ClientTags";
import useClientListTags from "./hooks/client-details-hooks/useClientListTags";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import * as _ from 'lodash';
import {gql} from "@apollo/client";
import useClientListPageSize from "./hooks/useClientListPageSize";
import useClientUtmSource from "./hooks/client-details-hooks/useClientUtmSource";
import useClientViews from "./hooks/useClientViews";
import {formatPhoneLink} from "../PlanList/utils";
import useClientListAdviserEmail from "./hooks/client-details-hooks/useClientListAdviserEmail";
import {Link} from "react-router-dom";
import {colors} from "../AppTheme";
import useClientDateRange from "./hooks/client-details-hooks/useClientDateRange";
import useClientListState from "./hooks/client-details-hooks/useClientListState";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import useClientListBirthDate from "./hooks/client-details-hooks/useClientListBirthDate";
import {GetLicensedStates} from "./widgets/PersonalInfo";
import {client} from "../Apollo/EnrollmentApolloClient";
import useClientListTimezone from "./hooks/client-details-hooks/useClientListTimezone";
import useAffiliationPartnerships from "./hooks/useAffiliationPartnerships";
import useClientListPartnership from "./hooks/client-details-hooks/useClientListPartnership";
import AscendAutocomplete from "../shared/AscendAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchInput: {
      minWidth: '380px',
      width: '380px'
    },
    statusSelector: {
      minWidth: '186px'
    },
    stateSelector: {
      width: '100px',
      minWidth: '100px'
    },
    agentSelector: {
      minWidth: '188px'
    },
    dateRange: {
      position: 'absolute',
      zIndex: 1
    }
  }),
);


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false,
    renderCell: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown user';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
    }
  },
  { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{params.row.email}</Link>
    }
  },
  { field: 'phoneNumber', headerName: 'Phone', width: 140, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phoneNumber) {
        return formatPhoneLink(params.row.phoneNumber, true)
      }
      return ''
    }
  },
  { field: 'birthDate', headerName: 'BirthDate', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Quote submitted timestamp', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'userId', headerName: 'Auth', width: 70, sortable: false, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.userId ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'agent', headerName: 'Agent responsible', width: 150, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const agent = params.row.agent;
      if (agent) {
        return getAgentDisplayName(agent)
      } else {
        return '-'
      }
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'calls', headerName: 'Calls', width: 70, sortable: false, disableColumnMenu: true, },
  { field: 'mostRecentCall', headerName: 'Most Recent Call', width: 200, sortable: false, disableColumnMenu: true, },
  { field: 'followUpDate', headerName: 'Follow up date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'expectedCloseDate', headerName: 'Expected close date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.expectedCloseDate) {
        return moment(params.row.expectedCloseDate).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
  { field: 'affiliationPartnershipSource', headerName: 'Lead Sources', width: 200, disableColumnMenu: true},
  { field: 'profileSource', headerName: 'Profile Source', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <Typography color={'textPrimary'}>{ _.startCase(_.lowerCase(params.row.profileSource))}</Typography>
    }
  },
  { field: 'adviserName', headerName: 'Advisor', width: 200, disableColumnMenu: true},
];

const ClientList = () => {
  const classes = useStyles();
  const [page, setPage] = useClientListPage();
  const [sorting, setSorting] = useClientListSorting();
  let history = useHistory();
  const [searchTerm, setSearchTerm] = useClientListSearchTerm();
  const [adviserEmail, setAdviserEmail] = useClientListAdviserEmail();
  const [utmSource, setUtmSource] = useClientUtmSource();
  const [searchStatus, setSearchStatus] = useClientListStatus();
  const [pageSize, setPageSize] = useClientListPageSize();
  const [searchAgentId, setSearchAgentId] = useClientListAgentId();
  const [clientTags, setClientTags] = useClientListTags();
  const [open, setOpen] = React.useState(false);
  const [bOpen, setBOpen] = React.useState(false);
  const [dateRange, setDateRange] = useClientDateRange();
  const [state, setState] = useClientListState();
  const [timezone, setTimezone] = useClientListTimezone();
  const [birthDate, setBirthDate] = useClientListBirthDate();
  const [rows, setRows] = React.useState<(ClientViewOutput & {calls: string})[]>([]);
  const [partnershipAutocompleteValue, setPartnershipAutocompleteValue] = useState('')

  const [partnership, setPartnership] = useClientListPartnership();
  const [getPartnerships, partnershipsData] = useAffiliationPartnerships();

  const [searchRequest, {loading, data, refetch}] = useClientViews();

  React.useEffect(() => {
      search();
  }, [page, sorting, pageSize]);

  const search = async () => {
    setRows([]);
    const clients = await searchRequest({
      variables: {
        filterInput: {
          searchTerm,
          status: searchStatus ? searchStatus as any : undefined,
          agentId: searchAgentId ? searchAgentId : undefined,
          tags: clientTags ? clientTags.map(t => t.tag.id) : undefined,
          utmSource: utmSource || undefined,
          adviserEmail,
          marketingCampaign: partnership?.campaign === '-' ? undefined : partnership?.campaign || null,
          marketingSource: partnership?.source || null,
          state: state ? state.toUpperCase() : null,
          timeZone: timezone || undefined,
          birthDate: birthDate.startDate || birthDate.endDate ? {from: birthDate.startDate ? moment(birthDate.startDate).startOf('day').toISOString() : moment('01-01-1900').startOf('day').toISOString(), to: moment(birthDate.endDate).endOf('day').toISOString()} : undefined,
          range: dateRange.startDate ? {from: moment(dateRange.startDate).startOf('day').toISOString(), to: moment(dateRange.endDate).endOf('day').toISOString()} : undefined,
        },
        pageInput: {
          page: page,
          size: pageSize
        },
        sort: sorting && {
          field: sorting.field,
          direction: sorting.sort
        } as ClientViewSortInput
      }
    }).then(res => res.data?.clientViews.data || []);

    if (clients.length) {
      const query = `
      query {
        ${clients.map((c, i) => 'c' + i + `: callHistoryItems(clientId: "${c.id}", page: {page: 0, size: 50}) {
          data {
            result
            createdAt
          }
          totalElements
        }`).join(' ')}
      }
    `;

      const calls = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});
      setRows(
        clients.map((c, i) => {
          const mostRecentCall = _.last(_.orderBy(calls['c' + i].data, c => moment(c.createdAt).unix()))?.createdAt;
          return {
            ...c,
            calls: calls['c' + i].data?.filter((c: CallHistoryOutput) => c.result === CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length + '/' + calls['c' + i].totalElements,
            mostRecentCall: mostRecentCall ? moment(mostRecentCall).format('L H:mm:ss') : ''
          }
        }))
    }
  }

  useEffect(() => {
    getPartnerships()
  }, []);

  const partnerships = React.useMemo(() => {
    if (partnershipsData.data) {
      return partnershipsData.data.affiliationPartnerships
    }
    return [];
  }, [partnershipsData.data])

  const onSearchClick = () => {
    if (page > 0) {
      setPage(0);
    } else {
      search();
    }
  }

  const inputProps: any = {
    endAdornment:
      <InputAdornment position="end">
        {!!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
          setSearchTerm('');
          onSearchClick()
        }} fontSize={'small'} color={'inherit'} />}
      </InputAdornment>,
  };

  const stateInputProps: any = {
    endAdornment:
      <InputAdornment position="end">
        {!!state && <CloseIcon cursor={'pointer'} onClick={() => {
          setState('');
          onSearchClick();
        }} fontSize={'small'} color={'inherit'} />}
      </InputAdornment>,
  };


  const dateRangeStr = React.useMemo(() => {
    if (!dateRange.startDate) {
      return 'Not selected';
    }
    return moment(dateRange.startDate).format('L') + ' - ' + moment(dateRange.endDate).format('L')
  }, [dateRange])

  const filteredPartnerships = useMemo(() => {
    return _.orderBy(partnerships, ['source', 'campaign'])
      .filter(p => `${p.source}: ${p.campaign}`.indexOf(partnershipAutocompleteValue) >= 0) || []
  }, [partnerships, partnershipAutocompleteValue]);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={loading} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color={'textPrimary'} variant={'h4'}>Client list</Typography>
              <Button className={'ml-40'} variant={'contained'} size={'small'} color={'primary'} onClick={() => history.push(paths.createClient)}>Create new client</Button>
            </Box>

            <Box display={'flex'} alignItems={'center'}>
              <Box display={'flex'}  alignItems={'center'} position={'relative'} mt={'20px'}>
                <Typography color={'textPrimary'} variant={'h5'}>Date range: {dateRangeStr}</Typography>
                <IconButton size={'small'} className={'ml-15'} onClick={() => setOpen(!open)}><img className={'w-15 h-15'} src={'/img/mode.svg'}/></IconButton>
                <IconButton size={'small'} className={'ml-15'} onClick={() => setDateRange({})}><img className={'w-15 h-15'} src={'/img/delete-icon.svg'}/></IconButton>
                <DateRangePicker
                  closeOnClickOutside
                  wrapperClassName={classes.dateRange}
                  open={open}
                  toggle={() => setOpen(!open)}
                  onChange={(range) => setDateRange(range)}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              {/*<FormSelect className={clsx('mb-0 mr-10', classes.statusSelector)}
                          label={'ADVISOR'}
                          value={adviserEmail}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setAdviserEmail(event.target.value as StatusDto)
                          }}
              >
                <MenuItem value={''}>-</MenuItem>
                {advisors.map(advisor => <MenuItem key={advisor.email} value={advisor.email}>{advisor.firstName} {advisor.lastName} ({advisor.email})</MenuItem>)}
              </FormSelect>*/}


              <AscendAutocomplete fullWidth
                                  loading={partnershipsData.loading}
                                  options={filteredPartnerships}
                                  value={partnership}
                                  onInputChange={(event, newInputValue) => {
                                    setPartnershipAutocompleteValue(newInputValue);
                                  }}
                                  getOptionLabel={(p: any) => {
                                    return p ? `${p.source}: ${p.campaign}` : ''
                                  }}
                                  onChange={(event, value) => {
                                    setPartnership(value as any);
                                  }}
                                  onKeyPress={event => {
                                    if (event.key === 'Enter' && filteredPartnerships.length === 1) {
                                      if (partnership === filteredPartnerships[0]) {
                                        onSearchClick();
                                      } else {
                                        setPartnership(filteredPartnerships[0])
                                      }
                                    }
                                  }}
                                  clearOnBlur
                                  renderInput={params => <TextInput
                                    className={clsx('mb-0 mr-10', classes.searchInput)}
                                    placeholder={'CAMPAIGN'}
                                    value={partnershipAutocompleteValue}
                                    {...params} />} />

              <TextInput
                className={clsx('mb-0', classes.searchInput)}
                fullWidth
                name="search"
                label="Search"
                InputProps={inputProps}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSearchClick();
                  }
                }}
                value={searchTerm}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value || '')}
              />
              <FormSelect className={clsx('mb-0 ml-10 mr-10', classes.statusSelector)}
                          label={'Status'}
                          value={searchStatus}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setSearchStatus(event.target.value as StatusDto)
                          }}
              >
                <MenuItem value={''}>-</MenuItem>
                <MenuItem value={StatusDto.NotYetEngaged}> <StatusView status={StatusDto.NotYetEngaged} /></MenuItem>
                <MenuItem value={StatusDto.Attempted}> <StatusView status={StatusDto.Attempted} /></MenuItem>
                <MenuItem value={StatusDto.Contacted}> <StatusView status={StatusDto.Contacted} /></MenuItem>
                <MenuItem value={StatusDto.FollowUp}> <StatusView status={StatusDto.FollowUp} /></MenuItem>
                <MenuItem value={StatusDto.SalesQualified}> <StatusView status={StatusDto.SalesQualified} /></MenuItem>
                <MenuItem value={StatusDto.ApplicationSubmitted}> <StatusView status={StatusDto.ApplicationSubmitted} /></MenuItem>
                <MenuItem value={StatusDto.Disenrolled}> <StatusView status={StatusDto.Disenrolled} /></MenuItem>
                <MenuItem value={StatusDto.Disqualified}> <StatusView status={StatusDto.Disqualified} /></MenuItem>
                <MenuItem value={StatusDto.Test}> <StatusView status={StatusDto.Test} /></MenuItem>
                <MenuItem value={StatusDto.Duplicated}> <StatusView status={StatusDto.Duplicated} /></MenuItem>
                <MenuItem value={StatusDto.OutOfService}> <StatusView status={StatusDto.OutOfService} /></MenuItem>
              </FormSelect>
              <Button variant={'contained'} size={'small'} color={'primary'} onClick={onSearchClick}>Search</Button>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginTop={'14px'}>
              <Box display={'flex'}  alignItems={'center'} position={'relative'} mt={'25px'} mr={'10px'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={'w-190'}
                    disableFuture
                    variant="inline"
                    format="MM/dd/yyyy"
                    label={'Birth date from'}
                    InputAdornmentProps={{
                      classes: {
                        root: 'hidden'
                      }
                    }}
                    placeholder={'MM / DD / YYYY'}
                    value={birthDate.startDate || null}
                    onChange={value => setBirthDate({startDate: value || undefined, endDate: birthDate?.endDate})}
                    invalidDateMessage={'Please enter a valid date'}
                    TextFieldComponent={TextInput as any}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    className={'w-190 ml-10'}
                    variant="inline"
                    format="MM/dd/yyyy"
                    label={'Birth date to'}
                    InputAdornmentProps={{
                      classes: {
                        root: 'hidden'
                      }
                    }}
                    placeholder={'MM / DD / YYYY'}
                    value={birthDate.endDate || null}
                    onChange={value => {
                      setBirthDate({startDate: birthDate?.startDate, endDate: value || undefined})
                    }}
                    invalidDateMessage={'Please enter a valid date'}
                    TextFieldComponent={TextInput as any}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <FormSelect className={clsx('mb-0 mr-8', classes.stateSelector)}
                          label={'State'}
                          value={state}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setState(event.target.value as string)
                          }}
              >
                <MenuItem value={''}>-</MenuItem>
                {GetLicensedStates().sort().map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
              </FormSelect>
              <FormSelect className={clsx('mb-0 mr-8', classes.stateSelector)}
                          label={'Timezone'}
                          value={timezone}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setTimezone(event.target.value as any)
                          }}
              >
                <MenuItem value={''}>-</MenuItem>
                <MenuItem value={ClientViewTimeZone.Akst}>{ClientViewTimeZone.Akst}</MenuItem>
                <MenuItem value={ClientViewTimeZone.Cst}>{ClientViewTimeZone.Cst}</MenuItem>
                <MenuItem value={ClientViewTimeZone.Est}>{ClientViewTimeZone.Est}</MenuItem>
                <MenuItem value={ClientViewTimeZone.Hst}>{ClientViewTimeZone.Hst}</MenuItem>
                <MenuItem value={ClientViewTimeZone.Mst}>{ClientViewTimeZone.Mst}</MenuItem>
                <MenuItem value={ClientViewTimeZone.Pst}>{ClientViewTimeZone.Pst}</MenuItem>
              </FormSelect>
              <TextInput
                  className={clsx('mb-0')}
                  fullWidth
                  name="utmSource"
                  label="UtmSource"
                  InputProps={inputProps}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      onSearchClick();
                    }
                  }}
                  value={utmSource}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setUtmSource(e.target.value || '')}
              />
              <Box width={200} mr={'10px'} ml={'10px'}>
                <TagSelector readOnly label={'Tags'} className={'mb-0'} selectedValues={clientTags} onChange={setClientTags} />
              </Box>
              <Box>
                <AgentSelector className={clsx('mb-0 mr-10 ', classes.agentSelector)}
                               showEmptyOption
                               value={searchAgentId}
                               onChange={setSearchAgentId}/>
              </Box>
              <Box width={'74px'}/>
            </Box>
          </Box>
        </Box>
        <div style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
          <DataGrid rows={rows}
                    paginationMode={"server"}
                    sortingMode={'server'}
                    columns={columns}
                    page={page}
                    disableSelectionOnClick
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                    onRowClick={(param, event) => {
                      history.push(paths.client + '/' + param.row.id)
                    }}
                    sortModel={sorting ? [sorting] : []}
                    onSortModelChange={(sortModel) => {
                      setSorting(sortModel[0])
                    }}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowCount={parseFloat(data?.clientViews?.totalElements || '0')}
                    pagination
          />
        </div>
      </Card>

    </Box>
  </Box>;
}

export default ClientList;

const advisors = [
  {firstName: 'Richard', lastName:	'Chan', email:	'richard@coverright.com'},
  {firstName: 'Jerry', lastName:	'Summers', email:	'jerry@artifexfinancial.com'},
  {firstName: 'Amir', lastName:	'Noor', email:	'amir@unitedfpg.com'},
  {firstName: 'Wes', lastName:	'Pettaway', email:	'wes.petteway@nm.com'},
  {firstName: 'Rudy', lastName: 	'Jimenez', email:	'rjimenez.ariasagencies@gmail.com'},
  {firstName: 'John', lastName:	'Middleton', email:	'john@brightonfinancial.com'},
  {firstName: 'Anthony', lastName:	'Depersio', email:	'adepersio@cspgllc.com'},
  {firstName: 'Sharon', lastName:	'Hayut', email:	'sharon.hayut@magnusfinancial.com'},
  {firstName: 'Mike', lastName:	'Dunne', email:	'michael@cereusfinancial.com'},
  {firstName: 'Stephen', lastName:	'Pedicini', email:	'pedicinis@hallcapmgt.com'},
  {firstName: 'Jack', lastName:	'McCaffery', email:	'jmccaffery@financialguide.com'},
  {firstName: 'Matthew', lastName:	'McKee', email:	'Matthew.McKee@morganstanley.com'},
  {firstName: 'Tyler', lastName: 	'End', email:	'tyler@retirable.com'},
  {firstName: 'Ian', lastName:	'Yamey', email:	'ian@retirable.com'},
  {firstName: 'Shawn', lastName:	'Tighe', email:	'shawn.tighe@alexbrown.com'},
  {firstName: 'Thomas', lastName: 	'Schulte', email:	'tschulte@uswealthmanagement.com'},
  {firstName: 'Jordan', lastName:	'Gilberti', email:	'jordan.gilberti@facetwealth.com'},
  {firstName: 'LJ', lastName:	'Jones', email:	'lj@developingfinancial.com'},
  {firstName: 'Tom', lastName:	'Bonvissuto', email:	'tom@interofp.com'},
  {firstName: 'Tracey', lastName:	'Beaux', email:	'tbreaux@protonmail.com'},
  {firstName: 'Delaney', lastName:	'Wallace', email:	'dwallace@barnumfg.com'},
  {firstName: 'Alex', lastName:	'Rusinowitz', email:	'alex@rcacap.com'},
  {firstName: 'Tayo', lastName: 	'Ogunnaike', email:	'Tayo.Ogunnaike@morganstanley.com'},
  {firstName: 'Dennis', lastName:	'Dering', email:	'Dennis.Dering@morganstanley.com'},
  {firstName: 'Olivia', lastName:	'Cero', email:	'Olivia.Cero@morganstanley.com'},
  {firstName: 'Nick', lastName:	'Berlen', email:	'nberlen@uswealthmanagement.com'},
]
