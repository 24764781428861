import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, IconButton, Paper} from "@material-ui/core";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import Preloader from "../shared/Preloader";
import moment from "moment";
import {useHistory} from "react-router-dom";
import paths from "../router-paths";
import ClearIcon from '@material-ui/icons/Clear';
import {DataGrid, GridCellParams, GridColDef, GridRowId, GridValueGetterParams} from "@mui/x-data-grid";

const useStyles = makeStyles((theme: Theme) => ({

}));

const PAGE_SIZE = 10;

interface ICallbacks {
  deleteRow: (id: GridRowId) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'createdAt', headerName: 'Date', width: 200,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => moment(params.getValue(params.id, 'createdAt') as string).format('L H:mm:ss')
  },
  { field: 'firstName', headerName: 'First name', sortable: false,width: 200 },
  { field: 'lastName', headerName: 'Last name', sortable: false,width: 200 },
  /*{ field: 'email', headerName: 'Email', sortable: false,width: 200 },
  { field: 'birthDate', headerName: 'Birth Date', sortable: false, width: 200,
    valueGetter: (params: ValueGetterParams) => params.getValue('birthDate') ? moment(params.getValue('birthDate') as string).format('L') : '-'
  },
  { field: 'age', headerName: 'Age',sortable: false, width: 200,
    valueGetter: (params: ValueGetterParams) => {
      const duration = moment.duration(moment().diff(moment(params.getValue('birthDate') as string)))
      return params.getValue('birthDate') ? `${duration.get('year')}y ${duration.get('month')}m` : '-'
    }
  },*/
  { field: 'status', headerName: 'Status', sortable: false, width: 200 },
  { field: '', headerName: '', sortable: false, width: 70, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <IconButton onClick={() => callbacks.deleteRow(params.row.id)}><ClearIcon style={{color: 'red'}}  /></IconButton>
  },
];

export default function RequestList() {
  const styles = useStyles();
  const [page, setPage] = React.useState(1);
  let history = useHistory();

  const {loading, data, refetch} = useQuery<IRequestsQueryResponse>(gql(requestsQuery), {
    fetchPolicy: 'no-cache',
    variables: {
      page: {
        page: page - 1,
        size: PAGE_SIZE
      }
    }
  });

  const [deleteRow, deleteData] = useMutation(gql(deleteRowMutation), {
    onCompleted: () => refetch()
  });

  const callbacks: ICallbacks = {
    deleteRow: id => deleteRow({variables: {id}})
  }

  return (
    <Box display={'flex'} flex={1}>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <Preloader in={loading || deleteData.loading} />

        <Paper>
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid rows={data?.medicareQuotes?.data || []}
                      paginationMode={"server"}
                      columns={columns(callbacks)}
                      page={page}
                      onRowClick={param => history.push(paths.request + '/' + param.row.id)}
                      onPageChange={(page) => {
                        setPage(page);
                      }}
                      pageSize={PAGE_SIZE}
                      rowCount={data?.medicareQuotes?.totalElements}
                      pagination
            />
          </div>
        </Paper>

      </Box>
    </Box>
  )
}

export interface IRequest {
  aptNumber: string,
  birthDate: string,
  createdAt: string,
  email: string,
  employed: string,
  enrolled: string,
  firstName: string,
  gender: string,
  hasMore20: string,
  id: string,
  lastName: string,
  receivedBenefits: string,
  receivesLIS: string,
  receivesMedicalSupport: string,
  restrictProviderNetwork: string,
  spouseEmployed: string,
  streetName: string,
  travelMoreThan30DaysAYear: string,
  zip: string,
  cId: string,
  status: string,
  county: string,
}

interface IRequestsQueryResponse {
  medicareQuotes: {
    totalPages: number,
    totalElements: number,
    data: IRequest[]
  }
}

const deleteRowMutation = `
  mutation ($id: UUID!) {
    saveRequest(data: {form: BASIC_INFO, id: $id, status: DELETED})
  }
`

export const quoteFields = `
        createdAt
        county
        employed
        id
        favorites
        restrictProviderNetwork
        travelMoreThan30DaysAYear
        doctorsWithinNetwork
        work8years
        enrolled
        coverageFilled
        enrolledFilled
        preferredPharmaciesFilled
        preferredDrugsFilled
        preferredDoctorsFilled
        hasMore20
        receivesMedicalSupport
        receivesLIS
        receivedBenefits
        governmentAssistant
        spouseEmployed
        spouseHasMore20
        zip
        pin
        doctorVisitsNumber
        specialistVisitsNumber
        coverage
        chronicIllness
        preferredPharmacies {
          address
          distance
          latitude
          longitude
          name
          npi
          zip
        }
        medicarePlanType
        prepayCoverage
        customAnswers
        preferredDrugs {
          frequency
          genericName
          genericRxcui
          isGeneric
          name
          packName
          packRxcui
          quantity
          rxcui
        }
        preferredDoctors {
          npi
          address {
            addressLine1
            addressLine2
            id
            isPcp
            specialty
          }
          name
          addressesNumber
        }
`

const requestsQuery = `
  query ($page: PageInput!) {
    medicareQuotes(page: $page) {
      totalPages
      totalElements
      data {
        ${quoteFields}
      }
    }
  }
`
