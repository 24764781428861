import {LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {LeadSourceOutput, QueryClientLeadSourcesArgs} from "../../enrollment-types";

export default function useLeadSources(options?: LazyQueryHookOptions<{clientLeadSources: LeadSourceOutput[]}, QueryClientLeadSourcesArgs>) {
  return useLazyQuery<{clientLeadSources: LeadSourceOutput[]}, QueryClientLeadSourcesArgs>(gql(callHistoryItemsQuery), options)
}

const callHistoryItemsQuery = `
query($clientId: UUID!) {
  clientLeadSources(clientId: $clientId) {
    campaign
    eventDate
    medium
    source
    term
    content
  }
}
`;
