import Card from "../../shared/Card";
import {IconButton, Typography} from "@material-ui/core";
import Param from "../../shared/Param";
import React from "react";
import { EnrollmentListItemOutput} from "../../enrollment-types";
import moment from "moment";
import paths from "../../router-paths";
import {useHistory} from "react-router";
import useDeletePdpEnrollment from "../hooks/useDeletePdpEnrollment";
import {config} from "../../config/config";

interface PdpEnrollmentInfoProps {
  enrollment: EnrollmentListItemOutput,
  onDeleteClick?: () => void,
  viewOnly?: boolean
}

const PdpEnrollmentInfo = ({enrollment, onDeleteClick, viewOnly}: PdpEnrollmentInfoProps) => {
  const history = useHistory();
  const [deleteEnrollment] = useDeletePdpEnrollment()

  const name = React.useMemo(() => {
    let name = '';
    if (!enrollment?.firstName && !enrollment?.lastName) {
      name = 'Unknown user';
    } else {
      name = enrollment?.firstName + ' ' + enrollment?.lastName || '';
    }
    return name
  }, [enrollment?.firstName, enrollment?.lastName])

  const onDelete = () => {
    deleteEnrollment({variables: {enrollmentId: enrollment.id}}).then(onDeleteClick)
  }

  const birthDate = React.useMemo(() => {
    return enrollment?.birthDate ? moment.utc(enrollment?.birthDate).format(config.dateFormat) : 'N/A'
  }, [enrollment?.birthDate])

  return <Card className={'w-260'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Pdp Enrollment</Typography>
      {!viewOnly && <div className={'flex-space-between flex-align-center'}>
        <IconButton size={'small'} onClick={() => history.push(paths.pdpEnrollmentEdit + `/${enrollment.clientId}/${enrollment?.id}`)}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-10'} onClick={onDelete}><img src={'/img/delete-icon.svg'}/></IconButton>
      </div>}
    </div>
    <Param className={'flex-column'} label={"NAME"} value={name}/>
    <div className={"flex-space-between"}>
      <Param className={'flex-column'} label={"DATE OF BIRTH"} value={birthDate}/>
      <Param className={'flex-column'} label={"PHONE"} value={enrollment?.phoneNumber || 'N/A'}/>
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.bidId && <Param className={'flex-column'} label={"PLAN"} value={enrollment?.bidId}/>}
      {enrollment?.started && <Param className={'flex-column'} label={"ENROLLMENT STARTED"} value={moment(enrollment?.started).format('MM/DD/YYYY')}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.carrierName && <Param className={'flex-column'} label={"CARRIER"} value={enrollment?.carrierName}/>}
      {enrollment?.status && <Param className={'flex-column'} label={"STATUS"} valueClassName={'yellow'} value={enrollment?.status}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.planName && <Param className={'flex-column'} label={"PLAN NAME"} value={enrollment?.planName}/>}
    </div>
  </Card>
}

export default PdpEnrollmentInfo;
