import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import TextInput from "../../shared/TextInput";
import {
  Box,
  Button,
  CircularProgress, createStyles,
  Divider,
  Grid,
  ListItemText,
  makeStyles,
  MenuItem, Theme,
  Typography
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import FormSelect from "../../shared/FormSelect";
import Card from "../../shared/Card";
import {RouteComponentProps, useHistory} from "react-router";
import {gql, useLazyQuery, useSubscription} from "@apollo/client";
import {
  ClientViewOutput,
  EnrollmentListItemOutput,
  EnrollmentStatusDto,
  EnrollmentType, Gender,
  MedigapEnrollmentPlanName, MedigapEnrollmentStatus, MedigapEnrollmentType
} from "../../enrollment-types";
import {Plan,  Sort} from "../../types";
import {client} from "../../Apollo/ApolloClient";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {config} from "../../config/config";
import paths from "../../router-paths";
import useZipCounties from "../hooks/useZipCounties";
import {
  MedigapPlanOutput, MedigapPlansField,
  PageableMedigapPlanOutput,
  QueryMedigapPlansArgs
} from "../../medigap-types";
import useSaveMedigapEnrollment from "../hooks/useSaveMedigapEnrollment";
import useAllEnrollments from "../hooks/useAllEnrollments";
import {getAge} from "../../PlanList/utils";
import useChangeMedigapEnrollmentStatus from "../hooks/useChangeMedigapEnrollmentStatus";
import * as _ from 'lodash';
import AgentSelector from "../../Agent/AgentSelector";
import useAgentByContext from "../../Agent/hooks/useAgentByContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    enrollmentTypeSelect: {
      '& p': {display: 'none'},
      '& .MuiListItemText-primary': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
  }),
);

const validationSchema = yup.object({
  zip: yup.string().required('Zip is required'),
  county: yup.string().required('County is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  carrierName: yup
    .string()
    .required('Carrier is required'),
  planName: yup
      .string(),
  status: yup
    .string()
    .required('Status is required'),
  naic: yup
    .string()
    .required('Carrier is required'),
  age: yup
    .string()
    .required('Age is required'),
  planKey: yup
    .string()
    .required('Plan is required'),
  tobacco: yup
    .boolean()
    .required('Tobacco is required'),
  agentId: yup
    .string(),
  birthDate: yup
    .date()
    .typeError('Please enter a birth date')
    .required('Birth Date is required'),
  gender: yup
    .string()
    .required('Plan is required'),
  enrollmentType: yup
    .string(),
  applicationDate: yup
    .date().nullable()
    .required('Application date is required')
    .typeError('Please enter a valid date'),
  effectiveDate: yup
    .date().nullable()
    .required('Effective date is required')
    .typeError('Please enter a valid date'),
});

interface IMgEnrollmentForm {
  firstName: string;
  lastName: string;
  email: string;
  zip: string;
  county: string;
  planKey: string;
  age: string;
  tobacco?: boolean;
  effectiveDate: string | null;
  enrollmentType?: string;
  gender: string;
  status: any;
  carrierName: string | null;
  planName: string;
  applicationDate: string | null;
  birthDate: string | null;
  naic: string;
  agentId: string | null;
}

const Form = ({input, onSubmit}: {input?: Partial<EnrollmentListItemOutput>, onSubmit: (data: IMgEnrollmentForm) => void}) => {
  const classes = useStyles();
  const [plans, setPlans] = React.useState<MedigapPlanOutput[]>([]);
  const [prevInput, setPrevInput] = React.useState<Partial<EnrollmentListItemOutput>>();

  const formik = useFormik({
    initialValues: {
      firstName: input?.firstName || '',
      lastName: input?.lastName || '',
      email: input?.email || '',
      zip: input?.zip || '',
      planKey: input?.mgPlanKey || '',
      age: input?.age ? input?.age + '' : '',
      agentId: input?.agentId || '',
      county: input?.county || '',
      enrollmentType: input?.enrollmentType || '',
      tobacco: typeof input?.tobacco === 'boolean' ? input?.tobacco : undefined,
      gender: input?.gender || '',
      naic: input?.naic || '',
      status: input?.status || EnrollmentStatusDto.Draft,
      planName: input?.mgPlanName || '',
      carrierName: input?.carrierName || '',
      applicationDate: input?.applicationDate ? moment(input?.applicationDate).format(config.dateFormat) : null,
      effectiveDate: input?.effectiveDate ? moment(input?.effectiveDate).format(config.dateFormat) : null,
      birthDate: input?.birthDate ? moment(input?.birthDate).format(config.dateFormat) : null,
    },
    validationSchema: validationSchema,
    onSubmit,
  });
  const {getCounties, counties, resetCounties} = useZipCounties(data => {
    if (data.plans_zip_county_fips.length === 1) {
      formik.setFieldValue('county', data.plans_zip_county_fips[0].countyname);
    }
  })
  const [companies, setCompanies] = React.useState<{name: string, title: string, naics: string[]}[]>([]);
  const [getCompanies, {loading}] = useLazyQuery(gql(companiesQuery), {
    onCompleted: data => {
      setCompanies(data.medigapCompanies.filter((c: any) => c.count).map((c: any) => ({...c, title: `${c.name} (${c.count})`})) || [])
    },
    client
  });

  React.useEffect(() => {
    if (!_.isEqual(prevInput, input)) {
      formik.setValues({
        firstName: formik.values.firstName || input?.firstName || '',
        lastName: formik.values.lastName || input?.lastName || '',
        email: formik.values.email || input?.email || '',
        zip: formik.values.zip || input?.zip || '',
        county: formik.values.county || input?.county || '',
        age: formik.values.age || input?.age ? input?.age + '' : '',
        tobacco: formik.values.tobacco || typeof input?.tobacco === 'boolean' ? input?.tobacco as boolean : undefined,
        gender: formik.values.gender || input?.gender || '',
        agentId: formik.values.agentId || input?.agentId || '',
        naic: formik.values.naic || input?.naic || '',
        status: formik.values.status || input?.status || '',
        planKey: formik.values.planKey || input?.mgPlanKey || '',
        carrierName: formik.values.carrierName || input?.carrierName || '',
        enrollmentType: formik.values.enrollmentType || input?.enrollmentType || '',
        planName: formik.values.planName || input?.mgPlanName || '',
        birthDate: formik.values.birthDate || input?.birthDate ? moment.utc(input?.birthDate).format(config.dateFormat) : null,
        applicationDate: formik.values.applicationDate || input?.applicationDate ? moment.utc(input?.applicationDate).format(config.dateFormat) : null,
        effectiveDate: formik.values.effectiveDate || input?.effectiveDate ? moment.utc(input?.effectiveDate).format(config.dateFormat) : null,
      })
      if (input?.zip !== formik.values.zip) {
        resetCounties();
      }
      setPrevInput(input)
    }
  }, [formik.values, prevInput, input]);

  React.useEffect(() => {
    if (!!formik.values.county && !!formik.values.age && isEffectiveDateValid(formik.values.effectiveDate)) {
      const eligibleDate = moment.utc(formik.values.birthDate).add(65, 'years');
      const diff = moment.duration(eligibleDate.diff(formik.values.effectiveDate)).asDays();
      let age = parseFloat(formik.values.age);
      if (diff < 31 && diff > 0) {
        age = 65
      }
      getCompanies({
        variables: {
          filter: {
            zip: formik.values.zip,
            county: formik.values.county,
            age,
            effectiveDate: moment(formik.values.effectiveDate).format('YYYY-MM-DD'),
            tobacco: !!formik.values.tobacco,
            companies: [],
            gender: formik.values.gender as Gender,
            monthlyPlanPremiumRanges: [],
            planNames: [],
          }
        }
      })
    }
  }, [formik.values])

  const history = useHistory();

  const onPlansCompleted = React.useCallback((data: any) => {
    const planList: MedigapPlanOutput[] = data.medigapPlans?.data || [];
    setPlans(planList);
    const selectedPlan = planList.find(p => p.naic === formik.values.naic && p.planName === formik.values.planName);
    if (selectedPlan) {
      formik.setFieldValue('naic', companies.find(c => c.naics.includes(selectedPlan.naic))?.naics[0] || '');
    }
  }, [input, companies])

  const [getPlans, {loading: plansLoading}] = useLazyQuery<{medigapPlans: PageableMedigapPlanOutput}, QueryMedigapPlansArgs>(gql(plansQuery), {
    onCompleted: onPlansCompleted,
    client: client
  });

  React.useEffect(() => {
    const zip = formik.values.zip;
    if (zip && zip.length === 5) {
      if (!counties.length) {
        getCounties({variables: {zip}})
      }
    }
    if (zip.length < 5) {
      if (formik.values.county) {
        formik.setFieldValue('county', '');
        resetCounties();
      }
    }
  }, [formik.values.zip, formik.values.county]);

  React.useEffect(() => {
    if (isEffectiveDateValid(formik.values.effectiveDate) && isEffectiveDateValid(formik.values.birthDate)) {
      formik.setFieldValue('age', getAge(formik.values.birthDate as string, formik.values.effectiveDate as string));
    }
  }, [formik.values.effectiveDate, formik.values.birthDate]);

  React.useEffect(() => {
    if (formik.values.county && formik.values.age && isEffectiveDateValid(formik.values.effectiveDate)) {
      const companyName = companies.find(c => c.naics.includes(formik.values.naic))?.name || '';
      const eligibleDate = moment.utc(formik.values.birthDate).add(65, 'years');
      const diff = moment.duration(eligibleDate.diff(formik.values.effectiveDate)).asDays();
      let age = parseFloat(formik.values.age);
      if (diff < 31 && diff > 0) {
        age = 65
      }
      getPlans({
        variables: {
          page: {
            size: 1000,
            page: 0
          },
          sort: [{direction: Sort.Asc, field: MedigapPlansField.PlanName}],
          filter: {
            zip: formik.values.zip,
            county: formik.values.county,
            age,
            effectiveDate: moment.utc(formik.values.effectiveDate).format('YYYY-MM-DD'),
            tobacco: !!formik.values.tobacco,
            gender: formik.values.gender as Gender,
            monthlyPlanPremiumRanges: [],
            planNames: [],
            companies: companyName ? [companyName] : [],
          }
        }
      })
    }
  }, [formik.values.naic, formik.values.county, companies])

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15 w-500 relative'}>
      <Box sx={{display: 'flex'}}>
        <TextInput
          name="firstName"
          label="First name"
          fullWidth
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextInput
          name="lastName"
          label="Last name"
          fullWidth
          className={'ml-5'}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Box>
      <Box sx={{display: 'flex'}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Birthdate'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
            helperText={formik.touched.birthDate && formik.errors.birthDate}
            value={formik.values.birthDate}
            onChange={value => formik.setFieldValue("birthDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>
        <TextInput
          name="age"
          label="Age"
          fullWidth
          className={'ml-5'}
          value={formik.values.age}
          onChange={formik.handleChange}
          error={formik.touched.age && Boolean(formik.errors.age)}
          helperText={formik.touched.age && formik.errors.age}
        />
      </Box>
      <TextInput
        name="email"
        label="Email"
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Box sx={{display: 'flex'}}>
        <FormSelect label={'Tobacco'}
                    value={formik.values.tobacco + ''}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("tobacco", event.target.value === 'true');
                    }}
                    error={formik.touched.tobacco && Boolean(formik.errors.tobacco)}
                    helperText={formik.touched.tobacco ? formik.errors.tobacco as string : undefined}
        >
          <MenuItem value={'true'}>Yes</MenuItem>
          <MenuItem value={'false'}>No</MenuItem>
        </FormSelect>
        <FormSelect label={'Gender'}
                    className={'ml-5'}
                    value={formik.values.gender}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("gender", event.target.value as string);
                    }}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    helperText={formik.touched.gender ? formik.errors.gender as string : undefined}
        >
          <MenuItem value={Gender.M}>Male</MenuItem>
          <MenuItem value={Gender.F}>Female</MenuItem>
        </FormSelect>
      </Box>
      <Divider />
      <Box sx={{display: 'flex', mt: 3}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Effective date'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.effectiveDate && Boolean(formik.errors.effectiveDate)}
            helperText={formik.touched.effectiveDate && formik.errors.effectiveDate}
            value={formik.values.effectiveDate}
            onChange={value => formik.setFieldValue("effectiveDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            label={'Application Date'}
            className={'ml-5'}
            InputAdornmentProps={{
              classes: {
                root: 'hidden'
              }
            }}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.applicationDate && Boolean(formik.errors.applicationDate)}
            helperText={formik.touched.applicationDate && formik.errors.applicationDate}
            value={formik.values.applicationDate}
            onChange={value => formik.setFieldValue("applicationDate", value)}
            invalidDateMessage={'Please enter a valid date'}
            TextFieldComponent={TextInput as any}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box sx={{display: 'flex'}}>
        <TextInput
          name="zip"
          label="Zip"
          fullWidth
          value={formik.values.zip}
          onChange={formik.handleChange}
          error={formik.touched.zip && Boolean(formik.errors.zip)}
          helperText={formik.touched.zip && formik.errors.zip}
        />
        <FormSelect label={'County'}
                    className={'ml-5'}
                    value={formik.values.county}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("naic", '');
                      formik.setFieldValue("plan", '');
                      formik.setFieldValue("county", event.target.value as string);
                    }}
                    error={formik.touched.county && Boolean(formik.errors.county)}
                    helperText={formik.touched.county ? formik.errors.county : undefined}
        >
          {counties.map(c => <MenuItem key={c.countyname} value={c.countyname}>{c.countyname}</MenuItem>)}

        </FormSelect>
      </Box>

      <Box sx={{display: 'flex'}}>
        <FormSelect label={loading ? 'Loading...' : 'Carrier'}
                    disabled={loading || plansLoading}
                    value={formik.values.naic}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("naic", event.target.value as string);
                      formik.setFieldValue("plan", '');
                      formik.setFieldValue("planKey", '');
                    }}
                    error={formik.touched.naic && (Boolean(formik.errors.naic) || Boolean(formik.errors.carrierName))}
                    helperText={formik.touched.naic ? (formik.errors.naic || formik.errors.carrierName) : undefined}
        >
          {companies.map(c => <MenuItem onClick={() => {
            formik.setFieldValue("carrierName", c.title.substr(0, c.title.indexOf('(') - 1))
          }} key={c.name} value={c.naics[0]}>{c.title}</MenuItem>)}
        </FormSelect>
        <FormSelect label={'Status'}
                    value={formik.values.status}
                    className={'ml-5'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      formik.setFieldValue("status", event.target.value as string);
                    }}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status ? formik.errors.status as string : undefined}
        >
          <MenuItem value={EnrollmentStatusDto.Draft}>Draft</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Enrolled}>Enrolled</MenuItem>
          <MenuItem value={EnrollmentStatusDto.New}>New</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Rejected}>Rejected</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Sent}>Sent</MenuItem>
        </FormSelect>
      </Box>
      <FormSelect label={'Plan'}
                  disabled={loading || plansLoading}
                  value={formik.values.planKey}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue("planKey", event.target.value as string);

                  }}
                  error={formik.touched.planKey && Boolean(formik.errors.planKey)}
                  helperText={formik.touched.planKey ? formik.errors.planKey : undefined}
      >
        {plans.map(p => <MenuItem key={p.key} onClick={() => {
          formik.setFieldValue("naic", p.naic)
          formik.setFieldValue("planName", p.planName)
        }} value={p.key as string}>{p.title} ({p.planName})</MenuItem>)}
      </FormSelect>
      <AgentSelector value={formik.values.agentId || undefined} onChange={id => formik.setFieldValue("agentId", id)} />
      <FormSelect label={'Enrollment type'}
                  className={classes.enrollmentTypeSelect}
                  value={formik.values.enrollmentType}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue("enrollmentType", event.target.value as string);
                  }}
                  error={formik.touched.enrollmentType && Boolean(formik.errors.enrollmentType)}
                  helperText={formik.touched.enrollmentType ? formik.errors.enrollmentType : undefined}
      >
        {[MedigapEnrollmentType.MgOep, MedigapEnrollmentType.MgUnderwritten, MedigapEnrollmentType.MgGi].map(type => <MenuItem key={type} onClick={() => {
          formik.setFieldValue("enrollmentType", type)
        }} value={type as string}>
          <ListItemText primary={getEnrollmentTypeTitle(type)} secondary={getEnrollmentTypeSubTitle(type)} />
        </MenuItem>)}
      </FormSelect>
      {!!formik.values['enrollmentType'] && <Typography color={'textPrimary'} variant={'body1'} className={'mb-20'}>
        Commission paid as and when premiums are paid by multiplying commission rate % by monthly premium
      </Typography>}

      <Grid container alignItems={"center"} justifyContent={"space-between"} direction={'row'}>
        <Grid item>
          <Button variant={'outlined'} onClick={() => history.goBack()}>CANCEL</Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
        </Grid>
      </Grid>
    </form>
);
}

const MgEnrollmentEditForm = ({ match: { params } }: RouteComponentProps<{clientId: string, id: string}>) => {
  const [getAgentByContext, {data: agentData}] = useAgentByContext();
  const [getEnrollments, {data, loading}] = useAllEnrollments();
  const [saveEnrollment, {loading: saveLoading}] = useSaveMedigapEnrollment();
  const [changeStatus, {loading: changeStatusLoading}] = useChangeMedigapEnrollmentStatus();
  const history = useHistory();
  const {data: clientData, loading: clientLoading} = useSubscription<{clientCard: ClientViewOutput}>(gql(query), {
    variables: {
      id: params.clientId
    }
  });

  const [initialData, setInitialData] = React.useState<Partial<EnrollmentListItemOutput>>();

  React.useEffect(() => {
    if (params.clientId) {
      getEnrollments({variables: {page: {size: 100, page: 0}, filter: {type: EnrollmentType.Mg, clientId: params.clientId}}})
      getAgentByContext();
    }
  }, [params])

  const save = React.useCallback((values: IMgEnrollmentForm) => {
    saveEnrollment({
      variables: {
        input: {
          clientId: params.id ? null : params.clientId,
          id: params.id,
          planName: values.planName as MedigapEnrollmentPlanName,
          carrierName: values.carrierName,
          zip: values.zip,
          county: values.county,
          gender: values.gender as Gender,
          age: parseFloat(values.age),
          tobacco: !!values.tobacco,
          agentId: values.agentId,
          naic: values.naic,
          planKey: values.planKey,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          enrollmentType: values.enrollmentType as MedigapEnrollmentType,
          applicationDate: moment(values.applicationDate).format('YYYY-MM-DD'),
          effectiveDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
        }
      }
    }).then(data => changeStatus({
      variables: {
        status: values.status as unknown as MedigapEnrollmentStatus,
        enrollmentId: data.data?.saveMedigapEnrollment
      }
    }))
      .then(() => history.push(paths.client + '/' + params.clientId + '/enroll'))
  }, [params])

  const fillFromClient = React.useCallback(() => {
    setInitialData({
      zip: clientData?.clientCard.zip,
      county: clientData?.clientCard.countyName,
      tobacco: clientData?.clientCard.tobacco,
      gender: clientData?.clientCard.gender as Gender || undefined,
      email: clientData?.clientCard.email || undefined,
      firstName: clientData?.clientCard.firstName,
      lastName: clientData?.clientCard.lastName,
      birthDate: clientData?.clientCard.birthDate,
    })

  }, [clientData])

  React.useEffect(() => {
    if (params.id) {
      setInitialData(data?.allEnrollments?.data.find(e => e.id === params.id))
    } else if (agentData?.agentByContext) {
      setInitialData(prev => ({
        ...prev,
        agentId: agentData?.agentByContext.id
      }));
    }
  }, [params.id, clientData, data, agentData])

  if (data && clientData && !clientLoading && !loading && !saveLoading && !changeStatusLoading) {
    return <Card>
      <Box sx={{display: 'flex', alignItems: 'center', width: 500, justifyContent: 'space-between'}}>
        <Typography color={'textPrimary'} variant={'h2'} className={'medium'}>{params.id ? 'Edit' : 'Create'} MG enrollment</Typography>
        <Typography className={'pointer underline'} variant={'body1'} color={'textPrimary'} onClick={fillFromClient}>Fill from the client data</Typography>
      </Box>
      <Form input={initialData} onSubmit={save} />
    </Card>
  }
  return <Card>
    <CircularProgress />
  </Card>
};

export default MgEnrollmentEditForm;



const plansQuery = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!, $sort: [MedigapPlansSortInput!]!) {
    medigapPlans: medigapPlansUnfiltered(filter: $filter, page: $page, sort: $sort) {
      data  {
        key
        naic
        orgName
        parentOrgName
        planName
        subTitle
        title
      }
      totalElements
    }
}
`;

const companiesQuery = `
  query($filter: MedigapPlansFilterInput!) {
    medigapCompanies(filter: $filter) {
      key
      name
      count
      naics
    }
  }
`;

const query = `
subscription($id: UUID!) {
  clientCard (data:{
    clientId: $id
  }) {
    zip
    countyName
    gender
    birthDate
    tobacco
    email
    firstName
    lastName
  }
}
`;

export const isEffectiveDateValid = (date?: string | null): boolean => {
  if (!date) {
    return false
  }

  return moment(date).isValid()
};

const getEnrollmentTypeTitle = (type: MedigapEnrollmentType): string => ({
  [MedigapEnrollmentType.MgOep]: 'MG - OEP',
  [MedigapEnrollmentType.MgUnderwritten]: 'MG - Underwritten',
    [MedigapEnrollmentType.MgGi]: 'MG - GI',
}[type])

const getEnrollmentTypeSubTitle = (type: MedigapEnrollmentType): string => ({
  [MedigapEnrollmentType.MgGi]: 'Enrolling in Medigap during a Guaranteed Issue period (e.g., Birthday Rule (CA), continuous guaranteed States (NY, CT, MA) or other GI period)',
  [MedigapEnrollmentType.MgOep]: 'Enrolling in Medigap within first 6 months of obtaining Part B',
  [MedigapEnrollmentType.MgUnderwritten]: 'Enrolling in Medigap with OEP or GI eligiblity'
}[type])
