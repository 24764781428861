import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteClientsFromAnyGroupArgs} from "../../enrollment-types";

export default function useDeleteClientsFromAnyGroup(options?: MutationHookOptions<{deleteClientsFromAnyGroup: string}, MutationDeleteClientsFromAnyGroupArgs>) {
  return useMutation<{deleteClientsFromAnyGroup: string}, MutationDeleteClientsFromAnyGroupArgs>(gql(mutation), options)
}

const mutation = `
mutation ($clientIds: [UUID!]!) {
  deleteClientsFromAnyGroup(clientIds: $clientIds)
}
`;

