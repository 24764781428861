import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {MutationSaveCategoryOrderArgs} from "../../content-management";

export default function useSaveCategoryOrder(options?: MutationHookOptions<any, MutationSaveCategoryOrderArgs>) {
  return useMutation<any, MutationSaveCategoryOrderArgs>(gql(saveVideoMutation), {
    ...options,
    client
  })
}

const saveVideoMutation = `
mutation ($ids: [UUID!]!) {
  saveCategoryOrder(ids: $ids)
}
`;
