import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  QueryShortenedCostActivationLinkArgs
} from "../../enrollment-types";

export default function useShortenedCostActivationLink(options?: LazyQueryHookOptions<{shortenedCostActivationLink: string}, QueryShortenedCostActivationLinkArgs>) {
  return useLazyQuery<{shortenedCostActivationLink: string}, QueryShortenedCostActivationLinkArgs>(gql(query), {...options})
}

const query = `
query($clientId: UUID!) {
  shortenedCostActivationLink(clientId: $clientId)
}
`;
