import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import {MutationPatchClientPersonalInfoArgs} from "../../../enrollment-types";

export function usePatchClientPersonalInfo(options?: MutationHookOptions<{patchClientPersonalInfo: boolean}, MutationPatchClientPersonalInfoArgs>) {
  return useMutation<{patchClientPersonalInfo: boolean}, MutationPatchClientPersonalInfoArgs>(
    gql(saveEnrollmentMutation),
    {
      ...options
    }
  );
}

const saveEnrollmentMutation = `
mutation ($clientId: UUID!, $preferredDrugs: [PreferredDrugInput!], $preferredPharmacies: [String!], $drugDeliveryType: ClientDrugDeliveryType) {
  patchClientPersonalInfo(clientId: $clientId, preferredDrugs: $preferredDrugs, preferredPharmacies: $preferredPharmacies, drugDeliveryType: $drugDeliveryType)
}
`;
