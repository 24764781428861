import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {uploadClient} from "../../Apollo/UploadApolloClient";

export function useUploadEnrollmentsImportCsv(options?: MutationHookOptions<{uploadEnrollmentsImportCsv: boolean}>) {
  return useMutation<{uploadEnrollmentsImportCsv: boolean}>(gql(mutation), {
    client: uploadClient,
    ...options
  })
}

const mutation = `
mutation ($csv: Upload!) {
  uploadEnrollmentsImportCsv(csv: $csv)
}
`;
