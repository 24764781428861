import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageablePdpEnrollmentOutput,
  QueryPdpEnrollmentsArgs
} from "../../enrollment-types";

export default function usePdpEnrollments(onCompleted?: (data: {pdpEnrollments: PageablePdpEnrollmentOutput}) => void) {
  return useLazyQuery<{pdpEnrollments: PageablePdpEnrollmentOutput}, QueryPdpEnrollmentsArgs>(
    gql(request),
    {
      onCompleted,
      fetchPolicy: 'no-cache',
    }
  );
}

const request = `
query ($page: PageInput!, $filter: PdpFilterInput){
  pdpEnrollments(page: $page, filter: $filter) {
    totalElements
    data {
      approvedAt
      birthDate
      cId
      clientId
      county
      firstName
      id
      email
      insulinSavings
      lastName
      pdpQuoteId
      phoneNumber
      planId
      started
      status
      zip
    }
  }
}
`;
