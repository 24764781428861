import React from "react";
import {DataGrid, GridColDef, GridSortDirection, GridValueGetterParams} from "@mui/x-data-grid";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import moment from "moment";
import {Box, Button, Chip, Typography} from "@material-ui/core";
import * as _ from 'lodash';
import {gql} from "graphql-tag";
import paths from "../router-paths";
import {
  AssignedTagViewOutput,
  CallHistoryOutput,
  CallHistoryResultOutput,
  ClientViewSortInput, LeadSourceOutput
} from "../enrollment-types";
import {client} from "../Apollo/EnrollmentApolloClient";
import useClientViews from "../Client/hooks/useClientViews";
import {colors} from "../AppTheme";
import {formatPhoneLink} from "../PlanList/utils";
import CheckIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {getAgentDisplayName} from "../Agent/AgentSelector";
import StatusView from "../Client/components/StatusView";
import {getTagLabel} from "../Client/widgets/ClientTags";
import ClearIcon from '@material-ui/icons/Clear';
import useSessionStorageState from "../shared/useSessionStorageState";
import useTags from "../Tags/hooks/useTags";
import Card from "../shared/Card";


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false,
    renderCell: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown user';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
    }
  },
  { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{params.row.email}</Link>
    }
  },
  { field: 'phoneNumber', headerName: 'Phone', width: 140, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phoneNumber) {
        return formatPhoneLink(params.row.phoneNumber, true)
      }
      return ''
    }
  },
  { field: 'birthDate', headerName: 'BirthDate', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Quote submitted timestamp', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'userId', headerName: 'Auth', width: 70, sortable: false, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.userId ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'agent', headerName: 'Agent responsible', width: 150, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const agent = params.row.agent;
      if (agent) {
        return getAgentDisplayName(agent)
      } else {
        return '-'
      }
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'calls', headerName: 'Calls', width: 70, sortable: false, disableColumnMenu: true, },
  { field: 'mostRecentCall', headerName: 'Most Recent Call', width: 200, sortable: false, disableColumnMenu: true, },
  { field: 'followUpDate', headerName: 'Follow up date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'expectedCloseDate', headerName: 'Expected close date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.expectedCloseDate) {
        return moment(params.row.expectedCloseDate).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
  { field: 'leadSources', headerName: 'Lead Sources', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      const sources = params.row.leadSources?.map((value: LeadSourceOutput) => value.source) || [];
      return <Typography color={'textPrimary'}>{_.uniq(sources).join(', ')}</Typography>
    }
  },
  { field: 'profileSource', headerName: 'Profile Source', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <Typography color={'textPrimary'}>{ _.startCase(_.lowerCase(params.row.profileSource))}</Typography>
    }
  },
  { field: 'adviserName', headerName: 'Advisor', width: 200, disableColumnMenu: true},
];

const MissingApps = () => {
  const tagsData = useTags();
  const [page, setPage] = usePage();
  const [pageSize, setPageSize] = usePageSize();
  const [sorting, setSorting] = useSorting();
  const [searchRequest, {loading, data, refetch}] = useClientViews();
  let history = useHistory();
  const [rows, setRows] = React.useState<any[]>([]);


  React.useEffect(() => {
    if (tagsData.data) {
      search(tagsData.data.tags.filter(tag => tag.name === 'Missed Appt –CSR Follow Up').map(tag => tag.id))
    }
  }, [tagsData.data])

  const search = async (tags: string[]) => {
    setRows([]);
    const clients = await searchRequest({
      variables: {
        filterInput: {
          tags,
        },
        pageInput: {
          page: page,
          size: pageSize
        },
        sort: sorting && {
          field: sorting.field,
          direction: sorting.sort
        } as ClientViewSortInput
      }
    }).then(res => res.data?.clientViews.data || []);
    const query = `
      query {
        ${clients.map((c, i) => 'c' + i + `: callHistoryItems(clientId: "${c.id}", page: {page: 0, size: 50}) {
          data {
            result
            createdAt
          }
          totalElements
        }`).join(' ')}
      }
    `;

    const calls = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});
    setRows(
      clients.map((c, i) => {
        const mostRecentCall = _.last(_.orderBy(calls['c' + i].data, c => moment(c.createdAt).unix()))?.createdAt;
        return {
          ...c,
          calls: calls['c' + i].data?.filter((c: CallHistoryOutput) => c.result === CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length + '/' + calls['c' + i].totalElements,
          mostRecentCall: mostRecentCall ? moment(mostRecentCall).format('L H:mm:ss') : ''
        }
      }))
  }

  const total = React.useMemo(() => {
    return data?.clientViews.totalElements ? parseFloat(data?.clientViews.totalElements) : 0;
  }, [data]);

  const getRowClassName = (followUpDate: string, lastCall: string, tags?: AssignedTagViewOutput[]) => {
    const matches = /[0-9]+d/g.exec(lastCall);
    if (matches?.length) {
      if (tags?.map(v => v.tag.name).includes('C1')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 3) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C2')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 5) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C3')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 10) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C4')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 14) {
          return 'warning-data-grid-row';
        }
      }
    }
    if (moment(followUpDate).isBefore(moment())) {
      return 'deactivated-data-grid-row';
    }
    return '';
  }

  return <Card padding={0}>
    <Box p={3} display={'flex'} alignItems={'center'}>
      <Typography color={'textPrimary'} variant={'h4'}>Missed Apps - CSR Follow Up</Typography>
    </Box>
    <div style={{ height: (52 * rows.length + 150) + 'px', minHeight: 200, width: '100%' }}>
        <DataGrid rows={rows}
                  loading={loading || tagsData.loading}
                  columns={columns}
                  paginationMode={"server"}
                  disableSelectionOnClick
                  page={page}
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  getRowClassName={params => getRowClassName(String(params.getValue(params.id, 'followUpDate')), params.row.lastCall, params.row.tags)}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  onRowClick={(param, event) => {
                    history.push(paths.client + '/' + param.row.id)
                  }}
                  rowCount={total}
                  pagination
        />
    </div>
  </Card>
}

export default MissingApps;

const usePage = () => {
  return useSessionStorageState<number>(
    'missingAppsPage',
    0
  )
}

const usePageSize = () => {
  return useSessionStorageState<number>(
    'missingAppsPageSize',
    20
  )
}

const useSorting = () => {
  return useSessionStorageState<{field: string, sort: GridSortDirection} | undefined>(
    'missingAppsSorting',
    {field: 'createdAt', sort: "desc"}
  )
}
