import React from "react";
import {
  Box, Divider,
  IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
  Typography
} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import Card from "../shared/Card";
import paths from "../router-paths";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from "react-router";
import useVideoCategories from "./hooks/useVideoCategories";
import ClearIcon from '@material-ui/icons/Clear';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../Apollo/ApolloClient";
import {Container, Draggable, DropResult} from "react-smooth-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import * as _ from "lodash";
import useSaveCategoryOrder from "./hooks/useSaveCategoryOrder";
import {useSnackbar} from "notistack";
import EditIcon from '@material-ui/icons/Edit';
import {VideoCategoryWithVideos} from "../content-management";

const VideoCategories = () => {
  let history = useHistory();
  const {data, refetch, loading} = useVideoCategories();
  const [saveOrder, saveOrderData] = useSaveCategoryOrder();
  const [rows, setRows] = React.useState<VideoCategoryWithVideos[]>([]);
  const {enqueueSnackbar} = useSnackbar();

  const [deleteRow, deleteData] = useMutation(gql(deleteRowMutation), {
    onCompleted: () => refetch(),
    client
  });

  React.useEffect(() => {
    if (data?.videoCategories) {
      setRows(_.sortBy(data?.videoCategories, 'sortingOrder'))
    }
  }, [data]);

  const onDrop = React.useCallback(({ removedIndex, addedIndex }: DropResult) => {
    const result = arrayMove(rows, removedIndex as number, addedIndex as number)
    saveOrder({variables: {ids: result.map(i => i.id)}});
    setRows(result);
  }, [rows]);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={false} />

      <Card padding={0}>
        <Box display={'flex'} p={'30px'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Typography color={'textPrimary'} variant={'h4'}>Video category list</Typography>
          <IconButton component="span" onClick={() => history.push(paths.videoCategory)}>
            <AddIcon />
          </IconButton>
        </Box>
        <List>
          <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {rows.map(({ id, title }, i, items) => (
              <Draggable key={id}>
                <ListItem button onClick={() => history.push(paths.videos + '/' + id)}>
                  <ListItemText primary={title} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemIcon onClick={() => history.push(paths.videoCategory + '/' + id)}>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        deleteRow({variables: {categoryId: id}})
                          .catch(() => enqueueSnackbar('Looks like you have videos in this category, delete or replace them!', {variant: "error"}))
                      }}>
                        <ClearIcon style={{color: 'red'}}  />
                      </IconButton>
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
                {i < items.length - 1 && <Divider />}
              </Draggable>
            ))}
          </Container>
        </List>
      </Card>
    </Box>
  </Box>;
}

export default VideoCategories;

const deleteRowMutation = `
  mutation ($categoryId: UUID!) {
    deleteVideoCategory(categoryId: $categoryId)
  }
`
