import {  useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import {gql} from "@apollo/client";
import {client} from "../../../Apollo/ApolloClient";
import {DrugInfoWithGeneric, QueryDrugsArgs} from "../../../types";

export function useDrugs(options?: LazyQueryHookOptions<IPrescriptionsResponse, QueryDrugsArgs>) {
  return useLazyQuery<IPrescriptionsResponse, QueryDrugsArgs>(gql(prescriptionsRequest), {
    client,
  })
}

interface IPrescriptionsResponse {
  drugs: DrugInfoWithGeneric[]
}

const prescriptionsRequest = `
query ($name: String!) {
  drugs(name: $name) {
    genericName
    genericRxcui
    isGeneric
    name
    rxcui
  }
}`;
