import {Box} from "@material-ui/core";
import Preloader from "../shared/Preloader";
import Card from "../shared/Card";
import React from "react";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import paths from "../router-paths";
import {useHistory} from "react-router";
import useUrgentCallRequests from "./hooks/useUrgentCallRequests";
import * as _ from 'lodash';


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      if (!firstName && !lastName) {
        return 'Unknown user';
      } else {
        return firstName + ' ' + lastName || '';
      }
    }
  },
  { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true,},
  { field: 'urgentCallType', headerName: 'Call Type', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => _.upperFirst(_.lowerCase(params.row.urgentCallType))
  },
];


export default function UrgentCalls() {
  let history = useHistory();
  const {data, loading} = useUrgentCallRequests()


  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={loading} />

      <Card padding={0}>
        <div style={{ height: 'calc(100vh - 250px)', width: '100%' }}>
          <DataGrid rows={data?.urgentCallRequests?.map(r => ({...r, id: r.clientId})) || []}
                    paginationMode={"client"}
                    sortingMode={'client'}
                    columns={columns}
                    page={0}
                    onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                    sortModel={[]}
                    rowCount={data?.urgentCallRequests?.length || 0}
          />
        </div>
      </Card>

    </Box>
  </Box>
}