import React from "react";
import Card from "../../shared/Card";
import {
  Box,
  Chip,
  Typography
} from "@material-ui/core";
import {ClientViewOutput} from "../../enrollment-types";
import * as _ from "lodash";
import ChipWrapper from "../../shared/ChipWrapper";

interface UserPrioritiesProps {
  client?: ClientViewOutput,
}

const UserPriorities = ({client}: UserPrioritiesProps) => {
  return <Card className={'fill-width'}>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-15'}>User priorities</Typography>
    </Box>
    <ChipWrapper title={'Top priority'}>
      {client?.filters?.extraBenefits.map(b => <Chip key={b} size="small" label={_.upperFirst(b.toLowerCase())}/>)}
    </ChipWrapper>
  </Card>;
}

export default UserPriorities;
