import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {QueryCompareOfferArgs, CompareOffer} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useCompareOffer(options?: LazyQueryHookOptions<{compareOffer: CompareOffer}, QueryCompareOfferArgs>) {
  return useLazyQuery<{compareOffer: CompareOffer}, QueryCompareOfferArgs>(gql(query), {...options, client, fetchPolicy: 'no-cache'})
}

const query = `
query($clientId: UUID) {
  compareOffer(clientId: $clientId) {
    clientId
    id
    plans {
      bidId
      county
      type
      zip
      planYear
    }
  }
}
`;
