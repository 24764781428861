import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Card from "../../shared/Card";
import {Box, Button, Chip, Divider, IconButton, MenuItem, Select, Typography} from "@material-ui/core";
import {ClientViewOutput, CoverRightProductDto, PurchasedProductOutput, TagViewOutput} from "../../enrollment-types";
import * as _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import FormSelect from "../../shared/FormSelect";
import useSavePurchasedProduct from "../hooks/useSavePurchasedProduct";
import * as yup from "yup";
import {useFormik} from "formik";
import CloseIcon from '@material-ui/icons/Close';
import TextInput from "../../shared/TextInput";
import useDeletePurchasedProduct from "../hooks/useDeletePurchasedProduct";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

interface ProductCounterProps {
  client?: ClientViewOutput
}

const ProductCounter = (props: ProductCounterProps) => {
  const classes = useStyles();
  const [saveProduct] = useSavePurchasedProduct()
  const [showForm, setShowForm] = React.useState(false);

  const primaryProducts = React.useMemo(() => {
      return props.client?.purchasedProducts.filter(p => p.isPrimaryPerson);
  }, [props.client?.purchasedProducts]);

  const enrollees = React.useMemo(() => {
      return _.groupBy(props.client?.purchasedProducts.filter(p => !p.isPrimaryPerson), v => v.firstName + ' ' + v.lastName);
  }, [props.client?.purchasedProducts]);

  const onAddProduct = (type: CoverRightProductDto, isPrimary: boolean, firstName?: string, lastName?: string) => {
    saveProduct({
      variables: {
        input: {
          clientId: props.client?.id,
          quantity: 1,
          type,
          isPrimaryPerson: isPrimary,
          firstName,
          lastName
        }
      }
    })
  }

  const saveEnrollee = async (firstName: string, lastName: string, products: CoverRightProductDto[]) => {
    setShowForm(false);
    for (const type of products) {
      await saveProduct({
        variables: {
          input: {
            clientId: props.client?.id,
            quantity: 1,
            type,
            isPrimaryPerson: false,
            firstName,
            lastName
          }
        }
      })
    }
  }

  return <Card>
    <Box className={'flex flex-space-between'}>
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-15'}>Product counter</Typography>
      <Typography color={'textPrimary'} variant={'h5'} className={'mb-15'}>Total product count: <span className={'fs-15 bold'}>{props.client?.purchasedProducts?.length}</span></Typography>
    </Box>
    <Enrollee name={props.client?.firstName + ' ' + props.client?.lastName}
              products={primaryProducts}
              onAddProduct={type => onAddProduct(type, true)} />
    <Divider className={'mb-24'} />
    {Object.values(enrollees).map((enrollee: PurchasedProductOutput[]) => <Enrollee products={enrollee}
                                                       name={enrollee[0].firstName + ' ' + enrollee[0].lastName}
                                                       onAddProduct={type => onAddProduct(type, false, enrollee[0].firstName as string, enrollee[0].lastName as string)} />)}
    {showForm && <EnrolleeForm onSave={saveEnrollee} onCancel={() => setShowForm(false)} />}
    <div>
      <Button startIcon={<AddIcon />} onClick={() => setShowForm(true)}>Add Enrollee</Button>
    </div>
  </Card>;
}

export default ProductCounter;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
});

const EnrolleeForm = (props: {onCancel: () => void, onSave: (firstName: string, lastName: string, products: CoverRightProductDto[]) => void}) => {
  const [products, setProducts] = React.useState<CoverRightProductDto[]>([]);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      props.onSave(values.firstName, values.lastName, products);
    },
  });

  return <form onSubmit={formik.handleSubmit}>
    <TextInput
      fullWidth
      name="firstName"
      label="First Name"
      value={formik.values.firstName}
      onChange={formik.handleChange}
      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
      helperText={formik.touched.firstName && formik.errors.firstName}
    />
    <TextInput
      fullWidth
      name="lastName"
      label="Last Name"
      value={formik.values.lastName}
      onChange={formik.handleChange}
      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
      helperText={formik.touched.lastName && formik.errors.lastName}
    />
    {products.map((p, i) => <Product product={p} key={i} onDelete={() => setProducts(prev => _.without(prev, p))} />)}
    <Selector mode={'select'} onSave={type => setProducts(prev => [...prev, type])} />
    <div className={'mb-15 flex-space-between flex-align-center'}>
      <Button variant={'contained'} color={'primary'} type={'submit'} disabled={!products.length || !formik.isValid}>Save Enrollee</Button>
      <div className={'w-20'} />
      <Button variant={'contained'} color={'default'} onClick={props.onCancel}>Cancel</Button>
    </div>
  </form>
}

type EnrolleeProps = {
  products?: PurchasedProductOutput[],
  name: string,
  onAddProduct: (type: CoverRightProductDto) => void
}

const Enrollee = (props: EnrolleeProps) => {
  const [deleteProduct] = useDeletePurchasedProduct();
  return <div>
    <Typography color={'textPrimary'} variant={'h4'} className={'mb-15'}>{props.name} ({props.products?.length})</Typography>
    {props.products?.map(p => <Product product={p.type} key={p.id} onDelete={() => deleteProduct({variables: {purchasedProductId: p.id}})} />)}
    <Selector onSave={props.onAddProduct} />
  </div>
}

const Selector = (props: {mode?: 'button' | 'select', onSave: (type: CoverRightProductDto) => void}) => {
  const [value, setValue] = React.useState<CoverRightProductDto>();
  const [mode, setMode] = React.useState(props.mode || 'button');

  React.useEffect(() => {
    if (mode === 'select') {
      setValue(undefined)
    }
  }, [mode]);

  const onAddClick = React.useCallback(() => {
    if (value) {
      props.onSave(value)
    }
    setMode('button')
  }, [value]);

  return <>
    {mode === 'button' && <Button className={'mb-15'} startIcon={<AddIcon />} onClick={() => setMode('select')}>Add product</Button>}
    {mode === 'select' && <div className={'flex'}>
      <FormSelect label={'Product type'}
                  fullWidth
                  value={value}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setValue(event.target.value as any);
                  }}
      >
        <MenuItem value={CoverRightProductDto.Ma}>{typeToString(CoverRightProductDto.Ma)}</MenuItem>
        <MenuItem value={CoverRightProductDto.Mg}>{typeToString(CoverRightProductDto.Mg)}</MenuItem>
        <MenuItem value={CoverRightProductDto.Pdp}>{typeToString(CoverRightProductDto.Pdp)}</MenuItem>
      </FormSelect>
      <div className={'ml-15'}>
        <Button variant={'contained'} color={'primary'} onClick={onAddClick} disabled={!value}>Add</Button>
      </div>
    </div>}
  </>
}

const Product = ({product, onDelete}: {product: CoverRightProductDto, onDelete: () => void}) => {
  return <div className={'mb-15 flex flex-space-between flex-align-center'}>
    <Typography color={'textPrimary'} className={'fs-12 mr-10'}>{typeToString(product)}</Typography>
    <IconButton size={'small'} onClick={onDelete}>
      <CloseIcon fontSize={'small'} color={'error'} />
    </IconButton>
  </div>
}

const typeToString = (type: CoverRightProductDto): string => {
  switch (type) {
    case CoverRightProductDto.Ma: return 'Medicare Advantage';
    case CoverRightProductDto.Mg: return 'Medicare Supplement';
    case CoverRightProductDto.Pdp: return 'PDP';
  }
}
