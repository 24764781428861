import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationChangePdpEnrollmentStatusArgs} from "../../enrollment-types";

export default function useChangePdpEnrollmentStatus(options?: MutationHookOptions<{changePdpEnrollmentStatus: string}, MutationChangePdpEnrollmentStatusArgs>) {
  return useMutation<{changePdpEnrollmentStatus: string}, MutationChangePdpEnrollmentStatusArgs>(gql(save), options)
}

const save = `
mutation ($enrollmentId: UUID!, $status: PdpEnrollmentStatus!) {
  changePdpEnrollmentStatus(enrollmentId: $enrollmentId, status: $status)
}
`;

