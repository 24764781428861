import React from "react";
import {makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {useLazyQuery, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {Box, Button, Paper, Typography} from "@material-ui/core";
import {IRequest} from "./RequestList";
import {RouteComponentProps, useHistory} from "react-router";
import moment from "moment";
import * as _ from 'lodash';
import Pharmacy from "./Pharmacy";
import Doctor from "./Doctor";
import Drug from "./Drug";
import {MedicareQuoteOutput} from "../types";
import useGetDrugsRequest, {DrugInfoWithPack} from "./useGetDrugsRequest";
import {config} from "../config/config";
import useMedicareQuote from "../shared/hooks/useMedicareQuote";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& tr td:first-child': {
      textAlign: 'right',
      paddingRight: 10,
    }
  }
}));

export default function RequestDetails({ match: { params } }: RouteComponentProps<{id: string}>) {
  const classes = useStyles();
  const [request, setRequest] = React.useState<MedicareQuoteOutput>();
  let history = useHistory();
  const [getQuote] = useMedicareQuote();

  React.useEffect(() => {
    getQuote({
      variables: {
        id: params.id
      },
    }).then(res => setRequest(res.data?.medicareQuote))
  }, [])

  const filterFields = (k: string) => ['age', 'firstName', 'lastName', 'email', 'createdAt', 'birthDate', 'zip', 'pin'].indexOf(k) >= 0;

  const customAnswers: {label: string, value: string}[] = React.useMemo(() => {
    if (request?.customAnswers) {
      return Object.values(JSON.parse(request.customAnswers)).reduce((prev: any[], cur: any) => {
        const result = prev;
        result.push({label: cur.form, value: getValue('value', cur)})
        Object.keys(cur).filter(k => k !== 'value' && k !== 'form').forEach(k => result.push({label: cur.form, value: getValue(k, cur)}));
        return result;
      }, [] as any[])
    } else {
      return [];
    }
  }, [request])

  return (
    <Box display={'flex'} flex={1}>
      {request && <Paper>
        <Box p={2} mr={3} width={'300'} position={'relative'}>
          <Box mb={2} display={'flex'} flex={1} justifyContent={'space-between'}>
            <Typography variant={'h2'}>Request details:</Typography>
            <a target={'_blank'} href={config.resultsUrl + `plans/${request.zip}?quote=${request.id}`}>Go to request</a>
          </Box>
          <table className={classes.table}>
            <tbody>
            {Object.keys({age: '', ...request}).filter(filterFields)
              .map((key: string) => <Param key={key} label={key} value={getValue(key, request)} />)}
            {customAnswers
              .map((item, i) => <Param key={i} label={item.label} value={item.value} />)}
            </tbody>
          </table>
          <Box mt={2}>
            <ColorButton variant={'contained'} color={'primary'} onClick={() => history.goBack()}>Back</ColorButton>
          </Box>
        </Box>
      </Paper>}
      {request && <Box ml={3} width={'350px'}>
        <Box mb={1}>
          <Paper>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h2'}>Doctors:</Typography>
              </Box>
              {!!request.preferredDoctors?.length ?
                request.preferredDoctors.map(doctor => <Doctor key={doctor.npi} doctor={doctor}/>) :
                <div>Not selected</div>}
            </Box>
          </Paper>
        </Box>
        <Box mb={1}>
          <Paper>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h2'}>Drugs:</Typography>
              </Box>
              {!!request.preferredDrugs?.length ?
                request.preferredDrugs.map(preferredDrug => <Drug key={preferredDrug.rxcui}
                                                                  drug={preferredDrug}/>) :
                <div>Not selected</div>}
            </Box>
          </Paper>
        </Box>
        <Paper>
          <Box p={2}>
            <Box mb={2}>
              <Typography variant={'h2'}>Pharmacies:</Typography>
            </Box>
            {!!request.preferredPharmacies?.length ?
              request.preferredPharmacies.map(pharmacy => <Pharmacy key={pharmacy.npi} pharmacy={pharmacy}/>) :
              <div>Not selected</div>}
          </Box>
        </Paper>
      </Box>}
    </Box>
  )
}

const getValue = (key: string, request: any) => {
  if (key === 'createdAt') {
    return moment(request[key]).format('L H:mm:ss')
  }
  if (key === 'birthDate') {
    return request[key] ? moment(request[key]).format('L') : '-'
  }
  if (key === 'age') {
    const duration = moment.duration(moment().diff(moment(request['birthDate'])))
    return request['birthDate'] ? `${duration.get('year')}y ${duration.get('month')}m` : '-'
  }
  if (Array.isArray(request[key]) && request[key].length === 0) {
    return '-'
  }
  if (Array.isArray(request[key])) {
    return (request[key] as any).join(', ')
  }
  if (request[key] === null || typeof request[key] === 'undefined') {
    return '-'
  }
  if (_.isBoolean(request[key])) {
    return !!request[key] ? 'Yes' : 'No';
  }
  return request[key];
}


const Param = ({label, value}: {label: string, value: string}) => <tr><td><b>{label}:</b></td><td>{value}</td></tr>

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#164d7b',
    '&:hover': {
      backgroundColor: '#164d7b',
    },
  },
}))(Button);
