import React, {useCallback} from "react";
import Card from "../../shared/Card";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@material-ui/core";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import {GraphQLErrorType} from "../../Apollo/EnrollmentApolloClient";
import {ClientViewOutput, DisqualifiedReasonDto, StatusDto} from "../../enrollment-types";
import FormSelect from "../../shared/FormSelect";
import useSaveStatus from "../hooks/useSaveStatus";
import StatusView from "../components/StatusView";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {useHistory} from "react-router-dom";
import paths from "../../router-paths";
import useSaveDisqualifiedReason from "../hooks/useSaveDisqualifiedReason";
import _ from "lodash";
import AgentSelector, {getAgentDisplayName} from "../../Agent/AgentSelector";
import useSaveExpectedCloseDate from "../hooks/useSaveExpectedCloseDate";

interface StatusProps {
  client?: ClientViewOutput
}


const Status = ({client}: StatusProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [saveDisqualifiedReason] = useSaveDisqualifiedReason()

  const [save, data] = useSaveStatus({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Status saved!', {variant: "success"});
      setShowForm(false);
    }
  });

  const [saveExpectedCloseDate] = useSaveExpectedCloseDate();

  const submit = useCallback((status: StatusDto, agentId?: string, followUpDate?: string | null, expectedCloseDate?: string | null, disqualifiedReason?: DisqualifiedReasonDto) => {
    if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(status) ) {
      if (!!followUpDate) {
        const followUpDateStr = moment(followUpDate).format('YYYY-MM-DD');
        save({
          variables: {
            id: client?.id,
            status,
            followUpDate: followUpDateStr,
            agentId,
          }
        }).then(() => {
          if (!!expectedCloseDate) {
            const expectedCloseDateStr = moment(expectedCloseDate).format('YYYY-MM-DD');
            return saveExpectedCloseDate({
              variables: {
                id: client?.id,
                expectedCloseDate: expectedCloseDateStr,
              }
            })
          }
        })
      } else {
        return;
      }
    } else if (status === StatusDto.Disqualified && disqualifiedReason) {
      save({
        variables: {
          id: client?.id,
          status,
          agentId,
        }
      }).then(() => saveDisqualifiedReason({
        variables: {
          id: client?.id,
          disqualifiedReason,
        }
      }))
    } else {
      save({
        variables: {
          id: client?.id,
          status,
          agentId,
        }
      })
    }
  }, [client]);

  const cancel = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  return <Card>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Status / Agent</Typography>
      {!showForm && <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showForm && <div>
      {!showForm && <Typography variant={'body1'} color={'textPrimary'} className={'mb-8'}>{client?.agent ? getAgentDisplayName(client.agent) : 'No agent'}</Typography>}

      <div className={'flex'}>
        <StatusView status={client?.status || undefined} />
        {client?.originalClientId && <Typography  onClick={() => history.push(paths.client + '/' + client?.originalClientId)}
          className={'pointer fs-12 ml-20 underline'} color={'textPrimary'}>Original</Typography>}
      </div>
      <Typography color={"textPrimary"} variant={"body1"} className={"mt-5"}>Follow up date: {client?.followUpDate ? moment(client?.followUpDate).format('L') : 'N/A'}</Typography>
      <Typography color={"textPrimary"} variant={"body1"} className={"mt-5"}>Expected close date: {client?.expectedCloseDate ? moment(client?.expectedCloseDate).format('L') : 'N/A'}</Typography>
    </div>}
    {showForm && <StatusForm agentId={client?.agent?.id}
                             status={client?.status || ''}
                             expectedCloseDate={client?.expectedCloseDate}
                             followUpDate={client?.followUpDate}
                             loading={data.loading}
                             onCancel={cancel}
                             onSubmit={submit}/>}
    <Box sx={{mt: 1}}>
      {!!client?.scheduledTagPlacement && <Typography color={"textPrimary"} variant={"body2"}>Tag will be placed in the end of day.</Typography>}
      {(!!client?.disqualifiedReason && client?.status === StatusDto.Disqualified) && <Typography className={'mt-15'} color={"textPrimary"} variant={"body2"}>{toHuman(client?.disqualifiedReason)}</Typography>}
    </Box>
  </Card>
};

export default Status;

const toHuman = (val?: string) => _.upperFirst(_.lowerCase(val || ''))


interface StatusFormProps {
  status: string;
  agentId?: string;
  followUpDate?: string | null;
  expectedCloseDate?: string | null;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (status: StatusDto, agentId?: string, followUpDate?: string | null, expectedCloseDate?: string | null, disqualifiedReason?: DisqualifiedReasonDto) => void;
}

const StatusForm = (props: StatusFormProps) => {
  const [disqualifiedReason, setDisqualifiedReason] = React.useState<DisqualifiedReasonDto>();
  const [disqualifiedOpen, setDisqualifiedOpen] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      status: props.status,
      followUpDate: props.followUpDate,
      expectedCloseDate: props.expectedCloseDate,
      agentId: props.agentId
    },
    onSubmit: values => {
      let error = false;
      if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto)) {
        if (!values.followUpDate) {
          formik.setFieldError('followUpDate', 'Date is required')
          error = true;
        }

        if ([StatusDto.FollowUp, StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.agentId) {
          formik.setFieldError('agentId', 'Agent is required for this status')
          error = true;
        }

        if ([StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.expectedCloseDate) {
          formik.setFieldError('expectedCloseDate', 'Date is required')
          error = true;
        }
      }

      if (!error) {
        props.onSubmit(values.status as StatusDto, values.agentId, values.followUpDate, values.expectedCloseDate, StatusDto.Disqualified === values.status && disqualifiedReason ? disqualifiedReason : undefined)
      }
    }
  });

  return <form onSubmit={formik.handleSubmit} className={'mt-15'}>
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={disqualifiedOpen}
      onClose={() => {
        setDisqualifiedReason(undefined)
        setDisqualifiedOpen(false)
      }}
    >
      <DialogTitle>Select Disqualified Reason</DialogTitle>
      <DialogContent>
        <FormSelect label={'Select'}
                    value={disqualifiedReason}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setDisqualifiedReason(event.target.value as DisqualifiedReasonDto);
                    }}
        >
          <MenuItem value={DisqualifiedReasonDto.NoLongerInterestedInSwitching}>No Longer Interested In Switching</MenuItem>
          <MenuItem value={DisqualifiedReasonDto.UnableToConnect}> Unable To Connect</MenuItem>
          <MenuItem value={DisqualifiedReasonDto.WentToAnotherBroker}> Went To Another Broker</MenuItem>
        </FormSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setDisqualifiedReason(undefined)
          setDisqualifiedOpen(false)
        }} color="primary">
          Cancel
        </Button>
        <Box color={'red'}>
          <Button onClick={() => {
            formik.setFieldValue("status", StatusDto.Disqualified as string);
            setDisqualifiedOpen(false)
          }} color="inherit">
            ok
          </Button>
        </Box>
      </DialogActions>
    </Dialog>

    <AgentSelector
      value={formik.values.agentId}
      onChange={(agentId) => {
        formik.setFieldValue("agentId", agentId);
      }}
      error={formik.touched.agentId && Boolean(formik.errors.agentId)}
      helperText={formik.touched.agentId ? formik.errors.agentId : undefined}/>

    <FormSelect label={'Status'}
                value={formik.values.status}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  if (event.target.value as StatusDto === StatusDto.Disqualified) {
                    setDisqualifiedReason(DisqualifiedReasonDto.NoLongerInterestedInSwitching)
                    setDisqualifiedOpen(true)
                  } else {
                    formik.setFieldValue("status", event.target.value as string);
                  }
                }}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status ? formik.errors.status : undefined}
    >
      <MenuItem value={StatusDto.NotYetEngaged}> <StatusView status={StatusDto.NotYetEngaged} /></MenuItem>
      <MenuItem value={StatusDto.Attempted}> <StatusView status={StatusDto.Attempted} /></MenuItem>
      <MenuItem value={StatusDto.Contacted}> <StatusView status={StatusDto.Contacted} /></MenuItem>
      <MenuItem value={StatusDto.FollowUp}> <StatusView status={StatusDto.FollowUp} /></MenuItem>
      <MenuItem value={StatusDto.SalesQualified}> <StatusView status={StatusDto.SalesQualified} /></MenuItem>
      <MenuItem value={StatusDto.ApplicationSubmitted}> <StatusView status={StatusDto.ApplicationSubmitted} /></MenuItem>
      <MenuItem value={StatusDto.Disenrolled}> <StatusView status={StatusDto.Disenrolled} /></MenuItem>
      <MenuItem value={StatusDto.Disqualified}> <StatusView status={StatusDto.Disqualified} /></MenuItem>
      <MenuItem value={StatusDto.Test}> <StatusView status={StatusDto.Test} /></MenuItem>
      <MenuItem value={StatusDto.Duplicated}> <StatusView status={StatusDto.Duplicated} /></MenuItem>
      <MenuItem value={StatusDto.OutOfService}> <StatusView status={StatusDto.OutOfService} /></MenuItem>
    </FormSelect>
    {
      [StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto) && <div className={'mb-15'}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="MM/dd/yyyy"
            label={'Follow Up Date'}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.followUpDate && Boolean(formik.errors.followUpDate)}
            helperText={formik.touched.followUpDate && formik.errors.followUpDate}
            value={formik.values.followUpDate}
            onChange={value => formik.setFieldValue("followUpDate", value)}
            invalidDateMessage={'Please enter a valid date'}
          />
        </MuiPickersUtilsProvider>
      </div>
    }
    {
      [StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && <div className={'mb-15'}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="MM/dd/yyyy"
            label={'Expected Close Date'}
            placeholder={'MM / DD / YYYY'}
            error={formik.touched.expectedCloseDate && Boolean(formik.errors.expectedCloseDate)}
            helperText={formik.touched.expectedCloseDate && formik.errors.expectedCloseDate}
            value={formik.values.expectedCloseDate || null}
            onChange={value => formik.setFieldValue("expectedCloseDate", value)}
            invalidDateMessage={'Please enter a valid date'}
          />
        </MuiPickersUtilsProvider>
      </div>
    }
    <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Grid>
    </Grid>
  </form>
};
