import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveCallHistoryArgs} from "../../enrollment-types";

export default function useSaveCallHistory(options?: MutationHookOptions<{saveCallHistory: string}, MutationSaveCallHistoryArgs>) {
  return useMutation<{saveCallHistory: string}, MutationSaveCallHistoryArgs>(gql(saveNoteMutation), options)
}

const saveNoteMutation = `
mutation ($input: CallHistoryInput!) {
  saveCallHistory(input: $input)
}
`;
