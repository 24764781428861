import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {MutationSaveVideoOrderArgs} from "../../content-management";

export default function useSaveVideoOrder(options?: MutationHookOptions<any, MutationSaveVideoOrderArgs>) {
  return useMutation<any, MutationSaveVideoOrderArgs>(gql(saveVideoMutation), {
    ...options,
    client
  })
}

const saveVideoMutation = `
mutation ($ids: [UUID!]!) {
  saveVideoOrder(ids: $ids)
}
`;
