import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableClientViewOutput,
  QueryClientViewsArgs
} from "../../enrollment-types";

export default function useClientViews(options?: LazyQueryHookOptions<{clientViews: PageableClientViewOutput}, QueryClientViewsArgs>) {
  return useLazyQuery<{clientViews: PageableClientViewOutput}, QueryClientViewsArgs>(gql(clientsQuery), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const clientsQuery = `
  query ($pageInput: PageInput!, $filterInput: ClientViewFilterInput, $sort: ClientViewSortInput) {
    clientViews(pageInput: $pageInput, filterInput: $filterInput, sort: $sort) {
      totalPages
      totalElements
      data {
    id
    agentMedicareQuote {
      quoteId
      zip
      countyName
    }
    agentMedigapQuote {
      id
    }
    clientFiles {
      link
      name
    }
    affiliationPartnershipSource
    enrolled
    adviserName
    adviserEmail
    profileSource
    customAnswers
    countyName
    email
    expectedCloseDate
    notes {
      clientId
      createdAt
      createdByAgent {
        email
        firstName
        id
        lastName
      }
      id
      note
      updatedAt
    }
    firstName
    lastName
    phoneNumber
    userId
    pin
    gender
    profileId
    quoteId
    status
    birthDate
    zip
    cId
    state
    followUpDate
    originalClientId
    pdpEnrollment {
      birthDate
      cId
      clientId
      createdAt
      firstName
      id
      insulinSavings
      lastName
      pdpQuoteId
      phoneNumber
      planId
      updatedAt
      userId
    }
    leadSources {
      source
    }
    filters {
      extraBenefits
    }
    pdpQuote {
      cId
      clientId
      countyName
      createdAt
      id
      insulinSavings
      updatedAt
      userId
      zip
    }
    medigapQuote {
      cId
      createdAt
      id
      medigapFilterState {
        age
        benefits
        companies
        county
        effectiveDate
        gender
        id
        monthlyPlanPremiumRanges
        planCategory
        planNames
        tobacco
        zip
      }
      updatedAt
      userId
    }
    medigapEnrollment {
      age
      approvedAt
      birthDate
      createdAt
      effectiveDate
      email
      firstName
      gender
      id
      lastName
      pdpBidId
      naic
      phoneNumber
      planName
      quoteId
      status
      tobacco
      userId
      medicareNumber
      preferredLanguage
      medicalDate
      hospitalDate
      approvedAt
      accessibleFormatPreference
      permanentAddress {
        aptNumber
        city
        county
        state
        streetName
        zip
      }
      mailingAddress {
        aptNumber
        city
        county
        state
        streetName
        zip
      }
    }
    scopeOfAppointmentStatus
    createdAt
    tags {
      expiresAt
      tag {
        color
        id
        name
      }
    }
    doctors {
      addresses {
        addressLine1
        addressLine2
        id
        isPcp
        specialty
      }
      pcpDetails {
        id
        networkDetails {
          carrierName
          name
        }
        pcpId
      }
      addressesNumber
      name
      npi
    }
    preferredDrugs {
      dosageRxcui
      frequency
      productRxcui
      purchaseFrequency
      quantity
    }
    agent {
      email
      firstName
      lastName
      id
    }
    purchasedProducts {
      firstName
      id
      isPrimaryPerson
      lastName
      quantity
      type
    }
          }
    }
  }
`
