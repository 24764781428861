import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './shared/helpers.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Preloader from "./shared/Preloader";
import {Box, MuiThemeProvider} from "@material-ui/core";
import AppTheme from "./AppTheme";
import { SnackbarProvider } from 'notistack';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <MuiThemeProvider theme={AppTheme}>
    <SnackbarProvider preventDuplicate classes={{
      variantSuccess: 'success-snack'
    }} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} autoHideDuration={6000} maxSnack={3}>
      <Suspense fallback={<Preloader />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </SnackbarProvider>
  </MuiThemeProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

