import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableClientViewOutput,
  QuerySalesQualifiedArgs
} from "../../enrollment-types";

export default function useSalesQualified(options?: LazyQueryHookOptions<{salesQualified: PageableClientViewOutput}, QuerySalesQualifiedArgs>) {
  return useLazyQuery<{salesQualified: PageableClientViewOutput}, QuerySalesQualifiedArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
  query ($agentId: UUID!, $pageInput: PageInput!) {
    salesQualified(agentId: $agentId, pageInput: $pageInput) {
      data {
        id
        firstName
        createdAt
        notes {
          note
          id
          createdAt
        }
        lastName
        phoneNumber
        state
        followUpDate
        expectedCloseDate
        tags {
          expiresAt
          tag {
            color
            id
            name
          }
        }
      }
      totalElements
    }
  }
`
