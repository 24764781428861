import React from "react";
import useAgentPerformanceAnalytics from "../Agent/hooks/useAgentPerformanceAnalytics";
import useAgentByContext from "../Agent/hooks/useAgentByContext";
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import {Box, CircularProgress, Grid} from "@material-ui/core";
import AgentSelector from "../Agent/AgentSelector";
import {AgentOutput} from "../enrollment-types";
import CSRDashboard from "../CSR/CSRDashboard";
import AgentDashboard from "../Agent/AgentDashboard";

const Dashboard = () => {
  const [getAnalytics, {data: requestData, loading: analyticsLoading, called: analyticsCalled}] = useAgentPerformanceAnalytics();
  const [agent, setAgent] = React.useState<AgentOutput>();
  const [getAgent, {loading: agentLoading}] = useAgentByContext({
    onCompleted: res => setAgent(res.agentByContext),
  });

  const isAgentManager = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER)
  }, [keycloak])

  React.useEffect(() => {
    getAgent()
  }, []);

  React.useEffect(() => {
    if (agent) {
      getAnalytics({variables: {agentId: agent.id}})
      // getAnalytics({variables: {agentId: '178d4937-734c-40e4-aa5b-635a9afaef2c'}})
    }
  }, [agent]);

  const isCSR = React.useMemo(() => {
    return !!agent?.isCSR;
  }, [agent]);

  const data = React.useMemo(() => {
    return requestData?.agentPerformanceAnalytics;
  }, [requestData]);
  
  const loading = React.useMemo(() => {
    return analyticsCalled ? analyticsLoading || agentLoading : true;
  }, [analyticsLoading, analyticsCalled, agentLoading]);

  return <Grid className={'mt-10'} container spacing={2} direction={'column'}>
    {isAgentManager && <Grid container spacing={2}>
      <Grid item xs={3}>
        <AgentSelector onChange={() => {}} onChangeAgent={v => setAgent(v as AgentOutput)} value={agent?.id} />
      </Grid>
    </Grid>}
    <Grid item xs={12}>
      {loading && <Box width={'100&'} height={'200px'} >
        <CircularProgress/>
      </Box>}
      {!loading && <>
        {isCSR && <CSRDashboard data={data || []} />}
        {!isCSR && <AgentDashboard data={data || []} agentId={agent?.id} />}
      </>}
    </Grid>
  </Grid>
}

export default Dashboard;
