import React from "react";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import moment from "moment";
import {colors} from "../../AppTheme";
import {formatPhoneLink} from "../../PlanList/utils";
import useFollowUps from "../hooks/useFollowUps";
import {AssignedTagViewOutput} from "../../enrollment-types";
import paths from "../../router-paths";
import useFollowUpsPage from "../hooks/useFollowUpsPage";
import useFollowUpsPageSize from "../hooks/useFollowUpsPageSize";
import {Chip} from "@material-ui/core";
import * as _ from 'lodash';
import {client} from "../../Apollo/EnrollmentApolloClient";
import {gql} from "graphql-tag";
import {calcDurationToNow} from "../../Layout";
import {config} from "../../config/config";

const columns: GridColDef[] = [
  { field: 'createdAt', headerName: 'Lead date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtDateStr = params.row.createdAt;
      if (createdAtDateStr) {
        return moment(createdAtDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false,
    renderCell: (params: GridValueGetterParams) => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown user';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
    }
  },
  { field: 'phoneNumber', headerName: 'Number', width: 140, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phoneNumber) {
        return formatPhoneLink(params.row.phoneNumber, true)
      }
      return ''
    }
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'lastCall', headerName: 'Last Call', width: 150, disableColumnMenu: true, sortable: false},
  { field: 'followUpDate', headerName: 'Follow up date', width: 100, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'expectedCloseDate', headerName: 'Exp. close date', width: 100, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const expectedCloseDateStr = params.row.expectedCloseDate;
      if (expectedCloseDateStr) {
        return moment(expectedCloseDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={value.tag.name}  />
        ))}
      </div>
    }
  },
  { field: 'notes', headerName: 'Notes', width: 600, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const notes = _.orderBy(params.row.notes || [], 'createdAt');
      return notes[notes.length - 1]?.note || ''
    }
  },
];

const FollowUps = (props: {agentId?: string}) => {
  const [page, setPage] = useFollowUpsPage();
  const [pageSize, setPageSize] = useFollowUpsPageSize();
  const [getFollowUps, {loading, data}] = useFollowUps();
  let history = useHistory();
  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.agentId) {
      getFollowUps({variables: {
          agentId: props.agentId,
          excludedTagsIds: [config.missingAppsTagId],
          pageInput: {
            page: page,
            size: pageSize
          },
        }
      })
    }
  }, [props.agentId, pageSize, page]);

  React.useEffect(() => {
    if (data?.followUps.data) {
      (async function () {
        const followUps = data?.followUps.data;
        const query = `
          query {
            ${followUps.map((f, i) => 'c' + i + `: callHistoryItems(clientId: "${f.id}", page: {page: 0, size: 50}) {
              data {
                createdAt
              }
              totalElements
            }`).join(' ')}
          }
        `;
        const calls = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});

        setRows(data?.followUps.data.map((f, i) => ({...f, lastCall: calcDurationToNow(_.orderBy(calls['c' + i].data, (v: any) => moment(v.createdAt).toDate().getTime(), 'desc')[0]?.createdAt)})))
      })()
    }
  }, [data])

  const total = React.useMemo(() => {
    return data?.followUps.totalElements ? parseFloat(data?.followUps.totalElements) : 0;
  }, [data]);

  const getRowClassName = (followUpDate: string, lastCall: string, tags?: AssignedTagViewOutput[]) => {
    const matches = /[0-9]+d/g.exec(lastCall);
    if (matches?.length) {
      if (tags?.map(v => v.tag.name).includes('C1')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 3) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C2')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 5) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C3')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 10) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C4')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 14) {
          return 'warning-data-grid-row';
        }
      }
    }
    if (moment(followUpDate).isBefore(moment())) {
      return 'deactivated-data-grid-row';
    }
    return '';
  }

  return <div style={{ height: (52 * rows.length + 150) + 'px', minHeight: 200, width: '100%' }}>
        <DataGrid rows={rows}
                  loading={loading}
                  columns={columns}
                  disableSelectionOnClick
                  paginationMode={"server"}
                  page={page}
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  getRowClassName={params => getRowClassName(String(params.getValue(params.id, 'followUpDate')), params.row.lastCall, params.row.tags)}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  onRowClick={(param, event) => {
                    history.push(paths.client + '/' + param.row.id)
                  }}
                  rowCount={total}
                  pagination
        />
    </div>
}

export default FollowUps;
