import React, {useEffect} from "react";
import {useFormik} from "formik";
import {Button, Grid, MenuItem, Typography} from "@material-ui/core";
import TextInput from "../shared/TextInput";
import * as yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useSaveVideo from "./hooks/useSaveVideo";
import paths from "../router-paths";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useVideo from "./hooks/useVideo";
import * as QueryString from "querystring";
import Card from "../shared/Card";
import useVideoCategories from "./hooks/useVideoCategories";
import FormSelect from "../shared/FormSelect";
import {Video} from "../content-management";

interface VideoFormProps {
  initial?: Video,
  onSubmit: (data: Video) => void,
  onCancel: () => void,
}

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
  url: yup
    .string()
    .required('Url is required'),
  description: yup
    .string()
    .required('Description is required'),
  duration: yup
    .string()
    .required('Duration is required'),
});




const VideoForm = (props: VideoFormProps) => {

  const [initial, setInitial] = React.useState();
  const {data, refetch, loading} = useVideoCategories();

  const formik = useFormik({
    initialValues: {
      title: props.initial?.title || '',
      url: props.initial?.url || '',
      description: props.initial?.description || '',
      duration: props.initial?.duration || '',
      categoryId: props.initial?.categoryId || '',
      id: props.initial?.id || '',
    },
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
    enableReinitialize: true
  });

  return <form onSubmit={formik.handleSubmit} className={'mt-15'}>
    <Grid container spacing={1} direction={'row'}>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="duration"
          label="Duration"
          value={formik.values.duration}
          onChange={formik.handleChange}
          error={formik.touched.duration && Boolean(formik.errors.duration)}
          helperText={formik.touched.duration && formik.errors.duration}
        />
      </Grid>
    </Grid>
    <TextInput
      fullWidth
      name="url"
      label="Url"
      value={formik.values.url}
      onChange={formik.handleChange}
      error={formik.touched.url && Boolean(formik.errors.url)}
      helperText={formik.touched.url && formik.errors.url}
    />
    {props.initial?.categoryId && <FormSelect label={'Category'}
                value={formik.values.categoryId}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  formik.setFieldValue("categoryId", event.target.value as string);
                }}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                helperText={formik.touched.categoryId ? formik.errors.categoryId as string : undefined}
    >
      {data?.videoCategories?.map(c => <MenuItem value={c.id}>{c.title}</MenuItem>)}
    </FormSelect>}
    <CKEditor
      editor={ ClassicEditor }
      data={formik.values.description}
      onReady={ (editor: any) => {
        // You can store the "editor" and use when it is needed.
        console.log( 'Editor is ready to use!', editor );
      } }
      onChange={ ( event: any, editor: any ) => {
        const data = editor.getData();
        formik.setFieldValue('description', data)
      } }
    />
    <div className={'h-20'} />
    <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button disabled={!formik.isValid} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Grid>
    </Grid>
  </form>
}

export const CreateVideoForm = () => {
  const params = QueryString.parse(document.location.search);
  const categoryId = params['?categoryId'];

  let history = useHistory();
  const [saveVideo] = useSaveVideo();

  const onSubmitForm = React.useCallback((video: Video) => {
    saveVideo({variables: {video: {...video, categoryId: categoryId, id: undefined}}}).then(() => {
      history.push(paths.videos + '/' + categoryId)
    })
  }, [categoryId]);

  return <Card>
    <Typography color={'textPrimary'} variant={'h4'}>Create video</Typography>
    <VideoForm onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + categoryId)} />
  </Card>
}

export const UpdateVideoForm = (props: RouteComponentProps<{id: string}>) => {
  const {id} = props.match.params;

  let history = useHistory();
  const [saveVideo] = useSaveVideo();
  const [getVideo, data] = useVideo();

  const initial = React.useMemo(() => {
      if (data?.data?.video) {
        return data?.data?.video;
      }
      return undefined;
  }, [data]);

  const onSubmitForm = React.useCallback((video: Video) => {
    if (initial) {
      saveVideo({variables: {video: {...video, id: initial.id}}}).then(() => {
        history.push(paths.videos + '/' + video.categoryId)
      })
    }
  }, [initial]);

  React.useEffect(() => {
    if (id) {
      getVideo({variables: {videoId: id}})
    }
  }, [id]);

  return initial ? <Card>
    <Typography color={'textPrimary'} variant={'h4'}>Update video</Typography>
    <VideoForm initial={initial} onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + initial.categoryId)} />
  </Card> : null
}
