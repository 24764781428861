import * as React from 'react';
import {DrugInfoWithGeneric} from "../../types";
import {ListItem, ListItemText, Typography} from "@material-ui/core";

interface DrugLineProps {
  drug: DrugInfoWithGeneric,
  onClick?: () => void,
}

export const DrugLine = (props: DrugLineProps) => {
  return <ListItem dense disableGutters onClick={props.onClick}>
    <ListItemText
      primary={props.drug.name}
      secondary={
        <Typography color={'textSecondary'}>{''}</Typography>
      }
    />
  </ListItem>
};