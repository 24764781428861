import React from "react";
import {CircularProgress, Grid, GridSize, Typography} from "@material-ui/core";
import Card from "../shared/Card";
import moment from "moment";
import {AgentPerformanceMetricOutput} from "../enrollment-types";
import Appointments from "../Agent/components/Appointments";
import useAppointments from "../Agent/hooks/useAppointments";

;

const CSRDashboard = (props: {data: AgentPerformanceMetricOutput[]}) => {
  const {data: appointmentsData} = useAppointments();

  const getMetric = React.useCallback(metrics(props.data || []), [props.data])

  const getColor = (val1?: number, val2?: number): string | undefined => {
    if (typeof val1 === 'undefined' || typeof val2 === 'undefined') {
      return undefined;
    } else {
      return val1 >= val2 ? '#5ecb9e' : '#c00000'
    }
  };

  return <Grid className={'mt-10'} container spacing={2} direction={'column'}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography className={'bold fs-18 w-400'} color={'textPrimary'}>Call Metrics</Typography>
        <Grid container xs={12}  direction={'column'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Outbound Calls'} values={[[{label: 'Today', value: getMetric(Metrics.outbound_call_today), color: getColor(getMetric(Metrics.outbound_call_today), getMetric(Metrics.outbound_call_mtd))}, {label: 'MTD', value: getMetric(Metrics.outbound_call_mtd)}]]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'Inbound Calls'} values={[[{label: 'Today', value: getMetric(Metrics.inbound_call_today), color: getColor(getMetric(Metrics.inbound_call_today), getMetric(Metrics.inbound_call_mtd))}, {label: 'MTD', value: getMetric(Metrics.inbound_call_mtd)}]]} />
            </Grid>
          </Grid>
          <Grid container className={'mt-8'} spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Outbound Success'} values={[[{label: 'Today', value: getMetric(Metrics.outbound_call_success_today) + '%', color: getColor(getMetric(Metrics.outbound_call_success_today), getMetric(Metrics.outbound_call_success_mtd))}, {label: 'MTD', value: getMetric(Metrics.outbound_call_success_mtd) + '%'}]]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'Average Talk Time'} values={[[{label: 'Today', value: secToMin(getMetric(Metrics.average_talk_time_today)), color: getColor(getMetric(Metrics.average_talk_time_today), getMetric(Metrics.average_talk_time_mtd))}, {label: 'MTD', value: secToMin(getMetric(Metrics.average_talk_time_mtd))}]]} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>Appointment Metrics</Typography>
        <Grid container xs={12} direction={'column'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Metric title={'Appointments Booked'} values={[
                [{label: 'Today', value: getMetric(Metrics.appointments_booked_today)}],
              ]} />
            </Grid>
            <Grid item xs={6}>
              <Metric title={'Appointments Booked'} values={[
                [{label: 'This Month', value: getMetric(Metrics.appointments_booked_mtd)}],
              ]} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item style={{marginLeft: -8, marginRight: -8, marginTop: 20}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={'bold fs-18 w-500'} color={'textPrimary'}>CoverRight Calendar</Typography>
          <div>
            <Card padding={0}>
              <Appointments appointments={appointmentsData?.appointments || []} />
            </Card>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default CSRDashboard;

const Metric = (props: {title: string, values: Value[][]}) => {
  return <Card className={'mv-16'}>
    <Typography className={'medium fs-16 mb-5'} color={'textPrimary'}>{props.title}</Typography>
    <Grid container>
      {props.values.map((valuesRow, i1) => <Grid key={i1 + 'i1'} container>
        {valuesRow.map((value, i2, array) => <Grid key={i2 + 'i2'} xs={Math.floor(12 / array.length) as GridSize} item>
          <Typography className={'fs-12'} color={'textSecondary'}>{value.label.toUpperCase()}</Typography>
          <Typography className={'bold fs-24'} style={{color: value.color}} color={'textPrimary'}>{value.value}</Typography>
        </Grid>)}
      </Grid>)}
    </Grid>
  </Card>
}

interface Value {
  color?: string
  label: string;
  value?: number | string;
}

const secToMin = (value?: number) => value ? moment.duration(value, 'seconds').format("h[h] mm[m]") : 0;

const metrics = (data: Array<AgentPerformanceMetricOutput>) => (metric: Metrics, secondaryKey: string | null = null): number => data.some(i => i.primaryKey === metric && i.secondaryKey === secondaryKey) ? Math.floor(parseFloat(data.find(i => i.primaryKey === metric && i.secondaryKey === secondaryKey)!.value)) : 0;

enum Metrics {
  inbound_call_mtd = 'inbound_call_mtd',
  inbound_call_today = 'inbound_call_today',
  average_talk_time_mtd = 'average_talk_time_mtd',
  average_talk_time_today = 'average_talk_time_today',
  outbound_call_mtd = 'outbound_call_mtd',
  outbound_call_today = 'outbound_call_today',
  outbound_call_success_mtd = 'outbound_call_success_mtd',
  outbound_call_success_today = 'outbound_call_success_today',
  appointments_booked_mtd = 'appointments_booked_mtd',
  appointments_booked_today = 'appointments_booked_today',
}