import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeletePurchasedProductArgs} from "../../enrollment-types";

export default function useDeletePurchasedProduct(options?: MutationHookOptions<any, MutationDeletePurchasedProductArgs>) {
  return useMutation<any, MutationDeletePurchasedProductArgs>(gql(deleterUserInfoMutation), options)
}

const deleterUserInfoMutation = `
mutation ($purchasedProductId: UUID!) {
  deletePurchasedProduct(purchasedProductId: $purchasedProductId)
}
`;
