import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, IconButton} from "@material-ui/core";
import Preloader from "../../shared/Preloader";
import Card from "../../shared/Card";
import {LeadSourceOutput} from "../../enrollment-types";
import moment from "moment";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useLeadSources from "../hooks/useLeadSources";
import useUpdateSources from "../hooks/useUpdateSources";

const columns: GridColDef[] = [
  { field: 'eventDate', headerName: 'Event Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'campaign', headerName: 'Campaign', width: 200 },
  { field: 'medium', headerName: 'Medium', width: 150 },
  { field: 'source', headerName: 'Source', width: 150 },
  { field: 'term', headerName: 'Term', width: 150 },
  { field: 'content', headerName: 'Content', width: 150 },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    }
  }),
);

interface LeadSourcesProps {
  leadSources?: LeadSourceOutput[];
  loading: boolean;
  clientId?: string;
}

const LeadSources = (props: LeadSourcesProps) => {
  const classes = useStyles();
  const [getLeadSources, {loading, refetch, data}] = useLeadSources({
    fetchPolicy: 'no-cache'
  });
  const [update, {loading: updateLoading}] = useUpdateSources();
  const [rows, setRows] = React.useState<LeadSourceOutput[]>([]);

  React.useEffect(() => {
    if (props.clientId) {
      getLeadSources({variables: {clientId: props.clientId}})
    }
  }, [props.clientId]);

  React.useEffect(() => {
    if (data?.clientLeadSources) {
      setRows(data.clientLeadSources)
    }
  }, [data]);

  const updateRows = () => {
    update({variables: {clientId: props.clientId}}).then(() => refetch({clientId: props.clientId}))
  }

  return <Card padding={0} className={'relative'}>
    <IconButton className={`${classes.refreshButton} ${loading ? classes.disabled : ''}`}
                disabled={loading}
                onClick={updateRows}>
      <img src={'/img/replay.svg'} />
    </IconButton>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={props.loading} />
        <div style={{ height: 300, width: '100%' }}>
          <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                    columns={columns}
                    paginationMode={'client'}
                    loading={loading || updateLoading}
                    page={0}
                    pageSize={100}
                    rowCount={rows.length}
          />
        </div>
    </Box>
  </Card>;;
}

export default LeadSources;
