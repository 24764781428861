import {Box, Button, createStyles, Dialog, makeStyles, MenuItem, Theme, Typography} from "@material-ui/core";
import React from "react";
import {colors} from "../../AppTheme";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

type FollowUpModal = {
  onClose: (date?: Date | null) => void,
  open: boolean,
  currentDate?: Date,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 500,
    },
    link: {
      color: colors.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    },
  })
);

const FollowUpModal = (props: FollowUpModal) => {
  const classes = useStyles();
  const [date, changeDate] = React.useState<Date | null>(new Date());

  React.useEffect(() => {
    props.currentDate && changeDate(props.currentDate)
  }, [props.currentDate])

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
  >
    <Box sx={{p: 3}}>
      <Typography className={'fs-20 lh-25 medium mb-24'} align={'center'}>This Follow Up date for this client has expired.  Do you want to update the Follow Up date for a future date?</Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          value={date}
          onChange={changeDate}
        />
      </MuiPickersUtilsProvider>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mt={'10px'}>
        <Button variant={'outlined'}
                className={'mr-16'}
                onClick={() => {props.onClose()}}>
          Close
        </Button>
        <Button variant={'contained'}
                color={'primary'}
                onClick={() => props.onClose(date)}>
          Save
        </Button>
      </Box>
    </Box>
  </Dialog>
}

export default FollowUpModal;
