import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {PharmacyLocationDistanceOutput} from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginBottom: theme.spacing(2),
    '& tr td:first-child': {
      textAlign: 'right',
      paddingRight: 10,
      width: '140px'
    }
  }
}));

export default function Pharmacy({pharmacy}: {pharmacy: PharmacyLocationDistanceOutput}) {
  const classes = useStyles();

  return (<div>
    {pharmacy && <table className={classes.table}>
      <tbody>
        <tr><td><b>name:</b></td><td>{pharmacy.name}</td></tr>
        <tr><td><b>address:</b></td><td>{pharmacy.address}</td></tr>
        <tr><td><b>latitude:</b></td><td>{pharmacy.latitude}</td></tr>
        <tr><td><b>longitude:</b></td><td>{pharmacy.longitude}</td></tr>
        <tr><td><b>npi:</b></td><td>{pharmacy.npi}</td></tr>
        <tr><td><b>zip:</b></td><td>{pharmacy.zip}</td></tr>
      </tbody>
    </table>}
  </div>)
}
