import {gql, useLazyQuery} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import React from "react";

const useZipCounties = (onCompleted: (data: any) => void) => {
  const [counties, setCounties] = React.useState<ICity[]>([]);
  const [getCounties] = useLazyQuery<ICityResponse, {zip: string}>(gql(cityRequest), {
    onCompleted: (data) => {
      setCounties(data.plans_zip_county_fips)
      onCompleted(data)
    },
    client: client
  });

  return {counties, getCounties, resetCounties: () => setCounties([])}
}

export default useZipCounties;



interface ICityResponse {
  plans_zip_county_fips: ICity[]
}

interface ICity {
  city: string,
  state: string,
  countyname: string
}

const cityRequest = `
  query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`