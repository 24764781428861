import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteMedigapEnrollmentArgs} from "../../enrollment-types";

export default function useDeleteMedigapEnrollment(options?: MutationHookOptions<{deleteMedigapEnrollment: string}, MutationDeleteMedigapEnrollmentArgs>) {
  return useMutation<{deleteMedigapEnrollment: string}, MutationDeleteMedigapEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($medigapEnrollmentId: UUID!) {
  deleteMedigapEnrollment(medigapEnrollmentId: $medigapEnrollmentId)
}
`;

