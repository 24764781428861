import React, {useCallback} from "react";
import Card from "../../../shared/Card";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@material-ui/core";
import {useFormik} from "formik";
import TextInput from "../../../shared/TextInput";
import {useSnackbar, VariantType} from "notistack";
import {GraphQLErrorType} from "../../../Apollo/EnrollmentApolloClient";
import {ClientNoteOutput, ClientViewOutput} from "../../../enrollment-types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import * as _ from 'lodash';
import useCreateClientNote from "./hooks/useCreateClientNote";
import useUpdateClientNote from "./hooks/useUpdateClientNote";
import {ApolloError} from "@apollo/client";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDeleteClientNote from "./hooks/useDeleteClientNote";
import moment from "moment";

interface NotesProps {
  client?: ClientViewOutput
}

const Notes = ({client}: NotesProps) => {
  const [notes, setNotes] = React.useState<Array<ClientNoteOutput | undefined>>([]);

  React.useEffect(() => {
    setNotes(client?.notes || [])
  }, [client?.notes])

  if (!client) {
    return null;
  }

  return <>
    <div className={'flex flex-align-center mb-10'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mr-10"}>Notes</Typography>
      <IconButton size={'small'} onClick={() => setNotes(prevState => [undefined, ..._.compact(prevState)])}><img src={'/img/add-icon.svg'}/></IconButton>
    </div>
    {notes.map((note, index) => <>
      {!note && <Box mb={2}>
        <Note note={note} clientId={client.id} showFormInit={!note} onCancel={() => {
          if (!note) {
            setNotes(prevState => _.compact(prevState))
          }
        }} />
      </Box>}
      {!!note && <Accordion key={index + note?.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography color={"textPrimary"} className={"fs-14 lh-14 medium mr-10"}>{note?.createdByAgent?.firstName + ' ' + note?.createdByAgent?.lastName}</Typography>
          <Typography color={"textSecondary"} className={'fs-12 lh-14'}>{moment(note?.updatedAt).format('L H:mm:ss')}</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Note note={note} clientId={client.id} />
        </AccordionDetails>
      </Accordion>}
    </>)}
  </>
};

export default Notes;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      whiteSpace: 'pre-line'
    },
    input: {
      backgroundColor: 'white'
    }
  }),
);

const Note = ({note, clientId, showFormInit, onCancel}: {note?: ClientNoteOutput, clientId: string, showFormInit?: boolean, onCancel?: () => void}) => {
  const classes = useStyles();
  const [showForm, setShowForm] = React.useState(showFormInit == true);
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const onError = (error: ApolloError) => {
    let text;
    switch (error.graphQLErrors[0]?.extensions?.type) {
      case GraphQLErrorType.NO_CONNECTION:
        text = "Service is not available";
        break;
      default:
        text = "Unknown error"
    }
    enqueueSnackbar(text, {variant: "error"});
  }

  const onCompleted = (text: string, variant: VariantType) => () => {
    enqueueSnackbar(text, {variant});
    setShowForm(false);
  }

  const [create, {loading: createLoading}] = useCreateClientNote({onError, onCompleted: onCompleted('Note created!', "success")});
  const [update, {loading: updateLoading}] = useUpdateClientNote({onError, onCompleted: onCompleted('Note saved!', "success")});
  const [deleteNote] = useDeleteClientNote({onError, onCompleted: onCompleted('Note deleted!', "info")});

  const save = React.useCallback(async (text: string) => {
    if (!note) {
      await create({
        variables: {
          clientId: clientId,
          note: text
        }
      })
    } else {
      await update({
        variables: {
          noteId: note.id,
          note: text
        }
      })
    }
  }, [note, clientId])

  const cancel = useCallback(() => {
    setShowForm(false);
    onCancel && onCancel();
  }, [setShowForm]);

  const submit = useCallback((text: string) => {
    if (text) {
      save(text)
    } else {
      cancel()
    }
  }, [clientId]);

  const loading = React.useMemo(() => {
    return createLoading || updateLoading;
  }, [createLoading, updateLoading])

  const handleClose = React.useCallback((res: boolean) => {
    if (res && note) {
      deleteNote({variables: {noteId: note.id}});
    }
    setOpen(false);
  }, [note])

  return <>
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>Are you sure you want to delete this note?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will not able to restore it later!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Box color={'red'}>
          <Button onClick={() => handleClose(true)} color="inherit">
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
    {!showForm && <Box position={'relative'} width={'100%'}>
      <Box position={'absolute'} right={25} top={-26}>
        <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>
      </Box>
      <Box position={'absolute'} right={-4} top={-27}>
        <IconButton size={'small'} onClick={() => setOpen(true)}><Box padding={'6px'}><img src={'/img/delete-icon.svg'}/></Box></IconButton>
      </Box>
      <Typography className={classes.note} variant={'body1'} color={'textPrimary'}>{note?.note || ''}</Typography>
    </Box>}
    {showForm && <NoteForm initialNote={note?.note || ''} loading={loading} onCancel={cancel} onSubmit={submit}/>}
  </>
}

interface NoteFormProps {
  initialNote: string;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (note: string) => void;
}

const NoteForm = (props: NoteFormProps) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      note: props.initialNote
    },
    onSubmit: values => props.onSubmit(values.note)
  });

  return <form onSubmit={formik.handleSubmit} className={'fill-width'}>
    <TextInput
      classes={{
        root: classes.input
      }}
      fullWidth
      multiline
      rows={20}
      name="note"
      label="Agent comments"
      className={'mb-15'}
      value={formik.values.note}
      onChange={formik.handleChange}
      error={formik.touched.note && Boolean(formik.errors.note)}
      helperText={formik.touched.note && formik.errors.note}
    />
    <Grid container alignItems={"center"} justify={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Grid>
    </Grid>
  </form>
};

