import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSavePurchasedProductArgs} from "../../enrollment-types";

export default function useSavePurchasedProduct(options?: MutationHookOptions<any, MutationSavePurchasedProductArgs>) {
  return useMutation<any, MutationSavePurchasedProductArgs>(gql(saveUserInfoMutation), options)
}

const saveUserInfoMutation = `
mutation ($input: PurchasedProductInput!) {
  savePurchasedProduct(input: $input)
}
`;
