import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveMedigapEnrollmentArgs} from "../../enrollment-types";

export default function useSaveMedigapEnrollment(options?: MutationHookOptions<{saveMedigapEnrollment: string}, MutationSaveMedigapEnrollmentArgs>) {
  return useMutation<{saveMedigapEnrollment: string}, MutationSaveMedigapEnrollmentArgs>(gql(save), options)
}

const save = `
mutation ($input: MedigapEnrollmentInput!) {
  saveMedigapEnrollment(input: $input)
}
`;

