import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../Apollo/ApolloClient";
import {MutationSaveVideoArgs} from "../../content-management";

export default function useSaveVideo(options?: MutationHookOptions<any, MutationSaveVideoArgs>) {
  return useMutation<any, MutationSaveVideoArgs>(gql(saveVideoMutation), {
    ...options,
    client
  })
}

const saveVideoMutation = `
mutation ($video: VideoInput!) {
  saveVideo(video: $video)
}
`;
