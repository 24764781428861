import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {DrugOutput} from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginBottom: theme.spacing(2),
    '& tr td:first-child': {
      textAlign: 'right',
      paddingRight: 10,
      width: '140px'
    }
  }
}));

export default function Drug({drug}: {drug: DrugOutput}) {
  const classes = useStyles();

  return (<div>
    {drug && <table className={classes.table}>
      <tbody>
        <tr><td><b>proprietary name:</b></td><td>{drug.name}</td></tr>
        <tr><td><b>dosage:</b></td><td>{drug.packName}</td></tr>
        <tr><td><b>frequency:</b></td><td>{drug.frequency}</td></tr>
        <tr><td><b>quantity:</b></td><td>{drug.quantity}</td></tr>
      </tbody>
    </table>}
  </div>)
}
