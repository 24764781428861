import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";

export default function useEnrollmentsImportStates(options?: any) {
  return useLazyQuery(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($pageInput: PageInput!) {
  enrollmentsImportStates(pageInput: $pageInput) {
    data {
      applicationDate
      birthDate
      carrier
      clientId
      countyName
      createdAt
      effectiveDate
      email
      errorMessage
      firstName
      gender
      id
      importStatus
      lastName
      maBidId
      mgPlanName
      pdpBidId
      phoneNumber
      product
      status
      terminationDate
      tobacco
      updatedAt
      zip
    }
    totalElements
  }
}
`
