import React from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import paths from "../router-paths";
import Preloader from "./Preloader";
import {config} from "../config/config";

declare let Keycloak: any;

export const keycloak = Keycloak(config.keycloak);

export enum KeycloakRoles {
  ROLE_CORPORATE_USER = 'ROLE_CORPORATE_USER',
  ROLE_HR = 'ROLE_HR',
  ROLE_PUBLIC_USER = 'ROLE_PUBLIC_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_TECH_ADMIN = 'ROLE_TECH_ADMIN',
  ROLE_AGENT_MANAGER = 'ROLE_AGENT_MANAGER',
  ROLE_CSR = 'ROLE_CSR',
}

export const hasKeycloakRole = (role: KeycloakRoles): boolean => {
  const userRoles: KeycloakRoles[] = keycloak?.tokenParsed?.realm_access?.roles || [];
  return userRoles.some(value => value === role);
}

export const getDefaultRole = (role: KeycloakRoles | KeycloakRoles[]): string | undefined => {
  const userRoles: KeycloakRoles[] = keycloak?.tokenParsed?.realm_access?.roles;
  const getRole = (role: KeycloakRoles) => userRoles.find(value => value === role);
  if (userRoles) {
    if (Array.isArray(role)) {
      for (const curr of role) {
        const found = getRole(curr);
        if (found) {
          return found;
        }
      }
    } else {
      return getRole(role);
    }
  }
  return undefined;
}

export function useUserId(): string | undefined {
  return keycloak?.tokenParsed?.sub;
}

export const withKeycloak = (WrappedComponent: any) => (props: any) => {
  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const [authtorized, setAuthtorized] = React.useState<boolean>();
  React.useEffect(() => {
    keycloak.init({onLoad: 'login-required'}).success((authenticated: boolean) => {
      console.log(keycloak);
      setAuthenticated(authenticated);
      const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
      setAuthtorized(roles.includes('ROLE_ADMIN') || roles.includes('ROLE_TECH_STAFF') || roles.includes('ROLE_CSR'));
    })
  }, []);

  // todo create component to failed authentication
  // todo create component to initialization

  let component = null;

  if (keycloak) {
    if (authenticated && authtorized) {
      component = <WrappedComponent {...{...props, keycloak}}/>;
    } else if (authenticated && !authtorized) {
      component = <Logout />;
    } else {
      component = <Preloader/>;
    }
  } else {
    component = <Preloader/>;
  }

  return component;
}


const Logout = () => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1} flexDirection={'column'}>
      <Box mb={2}>
        <Typography variant={'h3'}>You are not authorized</Typography>
      </Box>
      <Button size={'medium'} variant={'contained'} color={'primary'} onClick={() => keycloak.logout({redirectUri: document.location.origin})}>Logout</Button>
    </Box>
  )
}
