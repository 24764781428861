import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {client} from "../../../Apollo/ApolloClient";
import {DrugPackageOutput, QueryPackageDescriptionByRxcuiArgs} from "../../../types";

export  function useGetPackagesDescription() {
  return useLazyQuery<{packageDescriptionByRxcui: DrugPackageOutput}, QueryPackageDescriptionByRxcuiArgs>(
    gql(getPackageRequest),
    {
      fetchPolicy: 'no-cache',
      client,
    }
  );
}

const getPackageRequest = `
query ($rxcui: String!) {
  packageDescriptionByRxcui(rxcui: $rxcui) {
    packageDescription
    unitType
  }
}
`;
